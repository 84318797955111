import { HeaderButton } from "@tele2/t2-ui-kit";
import CartIcon from "@tele2/t2-ui-kit/build/Icon/icons/ShoppingCartOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useActiveCart from "../../../api/self-service/cart/useActiveCart";
import MiniCartButtonWrapper from "./MiniCartButtonWrapper";

const MiniCartButton: React.FC = () => {
  const { t } = useTranslation();
  const { data: activeCart, isLoading } = useActiveCart();
  if (!(activeCart && activeCart.salesProjectId > 0)) {
    return (
      <HeaderButton
        data-cy={"cart-button"}
        aria-label={t("module.self.service.cart.view")}
        disabled={true}
      >
        <CartIcon />
      </HeaderButton>
    );
  }
  return <MiniCartButtonWrapper activeCart={activeCart} disable={isLoading} />;
};
export default MiniCartButton;
