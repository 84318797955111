const getEnv = (): string => {
  if (window.location.origin.includes("dev")) {
    return "dev";
  }
  if (window.location.origin.includes("stage")) {
    return "stage";
  }
  if (
    window.location.origin.includes("local") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return "local";
  }
  if (window.location.origin.includes("test")) {
    return "dev";
  }
  return "prod";
};

export default getEnv;
