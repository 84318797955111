import { Button } from "@tele2/t2-ui-kit";
import { CartActionPermissions } from "../../../../api/self-service/checkout/useSalesProjectSummaryValidation";
import { SelfServiceMode } from "../../../../api/self-service/types";

import useInvalidateCheckoutData from "../../../../api/self-service/useInvalidateCheckoutData";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CONST_URL_SELF_SERVICE_CHECKOUT_CART } from "../../../../Routes/approutes";
import styled from "styled-components";
import useIsEndUserPortal from "../../../../utils/useIsEndUserPortal";
import { useTranslation } from "react-i18next";

interface ButtonProps {
  classes?: string[];
  click?: () => Promise<void> | void;
  danger?: boolean;
  dontClose?: boolean;
  invalidate?: boolean;
  secondary?: boolean;
  text: string;
}

const ButtonWrapper = styled.div`
  border-top: 1px solid #eee;
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  > button {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    > button {
      margin-top: 8px;
    }
  }
`;

interface CardButtonFooterProps {
  permissions: CartActionPermissions;
  salesProjectId: number;
  onClose?: () => void;
  setShowConfirmOrderModal: (value: boolean) => void;
  setShowConfirmDeleteCartModal: (value: boolean) => void;
  setShowSendProjectToOrderApproval: (value: boolean) => void;
  setShowRevertProjectToDraftModal: (value: boolean) => void;
}

const MiniCartModalFooter: React.FC<CardButtonFooterProps> = ({
  permissions,
  salesProjectId,
  setShowConfirmOrderModal,
  setShowConfirmDeleteCartModal,
  setShowSendProjectToOrderApproval,
  setShowRevertProjectToDraftModal,
  onClose,
}) => {
  const { t } = useTranslation();
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const isEndUserPortal = useIsEndUserPortal();
  const navigate = useNavigate();

  const mode = isEndUserPortal
    ? SelfServiceMode.END_USER
    : SelfServiceMode.CARTS;

  const buttons = [];
  if (permissions.cancelProject) {
    buttons.push({
      click: () => {
        setShowConfirmDeleteCartModal?.(true);
      },
      dontClose: true,
      invalidate: true,
      secondary: true,
      text: t("module.self.service.cancel"),
    });
  }

  if (permissions.placeOrder && isEndUserPortal) {
    buttons.push({
      click: () => {
        setShowConfirmOrderModal(true);
      },
      dontClose: true,
      text: isEndUserPortal
        ? t("module.subscription.end_users.send.cart")
        : t("module.self.service.send.order"),
    });
  }

  if (permissions.sendProjectToOrderApproval && isEndUserPortal) {
    buttons.push({
      click: () => {
        setShowSendProjectToOrderApproval(true);
      },
      dontClose: true,
      text: t("module.self.service.send.to.approval"),
    });
  }

  if (permissions.revertProjectToDraft && isEndUserPortal) {
    buttons.push({
      click: () => {
        setShowRevertProjectToDraftModal(true);
      },
      danger: true,
      text: t("module.self.service.reject.offer"),
    });
  }

  if (mode === SelfServiceMode.CARTS) {
    buttons.push({
      click: () =>
        navigate(
          `${CONST_URL_SELF_SERVICE_CHECKOUT_CART}?salesProjectId=${salesProjectId}`
        ),
      text: t("module.self.service.go.to.cart"),
    });
  }

  return (
    <ButtonWrapper>
      {buttons.map((b: ButtonProps, key: number) => {
        return (
          <React.Fragment key={key}>
            <Button
              md
              {...(b.secondary && { secondary: true })}
              {...(b.danger && { danger: true })}
              onClick={async (
                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                if (b.click) {
                  e.preventDefault();
                  await b.click();
                }
                if (b.invalidate) {
                  invalidateCheckoutData();
                }
                if (!b.dontClose) {
                  onClose?.();
                }
              }}
            >
              {b.text}
            </Button>
          </React.Fragment>
        );
      })}
    </ButtonWrapper>
  );
};

export default MiniCartModalFooter;
