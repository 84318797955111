import { Button } from "@tele2/t2-ui-kit";
import ModalBody from "@tele2/t2-ui-kit/build/Modal/ModalBody";
import useCancelProject from "../../../../api/self-service/cancel-project/useCancelProject";

import ModalFooter from "../../../../components/modal/ModalFooter";
import ReactModal from "../../../../components/modal/ReactModal";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
  CONST_ROUTE_END_USER,
  CONST_URL_SELF_SERVICE_INDEX,
} from "../../../approutes";
import useIsEndUserPortal from "../../../../utils/useIsEndUserPortal";
import { useTranslation } from "react-i18next";

type DeleteCartModalProps = {
  onClose: () => void;
  onCloseParent?: () => void;
  salesProjectId: number;
};

const DeleteCartModal: React.FC<DeleteCartModalProps> = ({
  onClose,
  onCloseParent,
  salesProjectId,
}) => {
  const { t } = useTranslation();
  const isEndUserPortal = useIsEndUserPortal();
  const navigate = useNavigate();

  const { cancelProject } = useCancelProject();

  return (
    <ReactModal onClose={onClose} title={t("general.confirm")}>
      <ModalBody>
        <div>{t("module.self.service.cancel.order.confirm")}</div>
      </ModalBody>
      <ModalFooter>
        <Button secondary onClick={onClose}>
          {t("general.close")}
        </Button>
        <Button
          data-testid={"delete cart"}
          onClick={async () => {
            await cancelProject(salesProjectId);
            onClose();
            onCloseParent?.();
            navigate(
              isEndUserPortal
                ? CONST_ROUTE_END_USER
                : CONST_URL_SELF_SERVICE_INDEX
            );
          }}
        >
          {t("module.self.service.cancel")}
        </Button>
      </ModalFooter>
    </ReactModal>
  );
};

export default DeleteCartModal;
