import { color, Text, TextInput } from "@tele2/t2-ui-kit";
import List from "@tele2/t2-ui-kit/build/Icon/icons/List";
import Tune from "@tele2/t2-ui-kit/build/Icon/icons/Tune";
import { useModuleReadRight } from "../../../../api/session/useModuleRight";
import * as React from "react";
import { Link } from "react-router-dom";
import { SelfServiceRenderMode } from "../product-catalog-type";
import {
  DropDownValue,
  ROOT_DEFAULT,
  StyledDropDown,
} from "../ProductCatalogContainer";
import {
  useBaseSubsId,
  useCatalogBaseServices,
  useNodeKeyState,
  useProductSearch,
  useSelfServiceMode,
} from "../ProductCatalogContext";
import ProductCatalogTreeView from "../ProductCatalogTreeView";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledTuneIcon = styled(Tune)`
  margin-right: 5px;
  vertical-align: bottom;
`;

const StyledCategoryIcon = styled(List)`
  margin-right: 5px;
  vertical-align: bottom;
`;
const StyledSearchBox = styled(TextInput)`
  border: ${color.BRAND_PREMIUM_GREY} 1px solid;
  border-radius: 4px;
  margin-top: 15px;
`;
const TextWrapper = styled.div`
  margin: 5px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`;

export const StyledAdditionalSubsInfo = styled(Text)`
  display: block;
`;

const AccordionWrapper = styled.div`
  background-color: ${color.BRAND_WHITE};
  border: ${color.BRAND_PREMIUM_GREY} 1px solid;
  border-radius: 4px;
  > .t2-accordion {
    border: none;
  }
`;

const Filter: React.FC = () => {
  const { t } = useTranslation();
  const serviceRenderMode = useSelfServiceMode();
  const isEndUser = serviceRenderMode === SelfServiceRenderMode.END_USER;
  const [baseSubsId, setBaseSubsId] = useBaseSubsId();
  const hasEndUserOrder = useModuleReadRight("ORDERS_END_USERS");
  const [search, setSearch] = useProductSearch();
  const [, setNodeKey] = useNodeKeyState();
  const availableBaseService = useCatalogBaseServices();
  const handleBaseSubsSelect = (value: unknown) => {
    setBaseSubsId(Number(value));
    setSearch("");
    setNodeKey(ROOT_DEFAULT);
  };

  const baseServiceArray: DropDownValue[] = React.useMemo(() => {
    if (availableBaseService) {
      return availableBaseService
        .filter((s) => s.canOrder || hasEndUserOrder)
        .map((item) => {
          return {
            dropdownLabel: (
              <>
                {item.name}
                <StyledAdditionalSubsInfo md light>
                  {item.alias
                    ? item.alias
                    : t("general.subs.id.arg", [item.subsId])}
                </StyledAdditionalSubsInfo>
              </>
            ),
            option: item.subsId,
          };
        });
    }
    return [];
  }, [availableBaseService, hasEndUserOrder, t]);

  const findOptionByValue = (value: unknown, list?: DropDownValue[]) => {
    return list?.find((item) => {
      return item.option === value;
    });
  };

  const selectedBaseSubsId = React.useMemo(
    () => findOptionByValue(baseSubsId, baseServiceArray),
    [baseServiceArray, baseSubsId]
  );
  return (
    <>
      {baseServiceArray.length > 0 ? (
        <>
          <TextWrapper>
            <div>
              <StyledCategoryIcon />
              {t("module.subscription.product_catalog")}
            </div>
          </TextWrapper>
          <StyledDropDown
            options={baseServiceArray}
            data-testid="baseservices"
            id="baseServices"
            data-cy="baseServices"
            onSelect={handleBaseSubsSelect}
            selectedOption={selectedBaseSubsId}
            widthCss="100%"
          />
          {!isEndUser && (
            <TextWrapper>
              <div>
                <Text md>
                  {t("module.self.service.make_changes")}{" "}
                  <Link to={`/subscription/${baseSubsId}/accounts`}>
                    {t("module.self.service.make_changes.click_here")}
                  </Link>
                </Text>
              </div>
            </TextWrapper>
          )}
          <StyledSearchBox
            isSearch
            type="search"
            role="search"
            id="searchBox"
            data-cy="searchBox"
            data-testid="searchBox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            placeholder={t("general.search")}
            block={true}
            value={search}
            label=""
          />
          <TextWrapper>
            <div>
              <StyledTuneIcon />
              {t("general.filter")}
            </div>
          </TextWrapper>

          <AccordionWrapper>
            <ProductCatalogTreeView />
          </AccordionWrapper>
        </>
      ) : (
        <>
          <TextWrapper>{t("module.self.service.order.incomplete")}</TextWrapper>
        </>
      )}
    </>
  );
};

export default Filter;
