import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useConnectContact from "../../../../api/self-service/connect-contact/useConnectContact";
import useContacts from "../../../../api/self-service/contacts/useContacts";
import useDisconnectContact from "../../../../api/self-service/disconnect-contact/useDisconnectContact";
import Block from "../../../../components/Block";
import LinkStyleButton from "../../../../components/Buttons/LinkStyleButton";

interface ContactProps {
  oppRowId: number;
  salesProjectId: number;
}

const Contact: React.FC<ContactProps> = ({ oppRowId, salesProjectId }) => {
  const { t } = useTranslation();
  const { data: contactModel } = useContacts({ oppRowId, salesProjectId });
  const { connect, isPending: connecting } = useConnectContact();
  const { disconnect, isPending: disconnecting } = useDisconnectContact();
  const [selectedContact, setSelectedContact] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);

  const connectContact = async () => {
    if (selectedRole && selectedContact) {
      await connect({
        oppRowId,
        salesProjectId,
        contactId: selectedContact,
        roleId: selectedRole,
      });
      setSelectedRole(0);
      setSelectedContact(0);
    }
  };

  const disconnectContact = async (contactId: number) => {
    if (contactId) {
      await disconnect({ contactId, salesProjectId, oppRowId });
    }
  };
  if (connecting || disconnecting) {
    return <Block />;
  }
  if (!contactModel) {
    return null;
  }
  const {
    contactsEditable,
    availableContacts,
    roleOptions,
    configuredContacts,
  } = contactModel;
  return (
    <div className="card">
      <div className="card-body">
        {contactsEditable && (
          <div className="opp-add-contact-box">
            <div className="form-group">
              <label>
                {t("module.self.service.contact")}{" "}
                <span className="input-required">*</span>
              </label>
              <select
                className="form-control attr-value"
                name="contactId"
                value={selectedContact}
                onChange={(e) => {
                  setSelectedContact(Number(e.target.value));
                }}
              >
                <option value="0">{t("general.choose")}</option>
                {availableContacts &&
                  availableContacts.map(
                    ({ contactId, firstName, lastName }) => (
                      <option key={contactId} value={contactId}>{`${
                        firstName || ""
                      } ${lastName || ""}`}</option>
                    )
                  )}
              </select>
            </div>

            <div className="form-group">
              <span className="attr-label">
                {t("module.self.service.role")}
              </span>
              <select
                className="form-control attr-value"
                name="roleId"
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(Number(e.target.value));
                }}
              >
                <option value="0">{t("general.choose")}</option>
                {roleOptions &&
                  roleOptions.map(({ id, description }) => (
                    <option key={id} value={id}>
                      {description}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className="form-group"
              style={{ marginTop: "8px", display: "inline-block" }}
            >
              <button
                type="button"
                className="btn btn-secondary add-contact"
                style={{ marginRight: "8px" }}
                onClick={() => connectContact()}
              >
                {t("general.add")}
              </button>
              <Link to="/contact/customer-contact">
                {t("module.contact.contacts")} &raquo;
              </Link>
            </div>
          </div>
        )}
        {configuredContacts && configuredContacts.length > 0 && (
          <>
            <table className="table table-sm table-hover contacts">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("general.name")}</th>
                  <th>{t("general.email")}</th>
                  <th>{t("general.phone")}</th>
                  <th>{t("general.mobile")}</th>
                  <th>{t("general.role")}</th>
                </tr>
              </thead>
              <tbody>
                {configuredContacts.map(
                  ({
                    visibleOnAllSubs,
                    firstName,
                    lastName,
                    email,
                    phone,
                    mobile,
                    role,
                    contactId,
                  }) => (
                    <tr key={`${contactId}-${role}`}>
                      <td>
                        {visibleOnAllSubs && (
                          <span
                            className="fa fa-lock"
                            title={t("general.global.contact")}
                          />
                        )}
                        {!visibleOnAllSubs && contactsEditable && (
                          <LinkStyleButton
                            className="delete-contact"
                            title={t("general.contact.remove.from.subs")}
                            onClick={() => {
                              disconnectContact(contactId);
                            }}
                          >
                            <span className="fa fa-trash" />
                          </LinkStyleButton>
                        )}
                      </td>
                      <td>
                        {firstName} {lastName}
                      </td>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td>{mobile}</td>
                      <td>{role}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};
export default Contact;
