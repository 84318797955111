import * as React from "react";
import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_MAINTENANCE_CASES,
  CONST_ROUTE_MAINTENANCE_CREATE_CASE,
  CONST_ROUTE_MAINTENANCE_CREATE_CASE_CONFIRM,
  CONST_ROUTE_MAINTENANCE_NET_STATUS,
  CONST_ROUTE_MAINTENANCE_NET_STATUS_HISTORY,
  CONST_ROUTE_MAINTENANCE_OVERVIEW,
  CONST_ROUTE_MAINTENANCE_PLANNED_WORKS,
  CONST_ROUTE_MAINTENANCE_SERVICE_CLOUD,
  CONST_ROUTE_MAINTENANCE_TICKET,
} from "../approutes";

const ServiceCloudTickets = React.lazy(
  () => import("./ServiceCloud/ServiceCloudTickets")
);
const Ticket = React.lazy(() => import("./Ticket/Ticket"));
const Cases = React.lazy(() => import("./Cases"));
const NetStatus = React.lazy(() => import("./NetStatus/NetStatus"));
const NetStatusHistory = React.lazy(
  () => import("./NetStatus/NetStatusHistory")
);
const Overview = React.lazy(() => import("./Overview"));
const PlannedWorks = React.lazy(() => import("./PlannedWorks"));
const CreateCase = React.lazy(() => import("./CreateCase/CreateCase"));
const CreateCaseConfirm = React.lazy(
  () => import("./CreateCase/CreateCaseConfirm")
);

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_MAINTENANCE_PLANNED_WORKS,
    component: PlannedWorks,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_NET_STATUS,
    component: NetStatus,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_NET_STATUS_HISTORY,
    component: NetStatusHistory,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_SERVICE_CLOUD,
    component: ServiceCloudTickets,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_CASES,
    component: Cases,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_TICKET,
    component: Ticket,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_CREATE_CASE,
    component: CreateCase,
  },
  {
    path: CONST_ROUTE_MAINTENANCE_CREATE_CASE_CONFIRM,
    component: CreateCaseConfirm,
  },
];

const ReactRoute = () => {
  /*
    TODO: MST Maintenance overview with new design, hidden for now due to canceled project
    routes.push({
      path: CONST_ROUTE_MAINTENANCE_OVERVIEW,
      component: MstOverview,
    }); */
  routes.push({
    path: CONST_ROUTE_MAINTENANCE_OVERVIEW,
    component: Overview,
  });
  return routes;
};

export default ReactRoute;
