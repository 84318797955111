import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";
import useSaveAttribute from "../../../api/self-service/save-attribute/useSaveAttribute";
import useSelectBoxOptions, {
  IAttributeOption,
} from "../../../api/self-service/select-box-options/useSelectBoxOptions";
import useUpdateMatrixRow from "../../../api/self-service/update-matrix-row/useUpdateMatrixRow";
import useDebounceValue from "../../../utils/useDebounceValue";
import useDetectOutsideClick from "../../../utils/useDetectOutsideClick";
import {
  useComponentsAttributes,
  useUpdateAllSlaveAttributes,
  useUpdateOverviewAttribute,
} from "../../component/ComponentsContext";
import DropdownTable from "../../dropdown/DropdownTable";
import ReactDropDown from "../../dropdown/ReactDropDown";
import { AttributeProps } from "../AttributeRow";
import Undo from "../Undo";

const SelectAttribute: React.FC<AttributeProps> = ({
  attrData,
  relatedAttrData,
  compAttrId,
  matrixRowId,
  subsId,
  prodCompAttrId,
  isMatrixCell,
  required,
  size,
  hash,
  hiddenCompAttrIds,
  readOnly,
  label,
  oppRowId,
  overViewAttribute,
  prodCompId,
  parentProdCompAttrId,
}) => {
  const { subRoleType } = useComponentsAttributes();
  const containerRef = React.useRef(null);
  const attrDataString = attrData || "";
  const [inputValue, setInputValue] = React.useState(attrDataString);
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(true);
  const [ignoreFilter, setIgnoreFilter] = React.useState(true);
  const [showDropdown, setShowDropdown] = React.useState<boolean | undefined>(
    undefined
  );
  const [valueToSave, setValueToSave] = React.useState<string | null>(null);
  React.useEffect(() => {
    setInputValue(attrData || "");
  }, [attrData]);
  useDetectOutsideClick(containerRef, () =>
    setShowDropdown((prevState) => {
      // To prevent save on initial outside click
      if (!prevState) {
        return undefined;
      }
      return false;
    })
  );
  const debounceValue = useDebounceValue<string>(inputValue, 1000);
  const { data, isLoading: loadingData } = useSelectBoxOptions(
    {
      compAttrId,
      filter: ignoreFilter ? "" : debounceValue,
      matrixRowId,
      prodCompAttrId,
      subsId,
      subRoleType,
    },
    Boolean(showDropdown)
  );
  const { saveAttribute, isPending: isSavingAttribute } =
    useSaveAttribute(label);
  const { updateMatrixRow, isPending: isSavingMatrixAttribute } =
    useUpdateMatrixRow(label, parentProdCompAttrId);
  const updateOverviewAttribute = useUpdateOverviewAttribute();
  const updateAllSlaveAttributes = useUpdateAllSlaveAttributes(label);
  const saveValue = React.useCallback(
    async (newValue: string) => {
      if (readOnly) {
        return;
      }
      if (showDropdown) {
        return;
      }
      if (savedSuccessfully && newValue === attrDataString) {
        return;
      }
      if (isMatrixCell) {
        updateMatrixRow({
          data: newValue,
          matrixRowId,
          subsId,
          compAttrId,
          oppRowId,
          prodCompId,
        });
      } else {
        const response = await saveAttribute({
          value: newValue,
          hash,
          compAttrId,
          prodCompAttrId,
          hiddenCompAttrIds,
          subsId,
          prodCompId,
        });

        const validSave = response.validationCode === "OK";
        setSavedSuccessfully(validSave);
        if (overViewAttribute) {
          updateOverviewAttribute(subsId, prodCompId, prodCompAttrId, response);
          if (validSave) {
            // If this is an overview field, also update slave fields
            await updateAllSlaveAttributes({
              value: newValue,
              hash,
              compAttrId,
              prodCompAttrId,
              hiddenCompAttrIds,
              subsId,
              prodCompId,
            });
          }
        }
      }
    },
    [
      attrDataString,
      compAttrId,
      hash,
      hiddenCompAttrIds,
      isMatrixCell,
      matrixRowId,
      oppRowId,
      overViewAttribute,
      prodCompAttrId,
      prodCompId,
      readOnly,
      saveAttribute,
      savedSuccessfully,
      showDropdown,
      subsId,
      updateAllSlaveAttributes,
      updateMatrixRow,
      updateOverviewAttribute,
    ]
  );
  // The debounced save is needed since we save on onBlur for the input field and onSelect for the dropdown.
  // If the user has written in the input field and then selecting from the dropdown the onBlur is triggered
  // before the onSelect causing a double save if not debounced.
  // Also do not hide the dropdown on blur. This will make the onSelect not trigger.
  React.useEffect(() => {
    if (valueToSave !== null) {
      const handler = setTimeout(() => {
        saveValue(valueToSave);
        setValueToSave(null);
      }, 200);
      return () => {
        clearTimeout(handler);
      };
    }
    return undefined;
  }, [saveValue, valueToSave]);
  React.useEffect(() => {
    if (showDropdown === false) {
      setValueToSave(inputValue);
    }
  }, [inputValue, showDropdown]);
  const inputClasses = classNames(
    "form-control",
    "attr-dynamic-select",
    isMatrixCell ? "attr-matrix-value" : "attr-value",
    required ? "attr-required" : "",
    attrDataString !== inputValue ? "attr-unsaved-change" : ""
  );
  const iAttributeOptions = data?.options || [];
  const columns = data?.columns || [];
  const isTableDropdown = columns.length > 0;
  const id = `attr_${subsId}_${prodCompAttrId}`;
  const rows = loadingData ? optionsWhileLoading : iAttributeOptions;
  const saveOnSelect = (a: IAttributeOption) => {
    setInputValue(a.value);
    setShowDropdown(false);
    setValueToSave(a.value);
  };
  const saveOnBlur = () => {
    setValueToSave(inputValue);
  };
  return !readOnly ? (
    <>
      <div className="form-group" ref={containerRef}>
        <div className="input-group">
          {showDropdown && !isTableDropdown && (
            <ReactDropDown<IAttributeOption>
              format={(option) => (
                <>
                  {getPrefix(option)}
                  {option.value}
                  {getSuffix(option)}
                </>
              )}
              rows={rows}
              onSelect={saveOnSelect}
            />
          )}
          {showDropdown && isTableDropdown && (
            <DropdownTableWrapper className={"dropdown-menu combo-box-menu"}>
              <DropdownTable<IAttributeOption>
                columns={columns}
                rows={rows}
                onSelect={saveOnSelect}
                format={(row) => {
                  if (row.values && Object.keys(row.values).length > 0) {
                    return columns.map((column, i) => (
                      <td key={column.id}>
                        {i === 0 ? getPrefix(row) : ""}
                        {row.values?.[column.id]}
                        {i === columns.length - 1 ? getSuffix(row) : ""}
                      </td>
                    ));
                  }
                  return (
                    <td colSpan={columns.length}>
                      {getPrefix(row)}
                      {row.value}
                      {getSuffix(row)}
                    </td>
                  );
                }}
              />
            </DropdownTableWrapper>
          )}
          <input
            data-cy={prodCompAttrId}
            onFocus={() => setShowDropdown(true)}
            type="text"
            value={inputValue}
            onChange={(e) => {
              setIgnoreFilter(false);
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                setShowDropdown(false);
              }
            }}
            onBlur={saveOnBlur}
            className={inputClasses}
            id={id}
            name={id}
            autoComplete="off"
            size={size || 20}
            disabled={isSavingAttribute || isSavingMatrixAttribute}
          />
          <span className="combo-box-btn input-group-append">
            <button
              tabIndex={-1}
              className="btn btn-secondary dropdown-toggle"
              disabled={isSavingAttribute || isSavingMatrixAttribute}
              type="button"
              onClick={() => {
                setIgnoreFilter(true);
                setShowDropdown(!showDropdown);
              }}
            >
              {loadingData && (
                <i className="combo-box-icon fa fa-spinner fa-spin" />
              )}
            </button>
          </span>
        </div>
      </div>
      <Undo
        relatedAttrData={relatedAttrData}
        attrData={attrData}
        onClick={() => {
          if (relatedAttrData) {
            setInputValue(relatedAttrData);
            saveValue(relatedAttrData);
          }
        }}
      />
    </>
  ) : (
    <>{attrData}</>
  );
};

export default SelectAttribute;

const getPrefix = (option: IAttributeOption) => {
  return option.level > 0 ? (
    <i className={`tree-addon${option.level + 1}`} />
  ) : null;
};

const getSuffix = (option: IAttributeOption) => {
  return option.status > 0 ? (
    <i className={getStatusClass(option.status)} />
  ) : null;
};

const getStatusClass = (status: number) => {
  switch (status) {
    case 1:
      return "fa fa-exclamation-triangle";
    case 2:
      return "fa fa-exclamation-circle";
    default:
      return "fa fa-check";
  }
};

const optionsWhileLoading = [
  {
    id: -1,
    selectable: false,
    level: 0,
    status: 0,
    selected: false,
    value: "Loading",
  },
];

const DropdownTableWrapper = styled.div`
  width: 100%;
`;
