import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import styled from "styled-components";
import ProductCatalogContainer from "../../SelfService/ProductCatalog/ProductCatalogContainer";
import EndUserWrapper from "../EndUserWrapper";

const CatalogWrapper = styled.div`
  max-width: 1490px;
  width: 100%;
`;

const EndUserCatalog: React.FC = () => {
  return (
    <EndUserWrapper
      backgroundColor={color.DIGITAL_LIGHT_GREY}
      title="module.subscription.end_users.tab.shopping"
    >
      <CatalogWrapper>
        <ProductCatalogContainer />
      </CatalogWrapper>
    </EndUserWrapper>
  );
};

export default EndUserCatalog;
