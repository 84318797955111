import * as React from "react";
import * as ReactDOM from "react-dom";
import { ModalContext } from "./ReactModal";
import { PropsWithChildren } from "react";

const ModalFooter: React.FC<PropsWithChildren> = ({ children }) => {
  const footerRef = React.useContext(ModalContext);
  const [tick, setTick] = React.useState(false);
  const modalFooterRoot = footerRef?.current;
  if (modalFooterRoot) {
    return ReactDOM.createPortal(children, modalFooterRoot as Element);
  } else {
    window.requestAnimationFrame(() => {
      // Force rerender if modal footer not found
      setTick(() => !tick);
    });
    return null;
  }
};

export default ModalFooter;
