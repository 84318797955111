/* Temporary until react-router */
import * as React from "react";
import { useLocation } from "react-router-dom";

export function useUrlParams(paramName: string): string | null {
  const [param, setParam] = React.useState(paramFromName(paramName));
  const location = useLocation();
  React.useEffect(() => {
    setParam(paramFromName(paramName));
  }, [location, paramName]);
  return param;
}

const paramFromName = (paramName: string): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

export function useAllUrlParams(paramName: string): string[] {
  const [param, setParam] = React.useState(paramsFromName(paramName));
  const location = useLocation();
  React.useEffect(() => {
    setParam(paramsFromName(paramName));
  }, [location, paramName]);
  return param;
}

const paramsFromName = (paramName: string): string[] => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.getAll(paramName);
};
