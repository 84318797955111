import * as React from "react";
import IvrComponent from "../../Routes/SelfService/EditComponents/InvoiceRecipient";
import AttributeRow from "../AttributeRow/AttributeRow";
import ReactCollapsible from "../collapsible/ReactCollapsible";
import {
  useComponents,
  useOneFieldForAllProducts,
  useUpdateComponent,
} from "./ComponentsContext";

const Components: React.FC = () => {
  const [components] = useComponents();
  const updateComponent = useUpdateComponent();
  const [oneFieldForAllProducts] = useOneFieldForAllProducts();
  return (
    <>
      {components.map((c) => (
        <div key={c.subsId + "-" + c.prodCompId} className="components">
          {c.prodName ? (
            <div className="row attr-row" data-section="attribute">
              <div className="col-sm-12">
                <h3>{c.prodName}</h3>
              </div>
            </div>
          ) : null}
          <ReactCollapsible
            title={(show) =>
              c.showName ? (
                <div className="row attr-row">
                  <div className="col-sm-12 header-row">
                    <span
                      className={`fa fa-fw ${
                        show ? "fa-caret-down" : "fa-caret-right"
                      }`}
                    />{" "}
                    {c.name}
                  </div>
                </div>
              ) : null
            }
          >
            {c.packageInstanceId !== 0 ? (
              <IvrComponent
                invoiceRecipientEditable={true}
                invoiceRecipient={c.invoiceRecipient || undefined}
                oppRowId={c.subsId}
                slaveField={true}
                invoiceRecipientValidationMessage={
                  c.invoiceRecipientValidationMessage || ""
                }
                onSave={({
                  invoiceRecipient,
                  invoiceRecipientValidationMessage,
                }) => {
                  c.invoiceRecipient = invoiceRecipient
                    ? invoiceRecipient
                    : null;
                  c.invoiceRecipientValidationMessage =
                    invoiceRecipientValidationMessage;
                  updateComponent(c);
                }}
              />
            ) : null}
            {c.attributes.map((attr) => (
              <AttributeRow
                key={
                  attr.subsId + "-" + attr.prodCompAttrId + "-" + attr.scaSubsId
                }
                hidden={c.collapsed}
                {...attr}
                oneFieldForAllProducts={oneFieldForAllProducts}
              />
            ))}
          </ReactCollapsible>
        </div>
      ))}
    </>
  );
};

export default Components;
