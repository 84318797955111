export const formatToLocalNumber = (
  value: number,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
  locale = document.documentElement.lang || "en-US"
): string => {
  return Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits || 0,
    maximumFractionDigits: maximumFractionDigits || 0,
  }).format(value);
};
