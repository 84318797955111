import * as React from "react";
import useGetApproval from "../../../api/self-service/approver-list/useGetApproval";
import { useTranslation } from "react-i18next";

export interface ApprovalFormProps {
  setApprover: (n: number) => void;
  approver: number;
}

const ApprovalForm: React.FC<ApprovalFormProps> = ({
  setApprover,
  approver,
}) => {
  const { t } = useTranslation();
  const { data } = useGetApproval();
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApprover(Number(e.target.value));
  };
  if (!data) {
    return null;
  }
  return (
    <form>
      <div id="approver-content-div-new">
        <div className="modal-body">
          {data.usersExists ? (
            <>
              <p>{t("module.self.service.insufficient.permissions")}</p>
              <div className="form-group">
                <label className="sr-only">{t("general.user")}</label>
                <ul className="list-group">
                  {data.approvalUserLevel3 && (
                    <li className="list-group-item d-flex align-items-center list-group-item-secondary">
                      <input
                        disabled={true}
                        type="radio"
                        name="approvalUserLevel3"
                        value={data.approvalUserLevel3.userId}
                      />
                      <span className="ml-3">
                        {data.approvalUserLevel3.showName}
                      </span>
                    </li>
                  )}
                  {data.approvalUserLevel2 && (
                    <li className="list-group-item d-flex align-items-center">
                      <RadioCheckBox
                        checked={approver === data.approvalUserLevel2.userId}
                        onChange={onValueChange}
                        value={data.approvalUserLevel2.userId}
                        name={"approvalUserLevel2"}
                      />
                      <span className="ml-3">
                        {data.approvalUserLevel2.showName}
                      </span>
                    </li>
                  )}
                  {data.approvalUserLevel1 && (
                    <li className="list-group-item d-flex align-items-center list-group-item-success">
                      <RadioCheckBox
                        data-testid={"approvalUserLevel1"}
                        checked={approver === data.approvalUserLevel1.userId}
                        onChange={onValueChange}
                        value={data.approvalUserLevel1.userId}
                        name={"approvalUserLevel1"}
                      />
                      <span className="ml-3">
                        {data.approvalUserLevel1.showName}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <p>{t("module.self.service.send.order.missing.attestant")}</p>
          )}
        </div>
      </div>
    </form>
  );
};
const RadioCheckBox: React.FC<{
  checked: boolean;
  value: number;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ checked, value, name, onChange, ...props }) => {
  return (
    <input
      {...props}
      type="radio"
      checked={checked}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};
export default ApprovalForm;
