export const isSameComponent = (
  c1: { subsId: number; prodCompId: number },
  c2: { subsId: number; prodCompId?: number }
): boolean => {
  if (c1.subsId === c2.subsId) {
    // prodCompId is not always set for all products. It is ok if both are 0
    if (!c1.prodCompId !== !c2.prodCompId) {
      throw new Error("Faulty component comparison!");
    }
    return !(
      (c1.prodCompId || c2.prodCompId) &&
      c1.prodCompId !== c2.prodCompId
    );
  }
  return false;
};

export const isSameComponentAttribute = (
  a1: { prodCompAttrId: number },
  a2: { prodCompAttrId: number }
): boolean => {
  return a1.prodCompAttrId === a2.prodCompAttrId;
};
