import { color, H1 } from "@tele2/t2-ui-kit";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";

interface Props {
  backgroundColor?: string;
  title: string;
}

const EndUserLayout: React.FC<PropsWithChildren<Props>> = ({
  backgroundColor,
  children,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <EndUserContainer $backgroundColor={backgroundColor || color.BRAND_WHITE}>
      <Layout
        title={title}
        breadcrumbs={undefined}
        backgroundColor={backgroundColor}
      >
        <H1 marginTop={"lg"} marginBottom={"lg"}>
          {t(title)}
        </H1>
        {children}
      </Layout>
    </EndUserContainer>
  );
};
export default EndUserLayout;

const EndUserContainer = styled.div<{ $backgroundColor: string }>`
  padding-top: 72px;
  flex: 1;
  background-color: ${(props) => props.$backgroundColor};
`;
