import { getOppGroupKey } from "../../../../api/self-service/checkout/useCartOppRows";
import { OpportunityRowContainer } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import { CartActionPermissions } from "../../../../api/self-service/checkout/useSalesProjectSummaryValidation";
import * as React from "react";
import styled from "styled-components";

import CartRowBaseSubscription from "./CartRowBaseSubscription";
import CartRowPackageGroup from "./CartRowPackageGroup";
import CartRowProduct from "./CartRowProduct";
import CartRowSubscription from "./CartRowSubscription";
import { useTranslation } from "react-i18next";
import { DeleteOppRowModalObj } from "../MiniCartButtonWrapper";

interface CartContentsProps {
  container: OpportunityRowContainer;
  permissions: CartActionPermissions;
  salesProjectId: number;
  onClose?: () => void;
  setShowConfirmDeleteOppRowModal: (props: DeleteOppRowModalObj) => void;
}

const ContentGrid = styled.div`
  display: grid;
  width: 100%;
  > div {
    padding: 8px 4px;
  }
  grid-template-columns:
    repeat(3, auto)
    75px auto
    50px;
  > div {
    border-bottom: 1px solid #ddd;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    > div.action-column {
      border-bottom: 1px solid #ddd;
    }

    > div:empty {
      display: none;
    }
  }
`;

const MiniCartContents: React.FC<CartContentsProps> = ({
  container,
  permissions,
  salesProjectId,
  onClose,
  setShowConfirmDeleteOppRowModal,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {container.opportunityGroups ? (
        <ContentGrid>
          {container.opportunityGroups.map((group) => {
            const baseSubscriptionRow =
              group.shadow && group.rows[0].subscription?.saEnabled;

            const isPackageProductGroup = group.packageInstanceId > 0;

            if (baseSubscriptionRow) {
              return (
                <CartRowBaseSubscription
                  key={`base-subs-${group.rows[0].subscription.id}`}
                  row={group.rows[0]}
                />
              );
            }

            const subscriptionRow = group.shadow ? group.rows[0] : null;
            if (subscriptionRow)
              return (
                <CartRowSubscription
                  key={`shadow-${subscriptionRow.id}-${subscriptionRow.baseSubsId}`}
                  row={subscriptionRow}
                />
              );

            return (
              <React.Fragment key={getOppGroupKey(group)}>
                {isPackageProductGroup && (
                  <CartRowPackageGroup
                    group={group}
                    salesProjectId={salesProjectId}
                    permissions={permissions}
                    onClose={onClose}
                    setShowConfirmDeleteOppRowModal={
                      setShowConfirmDeleteOppRowModal
                    }
                  />
                )}
                {group.rows.map((row) => {
                  return (
                    <CartRowProduct
                      group={group}
                      key={`opp-row-${row.id}-${row.baseSubsId}`}
                      salesProjectId={salesProjectId}
                      permissions={permissions}
                      onClose={onClose}
                      row={row}
                      setShowConfirmDeleteOppRowModal={
                        setShowConfirmDeleteOppRowModal
                      }
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </ContentGrid>
      ) : (
        <div className="search-results">
          {t("module.self.service.cart.empty")}
        </div>
      )}
    </div>
  );
};

export default MiniCartContents;
