import * as React from "react";
import { useTranslation } from "react-i18next";
import useCreateMatrixRow from "../../../api/self-service/create-matrix-row/useCreateMatrixRow";
import useDeleteMatrixRow from "../../../api/self-service/delete-matrix-row/useDeleteMatrixRow";
import { toErrorClass } from "../../../Routes/SelfService/EditComponents/utils";
import LinkStyleButton from "../../Buttons/LinkStyleButton";
import AttributeCol from "../AttributeCol";
import { AttributeRowProps, AttributeRowType } from "../AttributeRow";
import HelpTextIcon from "../HelpTextIcon";

interface MatrixRowProps extends AttributeRowType {
  readOnly?: boolean;
  prodCompId: number;
  parentProdCompAttrId: number;
}

const MatrixRow: React.FC<MatrixRowProps> = ({
  readOnly,
  cells,
  id,
  subsId,
  prodCompId,
  prodCompAttrId,
  parentProdCompAttrId,
}) => {
  const { t } = useTranslation();
  const { deleteMatrixRow } = useDeleteMatrixRow();
  return (
    <>
      {!readOnly ? (
        <td className="text-nowrap">
          <LinkStyleButton
            className="attr-matrix-delete-row"
            onClick={async () => {
              await deleteMatrixRow({
                subsId,
                prodCompId,
                matrixRowId: id,
                prodCompAttrId,
              });
            }}
          >
            <span className="fa fa-trash" title={t("general.delete")} />
          </LinkStyleButton>
          {/* TODO Remove comment when Melody can handle moveable rows
          i className="fa fa-arrows-v attr-matrix-move-row" title="{{i18n "general.move"}}"></i></span> */}
        </td>
      ) : null}

      {cells.map((c, i) => (
        <td
          key={i}
          className={`attr-matrix-cell${
            c.validationClass ? toErrorClass(c.validationCode) : ""
          }`}
        >
          <AttributeCol
            isMatrixCell
            {...c}
            subsId={subsId}
            oppRowId={subsId}
            matrixRowId={id}
            prodCompId={prodCompId} // Override prodCompId from cell
            parentProdCompAttrId={parentProdCompAttrId} // Override parentProdCompAttrId from cell
          />
        </td>
      ))}
    </>
  );
};

const MatrixAttribute: React.FC<AttributeRowProps> = (props) => {
  const { t } = useTranslation();
  const {
    readOnly,
    indent,
    subsId,
    prodCompAttrId,
    columns,
    rows,
    maxRows = 0,
    prodCompId,
  } = props;
  const matrixColumns = columns || [];
  const matrixRows = rows || [];
  const { createMatrixRow } = useCreateMatrixRow(matrixColumns);
  return (
    <div
      className="attr-matrix-container"
      style={{ marginLeft: indent * 10 + "px" }}
    >
      <table className="attr-matrix-table" id={`${subsId}_${prodCompAttrId}`}>
        <thead>
          <tr>
            {!readOnly ? <th></th> : null}
            {matrixColumns.map((c, i) => (
              <th key={i} className="attr-matrix-column-cell">
                <span title={c.helpText || ""}>
                  {c.label}
                  {c.required ? (
                    <span className="input-required"> *</span>
                  ) : null}
                </span>
                <HelpTextIcon>{c.helpText}</HelpTextIcon>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {matrixRows.map((r) => (
            <tr key={r.id}>
              <MatrixRow
                readOnly={readOnly}
                cells={r.cells}
                id={r.id}
                subsId={r.subsId}
                prodCompAttrId={r.prodCompAttrId}
                scaSubsId={r.scaSubsId}
                prodCompId={prodCompId}
                parentProdCompAttrId={prodCompAttrId}
              />
            </tr>
          ))}
        </tbody>
      </table>
      {!readOnly && (
        <div className="attr-matrix-operations">
          <button
            className={`btn btn-secondary btn-sm attr-matrix-create-row${
              matrixRows.length >= maxRows ? " hidden" : ""
            }`}
            onClick={async () => {
              await createMatrixRow({ subsId, prodCompAttrId, prodCompId });
            }}
          >
            {t("module.self.service.matrix.add.row")}
          </button>
        </div>
      )}
    </div>
  );
};
export default MatrixAttribute;
