import SimCardFeeInfo from "./SimCardFeeInfo/SimCardFeeInfo";
import { useComponentsAttributes } from "../../component/ComponentsContext";
import { Types } from "../../component/types";
import * as React from "react";
import PortMsisdnInfo from "./PortMsisdnInfo";

interface Props {
  elementLabel: string | null;
  isValid: boolean;
}
const ElementLabelComponent: React.FC<Props> = ({ elementLabel, isValid }) => {
  const { componentsType } = useComponentsAttributes();
  return React.useMemo(() => {
    if (![Types.EDIT_COMPONENTS, Types.BATCH_UPDATE].includes(componentsType)) {
      return null;
    }
    switch (elementLabel) {
      case "sim_delivery_type":
      case "multisim_new_deltype_1":
      case "multisim_new_deltype_2":
      case "multisim_new_deltype_3":
      case "multisim_new_deltype_4":
      case "multisim_new_deltype_5":
        return <SimCardFeeInfo />;
      case "port_orgnr":
        return <PortMsisdnInfo isValid={isValid} />;
      default:
        return null;
    }
  }, [componentsType, elementLabel, isValid]);
};
export default ElementLabelComponent;
