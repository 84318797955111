import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

export interface User {
  customerId: number;
  firstName: string;
  lastName: string;
  customerName: string;
  login: string;
  changePassword: boolean;
}
export enum SessionStatus {
  NONE = "NONE",
  EXPIRED = "EXPIRED",
  UPDATING = "UPDATING",
  VALID = "VALID",
}

interface TSOSession {
  sessionStatus: SessionStatus;
  startUri?: string;
  user?: User;
  timestamp: number;
  cacheUpdated: number;
  iss?: string;
  version?: string;
}

const get = async () => {
  const { data } = await axios.get<TSOSession>("/api/session");
  return data;
};

const QUERY_KEY = "tso-session";
const useTSOSession = () =>
  useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: get,
    refetchInterval: 300_000,
  });

export default useTSOSession;

export const useInvalidateTSOSession = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

export const useInvalidateAllData = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries();
  }, [queryClient]);
};
