import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { color, Flex, H1, Text } from "@tele2/t2-ui-kit";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const ErrorPageWrapper = styled.div`
  margin-top: 10vh;
  margin-top: min(10vh, 150px);
`;

const Hero = styled(H1)`
  font-style: normal;
  font-weight: normal;
  font-size: 188px;
  line-height: 188px;

  color: ${color.BRAND_PREMIUM_GREY};
`;

const ErrorLocationText = styled(Text)`
  text-align: center;
  word-break: break-all;
  max-width: 600px;
`;

const ErrorPage: React.FC<{
  resetErrorCode?: () => void;
  status: number;
  errorCode: string | undefined;
  source: string;
}> = ({ status, errorCode, resetErrorCode, source }) => {
  const { t } = useTranslation();
  const { reset } = useQueryErrorResetBoundary();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const firstRenderRef = React.useRef(true);
  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      resetErrorCode?.();
      reset();
    }
    return () => {
      resetErrorCode?.();
      reset();
    };
  }, [reset, resetErrorCode, routerLocation]);

  React.useEffect(() => {
    if (status === 404 && window.location.pathname.endsWith(".html")) {
      navigate(
        window.location.pathname.substring(
          0,
          window.location.pathname.length - 5
        ) + window.location.search
      );
    }
  }, [status, navigate]);

  return (
    <ErrorPageWrapper>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Hero>{status > 0 ? status : t("general.error")}</Hero>
        {status > 0 && <H1>{t(`error.${status}.header`)}</H1>}
        <Text xl>
          {status > 0
            ? t(`error.${status}.message`)
            : t("general.error.message")}
        </Text>
        {`${source}: `}
        {errorCode && <Text xl>{errorCode}</Text>}
        <ErrorLocationText>{window.location.href}</ErrorLocationText>
      </Flex>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
