import { Utility } from "@tele2/t2-ui-kit";
import {
  GeneratedReportNotification,
  LoginMessageNotification,
  OrderCaseActivity,
} from "../api/notifications/useNotifications";
import * as React from "react";
import { Link } from "react-router-dom";
import { CONST_URL_HOME_USER_REPORTS } from "../Routes/approutes";
import { TFunction, useTranslation } from "react-i18next";

export enum NotificationState {
  NOT_READ = "NOT_READ",
  READ = "READ",
}

export enum NotificationsViewState {
  NONE = "NONE",
  ALL = "ALL",
  NOT_READ = "NOT_READ",
  NEW = "NEW",
}

export enum NotificationType {
  OPERATING_TICKETS = "OPERATING_TICKETS",
  ARS_TICKETS = "ARS_TICKETS",
  BROWSER_WARNING = "BROWSER_WARNING",
  CASE = "CASE",
  GENERATED_REPORT = "GENERATED_REPORT",
  LOGIN_MESSAGE = "LOGIN_MESSAGE",
  PLANNED_WORKS_CONTACT = "PLANNED_WORKS_CONTACT",
}

export interface NotificationProps {
  id: string;
  title: string;
  body: string | JSX.Element;
  color?: Utility["color"];
  link?: string;
  state?: NotificationState;
  type: NotificationType;
}

export const colorFromLevel = (level: string): Utility["color"] | undefined => {
  switch (level) {
    case "alert-success": {
      // SUCCESS
      return "BRAND_GREEN";
    }
    case "alert-info": {
      // INFO
      return "FUNCTIONAL_BLUE";
    }
    case "alert-warning": {
      // WARNING
      return "FUNCTIONAL_ORANGE";
    }
    case "alert-danger": {
      // DANGER
      return "FUNCTIONAL_RED";
    }
  }
  return;
};

const getLevelFromColor = (level?: string) => {
  switch (level) {
    case "FUNCTIONAL_BLUE":
      return 1;
    case "FUNCTIONAL_ORANGE":
      return 2;
    case "FUNCTIONAL_RED":
      return 3;
    case "BRAND_GREEN":
    default:
      return 0;
  }
};

export function getBrowserWarningNotification(
  t: TFunction<string>
): NotificationProps | undefined {
  const ua = window.navigator.userAgent;
  const isLessThanIE11 = ua.indexOf("MSIE ") >= 0;
  const isIE11 = !!(ua.match(/Trident/) && ua.match(/rv[ :]11/));

  if (isLessThanIE11) {
    return {
      id: NotificationType.BROWSER_WARNING,
      type: NotificationType.BROWSER_WARNING,
      title: "Browser warning",
      color: colorFromLevel("alert-info"),
      body: t("module.browser.error"),
    };
  }

  if (isIE11) {
    return {
      id: NotificationType.BROWSER_WARNING,
      type: NotificationType.BROWSER_WARNING,
      title: "Browser warning",
      color: colorFromLevel("alert-info"),
      body: t("module.browser.error.ie11"),
    };
  }
  return;
}

export const sortNotificationsByLevel = (
  notifications: NotificationProps[]
): NotificationProps[] =>
  notifications.sort(
    (a, b) => getLevelFromColor(b.color) - getLevelFromColor(a.color)
  );

export const getCaseNotification = (
  caseActivity: OrderCaseActivity,
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.CASE + "_" + caseActivity.caseId,
    type: NotificationType.CASE,
    title: t("module.notification.order"),
    color: colorFromLevel("alert-info"),
    body: <OrderNotification {...caseActivity} />,
  };
};

export const getLoginMessageNotification = (
  loginMessage: LoginMessageNotification,
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.LOGIN_MESSAGE + "_" + loginMessage.id,
    type: NotificationType.LOGIN_MESSAGE,
    title: t("module.notification.login_messages"),
    color: colorFromLevel(loginMessage.level),
    body: (
      <div
        dangerouslySetInnerHTML={{
          __html: loginMessage.translation,
        }}
      />
    ),
  };
};

export const getArsNotification = (
  numberOfArsTickets: number,
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.ARS_TICKETS + "_" + numberOfArsTickets,
    type: NotificationType.ARS_TICKETS,
    title: t("module.notification.arstickets"),
    color: colorFromLevel("alert-warning"),
    body: t("module.home.support.cases", [numberOfArsTickets]),
    link: "/maintenance/cases",
  };
};

export const getGeneratedReportsNotifications = (
  generatedReport: GeneratedReportNotification,
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.GENERATED_REPORT + "_" + generatedReport.id,
    type: NotificationType.GENERATED_REPORT,
    title: generatedReport.error
      ? t("module.notification.generated.report.error")
      : t("module.notification.generated.report"),
    color: colorFromLevel(
      generatedReport.error ? "alert-warning" : "alert-success"
    ),
    body: `${generatedReport.description}: ${generatedReport.filename}`,
    link: CONST_URL_HOME_USER_REPORTS,
  };
};

export const getOperationalDisturbancesNotification = (
  numberOfOperatingTickets: number,
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.OPERATING_TICKETS + "_" + numberOfOperatingTickets,
    type: NotificationType.OPERATING_TICKETS,
    title: t("module.notification.operating_information_count"),
    color: colorFromLevel("alert-warning"),
    body: t("module.maintenance.net.status.operational.disturbances", [
      numberOfOperatingTickets,
    ]),
    link: "/maintenance/net-status",
  };
};

export const getPlannedWorksContactNotification = (
  t: TFunction<string>
): NotificationProps => {
  return {
    id: NotificationType.PLANNED_WORKS_CONTACT,
    type: NotificationType.PLANNED_WORKS_CONTACT,
    title: t("module.notification.planned_works"),
    color: colorFromLevel("alert-warning"),
    body: t("module.home.planned_works.notification"),
    link: "/home/options-own-contact-roles",
  };
};

interface NotificationStorageProps {
  state: NotificationState;
}

export const saveNotificationsToStorage = (
  userLogin: string,
  notifications: NotificationProps[]
) => {
  const parsedStorage = getUserLocalStorage(userLogin);
  const notificationsMap: Record<string, NotificationStorageProps> = {};
  notifications.forEach(
    (n) =>
      (notificationsMap[n.id] = {
        state: n.state || NotificationState.NOT_READ,
      })
  );
  parsedStorage["notification"] = notificationsMap;
  localStorage.setItem(userLogin, JSON.stringify(parsedStorage));
};

export const markNotificationAsDismissed = (userLogin: string, id: string) => {
  const parsedStorage = getUserLocalStorage(userLogin);
  if (parsedStorage["notification"]) {
    const notification = parsedStorage["notification"][id];
    if (notification) {
      notification.state = NotificationState.READ;
    }
  }
  localStorage.setItem(userLogin, JSON.stringify(parsedStorage));
};

export const getNotificationsFromStorage = (
  userLogin: string
): Record<string, NotificationStorageProps> => {
  const parsedStorage = getUserLocalStorage(userLogin);
  if (parsedStorage["notification"]) {
    return parsedStorage["notification"] as Record<
      string,
      NotificationStorageProps
    >;
  }
  return {};
};

const getUserLocalStorage = (userLogin: string) => {
  const userStorage = localStorage.getItem(userLogin);
  if (userStorage) {
    return JSON.parse(userStorage);
  }
  return {};
};

const OrderNotification: React.FC<OrderCaseActivity> = ({
  alias,
  caseId,
  orderId,
  prodName,
  subsId,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {t("module.orders.worklog.new.notification", [""]).slice(0, -1)}
      <Link to={`/orders/order?orderId=${orderId}&caseId=${caseId}`}>
        {[prodName, alias, subsId].filter((v) => v).join(", ")}
      </Link>
    </>
  );
};
