import { Button, Flex } from "@tele2/t2-ui-kit";
import Launch from "@tele2/t2-ui-kit/build/Icon/icons/LaunchOutlined";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import * as React from "react";
import { CONST_URL_HOME_HOME } from "../approutes";
import getEnv from "../../utils/getEnv";

declare global {
  interface Window {
    OneTrust: {
      OnConsentChanged: (e: () => void) => void;
      ToggleInfoDisplay: () => void;
      initializeCookiePolicyHtml: () => void;
      changeLanguage: (language: string) => void;
    };
    OptanonWrapper: () => void;
  }
}

export const openCookieBanner = () => {
  try {
    window.OneTrust.ToggleInfoDisplay();
  } catch (e) {
    console.error(e);
  }
};

export const initCookies = () => {
  const addListener = () => {
    if (window.OneTrust) {
      try {
        window.OneTrust.OnConsentChanged(() => {
          Object.entries(callbacks).forEach((c) => c[1]());
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      setTimeout(addListener, 250);
    }
  };
  const env = getEnv();
  if (["local", "test", "dev", "stage", "prod"].includes(env)) {
    window.OptanonWrapper = () => {};
    const script = document.createElement("script");
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.type = "text/javascript";
    script.dataset.domainScript = `02cd8f5e-85fe-43e2-aea9-8f907c5d0633${
      ["local", "test", "dev", "stage"].includes(env) ? "-test" : ""
    }`;
    script.dataset.language = (
      document.documentElement.lang ||
      navigator.language ||
      "en"
    ).substring(0, 2);
    script.onload = addListener;
    document.body.appendChild(script);
  }
};

const cookieContains = (value: string): boolean => {
  const regex = /OptanonConsent=([^;]+)/;
  const matches = regex.exec(document.cookie);
  const cookieValue = matches !== null ? decodeURI(matches[1]) : "false";
  return cookieValue.includes(value);
};

const callbacks: { [key: number]: () => void } = {};

const useCookieWatcher = (name: string): boolean => {
  const [state, setState] = React.useState<boolean>(cookieContains(name));
  React.useEffect(() => {
    const rand = Math.floor(Math.random() * 1000000);
    callbacks[rand] = () => setState(cookieContains(name));
    return () => {
      delete callbacks[rand];
    };
  }, [name]);
  return state;
};

export const useAcceptStatistics = () => useCookieWatcher("C0002%3A1");
export const useAcceptFunctionality = () => useCookieWatcher("C0003%3A1");
export const useAcceptMarketing = () => useCookieWatcher("C0004%3A1");

const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    try {
      if (window.OneTrust) {
        window.OneTrust.initializeCookiePolicyHtml();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <Layout
      title="module.cookie.policy"
      breadcrumbs={[
        { title: "module.home", link: CONST_URL_HOME_HOME },
        { title: "module.cookie.policy", link: "" },
      ]}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t("module.cookie.ingress"),
        }}
      />
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Button onClick={openCookieBanner} data-testid="cookie-policy-settings">
          {t("module.cookie.settings")}
          <Launch sm marginLeft={"xs"} />
        </Button>
      </Flex>
      <div id="ot-sdk-cookie-policy"></div>
    </Layout>
  );
};

export default CookiePolicy;
