import "@tele2/style/dist/main.bundle.css";
import "@tele2/t2-ui-kit/build/global.css";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.css";
import "./main.css";
import { QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import Qs from "qs";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n.ts";

import { queryClient } from "./hooks/withQueryClient";
import reportWebVitals from "./reportWebVitals";

// Be compatible with servlet spec
//https://github.com/spring-projects/spring-boot/issues/14487
axios.defaults.paramsSerializer = (params) =>
  Qs.stringify(params, { arrayFormat: "repeat" });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
