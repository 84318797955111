import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

interface SaveAliasRequest {
  alias: string;
  oppRowId: number;
  salesProjectId: number;
}

interface SaveAliasResponse {
  saved: boolean;
  value: string;
  message: string;
}

const post = async (request: SaveAliasRequest) => {
  const { data } = await axios.post<SaveAliasResponse>(
    "/api/self-service/save-alias",
    null,
    { params: request }
  );
  return data;
};

const useSaveAlias = () => {
  const { mutateAsync, isPending } = useMutation<
    SaveAliasResponse,
    AxiosError,
    SaveAliasRequest
  >({ mutationFn: post });
  const saveAlias = useCallback(
    async (request: SaveAliasRequest) => {
      return await mutateAsync(request);
    },
    [mutateAsync]
  );
  return { saveAlias, isPending };
};

export default useSaveAlias;
