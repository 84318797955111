import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  IValidationStatuses,
  validationStatus,
} from "../../../components/Validation/validation-utils";

export interface RowValidation {
  subsStatusId: number;
  validations: Validation[];
}

export interface Validation {
  description: string;
  type: string;
  prodCompAttrId: number;
  subsId: number;
  subsStatusId: number;
}

export interface ValidationGroups {
  hasErrors: boolean;
  groups: { category: string; validations: Validation[] }[];
  statuses: IValidationStatuses;
}

function groupValidations(v: RowValidation, t: TFunction): ValidationGroups {
  const missingAttributes = v.validations.filter(
    (t) => t.type === "REQUIRED_ATTRIBUTE_MISSING"
  );
  const others = v.validations.filter(
    (t) => t.type !== "REQUIRED_ATTRIBUTE_MISSING"
  );
  return {
    groups: [
      {
        category: t("module.self.service.missing.mandatory.fields"),
        validations: missingAttributes,
      },
      {
        category: t("general.other"),
        validations: others,
      },
    ],
    hasErrors: v.validations.length > 0,
    statuses: validationStatus(v.subsStatusId),
  };
}

const getRowValidation = async (
  oppRowId: number,
  packageInstanceId: number,
  t: TFunction
) => {
  const { data } = await axios.get<RowValidation>(
    "/api/self-service/row-validations",
    {
      params: { oppRowId, packageInstanceId },
    }
  );
  return groupValidations(data, t);
};

const QUERY_KEY = "opp_row-validation-result";
const getQueryKey = (oppRowId: number, packageInstanceId: number) => {
  return [QUERY_KEY, oppRowId, packageInstanceId];
};

export const useRemoveValidation = (
  oppRowId: number,
  packageInstanceId: number
): (() => void) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.removeQueries({
      queryKey: getQueryKey(oppRowId, packageInstanceId),
    });
  }, [oppRowId, packageInstanceId, queryClient]);
};

export const useInvalidateValidation = (
  oppRowId: number,
  packageInstanceId: number
): (() => void) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: getQueryKey(oppRowId, packageInstanceId),
    });
  }, [oppRowId, packageInstanceId, queryClient]);
};

export const useInvalidateAllValidations = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY], exact: false });
  }, [queryClient]);
};

const useGetOppRowValidation = (
  oppRowId: number,
  packageInstanceId: number
) => {
  const { t } = useTranslation();
  return useQuery<ValidationGroups, AxiosError>({
    queryKey: getQueryKey(oppRowId, packageInstanceId),
    queryFn: () => getRowValidation(oppRowId, packageInstanceId, t),
  });
};

export default useGetOppRowValidation;
