import {
  Button,
  color,
  margin,
  TextInput,
  useIsMobile,
} from "@tele2/t2-ui-kit";
import Close from "@tele2/t2-ui-kit/build/Icon/icons/Close";

import Done from "@tele2/t2-ui-kit/build/Icon/icons/Done";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useSavePassword, {
  SavePasswordType,
} from "../../../api/home/password/useSaveOptions";
import { useMessage } from "../../Message/Message";

import { DetailsHeader, DetailsWrapper } from "../TopMenu-styles";

export const FormErrorWrapper = styled.div`
  color: ${color.DARK_GREY};
  font-size: 14px;
  line-height: 16px;

  > div {
    align-items: start;
    display: flex;
    margin-bottom: ${margin.XXS};

    > .t2-icon {
      margin-right: ${margin.XXS};
      flex-shrink: 0;
    }
    > div {
      margin-top: 5px;
    }
  }
`;

const UpdatePassword: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const message = useMessage();
  const [formState, setFormState] = React.useState<SavePasswordType>({
    password1: "",
    password2: "",
  });

  const handleChange = (fieldName: string, value: string | boolean) => {
    setFormState({ ...formState, [fieldName]: value });
  };

  interface ErrorState {
    digit: boolean;
    length: boolean;
    lower: boolean;
    match: boolean;
    upper: boolean;
    special: boolean;
  }

  const [formErrors, setFormErrors] = React.useState<ErrorState>({
    digit: false,
    length: false,
    lower: false,
    match: false,
    upper: false,
    special: false,
  });

  const checkErrors = React.useCallback(() => {
    const passwordLength = 12;

    const testDigit = new RegExp(/\d/);
    const testUpper = new RegExp(/[A-Z]/);
    const testLower = new RegExp(/[a-z]/);
    const special = new RegExp(/\W/);

    setFormErrors({
      digit: testDigit.test(formState.password1 ?? ""),
      length:
        formState.password1 != null &&
        formState.password1.length >= passwordLength,
      lower: formState.password1 != null && testLower.test(formState.password1),
      match:
        formState.password1 != null &&
        formState.password1.length > 0 &&
        formState.password1 === formState.password2,
      upper: formState.password1 != null && testUpper.test(formState.password1),
      special: formState.password1 != null && special.test(formState.password1),
    });
  }, [formState]);

  React.useEffect(() => {
    checkErrors();
  }, [checkErrors, formState]);

  const resetPasswords = () => {
    setFormState({
      password1: "",
      password2: "",
    });
  };

  const { isPending, mutateAsync: savePassword } = useSavePassword();

  const { password1, password2 } = formState;

  const allOk = Object.values(formErrors).every((value) => value === true);

  return (
    <DetailsWrapper>
      {!isMobile && (
        <DetailsHeader xl>{t("general.password.change")}</DetailsHeader>
      )}
      <TextInput
        block
        disabled={isPending}
        isStatic
        label={t("general.password")}
        onBlur={checkErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange("password1", e.target.value);
        }}
        type="password"
        value={password1 ?? ""}
      />
      <TextInput
        block
        disabled={isPending}
        isStatic
        label={t("general.password.again")}
        onBlur={checkErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange("password2", e.target.value);
        }}
        type="password"
        value={password2 ?? ""}
      />
      <Button
        block
        disabled={isPending || !allOk}
        onClick={async () => {
          await savePassword(formState);
          message({
            interval: 3000,
            message: t("general.saved"),
            theme: "positive",
          });
          resetPasswords();
        }}
      >
        {t("general.password.change")}
      </Button>
      {password1 != null && password1.length > 2 && !allOk && (
        <FormErrorWrapper>
          <div>{t("general.password.rules.header")}</div>
          {Object.entries(formErrors).map(([key, value], i) => {
            if (value) return null;
            return (
              <div key={`form-error-${i}`}>
                {value ? (
                  <Done color="BRAND_GREEN" />
                ) : (
                  <Close color="ALERT_RED" />
                )}
                <div>{t(`general.password.rules.${key}`)}</div>
              </div>
            );
          })}
        </FormErrorWrapper>
      )}
    </DetailsWrapper>
  );
};

export default UpdatePassword;
