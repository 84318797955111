import { color, Flex, Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useModuleReadRight } from "../../../api/session/useModuleRight";
import Layout from "../../../components/Layout/Layout";
import { LayoutWrapper } from "../../../components/Product";
import { CONST_URL_HOME_HOME, CONST_URL_ORDERS_INDEX } from "../../approutes";
import FooterCart from "./FooterCart";
import ProductCatalogContainer from "./ProductCatalogContainer";
import { useCatalog, useCatalogBaseServices } from "./ProductCatalogContext";

const breadcrumbs = [
  { link: CONST_URL_HOME_HOME, title: "module.home" },
  { link: CONST_URL_ORDERS_INDEX, title: "module.orders" },
  { link: "", title: "module.self.service.add.products.to.cart" },
];

const ProductCatalog: React.FC = () => {
  const hasModuleRight = useModuleReadRight("SELF_SERVICE");
  const availableBaseSubs = useCatalogBaseServices();
  const [catalog] = useCatalog();
  if (!hasModuleRight) {
    return null;
  }
  return catalog || availableBaseSubs?.length === 0 ? (
    <LayoutWrapper>
      <Layout
        title="module.self.service.add.products.to.cart"
        breadcrumbs={breadcrumbs}
        backgroundColor={color.DIGITAL_LIGHT_GREY}
      >
        <ProductCatalogContainer />
      </Layout>
      <FooterCart />
    </LayoutWrapper>
  ) : (
    <Flex justifyContent="center" alignItems="center">
      <Loader />
    </Flex>
  );
};

export default ProductCatalog;
