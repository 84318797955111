import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";

export interface DropDownProps<T> extends UlDropDownProps {
  field?: keyof T;
  rows: T[];
  columns?: { id: number; name: string }[];
  onSelect: (row: T) => void;
  format?: (row: T) => React.ReactNode;
  widthCss?: string;
}

interface UlDropDownProps {
  fullHeight?: boolean;
  position?: "top-left" | "top-right";
  widthCss?: string;
}

interface DropDownLiProps {
  selectable?: boolean;
}

const Ul = styled.ul`
  &:focus {
    outline: none;
  }
  ${(props: UlDropDownProps) => props.widthCss && `width: ${props.widthCss}`};
  ${(props: UlDropDownProps) => props.fullHeight && "max-height: unset"};

  ${(props: UlDropDownProps) =>
    props.position === "top-right" && "right: 0; left: unset;"};
`;

const DropDown = <T extends DropDownLiProps>(
  props: React.PropsWithChildren<DropDownProps<T>>
): JSX.Element => {
  const {
    rows,
    field,
    onSelect,
    format,
    fullHeight = false,
    position = "top-left",
    widthCss,
  } = props;
  const [selected, setSelected] = React.useState<number>(-1);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    if (e.key === "ArrowDown") {
      setSelected(selected >= rows.length - 1 ? 0 : selected + 1);
    } else if (e.key === "ArrowUp") {
      setSelected(selected <= 0 ? rows.length - 1 : selected - 1);
    } else if (e.key === "Enter") {
      onSelect(rows[selected]);
    }
  };
  const renderRow = (rowElement: T[keyof T]): React.ReactNode => {
    return rowElement as unknown as React.ReactNode;
  };
  return (
    <Ul
      role={"listbox"}
      className="dropdown-menu combo-box-menu"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      fullHeight={fullHeight}
      position={position}
      widthCss={widthCss}
    >
      {rows.map((row, i) => {
        const classes = classNames("dropdown-item", i === selected && "active");
        return (
          <li
            key={i}
            role={"option"}
            aria-selected={i === selected}
            className={
              row.selectable === false ? "combo-box-disabled" : classes
            }
            onClick={() => {
              if (row.selectable !== false) {
                setSelected(i);
                onSelect(row);
              }
            }}
          >
            {format ? format(row) : field ? renderRow(row[field]) : ""}
          </li>
        );
      })}
    </Ul>
  );
};

export default DropDown;
