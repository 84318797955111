import ChevronLeft from "@tele2/t2-ui-kit/build/Icon/icons/ChevronLeftOutlined";
import ChevronRight from "@tele2/t2-ui-kit/build/Icon/icons/ChevronRightOutlined";
import * as React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  // to make controls on top of content, pass position absolute
  // position: absolute;
`;

const StepButton = styled.div`
  cursor: pointer;
`;

interface CarouselButtonProps {
  dir: "left" | "right";
  onClick: () => void;
}

const CarouselButton: React.FC<CarouselButtonProps> = ({ dir, onClick }) => {
  return (
    <ButtonWrapper>
      <StepButton onClick={onClick}>
        {dir === "left" ? <ChevronLeft xl /> : <ChevronRight xl />}
      </StepButton>
    </ButtonWrapper>
  );
};
export default CarouselButton;
