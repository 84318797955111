import useHoverMouse from "@tele2/t2-ui-kit/build/Hooks/useHoverMouse";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import usePriceDetails, {
  useInvalidatePriceDetails,
} from "../../../../api/self-service/price-details/usePriceDetails";
import { useComponentsAttributes } from "../../../../components/component/ComponentsContext";
import { ISubscription, subscribeReact } from "../../../../stomp";
import PriceDetailsPopover from "./PriceDetailsPopover";

const PriceDetails: React.FC = () => {
  const { t } = useTranslation();
  const { oppRowId, oppRowIds, packageInstanceId } = useComponentsAttributes();
  const { data } = usePriceDetails({ oppRowId, packageInstanceId });
  const invalidatePriceDetails = useInvalidatePriceDetails();
  React.useEffect(() => {
    // Subscribing to all unique opp row ids
    const subs: Promise<ISubscription>[] = [];
    oppRowIds.forEach((id) => {
      const topic = `/topic/subsId/${id}/type/6`;
      const sub = subscribeReact(topic, () => {
        invalidatePriceDetails();
      });
      subs.push(sub);
    });
    return () => {
      subs.forEach((sub) => sub.then((s) => s.unsubscribe()));
    };
  }, [invalidatePriceDetails, oppRowIds]);
  const [popover, setPopover] = React.useState(false);
  const refObject = useHoverMouse({
    onEnter: () => setPopover(true),
    onLeave: () => setPopover(false),
  });
  const hasDetails = data && Object.keys(data.details).length > 0;
  const render = data && (hasDetails || data.pricePerHourAndDiscount);
  const priceDetails = data?.total[data.defaultCurrency];
  return (
    <PriceDetailsWrapper ref={refObject}>
      {render && (
        <table className="table table-d table-hover inline">
          <tbody>
            {priceDetails &&
              priceDetails.map(({ currency, onetime }, i) => (
                <React.Fragment key={i + "onetime"}>
                  {onetime && (
                    <tr>
                      <td>{t("module.self.service.price.onetime")}</td>
                      <td className="text-right">
                        <strong>{onetime}</strong>
                      </td>
                      <td>{currency}</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            {priceDetails &&
              priceDetails.map(({ recurring, currency }, i) => (
                <React.Fragment key={i + "recurring"}>
                  {recurring && (
                    <tr>
                      <td>{t("module.self.service.price.recurring")}</td>
                      <td className="text-right">
                        <strong>{recurring}</strong>
                      </td>
                      <td>
                        {t("module.self.service.price.per.month", [currency])}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}

            {data.pricePerHourAndDiscount && (
              <>
                {data.pricePerHourAndDiscount.pricePerHour && (
                  <tr title={t("module.self.service.price.per.hour.hover")}>
                    <td>{t("module.self.service.price.per.hour.title")}</td>
                    <td className="text-right">
                      <strong>
                        {data.pricePerHourAndDiscount.pricePerHour}
                      </strong>
                    </td>
                    <td>
                      {t("module.self.service.price.per.hour", [
                        data.defaultCurrency,
                      ])}
                    </td>
                  </tr>
                )}
                {data.pricePerHourAndDiscount.discountPercentage > 0 && (
                  <tr>
                    <td colSpan={3}>
                      {t("module.self.service.price.discount.percentage", [
                        data.pricePerHourAndDiscount.discountPercentage,
                      ])}
                    </td>
                  </tr>
                )}
              </>
            )}
            <tr>
              <td colSpan={3} className="text-muted">
                {t("module.self.service.prices.without.vat")}{" "}
                {data.notCustomerSpecificPrice && (
                  <span
                    title={t("module.self.service.price.not.agreed.service")}
                    className="fa fa-exclamation-triangle text-danger"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {popover && hasDetails && <PriceDetailsPopover priceDetails={data} />}
    </PriceDetailsWrapper>
  );
};
export default PriceDetails;

const PriceDetailsWrapper = styled.div`
  position: relative;
`;
