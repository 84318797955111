import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { Attachment } from "../../agreements/attachments/useAgreementAttachments";
import { CustomerContactModel } from "../../contact/customer-contact-list/useCustomerContacts";
import { SalesProject } from "../carts/carts-types";
import { CartActionPermissions } from "./useSalesProjectSummaryValidation";

export interface CartSummaryModel {
  salesProject: SalesProject;
  attachments: Attachment[];
  codes: Record<number, string>;
  contacts: CustomerContactModel[];
  salesProjectEdit: boolean;
  permissions: CartActionPermissions;
  hasProjectErrors: boolean;
  attest: boolean;
  customerProjectTypes: SelectValue[] | null;
  deliveryAddress: OrderAddress | null;
  deliveryAddressType: DeliveryAddressType;
  deliveryAddresses: DeliveryAddress[] | null;
}

export interface OrderAddress {
  companyOrPrivate: string | null;
  address: string | null;
  zipCode: string | null;
  city: string | null;
  attention: string | null;
  goodsLabeling: string | null;
}

export interface DeliveryAddress {
  companyOrPrivate: string;
  address: string;
  zipCode: string;
  city: string;
}

export enum DeliveryAddressType {
  NONE = "NONE",
  FREETEXT_ONLY = "FREETEXT_ONLY",
  PREDEF_ONLY = "PREDEF_ONLY",
  PREDEF_FREETEXT = "PREDEF_FREETEXT",
}

export interface SelectValue {
  value: string;
  label: string;
  selected: boolean;
}

const QUERY_KEY = "salesProjectSummary";

const getSalesProjectSummary = async (salesProjectId: number) => {
  const { data } = await axios.get<CartSummaryModel>(
    "/api/self-service/checkout/summary",
    {
      params: { salesProjectId },
    }
  );
  return data;
};

const useSalesProjectSummary = (salesProjectId: number) => {
  return useSuspenseQuery<CartSummaryModel, AxiosError>({
    queryKey: [QUERY_KEY, salesProjectId],
    queryFn: () => getSalesProjectSummary(salesProjectId),
    staleTime: 100,
  });
};

export default useSalesProjectSummary;

export const useInvalidateSalesProjectSummary = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
