import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { OptionsModel } from "../options/useOptions";

export type SavePasswordType = Pick<OptionsModel, "password1" | "password2">;

const post = async (options: SavePasswordType) => {
  const { data } = await axios.post<void>("/api/home/password", options);
  return data;
};

const useSavePassword = () => {
  return useMutation<void, AxiosError, SavePasswordType>({
    mutationFn: post,
  });
};
export default useSavePassword;
