export const CONST_ROUTE_ADMIN = "admin";
export const CONST_ROUTE_AGREEMENTS = "agreements";
export const CONST_ROUTE_BUSINESS_TRUNK = "business-trunk";
export const CONST_ROUTE_CONTACT = "contact";
export const CONST_ROUTE_DOCUMENTS = "documents";
export const CONST_ROUTE_HOME = "home";
export const CONST_ROUTE_KST = "kst";
export const CONST_ROUTE_INVOICE = "invoice";
export const CONST_ROUTE_MAINTENANCE = "maintenance";
export const CONST_ROUTE_SELF_SERVICE = "self-service";
export const CONST_ROUTE_ORDERS = "orders";
export const CONST_ROUTE_STATISTICS = "statistics";
export const CONST_ROUTE_SUBSCRIPTION = "subscription";
export const CONST_ROUTE_DDOS = "ddos";
export const CONST_ROUTE_MOBILE_SECURITY = "mobile-security";
export const CONST_ROUTE_MOBILE_SECURITY_DEVICES = "devices";

export const CONST_ROUTE_STATISTICS_NET_GRAPHS = "net-graphs";
export const CONST_ROUTE_STATISTICS_VOIP_GRAPHS = "voip-graphs";
export const CONST_ROUTE_STATISTICS_CFW_GRAPHS = "cfw-graphs";
export const CONST_ROUTE_STATISTICS_ISEC_GRAPHS = "isec-graphs";
export const CONST_ROUTE_STATISTICS_SIMPLE_STATISTICS_VIEW =
  "simple-statistics-view";
export const CONST_ROUTE_STATISTICS_SUBSCRIPTION_SLA = "subscription-sla";
export const CONST_ROUTE_STATISTICS_TOPOLOGY = "topology";
export const CONST_ROUTE_STATISTICS_BASESUBSID_TOPOLOGY_SUBSID =
  ":baseSubsId/topology/:subsId";
export const CONST_ROUTE_STATISTICS_USAGE_REPORT = "usage-report";
export const CONST_ROUTE_STATISTICS_VOICE_REPORT = "voice-report";
export const CONST_ROUTE_STATISTICS_VOICE_REPORT_ADD = "voice-report-add";
export const CONST_ROUTE_STATISTICS_VOICE_REPORT_EDIT = "voice-report-edit";
export const CONST_ROUTE_STATISTICS_HCS = "hcs";
export const CONST_ROUTE_STATISTICS_PROOPTI = "proopti";
export const CONST_ROUTE_SELF_SERVICE_ACCESS_REQUEST = "access-request";
export const CONST_ROUTE_SELF_SERVICE_CARTS = "carts";
export const CONST_ROUTE_SELF_SERVICE_EDIT_COMPONENTS = "edit-components";
export const CONST_ROUTE_SELF_SERVICE_CATALOG = "catalog";
export const CONST_ROUTE_SELF_SERVICE_INDEX = "index";
export const CONST_ROUTE_SELF_SERVICE_BATCH_UPDATE = "batch-update";
export const CONST_ROUTE_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT =
  "punchout-session-timeout";
export const CONST_ROUTE_SELF_SERVICE_TERMS_OF_SERVICE = "terms-of-service";
export const CONST_ROUTE_SELF_SERVICE_CHECKOUT_CART = "checkout/cart";
export const CONST_ROUTE_SELF_SERVICE_CHECKOUT_SUMMARY = "checkout/summary";
export const CONST_ROUTE_SELF_SERVICE_SUBSCRIPTION_OWNERSHIP_REQUEST =
  "subscription/ownership-request";
export const CONST_ROUTE_SELF_SERVICE_SUBSCRIPTION_IMPORT_ADD =
  "subscription/import/add";
export const CONST_ROUTE_SELF_SERVICE_CHECKOUT_CONFIRMATION =
  "checkout/confirmation";
export const CONST_ROUTE_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION =
  "checkout/additional-information";
export const CONST_ROUTE_SELF_SERVICE_PRODUCT = "product";
export const CONST_ROUTE_ORDERS_ORDERS = "orders";
export const CONST_ROUTE_ORDERS_ORDER = "order";
export const CONST_ROUTE_ORDERS_ORDER_HISTORY = "orders-history";
export const CONST_ROUTE_MAINTENANCE_OVERVIEW = "overview";
export const CONST_ROUTE_MAINTENANCE_PLANNED_WORKS = "planned-works";
export const CONST_ROUTE_MAINTENANCE_NET_STATUS = "net-status";
export const CONST_ROUTE_MAINTENANCE_NET_STATUS_HISTORY = "net-status-history";
export const CONST_ROUTE_MAINTENANCE_SERVICE_CLOUD = "servicecloud";
export const CONST_ROUTE_MAINTENANCE_CASES = "cases";
export const CONST_ROUTE_MAINTENANCE_TICKET = "ticket";
export const CONST_ROUTE_MAINTENANCE_CREATE_CASE = "create-case";
export const CONST_ROUTE_MAINTENANCE_CREATE_CASE_CONFIRM =
  "create-case/confirm";
export const CONST_ROUTE_KST_SERVICE_REQUEST = "service-request";
export const CONST_ROUTE_KST_CHOOSE_SERVICE_REQUEST = "choose-service-request";
export const CONST_ROUTE_KST_TICKETS_LIST = "tickets/list";
export const CONST_ROUTE_INVOICE_ANALYSIS = "analysis";
export const CONST_ROUTE_INVOICE_DIMENSIONS = "dimensions";
export const CONST_ROUTE_INVOICE_INDEX = "index";
export const CONST_ROUTE_INVOICE_INVOICES = "invoices";
export const CONST_ROUTE_INVOICE_INVOICE = "invoice";
export const CONST_ROUTE_INVOICE_INVOICE_RECIPIENTS = "invoice-recipients";
export const CONST_ROUTE_INVOICE_INVOICE_WINDOW = "invoice-window";
export const CONST_ROUTE_HOME_HOME = "home";
export const CONST_ROUTE_HOME_RELEASE_NOTES = "release-notes";
export const CONST_ROUTE_HOME_CUSTOMER_INFO = "customer-info";
export const CONST_ROUTE_HOME_USER_REPORTS = "user-reports";
export const CONST_ROUTE_HOME_SEARCH = "search";
export const CONST_ROUTE_HOME_OPTIONS = "options";
export const CONST_ROUTE_HOME_OPTIONS_OWN_CONTACT_ROLES =
  "options-own-contact-roles";
export const CONST_ROUTE_COOKIE_POLICY = "cookiepolicy";
export const CONST_ROUTE_ELTEL_CMS = "eltel/cms";
export const CONST_ROUTE_DOCUMENTS_FOLDERS = "folders";
export const CONST_ROUTE_DOCUMENTS_PRODUCT_DOCUMENTS = "product-documents";
export const CONST_ROUTE_CONTACT_CONTACT_INFO = "contact-info";
export const CONST_ROUTE_CONTACT_CONTACT_INVOLVEMENT = "contact-involvement";
export const CONST_ROUTE_CONTACT_CUSTOMER_CONTACT = "customer-contact";
export const CONST_ROUTE_CONTACT_CUSTOMER_SERVICE = "customer-service";
export const CONST_ROUTE_BUSINESSTRUNK_TRUNK_SUBSCRIPTIONS =
  "trunk-subscriptions";
export const CONST_ROUTE_AGREEMENTS_INDEX = "index";
export const CONST_ROUTE_ADMIN_END_USER_MODULES = "end-user-modules";
export const CONST_ROUTE_ADMIN_USERS = "users";
export const CONST_ROUTE_ADMIN_USER_LOG = "user-log";
export const CONST_ROUTE_ADMIN_USER = "user/:id";
export const CONST_ROUTE_ADMIN_NEW_USER = "user";
export const CONST_ROUTE_ADMIN_GROUPS = "groups";
export const CONST_ROUTE_ADMIN_GROUP = "group/:id";

export const CONST_ROUTE_SUBSCRIPTION_ACCOUNTS = ":baseSubsId/accounts";
export const CONST_ROUTE_SUBSCRIPTION_DRILL_DOWN = ":baseSubsId/drill-down";
export const CONST_ROUTE_SUBSCRIPTION_MST_ACTIONS = ":baseSubsId/mst-actions";
export const CONST_ROUTE_SUBSCRIPTION_OVERVIEW = "overview";
export const CONST_ROUTE_END_USER = "end-users";
export const CONST_ROUTE_END_USER_PRODUCT_CATALOG = "product-catalog";
export const CONST_ROUTE_END_USER_PRODUCT = "product";
export const CONST_ROUTE_END_USER_SUBSCRIPTIONS = "subscriptions";
export const CONST_ROUTE_END_USER_ORDER_DELIVERY = "order-delivery";
export const CONST_ROUTE_END_USER_DOCUMENTS = "documents";
export const CONST_ROUTE_SUBSCRIPTION_GROUPS = "groups";
export const CONST_ROUTE_SUBSCRIPTION_SITES = "sites";
export const CONST_ROUTE_SUBSCRIPTION_CONTACTS = "contacts";
export const CONST_ROUTE_SUBSCRIPTION_WINDOW = "subscription-window";
export const CONST_ROUTE_SUBSCRIPTION_DOCUMENTS = "documents";
export const CONST_ROUTE_SUBSCRIPTION_SUBSCRIPTION = "subscription";
export const CONST_ROUTE_SUBSCRIPTION_PRICE_LIST = "price-list";
export const CONST_ROUTE_SUBSCRIPTION_ALIAS = "alias";
export const CONST_ROUTE_SUBSCRIPTION_ALIAS_PREVIEW = "alias-preview";
export const CONST_ROUTE_SUBSCRIPTION_END_CUSTOMERS = "end-customers";
export const CONST_ROUTE_SUBSCRIPTION_MERIDIX = "meridix";
export const CONST_ROUTE_SUBSCRIPTION_REFRESH = "refresh";
export const CONST_ROUTE_SUBSCRIPTION_REFRESH_CONFIRM = "refresh/confirm";
export const CONST_ROUTE_SUBSCRIPTION_MAP = "map";
export const CONST_ROUTE_DDOS_INDEX = "index";
export const CONST_ROUTE_DDOS_REPORT = "report/:subsId";

export const CONST_URL_STATISTICS_INDEX = `/${CONST_ROUTE_STATISTICS}`;
export const CONST_URL_STATISTICS_NET_GRAPHS = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_NET_GRAPHS}`;
export const CONST_URL_STATISTICS_VOIP_GRAPHS = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_VOIP_GRAPHS}`;
export const CONST_URL_STATISTICS_CFW_GRAPHS = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_CFW_GRAPHS}`;
export const CONST_URL_STATISTICS_ISEC_GRAPHS = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_ISEC_GRAPHS}`;
export const CONST_URL_STATISTICS_SIMPLE_STATISTICS_VIEW = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_SIMPLE_STATISTICS_VIEW}`;
export const CONST_URL_STATISTICS_SUBSCRIPTION_SLA = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_SUBSCRIPTION_SLA}`;
export const CONST_URL_STATISTICS_TOPOLOGY = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_TOPOLOGY}`;
export const CONST_URL_STATISTICS_BASESUBSID_TOPOLOGY_SUBSID = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_BASESUBSID_TOPOLOGY_SUBSID}`;
export const CONST_URL_STATISTICS_USAGE_REPORT = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_USAGE_REPORT}`;
export const CONST_URL_STATISTICS_VOICE_REPORT = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_VOICE_REPORT}`;
export const CONST_URL_STATISTICS_VOICE_REPORT_ADD = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_VOICE_REPORT_ADD}`;
export const CONST_URL_STATISTICS_VOICE_REPORT_EDIT = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_VOICE_REPORT_EDIT}`;
export const CONST_URL_STATISTICS_HCS = `/${CONST_ROUTE_STATISTICS}/${CONST_ROUTE_STATISTICS_HCS}`;
export const CONST_URL_SELF_SERVICE_CARTS = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CARTS}`;
export const CONST_URL_SELF_SERVICE_CATALOG = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CATALOG}`;
export const CONST_URL_SELF_SERVICE_CHECKOUT_CART = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CHECKOUT_CART}`;
export const CONST_URL_SELF_SERVICE_CHECKOUT_SUMMARY = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CHECKOUT_SUMMARY}`;
export const CONST_URL_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION}`;
export const CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CHECKOUT_CONFIRMATION}`;
export const CONST_URL_SELF_SERVICE_TERMS_OF_SERVICE = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_TERMS_OF_SERVICE}`;
export const CONST_URL_SELF_SERVICE_INDEX = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_INDEX}`;
export const CONST_URL_SELF_SERVICE_SUBSCRIPTION_IMPORT = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_SUBSCRIPTION_OWNERSHIP_REQUEST}`;
export const CONST_URL_SELF_SERVICE_BATCH_UPDATE = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_BATCH_UPDATE}`;
export const CONST_URL_SELF_SERVICE_PRODUCT = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_PRODUCT}`;
export const CONST_URL_SELF_SERVICE_EDIT_COMPONENTS = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_EDIT_COMPONENTS}`;
export const CONST_URL_SELF_SERVICE_ACCESS_REQUEST = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_ACCESS_REQUEST}`;
export const CONST_URL_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT = `/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT}`;
export const CONST_URL_ORDERS_INDEX = `/${CONST_ROUTE_ORDERS}`;
export const CONST_URL_ORDERS_ORDERS = `/${CONST_ROUTE_ORDERS}/${CONST_ROUTE_ORDERS_ORDERS}`;
export const CONST_URL_ORDERS_ORDER = `/${CONST_ROUTE_ORDERS}/${CONST_ROUTE_ORDERS_ORDER}`;
export const CONST_URL_ORDERS_ORDER_HISTORY = `/${CONST_ROUTE_ORDERS}/${CONST_ROUTE_ORDERS_ORDER_HISTORY}`;
export const CONST_URL_MAINTENANCE_INDEX = `/${CONST_ROUTE_MAINTENANCE}`;
export const CONST_URL_MAINTENANCE_OVERVIEW = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_OVERVIEW}`;
export const CONST_URL_MAINTENANCE_PLANNED_WORKS = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_PLANNED_WORKS}`;
export const CONST_URL_MAINTENANCE_NET_STATUS = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_NET_STATUS}`;
export const CONST_URL_MAINTENANCE_NET_STATUS_HISTORY = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_NET_STATUS_HISTORY}`;
export const CONST_URL_MAINTENANCE_SERVICE_CLOUD = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_SERVICE_CLOUD}`;
export const CONST_URL_MAINTENANCE_CASES = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_CASES}`;
export const CONST_URL_MAINTENANCE_TICKET = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_TICKET}`;
export const CONST_URL_MAINTENANCE_CREATE_CASE = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_CREATE_CASE}`;
export const CONST_URL_MAINTENANCE_CREATE_CASE_CONFIRM = `/${CONST_ROUTE_MAINTENANCE}/${CONST_ROUTE_MAINTENANCE_CREATE_CASE_CONFIRM}`;
export const CONST_URL_KST_SERVICE_REQUEST = `/${CONST_ROUTE_KST}/${CONST_ROUTE_KST_SERVICE_REQUEST}`;
export const CONST_URL_KST_CHOOSE_SERVICE_REQUEST = `/${CONST_ROUTE_KST}/${CONST_ROUTE_KST_CHOOSE_SERVICE_REQUEST}`;
export const CONST_URL_KST_TICKETS_LIST = `/${CONST_ROUTE_KST}/${CONST_ROUTE_KST_TICKETS_LIST}`;
export const CONST_URL_INVOICE_ANALYSIS = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_ANALYSIS}`;
export const CONST_URL_INVOICE_DIMENSIONS = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_DIMENSIONS}`;
export const CONST_URL_INVOICE_INDEX = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_INDEX}`;
export const CONST_URL_INVOICE_INVOICES = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_INVOICES}`;
export const CONST_URL_INVOICE_INVOICE = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_INVOICE}`;
export const CONST_URL_INVOICE_INVOICE_RECIPIENTS = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_INVOICE_RECIPIENTS}`;
export const CONST_URL_INVOICE_INVOICE_WINDOW = `/${CONST_ROUTE_INVOICE}/${CONST_ROUTE_INVOICE_INVOICE_WINDOW}`;
export const CONST_URL_HOME_HOME = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_HOME_HOME}`;
export const CONST_URL_HOME_RELEASE_NOTES = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_HOME_RELEASE_NOTES}`;
export const CONST_URL_HOME_CUSTOMER_INFO = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_HOME_CUSTOMER_INFO}`;
export const CONST_URL_HOME_USER_REPORTS = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_HOME_USER_REPORTS}`;
export const CONST_URL_HOME_OPTIONS = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_HOME_OPTIONS}`;
export const CONST_URL_COOKIE_POLICY = `/${CONST_ROUTE_COOKIE_POLICY}`;
export const CONST_URL_ELTEL_CMS = `/${CONST_ROUTE_HOME}/${CONST_ROUTE_ELTEL_CMS}`;
export const CONST_URL_DOCUMENTS_FOLDERS = `/${CONST_ROUTE_DOCUMENTS}/${CONST_ROUTE_DOCUMENTS_FOLDERS}`;
export const CONST_URL_DOCUMENTS_PRODUCT_DOCUMENTS = `/${CONST_ROUTE_DOCUMENTS}/${CONST_ROUTE_DOCUMENTS_PRODUCT_DOCUMENTS}`;
export const CONST_URL_CONTACT_INDEX = `/${CONST_ROUTE_CONTACT}`;
export const CONST_URL_CONTACT_CONTACT_INFO = `/${CONST_ROUTE_CONTACT}/${CONST_ROUTE_CONTACT_CONTACT_INFO}`;
export const CONST_URL_CONTACT_CONTACT_INVOLVEMENT = `/${CONST_ROUTE_CONTACT}/${CONST_ROUTE_CONTACT_CONTACT_INVOLVEMENT}`;
export const CONST_URL_CONTACT_CUSTOMER_CONTACT = `/${CONST_ROUTE_CONTACT}/${CONST_ROUTE_CONTACT_CUSTOMER_CONTACT}`;
export const CONST_URL_CONTACT_CUSTOMER_SERVICE = `/${CONST_ROUTE_CONTACT}/${CONST_ROUTE_CONTACT_CUSTOMER_SERVICE}`;
export const CONST_URL_BUSINESSTRUNK_TRUNK_SUBSCRIPTIONS = `/${CONST_ROUTE_BUSINESS_TRUNK}/${CONST_ROUTE_BUSINESSTRUNK_TRUNK_SUBSCRIPTIONS}`;
export const CONST_URL_AGREEMENTS_INDEX = `/${CONST_ROUTE_AGREEMENTS}/${CONST_ROUTE_AGREEMENTS_INDEX}`;
export const CONST_URL_ADMIN_INDEX = `/${CONST_ROUTE_ADMIN}`;
export const CONST_URL_ADMIN_USER_LOG = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_USER_LOG}`;
export const CONST_URL_ADMIN_END_USER_MODULES = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_END_USER_MODULES}`;
export const CONST_URL_ADMIN_GROUP = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_GROUP}`;
export const CONST_URL_ADMIN_GROUPS = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_GROUPS}`;
export const CONST_URL_ADMIN_USERS = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_USERS}`;
export const CONST_URL_ADMIN_USER = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_USER}`;
export const CONST_URL_ADMIN_NEW_USER = `/${CONST_ROUTE_ADMIN}/${CONST_ROUTE_ADMIN_NEW_USER}`;
export const CONST_URL_SUBSCRIPTION_ACCOUNTS = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_ACCOUNTS}`;
export const CONST_URL_SUBSCRIPTION_DRILL_DOWN = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_DRILL_DOWN}`;
export const CONST_URL_SUBSCRIPTION_DOCUMENTS = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_DOCUMENTS}`;
export const CONST_URL_SUBSCRIPTION_MST_ACTIONS = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_MST_ACTIONS}`;
export const CONST_URL_SUBSCRIPTION_OVERVIEW = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_OVERVIEW}`;
export const CONST_URL_SUBSCRIPTION_GROUPS = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_GROUPS}`;
export const CONST_URL_SUBSCRIPTION_SITES = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_SITES}`;
export const CONST_URL_SUBSCRIPTION_WINDOW = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_WINDOW}`;
export const CONST_URL_SUBSCRIPTION_INDEX = `/${CONST_ROUTE_SUBSCRIPTION}`;
export const CONST_URL_SUBSCRIPTION_SUBSCRIPTION = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_SUBSCRIPTION}`;
export const CONST_URL_SUBSCRIPTION_PRICE_LIST = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_PRICE_LIST}`;
export const CONST_URL_SUBSCRIPTION_ALIAS = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_ALIAS}`;
export const CONST_URL_SUBSCRIPTION_ALIAS_PREVIEW = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_ALIAS_PREVIEW}`;
export const CONST_URL_SUBSCRIPTION_MAP = `/${CONST_ROUTE_SUBSCRIPTION}/${CONST_ROUTE_SUBSCRIPTION_MAP}`;
export const CONST_URL_DDOS_INDEX = `/${CONST_ROUTE_DDOS}/${CONST_ROUTE_DDOS_INDEX}`;
export const CONST_URL_DDOS_REPORT = `/${CONST_ROUTE_DDOS}/${CONST_ROUTE_DDOS_REPORT}`;
export const CONST_URL_MOBILE_SECURITY_DEVICES = `/${CONST_ROUTE_MOBILE_SECURITY}/${CONST_ROUTE_MOBILE_SECURITY_DEVICES}`;
export const CONST_URL_END_USER_PRODUCT_CATALOG = `/${CONST_ROUTE_END_USER}/${CONST_ROUTE_END_USER_PRODUCT_CATALOG}`;
export const CONST_URL_END_USER_PRODUCT = `/${CONST_ROUTE_END_USER}/${CONST_ROUTE_END_USER_PRODUCT}`;
export const CONST_URL_END_USER_SUBSCRIPTIONS = `/${CONST_ROUTE_END_USER}/${CONST_ROUTE_END_USER_SUBSCRIPTIONS}`;
export const CONST_URL_END_USER_ORDER_DELIVERY = `/${CONST_ROUTE_END_USER}/${CONST_ROUTE_END_USER_ORDER_DELIVERY}`;
export const CONST_URL_END_USER_DOCUMENTS = `/${CONST_ROUTE_END_USER}/${CONST_ROUTE_END_USER_DOCUMENTS}`;

export const getURLWithParams = (url: string, params: unknown[]) => {
  let index = 0;
  return url
    .split("/")
    .map((value) => {
      if (value.indexOf(":") === 0) {
        return params[index++];
      }
      return value;
    })
    .join("/");
};
