import { color } from "@tele2/t2-ui-kit";
import { useModuleReadRight } from "../../api/session/useModuleRight";
import * as React from "react";
import {
  openCookieBanner,
  useAcceptFunctionality,
} from "../../Routes/CookiePolicy/CookiePolicy";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Chat: React.FC = () => {
  const { t } = useTranslation();
  const chatEnabled = useModuleReadRight("IM");
  const acceptFunctionality = useAcceptFunctionality();
  if (!chatEnabled || acceptFunctionality) {
    return null;
  }
  return (
    <ButtonWrapper
      onClick={openCookieBanner}
      data-testid="chat-button-settings"
    >
      <i className="fa fa-commenting" />
      &nbsp;{t("module.cookie.chat")}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  background: ${color.BRAND_BLACK};
  border: none;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  padding: 10px;
  margin: 0;
  position: fixed;
  bottom: 0;
  right: 12px;
  height: 46px;
  z-index: 1000;
  color: white;
`;

export default Chat;
