import * as React from "react";
import { createPortal } from "react-dom";

class Portal extends React.Component<{
  children: React.ReactNode;
  containerId: string;
}> {
  modalRoot = document.getElementById(this.props.containerId);
  container = document.createElement("div");

  public componentDidMount(): void {
    this.modalRoot?.appendChild(this.container);
  }

  public componentWillUnmount(): void {
    this.modalRoot?.removeChild(this.container);
  }

  public render(): React.ReactElement {
    return createPortal(this.props.children, this.container);
  }
}

export default Portal;
