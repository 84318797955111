import { IOpportunityCaseData } from "../../../../api/self-service/edit-components-model/useEditComponentsModel";
import { RoleType } from "../../../../api/self-service/types";
import * as React from "react";
import Case from "./Case";
import { useTranslation } from "react-i18next";

interface CasesProps {
  cases: IOpportunityCaseData[];
  oppRowId: number;
  roleType: RoleType;
}

const Cases: React.FC<CasesProps> = ({ cases, oppRowId, roleType }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-sm-12 header-row">
          {t("module.self.service.case.data")}
        </div>
      </div>
      {cases.map((c) => (
        <Case key={c.id} caseData={c} oppRowId={oppRowId} roleType={roleType} />
      ))}
    </>
  );
};
export default Cases;
