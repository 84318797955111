import { AppRouteProps } from "../types";
import * as React from "react";

const Devices = React.lazy(() => import("./Devices"));
const Events = React.lazy(() => import("./Events"));

const routes: AppRouteProps[] = [
  {
    path: "devices",
    component: Devices,
  },
  {
    path: "events",
    component: Events,
  },
];

export default routes;
