import {
  HeaderButton,
  PopupManager,
  useIsMobile,
  UserMenu,
} from "@tele2/t2-ui-kit";

import AccountCircleOutlined from "@tele2/t2-ui-kit/build/Icon/icons/AccountCircleOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface HeaderUserMenuItemProps {
  hidden?: boolean;
  icon: React.ReactElement;
  label: string;
  component?: React.ReactElement;
  clickHandler?: () => void;
}

export interface HeaderUserMenuProps {
  backLabel?: string;
  menuItems?: (onClose?: () => void) => HeaderUserMenuItemProps[];
  mobileTitle: string;
  user?: {
    firstName?: string;
    lastName?: string;
    customerName?: string;
  };
  numberOfNotifications?: number;
}

const UserMenuButton: React.FC<HeaderUserMenuProps> = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <PopupManager
      align="right"
      fullWidth={isMobile}
      element={() => (
        <HeaderButton
          aria-label={t("general.open.user.menu")}
          notification={
            props.numberOfNotifications
              ? String(props.numberOfNotifications)
              : undefined
          }
        >
          <AccountCircleOutlined />
        </HeaderButton>
      )}
    >
      {(onClose) => <UserMenu {...props} onClose={onClose} />}
    </PopupManager>
  );
};

export default UserMenuButton;
