import * as React from "react";
import { useEffect } from "react";

const useDetectOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current?.contains(e.target as Node)) {
        callback();
      }
    };
    // The Block component stops propagtion of mousedown events
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref]);
};
export default useDetectOutsideClick;
