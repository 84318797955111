import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { Subscription } from "../../subscription/subscription-view/useSubscriptionModel";
import { SalesProject } from "../carts/carts-types";
import { CartActionPermissions } from "./useSalesProjectSummaryValidation";

export interface OrderModel {
  container: OpportunityRowContainer;
  permissions: CartActionPermissions;
  activeSalesProject?: boolean;
  addOppRows?: boolean;
  allowAccessSearch?: boolean;
  hasAddMoreProducts?: boolean;
  showPriceSummary: boolean;
  salesProject: SalesProject;
  tableSessionId: number;
}

export interface OpportunityPrice {
  amount: string;
  currency: string;
}

export interface OpportunityGroup {
  rows: OpportunityRow[];
  packageInstanceId: number;
  packageName: string | null;
  baseSubsId: number;
  types: {
    id: number;
    description: string;
  }[];
  shadow: boolean;
  attributesViewable: boolean;
}

export interface OpportunityRow {
  id: number;
  packageInstanceId: number;
  packageName?: string | null;
  baseSubsId: number;
  dkId: number | null;
  attributesEditable: boolean;
  shadow: boolean;
  contactsEditable: boolean;
  attributesViewable: boolean;
  validations?: Array<Record<string, unknown>>;
  onetimePrices: OpportunityPrice[];
  recurringPrices: OpportunityPrice[];
  hourlyPrices: OpportunityPrice[];
  addonsEditable: boolean;
  selectedInSession: boolean;
  selectDisabled: boolean;
  statusId: number;
  subscription: Subscription;
  types: {
    id: number;
    description: string;
  }[];
  level: number;
  status: string;
  invoiceRecipientId: number;
  keyAttributes: string[];
  keyAttr?: string | null;
  productCatalogueRowId: number;
  itemPictureUrl: string | null;
  detailedPrice: boolean;
  notCustomerSpecificPrice: boolean;
  aliasRequired: boolean;
  aliasEditable: boolean;
  aliasViewable: boolean;
  invoiceRecipientEditable: boolean;
  invoiceRecipientViewable: boolean;
  contactsViewable: boolean;
  quantityEnabled: boolean;
  quantity: number;
  canDuplicate: boolean;
}

export interface OpportunityRowContainer {
  totalNumRows: number;
  numFilteredRows: number;
  numPagingItems: number;
  opportunityGroups: OpportunityGroup[];
  oppTypeFilterOptions: Array<{ id: number; text: string }>;
  prodNameFilterOptions: Array<{ id: number; text: string }>;
  recurringPricesTotal: OpportunityPrice[];
  onetimePricesTotal: OpportunityPrice[];
  bundleDiscountDisclaimer: boolean;
}

const getSalesProjectOppRows = async (
  salesProjectId: number
): Promise<OrderModel> => {
  const { data } = await axios.get(
    "/api/self-service/checkout/summary-opportunity-rows",
    { params: { salesProjectId } }
  );
  return data;
};

const QUERY_KEY = "getSalesProjectOppRows";
const useSalesProjectOppRows = (salesProjectId: number) => {
  return useSuspenseQuery<OrderModel, AxiosError>({
    queryKey: [QUERY_KEY, salesProjectId],
    queryFn: () => getSalesProjectOppRows(salesProjectId),
    staleTime: 1000,
  });
};

export default useSalesProjectOppRows;

export const useInvalidateSalesProjectOppRows = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
