import WideMessageItem from "./WideMessageItem";
import * as React from "react";
import { MessagesContainer } from "./message-styles";
import { ContextType, MessageType } from "./message-types";
import MessageItem from "./MessageItem";

const MessagesContext = React.createContext<ContextType>({
  messageQueue: [],
  setMessageQueue: () => null,
  wideMessagesQueue: [],
  setWideMessagesQueue: () => null,
});

let id = 0;
export const useMessage = (): ((m: MessageType) => void) => {
  const { setMessageQueue } = React.useContext(MessagesContext);
  return React.useCallback(
    (m: MessageType) => {
      return setMessageQueue((prevState) => [...prevState, { id: ++id, ...m }]);
    },
    [setMessageQueue]
  );
};

export const useWideMessage = (): ((m: MessageType) => void) => {
  const { setWideMessagesQueue } = React.useContext(MessagesContext);
  return React.useCallback(
    (m: MessageType) => {
      return setWideMessagesQueue((old) => [...old, { id: ++id, ...m }]);
    },
    [setWideMessagesQueue]
  );
};

export const MessagesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [messageQueue, setMessageQueue] = React.useState<MessageType[]>([]);
  const [wideMessagesQueue, setWideMessagesQueue] = React.useState<
    MessageType[]
  >([]);
  const value = React.useMemo(() => {
    return {
      messageQueue,
      setMessageQueue,
      wideMessagesQueue,
      setWideMessagesQueue,
    };
  }, [messageQueue, wideMessagesQueue]);
  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
};

const Message: React.FC = () => {
  const { messageQueue, setMessageQueue } = React.useContext(MessagesContext);
  const { wideMessagesQueue, setWideMessagesQueue } =
    React.useContext(MessagesContext);
  return (
    <MessagesContainer data-testid="messages">
      {messageQueue.map((m: MessageType) => (
        <MessageItem key={m.id} setMessageQueue={setMessageQueue} {...m} />
      ))}
      {wideMessagesQueue.map((m: MessageType) => (
        <WideMessageItem
          key={m.id}
          setWideMessagesQueue={setWideMessagesQueue}
          {...m}
        />
      ))}
    </MessagesContainer>
  );
};

export default Message;
