import classNames from "classnames";
import * as React from "react";
import useSaveAttribute from "../../../api/self-service/save-attribute/useSaveAttribute";
import useUpdateMatrixRow from "../../../api/self-service/update-matrix-row/useUpdateMatrixRow";
import {
  useUpdateAllSlaveAttributes,
  useUpdateOverviewAttribute,
} from "../../component/ComponentsContext";
import { AttributeProps } from "../AttributeRow";

const CheckboxAttribute: React.FC<AttributeProps> = ({
  readOnly,
  isMatrixCell,
  subsId,
  prodCompAttrId,
  attrData,
  required,
  hash,
  hiddenCompAttrIds,
  label,
  compAttrId,
  matrixRowId,
  oppRowId,
  overViewAttribute,
  prodCompId,
  parentProdCompAttrId,
}) => {
  const attrDataString = attrData || "";
  const [inputValue, setInputValue] = React.useState(attrData || "0");
  React.useEffect(() => {
    setInputValue(attrData || "0");
  }, [attrData]);
  const { saveAttribute } = useSaveAttribute(label);
  const { updateMatrixRow } = useUpdateMatrixRow(label, parentProdCompAttrId);
  const updateOverviewAttribute = useUpdateOverviewAttribute();
  const updateAllSlaveAttributes = useUpdateAllSlaveAttributes(label);
  const saveValue = React.useCallback(
    async (newValue: string) => {
      if (readOnly) {
        return;
      }
      if (newValue === attrDataString) {
        return;
      }
      setInputValue(newValue);
      if (isMatrixCell) {
        updateMatrixRow({
          data: newValue,
          matrixRowId,
          subsId,
          compAttrId,
          oppRowId,
          prodCompId,
        });
      } else {
        const response = await saveAttribute({
          value: newValue,
          hash,
          compAttrId,
          prodCompAttrId,
          hiddenCompAttrIds,
          subsId,
          prodCompId,
        });
        const validSave = response.validationCode === "OK";
        if (overViewAttribute) {
          updateOverviewAttribute(subsId, prodCompId, prodCompAttrId, response);
          if (validSave) {
            // If this is an overview field, also update slave fields
            await updateAllSlaveAttributes({
              value: newValue,
              hash,
              compAttrId,
              prodCompAttrId,
              hiddenCompAttrIds,
              subsId,
              prodCompId,
            });
          }
        }
      }
    },
    [
      attrDataString,
      compAttrId,
      hash,
      hiddenCompAttrIds,
      isMatrixCell,
      matrixRowId,
      oppRowId,
      overViewAttribute,
      prodCompAttrId,
      prodCompId,
      readOnly,
      saveAttribute,
      subsId,
      updateAllSlaveAttributes,
      updateMatrixRow,
      updateOverviewAttribute,
    ]
  );
  const inputClasses = classNames(
    isMatrixCell ? "attr-matrix-value" : "attr-value",
    required ? "attr-required" : "",
    attrData !== inputValue ? "attr-unsaved-change" : ""
  );
  const id = `attr_${subsId}_${prodCompAttrId}`;
  return (
    <input
      data-cy={prodCompAttrId}
      className={inputClasses}
      id={id}
      name={id}
      type="checkbox"
      value="1"
      checked={inputValue === "1"}
      onChange={(e) => saveValue(e.target.checked ? "1" : "0")}
    />
  );
};

export default CheckboxAttribute;
