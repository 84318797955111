import { Button } from "@tele2/t2-ui-kit";
import useEsalesProduct from "../../../api/self-service/esales-product/useEsalesProduct";
import { RoleType } from "../../../api/self-service/types";
import Layout from "../../../components/Layout/Layout";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CONST_URL_HOME_HOME, CONST_URL_ORDERS_INDEX } from "../../approutes";
import FooterCart from "../ProductCatalog/FooterCart";
import { useUrlParams } from "../../../utils/useUrlParams";
import ProductDetails from "./components/ProductDetails";
import ProductTabs from "./components/ProductTabs";
import { useTranslation } from "react-i18next";

const breadcrumbs = [
  { link: CONST_URL_HOME_HOME, title: "module.home" },
  { link: CONST_URL_ORDERS_INDEX, title: "module.orders" },
  { link: "", title: "module.self.service.add.products.to.cart" },
];

const ProductPage: React.FC = () => {
  const { t } = useTranslation();
  const baseSubsId = Number(useUrlParams("baseSubsId") || 0);
  const prodId = useUrlParams("prodId");
  const navigate = useNavigate();
  const product = useEsalesProduct(prodId || "", {
    baseSubsId,
    roleType: RoleType.SELF_SERVICE,
  });

  if (!product) return null;
  return (
    <>
      <Layout
        title="module.self.service.add.products.to.cart"
        breadcrumbs={breadcrumbs}
      >
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("general.back")}
        </Button>

        <ProductDetails baseSubsId={baseSubsId} product={product} />
      </Layout>
      <ProductTabs product={product} />
      <FooterCart />
    </>
  );
};

export default ProductPage;
