function toFormData<T>(data: T): FormData {
  const formData = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      (data[key] as unknown as Blob[]).forEach((val) => {
        formData.append(key, val);
      });
    } else {
      formData.append(key, String(data[key]));
    }
  }
  return formData;
}

export default toFormData;
