import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { RoleType } from "../types";

export interface ActiveCart {
  salesProjectId: number;
  roleType: RoleType;
  subRoleType: RoleType;
  rows: number;
}

const get = async () => {
  const { data } = await axios.get<ActiveCart>(`/api/self-service/cart/active`);
  return data;
};

const QUERY_KEY = "active-cart";
const useActiveCart = () => {
  return useQuery<ActiveCart, AxiosError>({
    queryKey: [QUERY_KEY],
    queryFn: get,
  });
};
export default useActiveCart;

export const useInvalidateActiveCart = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
