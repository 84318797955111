import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { RoleType } from "../types";

interface SaveResult {
  saved: boolean;
  value: null; // Always null
  message: string;
}

interface SaveCaseRequest {
  caseId: number;
  data: string;
  oppRowId: number;
  roleType: RoleType;
}

const post = async (request: SaveCaseRequest) => {
  const { data } = await axios.post<SaveResult>(
    "/api/self-service/save-case",
    null,
    { params: request }
  );
  return data;
};

const useSaveCase = () => {
  const { mutateAsync, isPending } = useMutation<
    SaveResult,
    AxiosError,
    SaveCaseRequest
  >({ mutationFn: post });
  const saveCase = useCallback(
    async (request: SaveCaseRequest) => {
      return await mutateAsync(request);
    },
    [mutateAsync]
  );

  return { saveCase, isPending };
};

export default useSaveCase;
