import { AxiosError } from "axios";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCancelOppRow from "../../../../api/self-service/cancel-opp-row/useCancelOppRow";
import usePlaceOrder from "../../../../api/self-service/checkout/usePlaceOrder";
import { END_USER_MODES } from "../../../../api/self-service/edit-components-model/useEditComponentsModel";
import useGetOppRowValidations, {
  useInvalidateValidation,
} from "../../../../api/self-service/row-validations/useGetOppRowValidations";
import Block from "../../../../components/Block";
import { useComponentsAttributes } from "../../../../components/component/ComponentsContext";
import { useMessage } from "../../../../components/Message/Message";
import { getPath } from "../../../../utils/paths";
import {
  CONST_ROUTE_END_USER,
  CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION,
} from "../../../approutes";

const QuickCaseButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const message = useMessage();
  const { referrer, packageInstanceId, oppRowId, salesProjectId, mode } =
    useComponentsAttributes();
  const [buttonPressed, setButtonPressed] = useState(false);
  const {
    data: validations,
    isFetching: fetchingValidations,
    isSuccess: validationsLoaded,
  } = useGetOppRowValidations(oppRowId, packageInstanceId || 0);
  const isEndUserMode = END_USER_MODES.includes(mode);

  const { placeOrder, isPending: placingOrder } = usePlaceOrder(
    (error: AxiosError) => {
      const errorData = error.response?.data as { reference?: string };
      if (isEndUserMode) {
        let errorMessage = t("general.error");
        if (errorData.reference) {
          errorMessage +=
            "\n" +
            t("module.self.service.checkout.sales.project.error.p3", [
              errorData.reference,
            ]);
        }
        message({
          interval: 6000,
          message: errorMessage,
          theme: "alert",
        });
      } else {
        const reference = errorData.reference
          ? `&reference=${errorData.reference}`
          : "";
        navigate(
          `${CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION}?salesProjectId=${salesProjectId}&failedOrder=1${reference}`
        );
      }
    }
  );
  const doPlaceOrder = React.useCallback(async () => {
    const data = await placeOrder({
      salesProjectId,
    });
    if (!data.notValid) {
      if (isEndUserMode) {
        navigate(CONST_ROUTE_END_USER);
      } else {
        navigate(
          `${CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION}?salesProjectId=${salesProjectId}`
        );
      }
    } else {
      message({
        interval: 6000,
        message: t("general.error"),
        theme: "alert",
      });
    }
  }, [isEndUserMode, message, navigate, placeOrder, salesProjectId, t]);
  const hasValidationErrors =
    validations &&
    validations.groups.filter((g) =>
      g.validations.some((v) => v.type !== "OTHER")
    ).length > 0;
  useEffect(() => {
    if (
      buttonPressed &&
      !fetchingValidations &&
      validationsLoaded &&
      !placingOrder
    ) {
      if (!hasValidationErrors) {
        doPlaceOrder();
      }
      setButtonPressed(false);
    }
  }, [
    hasValidationErrors,
    fetchingValidations,
    validationsLoaded,
    buttonPressed,
    placingOrder,
    doPlaceOrder,
  ]);
  const invalidateValidation = useInvalidateValidation(
    oppRowId,
    packageInstanceId
  );
  const { cancelOppRow, isPending: cancelingOrder } = useCancelOppRow();
  const order = () => {
    invalidateValidation();
    setButtonPressed(true);
  };
  const sendButtonClasses = classNames(
    "btn",
    "btn-primary",
    "btn-responsive",
    hasValidationErrors || placingOrder || fetchingValidations ? "disabled" : ""
  );
  const cancelButtonClasses = classNames(
    "btn",
    "btn-secondary",
    "btn-responsive",
    placingOrder ? "disabled" : ""
  );
  return (
    <>
      {(placingOrder || cancelingOrder) && <Block />}
      <button
        className={cancelButtonClasses}
        disabled={placingOrder}
        onClick={async () => {
          await cancelOppRow({ oppRowId, packageInstanceId, salesProjectId });
          navigate(getPath(referrer));
        }}
      >
        {t("general.cancel")}
      </button>
      <button
        id="send-order-btn"
        onClick={() => {
          order();
        }}
        className={sendButtonClasses}
      >
        {t("module.self.service.send.order")}
      </button>
    </>
  );
};
export default QuickCaseButtons;
