import * as React from "react";
import { AttributeRowProps, Type } from "./AttributeRow";
import TextAttribute from "./TextAttribute/TextAttribute";
import CheckboxAttribute from "./CheckboxAttribute/CheckboxAttribute";
import SelectAttribute from "./SelectAttribute/SelectAttribute";
import TextAreaAttribute from "./TextAreaAttribute/TextAreaAttribute";
import UrlAttribute from "./UrlAttribute/UrlAttribute";
import { TFunction, useTranslation } from "react-i18next";
import { formatDate } from "../../utils/dates";

const AttributeContent: React.FC<AttributeRowProps> = (props) => {
  const { t } = useTranslation();
  if (props.readOnly) {
    return renderReadOnly(t, props.attrData, props.type, props.dateReformat);
  }
  switch (props.type) {
    case Type.TEXT:
      return <TextAttribute {...props} />;
    case Type.CHECK_BOX:
      return <CheckboxAttribute {...props} />;
    case Type.SELECT:
      return <SelectAttribute {...props} />;
    case Type.TEXT_AREA:
      return <TextAreaAttribute {...props} />;
    case Type.URL:
      return <UrlAttribute {...props} />;
  }
  return <>{props.attrData}</>;
};
export default AttributeContent;

const renderReadOnly = (
  t: TFunction<string>,
  attrData: string | null,
  type: Type,
  dateReformat: boolean
) => {
  switch (type) {
    case Type.TEXT:
    case Type.SELECT:
      return <>{dateReformat ? formatDate(attrData || "") : attrData}</>;
    case Type.CHECK_BOX:
      return <>{t(attrData === "1" ? "general.yes" : "general.no")}</>;
    case Type.TEXT_AREA:
      return <pre>{attrData}</pre>;
    case Type.URL:
      return attrData ? (
        <a
          className="attr-type-url"
          target="_blank"
          rel={"noreferrer"}
          href={attrData}
        >
          {attrData}
        </a>
      ) : (
        <></>
      );
  }
  return <>{attrData}</>;
};
