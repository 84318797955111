import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { CartsListOpts, CartsResponse } from "./carts-types";

const QUERY_KEY = "get-carts";

export interface CartsModel extends CartsResponse {
  approvalUserId: number;
  filters: {
    keyId: number;
    text: string;
    type: string;
  }[];
  responsibleId: number;
  roleType: string;
  search?: string;
  statuses: {
    key: string;
    value: string;
  }[];
  statusId: number;
}

const getCarts = async (params: CartsListOpts) => {
  const { data } = await axios.get<CartsModel>("/api/self-service/carts", {
    params,
  });
  return data;
};

const useGetCarts = (params: CartsListOpts) => {
  return useQuery<CartsModel, AxiosError>({
    queryKey: [QUERY_KEY, params],
    queryFn: () => getCarts(params),
    placeholderData: keepPreviousData,
  });
};
export default useGetCarts;

export const useInvalidateGetCarts = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
