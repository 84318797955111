import enUS from "date-fns/locale/en-US";
import enGB from "date-fns/locale/en-GB";
import sv from "date-fns/locale/sv";
import * as React from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";

registerLocale("sv-SE", sv);
registerLocale("sv", sv);
registerLocale("en-US", enUS);
registerLocale("en-GB", enGB);
registerLocale("en", enGB);
setDefaultLocale("en");

const InputGroup = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>(function callback(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onClick, ...rest } = props;
  return (
    <div className="input-group" onClick={onClick}>
      <div className="input-group-prepend">
        <label htmlFor={rest.name}>
          <button className="btn btn-secondary calendar-trigger" type="button">
            <span className="fa fa-calendar" />
          </button>
        </label>
      </div>
      <input
        data-testid={rest.name}
        className="form-control"
        id={rest.name}
        {...rest}
        ref={ref}
        type="text"
      />
    </div>
  );
});

const Datepicker: React.FC<ReactDatePickerProps> = (props) => {
  return (
    <ReactDatePicker
      customInput={<InputGroup />}
      {...props}
      wrapperClassName="input-group"
      locale={document.documentElement.lang || navigator.language}
    />
  );
};
export default Datepicker;
