import { color, DropDown, useIsMobile } from "@tele2/t2-ui-kit";

import * as React from "react";
import styled from "styled-components";
import Filter from "./Filter";
import ProductCatalogList from "./ProductCatalogList";

export interface DropDownValue {
  dropdownLabel: React.ReactNode;
  option: number;
}

const LeftColumnWrapper = styled.div`
  flex: 0 0 25%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0 15px 0 15px;
  }
  @media screen and (min-width: 769px) {
    max-width: 100%;
    margin: 0;
  }
`;

const RightColumnWrapper = styled.div`
  width: 100%;
`;

export const StyledDropDown = styled(DropDown)`
  > #t2-dropdown-undefined {
    border: ${color.BRAND_PREMIUM_GREY} 1px solid;
    border-radius: 4px;
    height: 65px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
  max-width: 1490px;
`;

export const ROOT_DEFAULT = "Root";
const ProductCatalogContainer: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <FlexWrapper>
      {!isMobile && (
        <LeftColumnWrapper>
          <Filter />
        </LeftColumnWrapper>
      )}
      <RightColumnWrapper>
        <ProductCatalogList />
      </RightColumnWrapper>
    </FlexWrapper>
  );
};

export default ProductCatalogContainer;
