import { useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

const QUERY_KEY = "end-user-links";

export interface EndUserLink {
  url: string;
  caption: string;
  description: string;
}

const getEndUsersLinks = async () => {
  const { data } = await axios.get<EndUserLink[]>("/api/end-users/links");
  return data;
};

const useGetEndUsersLinks = () => {
  return useSuspenseQuery<EndUserLink[], AxiosError>({
    queryKey: [QUERY_KEY],
    queryFn: getEndUsersLinks,
  });
};
export default useGetEndUsersLinks;
