import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_ORDERS_ORDER,
  CONST_ROUTE_ORDERS_ORDER_HISTORY,
  CONST_ROUTE_ORDERS_ORDERS,
} from "../approutes";
import * as React from "react";

const Orders = React.lazy(() => import("./Orders"));
const OrdersHistory = React.lazy(() => import("./OrdersHistory"));
const Order = React.lazy(() => import("./Order"));

const routes: AppRouteProps[] = [
  { path: CONST_ROUTE_ORDERS_ORDER, component: Order },
  { path: CONST_ROUTE_ORDERS_ORDERS, component: Orders },
  { path: CONST_ROUTE_ORDERS_ORDER_HISTORY, component: OrdersHistory },
];

export default routes;
