import { color, Flex, Loader, Logo } from "@tele2/t2-ui-kit";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LoadingView: React.FC<{ name?: string }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ background: color.BRAND_LIGHT_GRAY }}
      >
        <StyledLoader>
          <Loader size={80} borderWidth={12} />
        </StyledLoader>
        {name && (
          <LoadingHeader>{t("module.home.welcome.user", [name])}</LoadingHeader>
        )}
        <LoadingText>{t("module.loading.view.header.message")}</LoadingText>
        <StyledLogo>
          <Logo variant={"tele2-b2b"} size={64} />
        </StyledLogo>
      </Flex>
    </>
  );
};
export default LoadingView;

const StyledLogo = styled.div`
  margin-top: auto;
`;

const StyledLoader = styled.div`
  margin-top: auto;
  margin-bottom: 32px;
`;

const LoadingHeader = styled.span`
  font-size: 28px;
  font-weight: 400;
  text-align: center;
`;

const LoadingText = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;
