import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface RevertProjectArgs {
  salesProjectId: number;
  reason: string;
}
const post = async (model: RevertProjectArgs) => {
  const { data } = await axios.post<boolean>(
    "/api/self-service/checkout/revert-project-to-draft",
    null,
    {
      params: model,
    }
  );
  return data;
};

const useRevertProjectToDraft = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation<
    boolean,
    AxiosError,
    RevertProjectArgs
  >({ mutationFn: post });
  const revertProject = useCallback(
    async (request: RevertProjectArgs) => {
      const data = await mutateAsync(request);
      invalidateCheckoutData();
      return data;
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { revertProject, isPending };
};

export default useRevertProjectToDraft;
