import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { SelectedOppRowsResponse } from "./useSetOppRowsSelected";

interface OppRowSessionDataParams {
  tableSessionId: number;
  salesProjectId: number;
}
const getOppRowsTableSessionData = async (params: OppRowSessionDataParams) => {
  const { data } = await axios.get<SelectedOppRowsResponse>(
    "/api/self-service/checkout/opportunity-rows/session-data",
    { params }
  );
  return data;
};

const QUERY_KEY = "opportunity-rows-session-data";
const useOppRowsTableSessionData = (params: OppRowSessionDataParams) =>
  useQuery<SelectedOppRowsResponse, AxiosError>({
    queryKey: [QUERY_KEY, params],
    queryFn: () => getOppRowsTableSessionData(params),
    enabled: Boolean(params.salesProjectId) && Boolean(params.tableSessionId),
  });

export default useOppRowsTableSessionData;

export const useInvalidateOppRowsTableSessionData = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
