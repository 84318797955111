import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export type GlobalLink = {
  url: string;
  caption: string;
  description: string;
};

const getGlobalLinks = async () => {
  const { data } = await axios.get<GlobalLink[]>("/api/home/global-links");
  return data;
};

const useGetGlobalLinks = () => {
  return useQuery<GlobalLink[], AxiosError>({
    queryKey: ["global-links"],
    queryFn: getGlobalLinks,
    staleTime: Infinity,
  });
};

export default useGetGlobalLinks;
