import * as React from "react";
import { useTranslation } from "react-i18next";
import AttributeRow from "../../../components/AttributeRow/AttributeRow";
import Components from "../../../components/component/Components";
import {
  useComponents,
  useComponentsAttributes,
  useInvoiceRecipientState,
  useOneFieldForAllProducts,
  useOverviewAttributesState,
  useUpdateAllInvoiceRecipients,
} from "../../../components/component/ComponentsContext";
import Alias from "./Alias";
import Cases from "./Cases";
import Contact from "./Contact";
import IvrComponent from "./InvoiceRecipient";

const ComponentsView: React.FC = () => {
  const { t } = useTranslation();
  const {
    oppRowId,
    packageInstanceId,
    roleType,
    firstRow,
    cases,
    contactsEditable,
    salesProjectId,
  } = useComponentsAttributes();
  const [components] = useComponents();
  const [invoiceRecipientState, setInvoiceRecipientState] =
    useInvoiceRecipientState();
  const updateAllInvoiceRecipients = useUpdateAllInvoiceRecipients();
  const [oneFieldForAllProducts, setOneFieldForAllProducts] =
    useOneFieldForAllProducts();
  const [overviewAttributes] = useOverviewAttributesState();
  return (
    <div className="component-container">
      <div className="components">
        {contactsEditable && (
          <Contact oppRowId={oppRowId} salesProjectId={salesProjectId} />
        )}
        {packageInstanceId !== 0 && (
          <>
            <div className="row attr-row">
              <div className="col-sm-12">
                <h3>{t("module.self.service.package.common.fields")}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-6">
                <label className="attr-label" htmlFor="alias-input">
                  {t("module.self.service.package.show.common.fields")}
                </label>
              </div>
              <div className="col-sm-6 col-6">
                <div className="form-group">
                  <input
                    className="prod-package-showfields-value"
                    id="prod-package-showfields"
                    name="prod-package-showfields"
                    type="checkbox"
                    value="1"
                    checked={oneFieldForAllProducts}
                    onChange={() =>
                      setOneFieldForAllProducts(!oneFieldForAllProducts)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {firstRow?.aliasViewable && <Alias />}
        {firstRow?.invoiceRecipientViewable && (
          <IvrComponent
            invoiceRecipient={invoiceRecipientState.invoiceRecipient}
            invoiceRecipientEditable={firstRow.invoiceRecipientEditable}
            invoiceRecipientValidationMessage={
              invoiceRecipientState.invoiceRecipientValidationMessage
            }
            oppRowId={oppRowId}
            slaveField={false}
            onSave={async (newInvoiceRecipientState) => {
              setInvoiceRecipientState(newInvoiceRecipientState);
              await updateAllInvoiceRecipients(
                newInvoiceRecipientState.invoiceRecipient
              );
            }}
          />
        )}
        {overviewAttributes?.map((attribute) => {
          return (
            <AttributeRow
              key={attribute.prodCompAttrId}
              overViewAttribute={true}
              hidden={false}
              {...attribute}
              slaveField={false}
              oneFieldForAllProducts={oneFieldForAllProducts}
            />
          );
        })}

        <Components />

        {cases && cases.length > 0 && (
          <Cases cases={cases} oppRowId={oppRowId} roleType={roleType} />
        )}
      </div>
      {components && components.length > 0 && (
        <p>
          <span className="input-required">*</span>
          {t("general.required")}
        </p>
      )}
    </div>
  );
};
export default ComponentsView;
