import { Button } from "@tele2/t2-ui-kit";
import { LoaderSpinner } from "@tele2/t2-ui-kit/build/Loader/LoaderSpinner";
import ModalBody from "@tele2/t2-ui-kit/build/Modal/ModalBody";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useDeleteOpportunityRow from "../../../../api/self-service/delete-opportunity-row/useDeleteOpportunityRow";
import useDeleteOpportunityRows, {
  DeleteOpportunityRowsModel,
} from "../../../../api/self-service/delete-opportunity-rows/useDeleteOpportunityRows";
import ModalFooter from "../../../../components/modal/ModalFooter";
import ReactModal from "../../../../components/modal/ReactModal";

export interface ConfirmDeleteOppRowModalProps {
  onClose: () => void;
  oppRowId?: number;
  packageInstanceId?: number;
  confirmMode: "SINGLE" | "BATCH";
  deleteOppRowsModel?: DeleteOpportunityRowsModel;
}

const ConfirmDeleteOppRowModal: React.FC<ConfirmDeleteOppRowModalProps> = ({
  onClose,
  oppRowId,
  packageInstanceId,
  confirmMode,
  deleteOppRowsModel,
}) => {
  const { t } = useTranslation();
  const { deleteOpportunityRows, isPending: batchDeleteLoading } =
    useDeleteOpportunityRows();
  const { deleteOpportunityRow, isPending } = useDeleteOpportunityRow();
  return (
    <ReactModal onClose={onClose} title={t("general.confirm")}>
      <ModalBody>
        {isPending ? (
          <LoaderSpinner />
        ) : (
          <div className="form-group">
            {t("general.confirm.delete.selected.rows")}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button xs secondary onClick={onClose}>
          {t("general.close")}
        </Button>
        <Button
          xs
          data-testid={"btnConfirm"}
          onClick={async () => {
            if (confirmMode === "SINGLE" && oppRowId) {
              await deleteOpportunityRow({ oppRowId, packageInstanceId });
              onClose();
            } else if (deleteOppRowsModel) {
              await deleteOpportunityRows(deleteOppRowsModel);
              onClose();
            }
          }}
          disabled={isPending || batchDeleteLoading}
        >
          {t("general.ok")}
        </Button>
      </ModalFooter>
    </ReactModal>
  );
};
export default ConfirmDeleteOppRowModal;
