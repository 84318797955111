import SalesForceChat from "../components/SalesForceChat/SalesForceChat";
import * as React from "react";
import { Navigate } from "react-router";
import { Route, Routes } from "react-router-dom";
import {
  CONST_ROUTE_ADMIN,
  CONST_ROUTE_AGREEMENTS,
  CONST_ROUTE_BUSINESS_TRUNK,
  CONST_ROUTE_CONTACT,
  CONST_ROUTE_DDOS,
  CONST_ROUTE_DOCUMENTS,
  CONST_ROUTE_END_USER,
  CONST_ROUTE_HOME,
  CONST_ROUTE_INVOICE,
  CONST_ROUTE_KST,
  CONST_ROUTE_MAINTENANCE,
  CONST_ROUTE_MOBILE_SECURITY,
  CONST_ROUTE_ORDERS,
  CONST_ROUTE_SELF_SERVICE,
  CONST_ROUTE_SELF_SERVICE_CATALOG,
  CONST_ROUTE_STATISTICS,
  CONST_ROUTE_SUBSCRIPTION,
  CONST_URL_ADMIN_USERS,
  CONST_URL_CONTACT_CONTACT_INFO,
  CONST_URL_COOKIE_POLICY,
  CONST_URL_END_USER_PRODUCT,
  CONST_URL_END_USER_PRODUCT_CATALOG,
  CONST_URL_HOME_HOME,
  CONST_URL_INVOICE_INDEX,
  CONST_URL_MAINTENANCE_NET_STATUS,
  CONST_URL_MAINTENANCE_OVERVIEW,
  CONST_URL_SELF_SERVICE_PRODUCT,
  CONST_URL_STATISTICS_SUBSCRIPTION_SLA,
  CONST_URL_SUBSCRIPTION_OVERVIEW,
} from "./approutes";
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import EndUserCatalog from "./EndUser/Catalog/EndUserCatalog";
import EndUserNavigation from "./EndUser/EndUserNavigation";
import ErrorPage from "./ErrorHandler/ErrorPage";
import ProductPage from "./SelfService/Product";
import ProductCatalog from "./SelfService/ProductCatalog";
import { SelfServiceRenderMode } from "./SelfService/ProductCatalog/product-catalog-type";
import ProductCatalogContext from "./SelfService/ProductCatalog/ProductCatalogContext";
import SuspenseBoundary from "./SuspenseBoundary";

import adminRoutes from "./Admin";
import agreementRoutes from "./Agreements";
import businessTrunkRoutes from "./BusinessTrunk";
import contactRoutes from "./Contact";
import ddosRoutes from "./Ddos";
import mobileSecurityRoutes from "./MobileSecurity";
import documentRoutes from "./Documents";
import endUserRoutes from "./EndUser";
import ErrorHandler from "./ErrorHandler";
import homeRoutes from "./Home";
import invoiceRoutes from "./Invoice";
import kstRoutes from "./Kst";
import maintenanceRoutes from "./Maintenance";
import ordersRoutes from "./Orders";
import RouteWrapper from "./RouteWrapper";
import selfServiceRoutes from "./SelfService";
import StartUri from "./StartUri";
import statisticsRoutes from "./Statistics";
import subscriptionRoutes from "./Subscription";
import { AppRouteProps } from "./types";

const buildRoutes = (path: string, routes: AppRouteProps[]) =>
  routes.map((r, i) => (
    <Route
      key={`${i}-${r.path}`}
      path={`/${path}/${r.path}`}
      element={<RouteWrapper component={r.component} />}
    />
  ));

const AppRoutes = (): React.ReactElement => {
  return (
    <ErrorHandler source={"Routes"}>
      <SuspenseBoundary>
        <Routes>
          {buildRoutes(CONST_ROUTE_ADMIN, adminRoutes)}
          {buildRoutes(CONST_ROUTE_AGREEMENTS, agreementRoutes)}
          {buildRoutes(CONST_ROUTE_BUSINESS_TRUNK, businessTrunkRoutes)}
          {buildRoutes(CONST_ROUTE_CONTACT, contactRoutes)}
          {buildRoutes(CONST_ROUTE_DOCUMENTS, documentRoutes)}
          {buildRoutes(CONST_ROUTE_HOME, homeRoutes)}
          {buildRoutes(CONST_ROUTE_KST, kstRoutes)}
          {buildRoutes(CONST_ROUTE_INVOICE, invoiceRoutes)}
          {buildRoutes(CONST_ROUTE_MAINTENANCE, maintenanceRoutes())}
          {buildRoutes(CONST_ROUTE_SELF_SERVICE, selfServiceRoutes)}
          {buildRoutes(CONST_ROUTE_ORDERS, ordersRoutes)}
          {buildRoutes(CONST_ROUTE_STATISTICS, statisticsRoutes)}
          {buildRoutes(CONST_ROUTE_SUBSCRIPTION, subscriptionRoutes)}
          {buildRoutes(CONST_ROUTE_DDOS, ddosRoutes)}
          {buildRoutes(CONST_ROUTE_MOBILE_SECURITY, mobileSecurityRoutes)}
          {buildRoutes(CONST_ROUTE_END_USER, endUserRoutes)}

          {/* Legacy breadcrumb redirects */}
          <Route
            path={`/${CONST_ROUTE_ADMIN}`}
            element={<Navigate to={`${CONST_URL_ADMIN_USERS}`} />}
          />
          <Route
            path={`/${CONST_ROUTE_CONTACT}`}
            element={<Navigate to={`${CONST_URL_CONTACT_CONTACT_INFO}`} />}
          />
          <Route
            path={`/${CONST_ROUTE_INVOICE}`}
            element={<Navigate to={`${CONST_URL_INVOICE_INDEX}`} />}
          />
          <Route
            path={`/${CONST_ROUTE_MAINTENANCE}`}
            element={<Navigate to={`${CONST_URL_MAINTENANCE_OVERVIEW}`} />}
          />
          <Route
            path={`/${CONST_ROUTE_ORDERS}`}
            element={<Navigate to={`/${CONST_ROUTE_SELF_SERVICE}/index`} />}
          />
          <Route
            path={`/${CONST_ROUTE_SELF_SERVICE}`}
            element={<Navigate to={`/${CONST_ROUTE_SELF_SERVICE}/index`} />}
          />
          <Route
            path={`/${CONST_ROUTE_STATISTICS}`}
            element={
              <Navigate to={`${CONST_URL_STATISTICS_SUBSCRIPTION_SLA}`} />
            }
          />
          <Route
            path={`/${CONST_ROUTE_SUBSCRIPTION}`}
            element={<Navigate to={`${CONST_URL_SUBSCRIPTION_OVERVIEW}`} />}
          />
          <Route
            path={"/home"}
            element={<Navigate to={CONST_URL_HOME_HOME} />}
          />
          <Route
            path={"/net-status/*"}
            element={<Navigate to={`${CONST_URL_MAINTENANCE_NET_STATUS}`} />}
          />
          <Route
            path={"/planned-work/*"}
            element={<Navigate to={`${CONST_URL_MAINTENANCE_NET_STATUS}`} />}
          />
          <Route path={"/login"} element={<Navigate to="/" />} />
          {/* End user old route if someone has an old link */}
          <Route
            path={"/subscription/end-users"}
            element={<Navigate to={`/${CONST_ROUTE_END_USER}`} />}
          />
          {/* End user index */}
          <Route path={CONST_ROUTE_END_USER} element={<EndUserNavigation />} />
          <Route path={"/"} element={<RouteWrapper component={StartUri} />} />
          <Route
            path={CONST_URL_COOKIE_POLICY}
            element={<RouteWrapper component={CookiePolicy} />}
          />
          {/* Self Service nested routes */}
          <Route
            element={
              <ProductCatalogContext
                selfServiceMode={SelfServiceRenderMode.PORTAL}
              />
            }
          >
            <Route
              key={CONST_ROUTE_SELF_SERVICE_CATALOG}
              path={`/${CONST_ROUTE_SELF_SERVICE}/${CONST_ROUTE_SELF_SERVICE_CATALOG}`}
              element={<RouteWrapper component={ProductCatalog} />}
            />
            <Route
              key={CONST_URL_SELF_SERVICE_PRODUCT}
              path={CONST_URL_SELF_SERVICE_PRODUCT}
              element={<RouteWrapper component={ProductPage} />}
            />
          </Route>
          <Route
            element={
              <ProductCatalogContext
                selfServiceMode={SelfServiceRenderMode.END_USER}
              />
            }
          >
            <Route
              key={CONST_URL_END_USER_PRODUCT}
              path={CONST_URL_END_USER_PRODUCT}
              element={<RouteWrapper component={ProductPage} />}
            />
            <Route
              key={CONST_URL_END_USER_PRODUCT_CATALOG}
              path={`${CONST_URL_END_USER_PRODUCT_CATALOG}`}
              element={<RouteWrapper component={EndUserCatalog} />}
            />
          </Route>
          <Route
            path="*"
            element={
              <ErrorPage status={404} source={"Routes"} errorCode={undefined} />
            }
          />
        </Routes>
        <SalesForceChat />
      </SuspenseBoundary>
    </ErrorHandler>
  );
};

export default AppRoutes;
