import { Footer as UiKitFooter } from "@tele2/t2-ui-kit";
import {
  FooterLangOptionProps,
  FooterMenuColumnProps,
  FooterMenuItemProps,
} from "@tele2/t2-ui-kit/build/Footer";
import axios from "axios";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import useGetGlobalLinks, {
  GlobalLink,
} from "../../api/home/global-links/useGetGlobalLinks";
import useMenuEntries, {
  MenuEntry,
} from "../../api/home/header/useMenuEntries";
import { CONST_URL_COOKIE_POLICY } from "../../Routes/approutes";
import { openCookieBanner } from "../../Routes/CookiePolicy/CookiePolicy";
import useIsEndUserPortal from "../../utils/useIsEndUserPortal";
import { useUrlParams } from "../../utils/useUrlParams";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hideFooter = useUrlParams("hideFooter");
  const isEndUserPortal = useIsEndUserPortal();
  const isEditComponents = useMatch("/self-service/edit-components");

  const { data: globalLinks } = useGetGlobalLinks();

  const { data: menuEntries } = useMenuEntries();

  const footerMenuColumns = useMemo(() => {
    let columns: FooterMenuColumnProps[] = [];
    if (menuEntries) {
      if (!isEndUserPortal) {
        columns.push({
          label: t("general.navigation"),
          footerMenuItems: menuEntries.map(
            (entry: MenuEntry): FooterMenuItemProps => ({
              text: t(String(entry.key)),
              url: String(entry.url),
            })
          ),
        });
      }
      const maintenanceEntry = menuEntries.find(
        ({ module }) => module === "MAINTENANCE"
      );
      if (maintenanceEntry) {
        columns.push({
          label: t(String(maintenanceEntry.key)),
          footerMenuItems: maintenanceEntry.entries.map(
            (entry: MenuEntry): FooterMenuItemProps => ({
              text: t(String(entry.key)),
              url: String(entry.url),
            })
          ),
        });
      }

      const staticMenuItems = () => [
        {
          label: t("header.title"),
          footerMenuItems: [
            {
              text: t("general.news"),
              url: "/home/customer-info",
            },
            {
              text: t("module.release.notes"),
              url: "/home/release-notes",
            },
            {
              text: t("module.cookie.settings"),
              onClickHandler: () => openCookieBanner(),
            },
            {
              text: t("module.cookie.policy"),
              url: CONST_URL_COOKIE_POLICY,
            },
          ],
        },
      ];

      columns = columns.concat(staticMenuItems());

      if (globalLinks) {
        columns.push({
          label: t("module.invoice.home.links"),
          footerMenuItems: globalLinks.map(
            (entry: GlobalLink): FooterMenuItemProps => ({
              text: String(entry.caption),
              url: String(entry.url),
              onClickHandler: (item) =>
                window.open(item.url ? item.url : "", "_blank")?.focus(),
            })
          ),
        });
      }
    }
    return columns;
  }, [globalLinks, isEndUserPortal, menuEntries, t]);
  return useMemo(() => {
    const mobileMenuItems = [
      {
        text: t("general.news"),
        url: "/home/customer-info",
      },
      {
        text: t("module.release.notes"),
        url: "/home/release-notes",
      },
      {
        text: t("module.cookie.settings"),
        onClickHandler: () => openCookieBanner(),
      },
      {
        text: t("module.cookie.policy"),
        url: CONST_URL_COOKIE_POLICY,
      },
    ];
    const languageOptions = [
      {
        dropdownLabel: t("general.language.en"),
        option: "en",
      },
      {
        dropdownLabel: t("general.language.sv"),
        option: "sv",
      },
    ];
    const getSelectedLangOption = (lang: string) => {
      return (
        languageOptions.find((opt) => lang.includes(opt.option)) ||
        languageOptions[0]
      );
    };
    return hideFooter || Boolean(isEditComponents) ? null : (
      <UiKitFooter
        onLogoClick={() => navigate("/")}
        onFooterLinkClick={(item) => navigate(String(item.url))}
        footerMenuColumns={footerMenuColumns}
        mobileMenuItems={mobileMenuItems}
        footerLangOptions={languageOptions}
        onLangOptionClick={async (item: FooterLangOptionProps) => {
          await axios.post(`/api/localization?locale=${item.option}`);
          window.location.reload();
        }}
        selectedLang={getSelectedLangOption(document.documentElement.lang)}
        maxWidth={980}
      />
    );
  }, [t, hideFooter, isEditComponents, footerMenuColumns, navigate]);
};
export default Footer;
