import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

const get = async (baseSubsId: number) => {
  const { data } = await axios.get<number[]>(
    "/api/self-service/cart/active/subscriptions",
    { params: { baseSubsId } }
  );
  return data;
};

const QUERY_KEY = "cart-active-subscriptions";
const useSubscriptionsInActiveCart = (baseSubsId: number) => {
  return useQuery({
    queryKey: [QUERY_KEY, baseSubsId],
    queryFn: () => get(baseSubsId),
  });
};
export default useSubscriptionsInActiveCart;

export const useInvalidateSubscriptionsInActiveCart = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
