import { init as initApm } from "@elastic/apm-rum";
import { useEffect, useState } from "react";
import { useAcceptStatistics } from "../Routes/CookiePolicy/CookiePolicy";
import getEnv from "../utils/getEnv";

const useApm = (): void => {
  const acceptStatistics = useAcceptStatistics();
  const [, setPrevAccepted] = useState(acceptStatistics);
  useEffect(() => {
    if (acceptStatistics) {
      const environment = getEnv();
      const serverUrl = "https://proxy-tso.pex.ymer.tele2.com/";
      initApm({
        active: "dev,stage,prod".includes(environment),
        serviceName: "tso-portal-ui",
        serverUrl: serverUrl,
        environment: environment,
      });
    }
    setPrevAccepted((prevState) => {
      if (prevState && !acceptStatistics) {
        // reload to disable script after change
        window.location.reload();
      }
      return acceptStatistics;
    });
  }, [acceptStatistics]);
};

export default useApm;
