import Portal from "../Portal/Portal";
import * as React from "react";
import { PropsWithChildren } from "react";
import { ContextMenuEntry } from "./context-menu-types";
import ContextMenu from "./ContextMenu";

export interface OpenContextMenuProps {
  xPos: number;
  yPos: number;
  positionLeft: boolean;
  entries: ContextMenuEntry[];
}

interface ContextMenuContextValue {
  openMenu: (props: OpenContextMenuProps) => void;
  closeMenu: () => void;
}

const ContextMenuContext = React.createContext<ContextMenuContextValue>({
  openMenu: () => undefined,
  closeMenu: () => undefined,
});

const ContextMenuContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [menu, setMenu] = React.useState<OpenContextMenuProps>();
  const contextValue = React.useMemo(() => {
    return {
      openMenu: (props: OpenContextMenuProps) => {
        setMenu(props);
      },
      closeMenu: () => setMenu(undefined),
    };
  }, []);
  React.useEffect(() => {
    if (menu) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [menu]);
  return (
    <ContextMenuContext.Provider value={contextValue}>
      {children}
      <Portal containerId="context-menu-container">
        <>
          {menu && <ContextMenu {...menu} closeMenu={contextValue.closeMenu} />}
        </>
      </Portal>
    </ContextMenuContext.Provider>
  );
};
export default ContextMenuContextProvider;

export const useContextMenuContext = () => {
  return React.useContext(ContextMenuContext);
};
