import Layout from "../components/Layout/Layout";
import * as React from "react";
import { Navigate } from "react-router";
import { CONST_URL_HOME_HOME } from "./approutes";
import { SessionContext } from "../SessionContext";

const StartUri: React.FC = () => {
  const { startUri } = React.useContext(SessionContext);
  if (startUri === "/") {
    throw new Error();
  }
  return (
    <Layout title={""}>
      <Navigate to={startUri || CONST_URL_HOME_HOME} />
    </Layout>
  );
};
export default StartUri;
