import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface DeleteOpportunityRowsModel {
  salesProjectId: number;
  tableSessionId: number;
}

const del = async (model: DeleteOpportunityRowsModel) => {
  await axios.post("/api/self-service/delete-opportunity-rows", null, {
    params: model,
  });
};

const useDeleteOpportunityRows = () => {
  const { mutateAsync, isPending } = useMutation<
    void,
    AxiosError,
    DeleteOpportunityRowsModel
  >({ mutationFn: del });
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const deleteOpportunityRows = useCallback(
    async (params: DeleteOpportunityRowsModel) => {
      await mutateAsync(params);
      invalidateCheckoutData();
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { deleteOpportunityRows, isPending };
};

export default useDeleteOpportunityRows;
