import ReactModal from "../../../../components/modal/ReactModal";
import * as React from "react";
import Filter from "./index";
import { useTranslation } from "react-i18next";

interface FilterModalProps {
  onClose?: () => void;
}
const FilterModal: React.FC<FilterModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <ReactModal onClose={onClose} title={t("general.filter")}>
      <Filter />
    </ReactModal>
  );
};
export default FilterModal;
