import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  useComponentsAttributes,
  useGetComponent,
  useUpdateComponent,
  useUpdateComponents,
} from "../../../components/component/ComponentsContext";
import { isSameComponentAttribute } from "../../../components/component/utils";
import { useMessage } from "../../../components/Message/Message";
import {
  getMessageTheme,
  getMessageType,
} from "../../../Routes/SelfService/EditComponents/utils";
import { AttributeComponent } from "../../subscription/subscription-view/useSubscriptionModel";
import { RoleType } from "../types";

interface UpdateMatrixRowHttpRequest {
  compAttrId: number;
  data: string;
  matrixRowId: number;
  oppRowId: number;
  salesProjectId: number;
  showCaseData?: boolean;
  simulateDraftForSalesProjectId?: boolean;
  subRoleType?: RoleType;
  subsId: number;
}

interface UpdateMatrixRowRequest {
  data: string;
  matrixRowId: number;
  subsId: number;
  compAttrId: number;
  oppRowId: number;
  prodCompId: number;
}

interface UpdateMatrixRowResponse {
  value: string;
  message: string;
  components?: AttributeComponent[];
  hash?: string;
  hashChanged: boolean;
  validationCode?: string;
  validationMessage?: string;
  invisibleCompAttrIds?: string;
}

const post = async (request: UpdateMatrixRowHttpRequest) => {
  const { data } = await axios.post<UpdateMatrixRowResponse>(
    "/api/self-service/update-matrix-row",
    convertToFormData(request)
  );
  return data;
};

const useUpdateMatrixRow = (label: string, parentProdCompAttrId: number) => {
  const message = useMessage();
  const {
    salesProjectId,
    showCaseData,
    simulateDraftForSalesProjectId,
    subRoleType,
  } = useComponentsAttributes();
  const getComponent = useGetComponent();
  const updateComponent = useUpdateComponent();
  const updateComponents = useUpdateComponents();
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (request: UpdateMatrixRowRequest) =>
      post({
        salesProjectId,
        showCaseData,
        simulateDraftForSalesProjectId,
        subRoleType,
        data: request.data,
        compAttrId: request.compAttrId,
        subsId: request.subsId,
        matrixRowId: request.matrixRowId,
        oppRowId: request.oppRowId,
      }),
  });
  const updateMatrixRow = useCallback(
    async (request: UpdateMatrixRowRequest) => {
      const response = await mutateAsync(request);
      if (!response.hashChanged) {
        // Matrix rows do not return an update component when the hash has not changed
        const component = getComponent(request.subsId, request.prodCompId);
        if (component) {
          const attribute = component.attributes.find((a) =>
            isSameComponentAttribute(a, {
              prodCompAttrId: parentProdCompAttrId,
            })
          );
          if (attribute) {
            const matrixRow = attribute.rows?.find(
              (row) => row.id === request.matrixRowId
            );
            if (matrixRow) {
              const matrixCell = matrixRow.cells.find(
                (cell) => cell.compAttrId === request.compAttrId
              );
              if (matrixCell) {
                matrixCell.validationMessage = response.message;
                matrixCell.attrData = response.value;
              }
            }
          } else {
            throw new Error(
              "Could not find attribute when updating matrix row"
            );
          }
          updateComponent(component);
        } else {
          throw new Error("Could not find component");
        }
      } else if (response.components) {
        updateComponents(response.components);
      }
      if (!response.message) {
        message(
          getMessageType(t, {
            attrLabel: label,
            message: response.validationMessage,
            theme: getMessageTheme(response.validationCode || ""),
          })
        );
      }
    },
    [
      getComponent,
      label,
      message,
      mutateAsync,
      parentProdCompAttrId,
      t,
      updateComponent,
      updateComponents,
    ]
  );
  return { updateMatrixRow, isPending };
};

export default useUpdateMatrixRow;

const convertToFormData = (request: UpdateMatrixRowHttpRequest) => {
  const formData = new FormData();
  formData.set("subsId", String(request.subsId));
  formData.set("salesProjectId", String(request.salesProjectId));
  formData.set("subRoleType", String(request.subRoleType));
  formData.set(
    "simulateDraftForSalesProjectId",
    String(request.simulateDraftForSalesProjectId || false)
  );
  formData.set("showCaseData", String(request.showCaseData || false));
  formData.set("oppRowId", String(request.oppRowId));
  formData.set("compAttrId", String(request.compAttrId));
  formData.set("matrixRowId", String(request.matrixRowId));
  formData.set("data", request.data);
  return formData;
};
