import { END_USER_MODES } from "../api/self-service/edit-components-model/useEditComponentsModel";
import { useMatch } from "react-router-dom";
import { useUrlParams } from "./useUrlParams";

const useIsEndUserPortal = (): boolean => {
  const endUser = useMatch("/end-users/*");
  const mode = useUrlParams("mode");
  return Boolean(endUser) || Boolean(END_USER_MODES.includes(mode || ""));
};

export default useIsEndUserPortal;
