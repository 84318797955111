import ElementLabelComponent from "./AttributeComponents/ElementLabelComponent";
import AttributeContent from "./AttributeContent";
import * as React from "react";
import { AttributeRowProps, Type } from "./AttributeRow";
import HelpTextIcon from "./HelpTextIcon";
import MatrixAttribute from "./MatrixAttribute/MatrixAttribute";

const AttributeCol: React.FC<AttributeRowProps> = (props) => {
  const { required, readOnly, helpText, label, indent, validationMessage } =
    props;
  const content = React.useMemo(() => {
    switch (props.type) {
      case Type.MATRIX:
        return (
          <>
            <label
              style={{ marginLeft: `${indent * 10}px` }}
              className="attr-label"
            >
              {label}
            </label>
            <HelpTextIcon>{helpText}</HelpTextIcon>
            {required ? <span className="input-required"> *</span> : null}
            <MatrixAttribute {...props} />
          </>
        );
      case Type.HEAD_LINE:
        return (
          <span
            className="attr-type-head-line"
            style={{ marginLeft: `${indent * 10}px` }}
          >
            {label}
          </span>
        );
      case Type.LABEL:
        return (
          <span
            className="attr-type-label"
            style={{ marginLeft: `${indent * 10}px` }}
          >
            {label}
          </span>
        );
      case Type.HELP_TEXT:
        return <div className="attr-embedded">{label}</div>;
      case Type.LINE_FEED:
        return <div className="col header-row">{"&nbsp"}</div>;
      default:
        return (
          <>
            <AttributeContent {...props} />
            {!readOnly && (
              <div className="help-block attr-validation">
                {validationMessage}
              </div>
            )}
          </>
        );
    }
  }, [helpText, indent, label, props, readOnly, required, validationMessage]);
  return (
    <>
      {content}
      <ElementLabelComponent
        elementLabel={props.elementLabel}
        isValid={!Boolean(validationMessage)}
      />
    </>
  );
};

export default AttributeCol;
