import { MainMenuItemProps } from "@tele2/t2-ui-kit";
import useGetEndUsersLinks from "../../api/end-users/useGetEndUsersLinks";
import * as React from "react";
import useEndUserRoutes from "./useEndUserRoutes";
import { useTranslation } from "react-i18next";

const useEndUserMenuItems = () => {
  const { t } = useTranslation();
  const endUserRoutes = useEndUserRoutes();
  const { data: links } = useGetEndUsersLinks();
  return React.useMemo<MainMenuItemProps[]>(() => {
    const arr: MainMenuItemProps[] = [];
    endUserRoutes.forEach((route) =>
      arr.push({
        ...route,
        isRoot: true,
      })
    );
    if (links && links.length > 0) {
      arr.push({
        isRoot: true,
        title: t("module.home.useful.links"),
        subItems: links.map((link) => ({
          isRoot: false,
          title: link.caption,
          url: link.url,
        })),
      });
    }
    return arr;
  }, [endUserRoutes, links, t]);
};
export default useEndUserMenuItems;
