import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useEditComponentsModel, {
  EditComponentsMode,
  EditComponentsModelRequest,
  useRemoveEditComponentsModel,
} from "../../../api/self-service/edit-components-model/useEditComponentsModel";
import ComponentsContext from "../../../components/component/ComponentsContext";
import { Types } from "../../../components/component/types";
import { useGATracking } from "../../../GAContext";
import { CONST_URL_SELF_SERVICE_EDIT_COMPONENTS } from "../../approutes";
import OpportunityContainer from "./Opportunity/OpportunityContainer";

const EditComponents: React.FC = () => {
  const { t } = useTranslation();
  const request = useEditComponentsRequest();
  const { data } = useEditComponentsModel(request);
  const removeEditComponentsModel = useRemoveEditComponentsModel();
  React.useEffect(() => {
    return () => {
      // Makes sure that that edit components model is not cached
      removeEditComponentsModel(request);
    };
  }, [request, removeEditComponentsModel]);
  const pageTitle = t("module.self.service.edit.component.title");
  useGATracking(pageTitle);
  React.useEffect(() => {
    document.title = `${pageTitle} - ${t("header.title")}`;
  }, [pageTitle, t]);
  return (
    <ComponentsContext {...data} componentsType={Types.EDIT_COMPONENTS}>
      <OpportunityContainer />
    </ComponentsContext>
  );
};

export default EditComponents;

const useQueryParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useEditComponentsRequest = (): EditComponentsModelRequest => {
  const params = useQueryParams();
  const oppRowId = params.get("oppRowId") || "";
  const salesProjectId = params.get("salesProjectId") || "";
  const packageInstanceId = params.get("packageInstanceId") || "";
  const referrer = params.get("referrer") || "";
  const mode = getMode(params.get("mode") || "");
  return useMemo(() => {
    return {
      oppRowId: Number(oppRowId),
      salesProjectId: Number(salesProjectId),
      packageInstanceId: Number(packageInstanceId),
      referrer,
      mode,
    };
  }, [mode, oppRowId, packageInstanceId, referrer, salesProjectId]);
};

const getMode = (mode: string): EditComponentsMode => {
  switch (mode) {
    case "ADD_FROM_OTHER_PAGE":
      return "ADD_FROM_OTHER_PAGE";
    case "EDIT_FROM_OTHER_PAGE":
      return "EDIT_FROM_OTHER_PAGE";
    case "QUICK_CASE_FROM_OTHER_PAGE":
      return "QUICK_CASE_FROM_OTHER_PAGE";
    case "QUICK_CASE_FROM_END_USER":
      return "QUICK_CASE_FROM_END_USER";
    case "ADD_FROM_END_USER":
      return "ADD_FROM_END_USER";
    case "EDIT_FROM_END_USER":
      return "EDIT_FROM_END_USER";
    case "ADD_FROM_CART":
      return "ADD_FROM_CART";
    case "EDIT_FROM_CART":
      return "EDIT_FROM_CART";
  }
  return "NONE";
};

export const getEditComponentsURL = (params: {
  salesProjectId: number;
  oppRowId: number;
  packageInstanceId?: number;
  referrer?: string;
  mode: EditComponentsMode;
}) => {
  const referrer = params.referrer ? `&referrer=${params.referrer}` : "";
  return `${CONST_URL_SELF_SERVICE_EDIT_COMPONENTS}?salesProjectId=${
    params.salesProjectId
  }&oppRowId=${params.oppRowId}&packageInstanceId=${
    params.packageInstanceId ?? 0
  }&mode=${params.mode}${referrer}`;
};
