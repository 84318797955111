import { Flex, Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import { PropsWithChildren } from "react";
import useTSOSession, {
  SessionStatus,
  useInvalidateAllData,
  useInvalidateTSOSession,
  User,
} from "./api/session/useTSOSession";
import LoadingView from "./components/LoadingView/LoadingView";

interface SessionContextProps {
  isLoading: boolean;
  startUri?: string;
  user?: User;
}

export const SessionContext = React.createContext<SessionContextProps>({
  isLoading: false,
});

const SessionContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [login, setLogin] = React.useState<string>();
  const [version, setVersion] = React.useState<string>();
  const [cacheUpdated, setCacheUpdated] = React.useState<number>();
  const { data } = useTSOSession();
  const invalidateAllData = useInvalidateAllData();
  const invalidateTSOSession = useInvalidateTSOSession();
  const onFocus = React.useCallback(() => {
    invalidateTSOSession();
  }, [invalidateTSOSession]);

  React.useEffect(() => {
    // Redirect the user to auth page if no status
    if (data.sessionStatus === SessionStatus.NONE) {
      redirectToLogin();
      return;
    }

    // Reload page if app version changed
    setVersion(data.version);
    if (version && version !== data.version) {
      window.location.reload();
      return;
    }

    // Has the user been changed?
    if (data.user && data.user.login !== login) {
      if (login) {
        // If it is a different user
        window.location.href = "/";
        return;
      }
      setLogin(data.user.login);
    }

    // Remember which IDP the user is using
    if (data.iss) {
      window.localStorage.setItem("iss", data.iss);
    }

    // Cache has been updated, expire all data
    if (cacheUpdated !== data.cacheUpdated) {
      setCacheUpdated(data.cacheUpdated);
      if (cacheUpdated) {
        invalidateAllData();
      }
    }

    if (
      data?.sessionStatus === SessionStatus.EXPIRED ||
      data?.sessionStatus === SessionStatus.UPDATING
    ) {
      // Poll the BE until the session is VALID
      const handler = setTimeout(() => {
        invalidateTSOSession();
      }, 5_000);
      return () => clearTimeout(handler);
    }
    return undefined;
  }, [
    version,
    cacheUpdated,
    data,
    invalidateAllData,
    invalidateTSOSession,
    login,
  ]);

  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  // Don't render any routes unless any VALID state
  const showContent = React.useMemo(() => {
    return (
      data?.sessionStatus === SessionStatus.VALID ||
      data?.sessionStatus === SessionStatus.UPDATING
    );
  }, [data?.sessionStatus]);
  const contextProps = React.useMemo(() => {
    return {
      isLoading: data?.sessionStatus === SessionStatus.UPDATING,
      startUri: data?.startUri,
      user: data?.user,
    };
  }, [data?.user, data?.startUri, data?.sessionStatus]);
  if (!data || data?.sessionStatus === SessionStatus.NONE) {
    return loading;
  }
  return (
    <SessionContext.Provider value={contextProps}>
      {showContent ? children : <LoadingView name={data.user?.firstName} />}
    </SessionContext.Provider>
  );
};
export default SessionContextProvider;

/*
 * iss parameter enables multi IDP support. The parameter indicates which
 * IDP the user should be redirected to.
 * see https://openid.net/specs/openid-connect-core-1_0.html#IDToken
 */

const resolveIss = () => {
  // Enables IDP to include iss parameter in query parameters to override
  // default or localStorage iss.
  return (
    new URLSearchParams(window.location.search).get("iss") ||
    window.localStorage.getItem("iss") ||
    ""
  );
};

export const redirectToLogin = () => {
  const url = new URL(window.location.href);
  // clear iss query param, only used to select IDP
  url.searchParams.delete("iss");

  const params = new URLSearchParams({
    // used for redirect back when IDP authenticated user
    state: url.toString(),
    iss: resolveIss(),
  });
  window.location.href = `/api/login?${params.toString()}`;
};

export const redirectToLogout = () => {
  const params = new URLSearchParams({
    iss: resolveIss(),
  });
  window.location.href = `/api/logout?${params.toString()}`;
};

const loading = (
  <Flex justifyContent="center" alignItems="center">
    <Loader />
  </Flex>
);
