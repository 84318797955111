import { color, Flex, MainMenuItemProps, useIsMobile } from "@tele2/t2-ui-kit";
import AccountBox from "@tele2/t2-ui-kit/build/Icon/icons/AccountBoxOutlined";
import AccountCircle from "@tele2/t2-ui-kit/build/Icon/icons/AccountCircleOutlined";
import Description from "@tele2/t2-ui-kit/build/Icon/icons/Description";
import Repeat from "@tele2/t2-ui-kit/build/Icon/icons/RepeatOutlined";
import SettingsApplications from "@tele2/t2-ui-kit/build/Icon/icons/SettingsApplicationsOutlined";
import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useUserGeneratedReports from "../../api/generated-reports/useUserGeneratedReports";

import useMenuEntries, {
  MenuEntry,
} from "../../api/home/header/useMenuEntries";
import { useModuleReadRight } from "../../api/session/useModuleRight";
import {
  CONST_URL_HOME_HOME,
  CONST_URL_HOME_OPTIONS,
  CONST_URL_HOME_USER_REPORTS,
} from "../../Routes/approutes";
import useEndUserMenuItems from "../../Routes/EndUser/useEndUserMenuItems";
import { redirectToLogout, SessionContext } from "../../SessionContext";
import useIsEndUserPortal from "../../utils/useIsEndUserPortal";
import { useUrlParams } from "../../utils/useUrlParams";
import ChangeLanguage from "./components/ChangeLanguage";
import UpdatePassword from "./components/UpdatePassword";

import { HeaderContext } from "./HeaderContext";

import MainMenuButton from "./MainMenuButton";
import MiniCartButton from "./MiniCart/MiniCartButton";
import NotificationButton from "./NotificationButton";
import SearchButton from "./SearchButton";

import SessionInfoIcon from "./SessionInfoIcon";

import { StyledHeader } from "./TopMenu-styles";
import UserMenuButton from "./UserMenuButton";

const formatMenuItem = (
  e: MenuEntry,
  t: TFunction<string>
): MainMenuItemProps => ({
  title: t(String(e.key)),
  url: e.url ? String(e.url) : undefined,
  subItems: e.entries.map((entry) => formatMenuItem(entry, t)),
});

const TopMenu: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isEndUserPortal = useIsEndUserPortal();
  const hasModuleOptions = useModuleReadRight("OPTIONS");
  const hasModuleSelfService = useModuleReadRight("SELF_SERVICE");
  const hasModuleSelfServiceEndUsers = useModuleReadRight(
    "SELF_SERVICE_END_USERS"
  );
  const { data: menuEntries } = useMenuEntries();

  const menuItems = React.useMemo(() => {
    if (!menuEntries) {
      return [];
    }
    return menuEntries.map((entry) => formatMenuItem(entry, t));
  }, [menuEntries, t]);

  const endUserMenuItems = useEndUserMenuItems();
  const { data: userGeneratedReports } = useUserGeneratedReports();
  const numberReports = React.useMemo(() => {
    return (
      userGeneratedReports?.filter(
        (r) => !r.consumed && r.generated !== null && !r.error
      ).length || 0
    );
  }, [userGeneratedReports]);
  const { user } = React.useContext(SessionContext);
  const navigate = useNavigate();
  const hideHeader = useUrlParams("hideHeader");
  const { header } = React.useContext(HeaderContext);

  const logoLink = React.useMemo(() => {
    if (isEndUserPortal) {
      return endUserMenuItems.length > 0 ? endUserMenuItems[0].url ?? "/" : "/";
    }
    return CONST_URL_HOME_HOME;
  }, [endUserMenuItems, isEndUserPortal]);

  const onLogoLinkClick = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault();
      navigate(logoLink);
    },
    [logoLink, navigate]
  );
  const userMenuOptions = React.useCallback(
    (onClose?: () => void) => [
      {
        icon: <AccountCircle />,
        label: t("module.options"),
        clickHandler: () => {
          navigate(CONST_URL_HOME_OPTIONS);
          onClose?.();
        },
        hidden: isEndUserPortal || !hasModuleOptions,
      },
      {
        icon: <Description />,
        label: t("module.home.user.reports.option.title"),
        clickHandler: () => {
          navigate(CONST_URL_HOME_USER_REPORTS);
          onClose?.();
        },
        hidden: isEndUserPortal,
        altContent: (
          <Flex>
            <span>{t("module.home.user.reports.option.title")}</span>
            {numberReports > 0 && (
              <NotificationCircle>{numberReports}</NotificationCircle>
            )}
          </Flex>
        ),
      },
      {
        icon: <Repeat />,
        label: t("general.password.change"),
        component: <UpdatePassword />,
        hidden:
          !user || !user.changePassword || isEndUserPortal || !hasModuleOptions,
      },
      {
        icon: <SettingsApplications />,
        label: t("general.language.change"),
        component: <ChangeLanguage />,
      },
      {
        icon: <AccountBox />,
        label: t("header.logout"),
        clickHandler: () => {
          redirectToLogout();
          onClose?.();
        },
      },
    ],
    [hasModuleOptions, isEndUserPortal, navigate, numberReports, user, t]
  );
  const handleMenuLinkClick = React.useCallback(
    (item: MainMenuItemProps) => {
      if (!item.url) {
        return;
      }
      if (item.url.startsWith("http")) {
        const a = document.createElement("a");
        a.href = item.url;
        a.rel = "noreferrer";
        a.target = "_blank";
        a.click();
      } else {
        navigate(item.url);
      }
    },
    [navigate]
  );
  return React.useMemo(() => {
    return hideHeader ? null : (
      <StyledHeader
        {...header}
        logoLink={logoLink}
        mainMenu={isEndUserPortal ? endUserMenuItems : menuItems}
        mobileHeaderLeft={
          <MainMenuButton
            onMainMenuChange={handleMenuLinkClick}
            menuItems={isEndUserPortal ? endUserMenuItems : menuItems}
          />
        }
        onLogoLinkClick={onLogoLinkClick}
        onMainMenuChange={handleMenuLinkClick}
      >
        <SessionInfoIcon />
        {isEndUserPortal || isMobile ? null : <SearchButton />}
        {(hasModuleSelfService || hasModuleSelfServiceEndUsers) && (
          <MiniCartButton />
        )}
        {isEndUserPortal ? null : <NotificationButton />}
        <UserMenuButton
          backLabel={t("general.back")}
          menuItems={userMenuOptions}
          mobileTitle={t("general.account.settings")}
          user={user}
          numberOfNotifications={!isEndUserPortal ? numberReports : undefined}
        />
      </StyledHeader>
    );
  }, [
    endUserMenuItems,
    handleMenuLinkClick,
    hasModuleSelfService,
    hasModuleSelfServiceEndUsers,
    header,
    hideHeader,
    isEndUserPortal,
    isMobile,
    logoLink,
    menuItems,
    numberReports,
    onLogoLinkClick,
    t,
    user,
    userMenuOptions,
  ]);
};
export default TopMenu;

const NotificationCircle = styled.span`
  text-align: center;
  color: ${color.BRAND_WHITE};
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  min-width: 18px;
  padding: 3px;
  position: absolute;
  right: 0;
  z-index: 1;
  &:after {
    content: "";
    background-color: ${color.FUNCTIONAL_RED};
    border-radius: 100%;
    bottom: 0;
    display: block;
    margin: auto;
    padding: 0 0 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;
