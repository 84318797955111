import * as React from "react";
import { CatalogNode } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import { Accordion } from "@tele2/t2-ui-kit";
import styled from "styled-components";
import {
  useCatalog,
  useNodeKeyState,
  useNodeTypeState,
} from "../ProductCatalogContext";
interface RenderCategoryProps {
  item: CatalogNode;
  nodeLevel: number;
  showKey: string;
}
const START_LEVEL = 1;
const END_LEVEL = 6;
const StyledSpan = styled.span<{ $selected: boolean }>`
  font-weight: ${(props) => (props.$selected ? "600" : "200")};
  cursor: pointer;
`;
const LayoutWrapper = styled.div<{ $level: number }>`
  margin-left: ${(props) => props.$level * 10 + `px;`};
  > .t2-checkbox > label {
    padding: 0 0 0 0;
  }
`;
const RenderCategory: React.FC<RenderCategoryProps> = ({
  item,
  nodeLevel,
  showKey,
}) => {
  const [nodeType, setNodeType] = useNodeTypeState();
  const [catalog] = useCatalog();
  const [selectedNode, setSelectedNode] = useNodeKeyState();
  const [currentLevel, setCurrentLevel] = React.useState<number>(START_LEVEL);
  const nodeKey: string = item.key || "Root";
  const array = selectedNode.split("|");

  return (
    <React.Fragment key={nodeKey}>
      {nodeLevel === 0 ? (
        <Accordion
          header={item.name}
          value={item.nodeType}
          defaultValue={catalog?.catalogTree.nodeType}
          open={nodeType === item.nodeType}
          id={item.nodeType}
          data-cy={item.nodeType}
        >
          <RenderCategory
            item={item}
            nodeLevel={nodeLevel + 1}
            showKey={showKey}
          />
        </Accordion>
      ) : (
        <>
          {nodeLevel > START_LEVEL && (
            <LayoutWrapper $level={nodeLevel - START_LEVEL}>
              <StyledSpan
                $selected={nodeKey === selectedNode}
                id={nodeKey}
                data-cy={nodeKey}
                onClick={() => {
                  setCurrentLevel(nodeLevel);
                  if (nodeType !== item.nodeType) {
                    setNodeType(item.nodeType);
                  }
                  if (selectedNode === nodeKey) {
                    setSelectedNode(nodeKey.slice(0, nodeKey.lastIndexOf("|")));
                  } else {
                    setSelectedNode(nodeKey);
                  }
                }}
              >
                {item.name}
              </StyledSpan>
            </LayoutWrapper>
          )}
          {item.subCategories.map((subItem: CatalogNode) => {
            if (
              nodeLevel === END_LEVEL ||
              !subItem.key.startsWith(showKey) ||
              nodeLevel > currentLevel
            ) {
              return null;
            }
            return (
              <RenderCategory
                item={subItem}
                key={subItem.key}
                nodeLevel={nodeLevel + 1}
                showKey={array.slice(0, nodeLevel + 2).join("|")}
              />
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};
export default RenderCategory;
