import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

const subscriptions: string[] = [];
let promise: Promise<Client> | null = null;

function getClient(): Promise<Client> {
  if (promise) {
    return promise;
  }

  promise = new Promise<Client>((resolve, reject) => {
    const client: Client = new Client({
      onConnect: () => resolve(client),
      onWebSocketError: () => reject(client),
      onStompError: () => reject(client),
      onWebSocketClose: () => {
        client.deactivate();
        promise = null;
        reject(client);
      },
      webSocketFactory: () =>
        new SockJS("/api/websocket/sockjs/stomp", null, {
          transports: "xhr-polling",
        }),
    });

    window.addEventListener("beforeunload", () => client.deactivate());

    client.activate();
  }).catch((client) => {
    console.log(`WebSocket failed to connect`);
    return client;
  });

  return promise;
}

export interface ISubscription {
  unsubscribe(): void;
}

export async function subscribe<T>(
  destination: string,
  callback: (s: T) => void
): Promise<ISubscription> {
  return doSubscribe(destination, true, callback);
}

export async function subscribeReact<T>(
  destination: string,
  callback: (s: T) => void
): Promise<ISubscription> {
  // React components should handle unsubscribing by themselves.
  return doSubscribe(destination, false, callback);
}

async function doSubscribe<T>(
  destination: string,
  autoUnsubscribe: boolean,
  callback: (s: T) => void
): Promise<ISubscription> {
  try {
    const client = await getClient();
    const subscription = client.subscribe(
      destination,
      (msg: { body: string }) => callback(JSON.parse(msg.body))
    );
    if (autoUnsubscribe) {
      subscriptions.push(subscription.id);
    }
    return subscription;
  } catch (error) {
    console.error("WebSocket failed to subscribe", error);
    return Promise.reject(error);
  }
}

export function unsubscribe() {
  if (subscriptions.length === 0) {
    return;
  }
  (async () => {
    const client = await getClient();
    while (subscriptions.length > 0) {
      client.unsubscribe(subscriptions.pop() as string);
    }
  })();
}

export async function send<T>(destination: string, body: T): Promise<void> {
  try {
    const client = await getClient();
    client.publish({ destination, body: JSON.stringify(body) });
  } catch (error) {
    console.error("WebSocket failed to publish", error);
  }
}
