import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface SelectBoxParams {
  compAttrId: number;
  filter: string;
  matrixRowId: number;
  prodCompAttrId: number;
  subRoleType?: string;
  subsId: number;
}

export interface IAttributeOption {
  id: number;
  value: string; // value and label
  selected: boolean;
  status: number;
  selectable: boolean; // !disabled
  level: number;
  values?: { [id: number]: string }; // cells
}

interface IAttributeColumn {
  id: number;
  name: string;
}

export interface IAttributeGrid {
  options: IAttributeOption[];
  columns?: IAttributeColumn[];
}

const getSelectBoxOptions = async (params: SelectBoxParams) => {
  const { data } = await axios.get<IAttributeGrid>(
    "/api/self-service/select-box-options",
    {
      params,
    }
  );
  return data;
};

const useSelectBoxOptions = (params: SelectBoxParams, enabled: boolean) => {
  return useQuery<IAttributeGrid, AxiosError>({
    queryKey: ["selectBoxOptions", params],
    queryFn: () => getSelectBoxOptions(params),
    enabled,
  });
};
export default useSelectBoxOptions;
