import * as React from "react";
import { useNodeKeyState } from "../ProductCatalogContext";
import { StyledLinkSpan } from "./ProductCatalogListItem";
import { useTranslation } from "react-i18next";

const SelectedNodePath: React.FC = () => {
  const { t } = useTranslation();
  const [nodeKey, setNodeKey] = useNodeKeyState();
  const array = nodeKey.split("|");

  return (
    <div>
      {t("module.subscription.product_catalog.selected.filter")}{" "}
      {array.map((path, index) => {
        const currentPath = nodeKey
          .split("|")
          .splice(0, index + 1)
          .join("|");
        return (
          <StyledLinkSpan
            key={currentPath}
            onClick={() => {
              setNodeKey(currentPath);
            }}
          >
            <b>{path === "Root" ? t("general.all") : path}</b>
            {array.length - 1 > index && " / "}
          </StyledLinkSpan>
        );
      })}
    </div>
  );
};
export default SelectedNodePath;
