import { margin } from "@tele2/t2-ui-kit";
import styled from "styled-components";

export const MessagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 72px;
  z-index: 2;
  > div {
    margin-bottom: ${margin.XXS};
  }

  .t2-alert {
    position: initial;
  }
`;
