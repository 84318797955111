import * as React from "react";
import { useMemo } from "react";

const RouteWrapper: React.FC<{ component: React.FC }> = ({
  component: Component,
}) => {
  return useMemo(() => <Component />, [Component]);
};

export default RouteWrapper;
