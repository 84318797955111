import Layout from "../../components/Layout/Layout";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useEndUserRoutes from "./useEndUserRoutes";

const EndUserNavigation: React.FC = () => {
  const endUserRoutes = useEndUserRoutes();
  const navigate = useNavigate();
  React.useEffect(() => {
    // endUserRoutes should always contain atleast 1 item
    if (endUserRoutes.length > 0) {
      navigate(endUserRoutes[0].url);
    }
  }, [endUserRoutes, navigate]);
  return <Layout title="header.title" />;
};
export default EndUserNavigation;
