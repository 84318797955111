import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import * as React from "react";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // One minute
      gcTime: 1800000, // 30 minutes
      refetchOnWindowFocus: false,
      throwOnError: true, // Throw errors to be picked up by error boundary. Hooks outside boundary must have useErrorBoundary: false
      retry: (failureCount, error) => {
        if (isAxiosError(error as Error)) {
          const axiosError = error as AxiosError;
          if (
            (axiosError.response?.status || 400) >= 400 &&
            (axiosError.response?.status || 400) < 500
          ) {
            return false;
          }
        }
        return failureCount < 3;
      },
    },
    mutations: {
      throwOnError: true,
    },
  },
});
/* 
  Temporary file to wrap components with QueryClient needed for react-query to work
*/

function withQueryClient<T>(Component: React.FC<T>) {
  return function cb(props: React.PropsWithChildren<T>): React.ReactElement {
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...props} />
      </QueryClientProvider>
    );
  };
}

export default withQueryClient;
