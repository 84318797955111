import getEnv from "../utils/getEnv";
import { useEffect } from "react";

const ID = "GTM-5QDCDMW";

export const useGtm = (): void => {
  useEffect(() => {
    const env = getEnv();
    if ("prod".includes(env)) {
      const script = document.createElement("script");
      script.innerHTML = `
      (function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),
                    dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })
          (window,document,'script','dataLayer','${ID}');`;
      window.document.body.appendChild(script);
    }
  }, []);
};

export default useGtm;
