import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { OwnershipRequest } from "./useGetOwnershipRequest";

const QUERY_KEY = "self-service-ownership-request-list";
const getOwnershipRequests = async () => {
  const { data } = await axios.get<OwnershipRequest[]>(
    "/api/self-service/ownership-request/list"
  );
  return data;
};

export const useInvalidateOwnershipRequests = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

const useGetOwnershipRequests = () => {
  return useSuspenseQuery<OwnershipRequest[], AxiosError>({
    queryKey: [QUERY_KEY],
    queryFn: getOwnershipRequests,
  });
};

export default useGetOwnershipRequests;
