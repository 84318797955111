import { MessageType } from "../../../components/Message/message-types";
import { TFunction } from "react-i18next";

export const getMessageTheme = (
  validationCode: string
): MessageType["theme"] => {
  switch (validationCode) {
    case "ERROR":
      return "alert";
    case "WARNING":
      return "com";
    case "OK":
    case "INFO":
    default:
      return "positive";
  }
};

export const getMessageType = (
  t: TFunction<string>,
  params: {
    message?: string;
    attrLabel?: string;
    theme: MessageType["theme"];
  }
) => {
  if (!params.message) {
    params.message = `${params.attrLabel || ""} ${t(
      "module.self.service.change.saved"
    )}`;
  }

  if (!params.theme) {
    params.theme = "positive";
  }
  return {
    interval: ["positive", "com"].indexOf(params.theme) === -1 ? 10000 : 2000,
    message: params.message || "",
    theme: params.theme,
  };
};

export const toErrorClass = (code: string | null) => {
  switch (code) {
    case "ERROR":
      return "has-error";
    case "WARNING":
      return "has-warning";
    case "INFO":
      return "info";
    default:
      return "ok";
  }
};
