import { Text } from "@tele2/t2-ui-kit";
import { OpportunityGroup } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import { CartActionPermissions } from "../../../../api/self-service/checkout/useSalesProjectSummaryValidation";
import * as React from "react";
import styled from "styled-components";
import ActionsColumn from "./ActionsColumn";
import { DeleteOppRowModalObj } from "../MiniCartButtonWrapper";

interface CartRowPackageGroupProps {
  group: OpportunityGroup;
  permissions: CartActionPermissions;
  salesProjectId: number;
  onClose?: () => void;
  setShowConfirmDeleteOppRowModal: (props: DeleteOppRowModalObj) => void;
}

const TypeStyling = styled(Text)`
  margin-right: 0.25rem;
`;

const NameStyling = styled(Text)``;

const CartRowPackageGroup: React.FC<CartRowPackageGroupProps> = ({
  group,
  permissions,
  salesProjectId,
  onClose,
  setShowConfirmDeleteOppRowModal,
}) => {
  return (
    <>
      <div>
        {group.types.map((type) => {
          return (
            <TypeStyling xl key={type.id}>
              {type.description}
            </TypeStyling>
          );
        })}
      </div>
      <div>
        <NameStyling xl medium>
          {group.packageName}
        </NameStyling>
      </div>
      <div />
      <div />
      <div className="text-center" />
      <ActionsColumn
        group={group}
        permissions={permissions}
        salesProjectId={salesProjectId}
        onClose={onClose}
        oppRowId={group.rows[0].id}
        attributesEditable={group.rows[0].attributesEditable}
        setShowConfirmDeleteOppRowModal={setShowConfirmDeleteOppRowModal}
      />
    </>
  );
};

export default CartRowPackageGroup;
