import * as React from "react";

import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OwnershipRequest } from "../../../../api/self-service/ownership-request/useGetOwnershipRequest";
import FormGroup, { Item } from "../../../../components/FormGroup/FormGroup";
import FormInput from "../../../../components/FormGroup/FormInput";
import FormSelect from "../../../../components/FormGroup/FormSelect";
import { formatDate } from "../../../../utils/dates";

interface MsisdnFormProps {
  control: Control<OwnershipRequest> | undefined;
  setValue: UseFormSetValue<OwnershipRequest>;
  getValues: UseFormGetValues<OwnershipRequest>;
  trigger: UseFormTrigger<OwnershipRequest>;
  errors: FieldErrors<OwnershipRequest> | undefined;
}

const OwnershipRequestForm: React.FC<MsisdnFormProps> = ({
  control,
  getValues,
  setValue,
  trigger,
  errors,
}) => {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState<string>(
    document.documentElement.lang.substring(0, 2) || navigator.language
  );
  const defaultDate = React.useMemo(() => {
    const date = new Date();
    return new Date(
      date.getMonth() < 11
        ? date.setMonth(date.getMonth() + 2)
        : date.setFullYear(date.getFullYear() + 1)
    );
  }, []);

  const isOrgNo = React.useCallback((value: string) => {
    return /^(5[0-9](1[3-9]|[2-9][0-9])\d{2}\d{4})$/.test(value);
  }, []);
  const isValidLuhnChecksum = React.useCallback((value: string) => {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;
    // The Luhn Algorithm.
    var nCheck = 0,
      bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }
    return nCheck % 10 === 0;
  }, []);
  const isValidPersonOrOrgNo = React.useCallback(
    (value: string) => {
      if (
        value &&
        !isValidLuhnChecksum(
          value.length < 11 ? value : value.substring(2, value.length)
        )
      ) {
        return false;
      }
      if (isOrgNo(value)) {
        setValue("validTo", null);
      } else {
        setValue("validTo", defaultDate);
      }
      return !(
        !/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]|6[1-9]|[78]\d|9[01])(\d{4})$/.test(
          value
        ) && !isOrgNo(value)
      );
    },
    [isOrgNo, isValidLuhnChecksum, defaultDate, setValue]
  );

  const languageOptions = React.useMemo(
    () => [
      {
        text: t("general.language.en"),
        value: "en",
      },
      {
        text: t("general.language.sv"),
        value: "sv",
      },
    ],
    [t]
  );

  React.useEffect(() => {
    setValue("lang", language);
  }, [setValue, language]);

  if (!control) {
    return null;
  }
  return (
    <FormWrapper>
      <FormGroup>
        <SubHeadarWrapper>
          {t("module.self.service.subscription.ownershiprequest.from")}
        </SubHeadarWrapper>
        <Item>
          <FormInput
            control={control as unknown as Control<FieldValues>}
            data-testid={"org-no"}
            name={"orgNo"}
            label={`${t(
              "module.self.service.subscription.ownershiprequest.orgnossn"
            )} *`}
            onBlur={(e) => {
              if (e.target.value.indexOf("-") > -1) {
                setValue("orgNo", e.target.value.replace("-", ""));
                trigger("orgNo").then();
                trigger("msisdn").then();
              }
              trigger("msisdn").then();
            }}
            onKeyUp={() => {
              if (!isOrgNo(getValues("orgNo"))) {
                trigger("msisdn").then();
              }
            }}
            rules={{
              required: true,
              validate: {
                isValidPersonOrOrgNo: isValidPersonOrOrgNo,
              },
            }}
          />
          {errors &&
            errors.orgNo &&
            errors.orgNo.type === "isValidPersonOrOrgNo" && (
              <span>{t("general.ssn.error")}</span>
            )}
        </Item>
        <Item>
          <FormInput
            control={control as unknown as Control<FieldValues>}
            name={"msisdn"}
            onBlur={() => trigger("msisdn")}
            onFocus={() => trigger("msisdn")}
            label={
              !isOrgNo(getValues("orgNo"))
                ? t(
                    "module.self.service.subscription.ownershiprequest.msisdn"
                  ) + " *"
                : t("module.self.service.subscription.ownershiprequest.msisdn")
            }
            required={!isOrgNo(getValues("orgNo"))}
            rules={{
              required: !isOrgNo(getValues("orgNo")),
              pattern: {
                value: /^\+[1-9]\d{1,14}$/,
                message: t("general.phone.error"),
              },
            }}
          />
        </Item>
        {getValues("msisdn") && getValues("validTo") && (
          <Item>
            <b>
              {`${t(
                "module.self.service.subscription.ownershiprequest.validto"
              )}  ${formatDate(getValues("validTo") ?? "")}`}
            </b>
          </Item>
        )}
        <SubHeadarWrapper>
          {t("module.self.service.subscription.ownershiprequest.contact")}
        </SubHeadarWrapper>
        <Item>
          <FormInput
            control={control as unknown as Control<FieldValues>}
            rules={{
              required: !getValues("contactMsisdn"),
              pattern: {
                value:
                  /[A-Za-z\d\-_+!$#%{|}~^'`]+(\.?[A-Za-z\d\-_+!$#%{|}~^'`]+)*@[A-Za-z\d-]+(\.[A-Za-z\d-]+)*\.[A-Za-z]{2,}/,
                message: t("general.email.error"),
              },
            }}
            onBlur={() => trigger("contactMsisdn")}
            onFocus={() => trigger("contactMsisdn")}
            name={"contactEmail"}
            info={"email@email.com"}
            label={
              !getValues("contactMsisdn")
                ? t(
                    "module.self.service.subscription.ownershiprequest.contactemail"
                  ) + " *"
                : t(
                    "module.self.service.subscription.ownershiprequest.contactemail"
                  )
            }
          />
        </Item>
        <Item>
          <FormInput
            control={control as unknown as Control<FieldValues>}
            rules={{
              required: !getValues("contactEmail"),
              pattern: {
                value: /^\+[1-9]\d{1,14}$/,
                message: t("general.phone.error"),
              },
            }}
            name={"contactMsisdn"}
            onBlur={() => trigger("contactEmail")}
            onFocus={() => trigger("contactEmail")}
            label={
              !getValues("contactEmail")
                ? t(
                    "module.self.service.subscription.ownershiprequest.contactmsisdn"
                  ) + " *"
                : t(
                    "module.self.service.subscription.ownershiprequest.contactmsisdn"
                  )
            }
          />
        </Item>
        <Item>
          <FormSelect
            data-testid="lang"
            data={languageOptions}
            name={"lang"}
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
            label={t("general.communication.language")}
          />
        </Item>
      </FormGroup>
    </FormWrapper>
  );
};
export default OwnershipRequestForm;

const SubHeadarWrapper = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const FormWrapper = styled.div`
  height: 450px;
`;
