import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useInvalidateCartOppRows } from "../checkout/useCartOppRows";
import { useInvalidateContacts } from "../contacts/useContacts";

interface ConnectContactRequest {
  oppRowId: number;
  salesProjectId: number;
  contactId: number;
  roleId: number;
}

const post = async (request: ConnectContactRequest) => {
  await axios.post("/api/self-service/connect-contact", null, {
    params: request,
  });
};

const useConnectContact = () => {
  const invalidateContacts = useInvalidateContacts();
  const invalidatePostOppRowsGet = useInvalidateCartOppRows();

  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  const connect = useCallback(
    async (request: ConnectContactRequest) => {
      await mutateAsync(request);
      invalidateContacts();
      invalidatePostOppRowsGet();
    },
    [invalidateContacts, invalidatePostOppRowsGet, mutateAsync]
  );

  return { connect, isPending };
};
export default useConnectContact;
