import * as React from "react";
import styled from "styled-components";

import { scrollElemHoriz } from "./carouselUtils";
import CarouselPager from "./components/CarouselPager";
import CarouselButton from "./components/CarouselButton";
import { PropsWithChildren } from "react";

const ComponentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 380px;
  justify-content: center;
  width: 400px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollContent = styled.div`
  flex-grow: 1;
  height: 350px;
  overflow-x: visible;
  overflow-y: hidden;
  width: 100%;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  // touch-action: none;
  // -webkit-overflow-scrolling: touch;
`;

const ItemsGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
`;

const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Carousel: React.FC<PropsWithChildren> = ({ children }) => {
  const [contentNode, setContentNode] = React.useState<HTMLDivElement>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [touchPosition, setTouchPosition] = React.useState<number>();
  const [touchPage, setTouchPage] = React.useState<number>();
  const [touchMove, setTouchMove] = React.useState<number>();

  const mappedChildren = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return (
        <ItemWrapper key={index}>
          {React.cloneElement(child, {
            ...child.props,
          })}
        </ItemWrapper>
      );
    }
    return null;
  });

  const itemsCount = mappedChildren?.length || 0;

  const checkPageEnd = React.useCallback(
    (nextPage: number) => {
      if (nextPage > itemsCount - 1) {
        setCurrentPage(0);
      } else if (nextPage < 0) {
        setCurrentPage(itemsCount - 1);
      } else {
        setCurrentPage(nextPage);
      }
    },
    [itemsCount]
  );

  const contentRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContentNode(node);
    }
  }, []);

  React.useEffect(() => {
    if (contentNode) {
      const toX = contentNode.clientWidth * currentPage;
      const duration = 350;
      const startingX = contentNode.scrollLeft;
      const maxX = contentNode.scrollWidth - contentNode.clientWidth;
      const targetX = toX < startingX ? -(startingX - toX) : toX - startingX;
      let moveX = targetX > maxX ? maxX - startingX : targetX; // check right limit
      moveX = startingX + targetX < 0 ? -startingX : moveX; // check left limit

      scrollElemHoriz(contentNode, startingX, moveX, duration);
    }
  }, [contentNode, currentPage]);

  function handleTouchStart(e: React.TouchEvent): void {
    const touchDown = e.touches[0].clientX;
    setTouchPage(currentPage);
    setTouchPosition(touchDown);
    setTouchMove(0);
  }

  function handleTouchMove(e: React.TouchEvent): void {
    if (touchPosition === undefined || touchPage === undefined) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = Math.round((touchPosition - currentTouch) / 50);

    setTouchMove(diff);
  }

  function handleTouchEnd(): void {
    setTouchPosition(undefined);
    setTouchPage(undefined);
    setTouchMove(undefined);
  }

  React.useEffect(() => {
    if (touchPage === undefined || touchMove === undefined) return;
    checkPageEnd(touchPage + touchMove);
  }, [touchMove, touchPage, checkPageEnd]);

  return (
    <ComponentWrapper>
      <ScrollWrapper>
        <CarouselButton
          dir="left"
          onClick={() => checkPageEnd(currentPage - 1)}
        />
        <ScrollContent
          ref={contentRef}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <ItemsGrid>{mappedChildren}</ItemsGrid>
        </ScrollContent>
        <CarouselButton
          dir="right"
          onClick={() => checkPageEnd(currentPage + 1)}
        />
      </ScrollWrapper>
      <CarouselPager
        activePage={currentPage}
        itemsCount={itemsCount}
        onPagerClick={setCurrentPage}
      />
    </ComponentWrapper>
  );
};

export default Carousel;
