import { Button, color, padding } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useActiveCart from "../../../../api/self-service/cart/useActiveCart";
import { ContentWidth } from "../../../../components/Layout/Layout";
import { CONST_URL_SELF_SERVICE_CHECKOUT_CART } from "../../../approutes";

const FooterWrapper = styled.div`
  background-color: ${color.BRAND_DARK_BLUE};
  height: 84px;
`;
const StyledContentWidth = styled(ContentWidth)`
  padding: ${padding.XL};
  color: ${color.BRAND_WHITE};
  display: flex;
  justify-content: space-between;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
  margin-right: 15px;
`;

const StyledProductSpan = styled.span`
  font-weight: 500;
  font-size: 18px;
`;

const FooterCart: React.FC = () => {
  const { t } = useTranslation();
  const { data: activeCart } = useActiveCart();
  const navigate = useNavigate();
  if (!activeCart?.salesProjectId || activeCart?.rows === 0) {
    return <></>;
  }
  return (
    <FooterWrapper>
      <StyledContentWidth $maxWidth={1176}>
        <StyledColumn>
          <span>{t("module.self.service.order.process.step1")}</span>
          <StyledProductSpan>
            {activeCart.rows} {t("general.products")}
          </StyledProductSpan>
        </StyledColumn>
        <StyledRow>
          <ButtonWrapper>
            <Button
              xs
              disabled={!activeCart?.salesProjectId}
              onClick={() => {
                navigate(
                  `${CONST_URL_SELF_SERVICE_CHECKOUT_CART}?salesProjectId=${activeCart?.salesProjectId}`
                );
              }}
            >
              {t("module.self.service.go.to.cart")}
            </Button>
          </ButtonWrapper>
        </StyledRow>
      </StyledContentWidth>
    </FooterWrapper>
  );
};
export default FooterCart;
