import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useCancelOppRow from "../../../../api/self-service/cancel-opp-row/useCancelOppRow";
import { EditComponentsMode } from "../../../../api/self-service/edit-components-model/useEditComponentsModel";
import Block from "../../../../components/Block";
import { useComponentsAttributes } from "../../../../components/component/ComponentsContext";
import { getPath } from "../../../../utils/paths";
import { CONST_URL_SELF_SERVICE_CHECKOUT_CART } from "../../../approutes";

const OpportunityButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mode, salesProjectId, referrer, oppRowId, packageInstanceId } =
    useComponentsAttributes();
  const { cancelOppRow, isPending } = useCancelOppRow();
  const showBackUrl = hasBackUrl(mode);
  const showCancelOppRow = hasCancelOppRow(mode);
  const editing = isEditing(mode);
  const endUser = isEndUser(mode);
  const goToCartUrl = React.useMemo(() => {
    if (endUser) {
      if (!hasOpenCart(referrer)) {
        return `${getPath(referrer)}${toCartHash(referrer)}`;
      } else {
        return `${getPath(referrer)}`;
      }
    } else {
      return `${CONST_URL_SELF_SERVICE_CHECKOUT_CART}?salesProjectId=${salesProjectId}`;
    }
  }, [endUser, referrer, salesProjectId]);
  return (
    <>
      {isPending && <Block />}
      {!editing && showCancelOppRow && (
        <button
          className="opp-referrer btn btn-secondary btn-responsive"
          onClick={async () => {
            await cancelOppRow({ oppRowId, packageInstanceId, salesProjectId });
            navigate(getPath(referrer), { replace: true });
          }}
        >
          {t("general.cancel")}
        </button>
      )}
      {showBackUrl && (
        <button
          className="btn btn-secondary btn-responsive"
          onClick={() => {
            if (referrer) {
              navigate(getPath(referrer));
            } else {
              navigate(-1);
            }
          }}
        >
          {t("general.back")}
        </button>
      )}
      <Link
        to={goToCartUrl}
        id="send-order-btn"
        className="btn btn-primary btn-responsive edit-component-go-to-cart-tracking"
      >
        {t("module.self.service.go.to.cart")}
      </Link>
    </>
  );
};
export default OpportunityButtons;

const toCartHash = (referrer: string) => {
  const index = referrer.lastIndexOf("#");
  if (index !== -1) {
    return "&openCart=1";
  }
  return "#openCart=1";
};
const hasOpenCart = (referrer: string) => {
  const index = referrer.lastIndexOf("#");
  if (index !== -1) {
    return referrer.indexOf("openCart") > -1;
  }
  return false;
};

const hasCancelOppRow = (mode: EditComponentsMode) => {
  switch (mode) {
    case "ADD_FROM_OTHER_PAGE":
    case "EDIT_FROM_OTHER_PAGE":
    case "ADD_FROM_END_USER":
    case "ADD_FROM_CART":
      return true;
    default:
      return false;
  }
};

const hasBackUrl = (mode: EditComponentsMode) => {
  switch (mode) {
    case "ADD_FROM_OTHER_PAGE":
    case "EDIT_FROM_OTHER_PAGE":
      return true;
    default:
      return false;
  }
};

const isEndUser = (mode: EditComponentsMode): boolean => {
  switch (mode) {
    case "EDIT_FROM_END_USER":
    case "ADD_FROM_END_USER":
      return true;
    default:
      return false;
  }
};

const isEditing = (mode: EditComponentsMode): boolean => {
  switch (mode) {
    case "EDIT_FROM_CART":
    case "EDIT_FROM_END_USER":
    case "EDIT_FROM_OTHER_PAGE":
      return true;
    default:
      return false;
  }
};
