import { CONST_ROUTE_DDOS_INDEX, CONST_ROUTE_DDOS_REPORT } from "../approutes";
import { AppRouteProps } from "../types";
import * as React from "react";

const DDos = React.lazy(() => import("./DDos"));
const SubscriptionDdosReport = React.lazy(
  () => import("./SubscriptionDdosReport/SubscriptionDdosReport")
);

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_DDOS_INDEX,
    component: DDos,
  },
  {
    path: CONST_ROUTE_DDOS_REPORT,
    component: SubscriptionDdosReport,
  },
];
export default routes;
