import {
  HeaderButton,
  MainMenuItemProps,
  MainMenuMobile,
  PopupManager,
} from "@tele2/t2-ui-kit";

import Menu from "@tele2/t2-ui-kit/build/Icon/icons/Menu";
import * as React from "react";
import useIsEndUserPortal from "../../utils/useIsEndUserPortal";

import MobileMenuSearch from "./MobileMenuSearch";
import { useTranslation } from "react-i18next";

const MainMenuButton: React.FC<{
  menuItems?: MainMenuItemProps[];
  onMainMenuChange: (item: MainMenuItemProps) => void;
}> = ({ menuItems, onMainMenuChange }) => {
  const { t } = useTranslation();
  const isEndUserPortal = useIsEndUserPortal();
  return (
    <PopupManager
      element={() => (
        <HeaderButton aria-label={t("general.open.main.menu")}>
          <Menu />
        </HeaderButton>
      )}
      fullWidth
    >
      {(onClose) => (
        <MainMenuMobile
          onMainMenuChange={onMainMenuChange}
          mainMenu={menuItems}
          mainMenuTop={isEndUserPortal ? undefined : <MobileMenuSearch />}
          onClose={onClose}
          title={t("general.portal")}
        />
      )}
    </PopupManager>
  );
};

export default MainMenuButton;
