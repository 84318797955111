import { Loader } from "@tele2/t2-ui-kit";

import Portal from "../Portal/Portal";
import * as React from "react";
import ErrorHandler from "../../Routes/ErrorHandler";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export interface ModalContentInterface {
  onClose?: () => void;
}
interface ModalProps extends ModalContentInterface {
  centered?: boolean;
  children: React.ReactNode;
  classes?: string;
  closeOnEscape?: boolean;
  hideFooter?: boolean;
  isLoading?: boolean;
  size?: "xl" | "lg" | "md" | "sm";
  title?: string;
  windowed?: boolean;
}

const ClickBox = styled.div`
  background-color: transparent;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  height: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ModalFooterContainer = styled.div`
  > :first-child:not(:only-child) {
    display: none;
  }
`;

const ModalContainer = styled.div`
  display: block;
  ${(props: { centered?: boolean }) =>
    props.centered &&
    `
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

const ModalDialog = styled.div`
  display: flex;
  max-height: calc(100% - 40px);
`;

// fix to be able to break out of the body overflow
const ModalConent = styled.div`
  transform: scale(1);
`;

const ModalBody = styled.div`
  overflow: auto;
`;

export const ModalContext =
  React.createContext<React.MutableRefObject<null> | null>(null);

const Modal: React.FC<ModalProps> = ({
  centered,
  children,
  hideFooter,
  isLoading,
  onClose,
  size,
  title,
  windowed,
}) => {
  const { t } = useTranslation();
  const footerContainerRef = React.useRef(null);
  const dialogClasses = ["modal-dialog"];
  if (size) {
    dialogClasses.push("modal-" + size);
  }
  if (windowed) {
    dialogClasses.push("modal-windowed");
  }
  React.useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const fallback = (
    <LoaderWrapper>
      <Loader loadingText={t("general.loading")} />
    </LoaderWrapper>
  );
  return (
    <Portal containerId="modal-container">
      <ModalContext.Provider value={footerContainerRef}>
        <ModalContainer
          centered={centered}
          className="modal show"
          role="dialog"
          tabIndex={-1}
        >
          <ClickBox
            onClick={() => {
              onClose?.();
            }}
          />
          <ModalDialog className={dialogClasses.join(" ")}>
            <ModalConent className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{title}</h4>
                <button
                  type="button"
                  className="close"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  &times;
                </button>
              </div>
              <ModalBody className="modal-body">
                <ErrorHandler source={"Modal"}>
                  <React.Suspense fallback={fallback}>
                    {children}
                  </React.Suspense>
                </ErrorHandler>
              </ModalBody>
              {hideFooter ? null : (
                <ModalFooterContainer
                  ref={footerContainerRef}
                  className="modal-footer"
                >
                  <button
                    className="btn btn-secondary"
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    {t("general.close")}
                  </button>
                </ModalFooterContainer>
              )}
            </ModalConent>
          </ModalDialog>
        </ModalContainer>
        <div className="modal-backdrop fade show" />
      </ModalContext.Provider>
    </Portal>
  );
};

export default Modal;
