import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface UndoProps {
  attrData: string | null;
  relatedAttrData: string | null;
  onClick: () => void;
}

const Undo: React.FC<UndoProps> = ({ relatedAttrData, attrData, onClick }) => {
  const { t } = useTranslation();
  if (!relatedAttrData || attrData === relatedAttrData) {
    return null;
  }
  return (
    <UndoWrapper
      role={"button"}
      title={t("module.self.service.attribute.revert", [relatedAttrData])}
      onClick={onClick}
    >
      <span className="fa fa-undo" />
      <span className="sr-only">{t("general.undo.changes")}</span>
    </UndoWrapper>
  );
};
export default Undo;

const UndoWrapper = styled.span`
  cursor: pointer;
`;
