import { Text } from "@tele2/t2-ui-kit";
import {
  OpportunityGroup,
  OpportunityRow,
} from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import { CartActionPermissions } from "../../../../api/self-service/checkout/useSalesProjectSummaryValidation";
import * as React from "react";
import styled from "styled-components";

import ActionsColumn from "./ActionsColumn";
import PriceCells from "./PriceCells";
import Validation from "./Validation";
import { useTranslation } from "react-i18next";
import { DeleteOppRowModalObj } from "../MiniCartButtonWrapper";

interface CartRowProductProps {
  group: OpportunityGroup;
  permissions: CartActionPermissions;
  salesProjectId: number;
  row: OpportunityRow;
  onClose?: () => void;
  setShowConfirmDeleteOppRowModal: (props: DeleteOppRowModalObj) => void;
}

const StyledType = styled(Text)`
  margin-right: 0.25rem;
`;

const StyledValidation = styled(Validation)`
  font-size: 16px;
`;

const CartRowProduct: React.FC<CartRowProductProps> = ({
  group,
  permissions,
  salesProjectId,
  onClose,
  row,
  setShowConfirmDeleteOppRowModal,
}) => {
  const { t } = useTranslation();
  const types =
    (group.packageInstanceId === 0 &&
      row.types?.length &&
      row.types.map((type, typeKey) => {
        return (
          <StyledType xl key={typeKey}>
            {type.description}
          </StyledType>
        );
      })) ||
    null;
  return (
    <>
      <div>{types}</div>
      <div>
        <Text xl>
          {row.subscription?.product?.name}
          {row.subscription?.alias && ` - ${row.subscription?.alias}`}
        </Text>
        <div>{row.keyAttr && <small>{row.keyAttr}</small>}</div>
      </div>
      <StyledValidation
        row={row}
        displayValidation={permissions.displayValidation}
      />
      <div className="text-center">
        <Text xl>
          {`${row.quantity > 0 ? row.quantity : 1} ${t("general.pcs")}`}
        </Text>
      </div>
      <PriceCells row={row} />
      {group.packageInstanceId > 0 ? (
        <div />
      ) : (
        <ActionsColumn
          group={group}
          permissions={permissions}
          salesProjectId={salesProjectId}
          onClose={onClose}
          oppRowId={row.id}
          setShowConfirmDeleteOppRowModal={setShowConfirmDeleteOppRowModal}
          attributesEditable={row.attributesEditable}
        />
      )}
    </>
  );
};
export default CartRowProduct;
