import { Text } from "@tele2/t2-ui-kit";

import { OpportunityRow } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import * as React from "react";
import styled from "styled-components";

interface CartRowBaseSubscriptionProps {
  row: OpportunityRow;
}

const RowStyle = styled.div`
  grid-column: span 6;
`;

const CartRowBaseSubscription: React.FC<CartRowBaseSubscriptionProps> = ({
  row,
}) => {
  const title = row?.subscription?.product?.name;

  return (
    <RowStyle>
      <Text xxl medium>
        {title}
      </Text>
    </RowStyle>
  );
};

export default CartRowBaseSubscription;
