import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useModuleReadRight } from "../../../api/session/useModuleRight";
import CreateOwnershipRequestModal from "../../../Routes/SelfService/OwnershipRequest/CreateOwnershipRequestModal";
import LinkStyleButton from "../../Buttons/LinkStyleButton";

const PortMsisdnInfo: React.FC<{ isValid: boolean }> = ({ isValid }) => {
  const haseModule = useModuleReadRight("SUBSCRIPTION_OWNERSHIP_REQUEST");
  const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);
  const { t } = useTranslation();
  if (!haseModule || isValid) {
    return null;
  }

  return (
    <Container>
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "module.self.service.subscription.ownershiprequest.infolinktext"
            ),
          }}
        />
        <LinkStyleButton onClick={() => setShowCreateModal(true)}>
          {t("module.self.service.subscription.ownershiprequest.infolinkurl")}
        </LinkStyleButton>
        {showCreateModal && (
          <CreateOwnershipRequestModal
            title={t(
              "module.self.service.subscription.ownershiprequest.create"
            )}
            onClose={() => setShowCreateModal(false)}
          />
        )}
      </div>
    </Container>
  );
};
export default PortMsisdnInfo;

const Container = styled.div`
  margin: 0.5em 0;
`;
