import { useEffect } from "react";
import { TFunction, useTranslation } from "react-i18next";
import useGetSalesforceChatProps, {
  SalesforceChatModel,
} from "../api/contact/customer-service/salesforcechat-model/useGetSalesforceChatProps";
import { useModuleReadRight } from "../api/session/useModuleRight";
import Window from "../global";
import { useAcceptFunctionality } from "../Routes/CookiePolicy/CookiePolicy";

const setScript = (
  url: string,
  data: SalesforceChatModel,
  t: TFunction<string>
): void => {
  if (url) {
    const script = document.createElement("script");
    script.setAttribute("src", url);
    script.onload = function () {
      initESW("", data, t);
    };
    document.body.appendChild(script);
  }
};

// check out the implementation on the public tele2 site here: https://dev.azure.com/tele2/Business%20IT/_git/B2B-SME-Web?path=%2Fsrc%2Fclient%2Fcomponents%2Fsalesforce-chat%2FuseSalesforceChat%2FinitESW.ts&_a=contents&version=GBmaster
const initESW = async (
  gslbBaseURL: string,
  data: SalesforceChatModel,
  t: TFunction<string>
): Promise<void> => {
  if (!window) return;
  const global = window as Window;
  const caseOrigin = "Chat";
  if (global.embedded_svc && data.enabled) {
    global.embedded_svc.settings.displayHelpButton = true; //Or false
    global.embedded_svc.settings.language = document.documentElement.lang; //For example, enter 'en' or 'en-US'
    global.embedded_svc.settings.defaultMinimizedText = t(
      "general.chat.withsupport"
    ); //(Defaults to Chat with an Expert)
    global.embedded_svc.settings.disabledMinimizedText = t(
      "general.chat.closed"
    ); //(Defaults to Agent Offline)

    global.embedded_svc.settings.loadingText = t("general.loading"); //(Defaults to Loading)

    //Sets the auto-population of pre-chat form fields
    global.embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: data.customerFirstName,
      LastName: data.customerLastName,
      Email: data.customerEmail,
      external_Id__c: "MEL-" + data.contactId,
      origin: caseOrigin,
      Account_Number__c: data.melodyAccountId,
    };

    global.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: "External Id",
        value: "MEL-" + data.contactId,
        transcriptFields: ["External_Id__c"],
        displayToAgent: true,
      },
      {
        label: "Account Number",
        value: data.melodyAccountId,
        transcriptFields: ["Account_Number__c"],
        displayToAgent: true,
      },
      {
        label: "Email",
        value: data.customerEmail,
        displayToAgent: true,
      },
    ];
    global.embedded_svc.settings.extraPrechatInfo = [
      {
        entityName: "Contact",
        showOnCreate: true,
        linkToEntityName: "Case",
        linkToEntityField: "ContactId",
        saveToTranscript: "Contact",
        entityFieldMaps: [
          {
            isExactMatch: true,
            fieldName: "External_Id__c",
            doCreate: true,
            doFind: true,
            label: "External Id",
          },
          {
            isExactMatch: false,
            fieldName: "Email",
            doCreate: true,
            doFind: false,
            label: "Email",
          },
        ],
      },
    ];

    // Settings for Chat
    global.embedded_svc.settings.directToButtonRouting = function () {
      if (data.customerServiceLabel === "Frank") {
        return data.frankId;
      } else if (data.customerServiceLabel === "Francis") {
        return data.francisId;
      } else if (
        data.customerServiceLabel === "SPOC" ||
        data.customerServiceLabel === "SPOC Enterprise" ||
        data.customerServiceLabel === "Enterprise"
      ) {
        return data.spocId;
      } else if (
        data.customerServiceLabel === "SPOC-LEITS" ||
        data.customerServiceLabel === "SPOC Discovery" ||
        data.customerServiceLabel === "Discovery"
      ) {
        return data.spocDiscoveryId;
      } else if (data.customerServiceLabel === "SME RAM") {
        return data.smeRamId;
      } else if (data.customerServiceLabel === "Power") {
        return data.powerId;
      } else if (data.customerServiceLabel === "Service Center") {
        return data.serviceCenterId;
      }
      return "";
    };

    global.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    global.embedded_svc.settings.entryFeature = "LiveAgent";

    global.embedded_svc.init(
      data.salesforceUrl,
      data.chatUrl,
      gslbBaseURL,
      data.chatId,
      "BCS_Chat",
      {
        baseLiveAgentContentURL: data.baseLiveAgentContentURL,
        deploymentId: data.deploymentId,
        buttonId: data.buttonId,
        baseLiveAgentURL: data.baseLiveAgentURL,
        eswLiveAgentDevName: data.eswLiveAgentDevName,
        isOfflineSupportEnabled: false,
      }
    );
  }
};

const useSalesForceChat = (): void => {
  const acceptFunctionality = useAcceptFunctionality();
  const contactCustomerService = useModuleReadRight("IM");
  const { t } = useTranslation();
  const { data } = useGetSalesforceChatProps(
    acceptFunctionality && contactCustomerService && Boolean(window)
  );
  useEffect(() => {
    if (data && data.enabled) {
      if (!(window as Window).embedded_svc) {
        setScript(data.scriptSource, data, t);
      } else {
        initESW("https://service.force.com", data, t).then();
      }
    }
  }, [data, t]);
};
export default useSalesForceChat;
