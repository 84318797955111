import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface LoginMessageNotification {
  id: number;
  translation: string;
  level: string;
}

export interface OrderCaseActivity {
  caseId: number;
  orderId: number;
  description: string;
  hasChanged: boolean;
  subsId: number;
  alias: string;
  prodName: string;
  worklogCount: number;
  managerMissing: boolean;
}

export interface GeneratedReportNotification {
  id: number;
  description: string;
  filename: string;
  error: boolean;
}

interface NotificationsModel {
  doesCustomerLackPlannedWorkContacts: boolean;
  numberOfArsTickets: number;
  numberOfOperatingTickets: number;
  loginMessages: LoginMessageNotification[];
  caseActivities: OrderCaseActivity[];
  generatedReports: GeneratedReportNotification[];
}

const getNotifications = async () => {
  const { data } = await axios.get<NotificationsModel>("/api/notifications");
  return data;
};

const useNotifications = () => {
  return useQuery<NotificationsModel, AxiosError>({
    queryKey: ["home-notifications"],
    queryFn: getNotifications,
    refetchInterval: 30_000,
    throwOnError: false,
  });
};
export default useNotifications;
