import { Flex, Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import Chat from "./components/Chat/Chat";
import ContextMenuContextProvider from "./components/ContextMenu/ContextMenuContext";
import Footer from "./components/Footer";
import Messages, { MessagesProvider } from "./components/Message/Message";

import TopMenu from "./components/TopMenu";

import { HeaderProvider } from "./components/TopMenu/HeaderContext";
import GAContext from "./GAContext";
import useApm from "./hooks/useApm";
import useGtm from "./hooks/useGtm";
import "./i18n.ts";
import NotificationsContex from "./NotificationsContext/NotificationsContext";
import AppRoutes from "./Routes";
import { initCookies } from "./Routes/CookiePolicy/CookiePolicy";
import ErrorHandler from "./Routes/ErrorHandler";
import { ErrorPageWrapper } from "./Routes/ErrorHandler/ErrorPage";
import SuspenseBoundary from "./Routes/SuspenseBoundary";
import ScrollToTop from "./scrollToTop";
import SessionContext from "./SessionContext";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .container.global-gdpr {
    flex: 1 1 auto;
    padding: 20px 16px;
  }

  > ${Flex}, ${ErrorPageWrapper}, .t2-legacy-route {
    flex: 1 1 auto;
    padding: 72px 0px;
  }
`;

export const LoaderWrapper = styled.div`
  max-width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

declare global {
  interface Window {
    dataLayer: {
      event: string;
      page_path: string;
      page_title: string;
      segment: string;
    }[];
  }
}

const App: React.FC = () => {
  useApm();
  useGtm();
  React.useEffect(() => {
    initCookies();
  }, []);
  return (
    <SuspenseBoundary
      fallback={
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      }
    >
      <AppContainer>
        <MessagesProvider>
          <Router>
            <ErrorHandler source={"App"}>
              <GAContext>
                <SessionContext>
                  <NotificationsContex>
                    <HeaderProvider>
                      <ContextMenuContextProvider>
                        <ScrollToTop />
                        <TopMenu />
                        <Messages />
                        <AppRoutes />
                        <Footer />
                        <Chat />
                      </ContextMenuContextProvider>
                    </HeaderProvider>
                  </NotificationsContex>
                </SessionContext>
              </GAContext>
            </ErrorHandler>
          </Router>
        </MessagesProvider>
      </AppContainer>
    </SuspenseBoundary>
  );
};
export default App;
