import * as React from "react";
import { Outlet } from "react-router";
import { AvailableBaseSubs } from "../../../api/home/useAvailableBaseSubs";
import useAvailableBaseServices from "../../../api/self-service/available-base-services/useAvailableBaseServices";
import useEsalesProductCatalogModel, {
  EsalesParams,
  EsalesProductCatalogModel,
  NodeType,
} from "../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import { RoleType } from "../../../api/self-service/types";
import { useModuleReadRight } from "../../../api/session/useModuleRight";
import { useUrlParams } from "../../../utils/useUrlParams";
import {
  CONST_URL_END_USER_PRODUCT,
  CONST_URL_SELF_SERVICE_PRODUCT,
} from "../../approutes";
import { SelfServiceRenderMode } from "./product-catalog-type";

const ROOT_DEFAULT = "Root";

interface ProductCatalogContextValue {
  nodeKeyState: [string, React.Dispatch<React.SetStateAction<string>>];
  productSearchState: [string, React.Dispatch<React.SetStateAction<string>>];
  nodeTypeState: [NodeType, React.Dispatch<React.SetStateAction<NodeType>>];
  baseSubsIdState: [number, React.Dispatch<React.SetStateAction<number>>];
  salesProjectIdState: [number, React.Dispatch<React.SetStateAction<number>>];
  catalog: [
    EsalesProductCatalogModel | undefined,
    React.Dispatch<React.SetStateAction<EsalesProductCatalogModel | undefined>>
  ];
  params: [EsalesParams, React.Dispatch<React.SetStateAction<EsalesParams>>];

  selfServiceMode: SelfServiceRenderMode;
  productLink: string;

  availableBaseServices?: AvailableBaseSubs[];
}

const ProductCatalogContext = React.createContext<ProductCatalogContextValue>({
  nodeKeyState: [ROOT_DEFAULT, () => null],
  productSearchState: ["", () => null],
  nodeTypeState: ["OPPORTUNITY", () => null],
  baseSubsIdState: [0, () => null],
  salesProjectIdState: [0, () => null],
  catalog: [undefined, () => null],
  availableBaseServices: undefined,
  selfServiceMode: SelfServiceRenderMode.PORTAL,
  productLink: "",
  params: [
    {
      salesProjectId: 0,
      baseSubsId: 0,
      roleType: RoleType.SELF_SERVICE,
      mode: "ADD_FROM_OTHER_PAGE",
      hasSelfService: true,
    },
    () => null,
  ],
});

export const useNodeKeyState = (): ProductCatalogContextValue["nodeKeyState"] =>
  React.useContext(ProductCatalogContext).nodeKeyState;

export const useSelfServiceMode =
  (): ProductCatalogContextValue["selfServiceMode"] =>
    React.useContext(ProductCatalogContext).selfServiceMode;

export const useProductLink = (): ProductCatalogContextValue["productLink"] =>
  React.useContext(ProductCatalogContext).productLink;

export const useCatalogBaseServices =
  (): ProductCatalogContextValue["availableBaseServices"] =>
    React.useContext(ProductCatalogContext).availableBaseServices;

export const useNodeTypeState =
  (): ProductCatalogContextValue["nodeTypeState"] =>
    React.useContext(ProductCatalogContext).nodeTypeState;

export const useProductSearch =
  (): ProductCatalogContextValue["productSearchState"] =>
    React.useContext(ProductCatalogContext).productSearchState;

export const useBaseSubsId =
  (): ProductCatalogContextValue["baseSubsIdState"] =>
    React.useContext(ProductCatalogContext).baseSubsIdState;

export const useSalesProjectId =
  (): ProductCatalogContextValue["salesProjectIdState"] =>
    React.useContext(ProductCatalogContext).salesProjectIdState;

export const useCatalog = (): ProductCatalogContextValue["catalog"] =>
  React.useContext(ProductCatalogContext).catalog;

export const useCatalogParams = (): ProductCatalogContextValue["params"] =>
  React.useContext(ProductCatalogContext).params;

const getBaseSubsId = (
  urlBaseSubsId: number,
  availableBaseService: AvailableBaseSubs[],
  hasEndUserOrder: boolean
) => {
  const canOrderServices = availableBaseService.filter(
    (s) => s.canOrder || hasEndUserOrder
  );
  if (canOrderServices.length === 0) {
    return 0;
  }
  if (urlBaseSubsId) {
    const baseService = canOrderServices.find(
      (value) => value.subsId === urlBaseSubsId
    );
    if (baseService) {
      return baseService.subsId;
    }
  }
  return canOrderServices[0].subsId;
};

interface Props {
  selfServiceMode: SelfServiceRenderMode;
}
const Provider: React.FC<Props> = ({ selfServiceMode }) => {
  const nodeKeyState = React.useState<string>(ROOT_DEFAULT);
  const hasEndUserOrder = useModuleReadRight("ORDERS_END_USERS");

  const urlBaseSubsId = Number(useUrlParams("baseSubsId"));
  const productSearchState = React.useState<string>("");
  const [nodeType, setNodeType] = React.useState<NodeType>("OPPORTUNITY");
  const [catalog, setCatalog] = React.useState<
    EsalesProductCatalogModel | undefined
  >(undefined);

  const { data: availableBaseServices } = useAvailableBaseServices(
    selfServiceMode === SelfServiceRenderMode.END_USER
  );

  const [baseSubsIdState, setBaseSubsIdState] = React.useState<number>(
    getBaseSubsId(urlBaseSubsId, availableBaseServices || [], hasEndUserOrder)
  );
  const salesProjectIdState = React.useState<number>(0);
  const [params, setParams] = React.useState<EsalesParams>({
    salesProjectId: salesProjectIdState[0],
    baseSubsId: baseSubsIdState,
    roleType: RoleType.SELF_SERVICE,
    mode: "ADD_FROM_OTHER_PAGE",
    hasSelfService: true,
    subRoleType: RoleType.NONE,
    relatedSubsId: undefined,
  });

  // Sets baseSubsId when user has chosen another baseSubs in dropdown
  React.useEffect(() => {
    setBaseSubsIdState(
      getBaseSubsId(urlBaseSubsId, availableBaseServices || [], hasEndUserOrder)
    );
  }, [
    setBaseSubsIdState,
    urlBaseSubsId,
    availableBaseServices,
    hasEndUserOrder,
  ]);

  React.useEffect(() => {
    setParams((prevState) => {
      return {
        ...prevState,
        baseSubsId: baseSubsIdState,
      };
    });
  }, [baseSubsIdState, setParams]);
  // catalog data is fetched when baseSubsIdState is set
  const { data } = useEsalesProductCatalogModel({
    ...params,
    subRoleType:
      selfServiceMode === SelfServiceRenderMode.END_USER
        ? RoleType.END_USER
        : RoleType.SELF_SERVICE,
  });

  // save catalog data in context
  React.useEffect(() => {
    if (data) {
      setCatalog(data);
      if (!nodeType) {
        setNodeType(
          data.catalogTree.nodeType === "PRODUCT" ? "PRODUCT" : "OPPORTUNITY"
        );
      }
    }
  }, [data, nodeType, setCatalog, setNodeType]);

  return (
    <ProductCatalogContext.Provider
      value={{
        nodeKeyState,
        nodeTypeState: [nodeType, setNodeType],
        productSearchState,
        baseSubsIdState: [baseSubsIdState, setBaseSubsIdState],
        salesProjectIdState,
        catalog: [catalog, setCatalog],
        params: [params, setParams],
        availableBaseServices,
        selfServiceMode,
        productLink:
          selfServiceMode === SelfServiceRenderMode.PORTAL
            ? CONST_URL_SELF_SERVICE_PRODUCT
            : CONST_URL_END_USER_PRODUCT,
      }}
    >
      <Outlet />
    </ProductCatalogContext.Provider>
  );
};

export default Provider;
