import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_ELTEL_CMS,
  CONST_ROUTE_HOME_CUSTOMER_INFO,
  CONST_ROUTE_HOME_HOME,
  CONST_ROUTE_HOME_OPTIONS,
  CONST_ROUTE_HOME_OPTIONS_OWN_CONTACT_ROLES,
  CONST_ROUTE_HOME_RELEASE_NOTES,
  CONST_ROUTE_HOME_SEARCH,
  CONST_ROUTE_HOME_USER_REPORTS,
} from "../approutes";
import * as React from "react";

const Cms = React.lazy(() => import("./Cms"));
const CustomerInfo = React.lazy(() => import("./CustomerInfo"));
const Home = React.lazy(() => import("./Home"));
const ReleaseNotes = React.lazy(() => import("./ReleaseNotes"));
const UserGeneratedReports = React.lazy(() => import("./UserGeneratedReports"));
const Options = React.lazy(() => import("./Options"));
const OptionsOwnContactRoles = React.lazy(
  () => import("./OptionsOwnContactRoles")
);
const Search = React.lazy(() => import("./Search/Search"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_HOME_HOME,
    component: Home,
  },
  {
    path: CONST_ROUTE_HOME_RELEASE_NOTES,
    component: ReleaseNotes,
  },
  {
    path: CONST_ROUTE_HOME_CUSTOMER_INFO,
    component: CustomerInfo,
  },
  {
    path: CONST_ROUTE_ELTEL_CMS,
    component: Cms,
  },
  {
    path: CONST_ROUTE_HOME_USER_REPORTS,
    component: UserGeneratedReports,
  },
  {
    path: CONST_ROUTE_HOME_SEARCH,
    component: Search,
  },
  {
    path: CONST_ROUTE_HOME_OPTIONS,
    component: Options,
  },
  {
    path: CONST_ROUTE_HOME_OPTIONS_OWN_CONTACT_ROLES,
    component: OptionsOwnContactRoles,
  },
];

export default routes;
