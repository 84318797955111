import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_CONTACT_CONTACT_INFO,
  CONST_ROUTE_CONTACT_CONTACT_INVOLVEMENT,
  CONST_ROUTE_CONTACT_CUSTOMER_CONTACT,
  CONST_ROUTE_CONTACT_CUSTOMER_SERVICE,
} from "../approutes";
import * as React from "react";

const ContactInfo = React.lazy(() => import("./ContactInfo"));
const ContactInvolvement = React.lazy(() => import("./ContactInvolvement"));
const CustomerContact = React.lazy(() => import("./CustomerContact"));
const CustomerService = React.lazy(() => import("./CustomerService"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_CONTACT_CONTACT_INFO,
    component: ContactInfo,
  },
  {
    path: CONST_ROUTE_CONTACT_CONTACT_INVOLVEMENT,
    component: ContactInvolvement,
  },
  {
    path: CONST_ROUTE_CONTACT_CUSTOMER_CONTACT,
    component: CustomerContact,
  },
  {
    path: CONST_ROUTE_CONTACT_CUSTOMER_SERVICE,
    component: CustomerService,
  },
];

export default routes;
