import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_SUBSCRIPTION_ACCOUNTS,
  CONST_ROUTE_SUBSCRIPTION_ALIAS,
  CONST_ROUTE_SUBSCRIPTION_CONTACTS,
  CONST_ROUTE_SUBSCRIPTION_DOCUMENTS,
  CONST_ROUTE_SUBSCRIPTION_DRILL_DOWN,
  CONST_ROUTE_SUBSCRIPTION_END_CUSTOMERS,
  CONST_ROUTE_SUBSCRIPTION_GROUPS,
  CONST_ROUTE_SUBSCRIPTION_MAP,
  CONST_ROUTE_SUBSCRIPTION_MERIDIX,
  CONST_ROUTE_SUBSCRIPTION_MST_ACTIONS,
  CONST_ROUTE_SUBSCRIPTION_OVERVIEW,
  CONST_ROUTE_SUBSCRIPTION_PRICE_LIST,
  CONST_ROUTE_SUBSCRIPTION_REFRESH,
  CONST_ROUTE_SUBSCRIPTION_REFRESH_CONFIRM,
  CONST_ROUTE_SUBSCRIPTION_SITES,
  CONST_ROUTE_SUBSCRIPTION_SUBSCRIPTION,
  CONST_ROUTE_SUBSCRIPTION_WINDOW,
} from "../approutes";
import * as React from "react";

const DrillDown = React.lazy(() => import("./Accounts/DrillDown/DrillDown"));
const SubscriptionAccounts = React.lazy(
  () => import("./Accounts/SubscriptionAccounts/SubscriptionAccounts")
);
const AliasView = React.lazy(() => import("./Alias/AliasView"));
const Groups = React.lazy(() => import("./Groups"));
const MapView = React.lazy(() => import("./Map"));
const PriceListView = React.lazy(() => import("./PriceList/PriceListView"));
const Refresh = React.lazy(() => import("./Refresh/Refresh"));
const RefreshConfirm = React.lazy(() => import("./Refresh/RefreshConfirm"));
const Subscription = React.lazy(() => import("./Subscription"));
const SubscriptionWindow = React.lazy(() => import("./SubscriptionWindow"));
const MstActions = React.lazy(() => import("./Accounts/MstActions/MstActions"));
const Contacts = React.lazy(() => import("./Contacts"));
const Documents = React.lazy(() => import("./Documents"));
const Overview = React.lazy(() => import("./Overview"));
const Sites = React.lazy(() => import("./Sites"));
const EndCustomers = React.lazy(() => import("./EndCustomers/EndCustomers"));
const Meridix = React.lazy(() => import("./Meridix"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_SUBSCRIPTION_OVERVIEW,
    component: Overview,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_ACCOUNTS,
    component: SubscriptionAccounts,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_DRILL_DOWN,
    component: DrillDown,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_MST_ACTIONS,
    component: MstActions,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_SUBSCRIPTION,
    component: Subscription,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_WINDOW,
    component: SubscriptionWindow,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_GROUPS,
    component: Groups,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_DOCUMENTS,
    component: Documents,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_SITES,
    component: Sites,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_REFRESH,
    component: Refresh,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_REFRESH_CONFIRM,
    component: RefreshConfirm,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_CONTACTS,
    component: Contacts,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_PRICE_LIST,
    component: PriceListView,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_ALIAS,
    component: AliasView,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_MAP,
    component: MapView,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_END_CUSTOMERS,
    component: EndCustomers,
  },
  {
    path: CONST_ROUTE_SUBSCRIPTION_MERIDIX,
    component: Meridix,
  },
];

export default routes;
