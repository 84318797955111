import { IOpportunityCaseData } from "../../../../api/self-service/edit-components-model/useEditComponentsModel";
import useSaveCase from "../../../../api/self-service/save-case/useSaveCase";
import { RoleType } from "../../../../api/self-service/types";
import classNames from "classnames";
import Datepicker from "../../../../components/Datepicker/Datepicker";
import { useMessage } from "../../../../components/Message/Message";
import * as React from "react";
import { formatDate, parseDate, toIsoDate } from "../../../../utils/dates";
import { useTranslation } from "react-i18next";

interface CaseProps {
  caseData: IOpportunityCaseData;
  oppRowId: number;
  roleType: RoleType;
}

const Case: React.FC<CaseProps> = ({ caseData, oppRowId, roleType }) => {
  const { t } = useTranslation();
  const message = useMessage();
  const inputState = React.useState<string>(caseData.value || "");
  const [savedState, setSavedState] = React.useState(caseData.value || "");
  const [validationMessage, setValidationMessage] = React.useState(
    caseData.validationMessage
  );
  const { saveCase } = useSaveCase();
  const saveValue = async (value: string) => {
    const data = await saveCase({
      data: value,
      oppRowId,
      roleType,
      caseId: caseData.id,
    });
    setValidationMessage(data.message);
    if (data.saved) {
      setSavedState(value);
    }
    if (!data.message) {
      message({
        message: `${caseData.label} ${t("module.self.service.change.saved")}`,
        theme: "positive",
        interval: 2000,
      });
    }
  };
  return (
    <>
      <div className="row" data-section="case">
        <div className="col-sm-4" title={caseData.help || ""}>
          <label className="attr-label">{caseData.label}</label>
          {!caseData.readOnly && (
            <>
              {caseData.help && (
                <span className="fa fa-question-circle" title={caseData.help} />
              )}
              {caseData.required && <span className="input-required">*</span>}
            </>
          )}
        </div>
        <div
          className="col-sm-8"
          data-case-id={caseData.id}
          data-opp-row-id={oppRowId}
          data-role-type={roleType}
        >
          {caseData.readOnly
            ? getText(caseData)
            : getContent(
                caseData,
                inputState,
                inputState[0] !== savedState,
                saveValue
              )}
          <div className="help-block attr-validation">{validationMessage}</div>
        </div>
      </div>
    </>
  );
};
export default Case;

const getText = (caseData: IOpportunityCaseData) => {
  if (caseData.type === "DATE") {
    return formatDate(caseData.value || "");
  }
  return caseData.value;
};

const getContent = (
  caseData: IOpportunityCaseData,
  inputState: [string, (value: string) => void],
  unsavedValue: boolean,
  saveValue: (value: string) => void
): React.ReactNode => {
  const [inputValue, setInputValue] = inputState;
  const { type, size, required } = caseData;
  const className = classNames(
    "form-control",
    "attr-case-value",
    required ? "attr-required" : "",
    unsavedValue ? "attr-unsaved-change" : ""
  );
  switch (type) {
    case "TEXT":
      return (
        <div className="form-group">
          <input
            className={className}
            type="text"
            value={inputValue}
            size={size || 50}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => saveValue(inputValue)}
          />
        </div>
      );
    case "CHECK_BOX":
      return (
        <div className="form-group">
          <input
            className={className}
            type="checkbox"
            value="CBY"
            checked={inputValue === "1" || inputValue === "CBY"}
            onChange={(e) => saveValue(e.target.checked ? "1" : "0")}
          />
        </div>
      );
    case "TEXT_AREA":
      return (
        <div className="form-group">
          <textarea
            className={className}
            cols={size || 40}
            rows={3}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => saveValue(inputValue)}
            value={inputValue}
          />
        </div>
      );
    case "DATE":
      return (
        <div className="form-inline">
          <div className="form-group">
            <Datepicker
              name={caseData.name}
              selected={inputValue ? parseDate(inputValue) : null}
              dateFormat="P"
              onChange={(date) => {
                setInputValue(date ? toIsoDate(date) : "");
                saveValue(date ? toIsoDate(date) : "");
              }}
              className={className}
              minDate={new Date()}
            />
          </div>
        </div>
      );
    case "FLOAT":
    case "TIME":
      return (
        <div className="form-group">
          <input
            className={className}
            type="text"
            value={inputValue}
            size={size || 15}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => saveValue(inputValue)}
          />
        </div>
      );
  }
  return null;
};
