import { breakpoints, color, Price } from "@tele2/t2-ui-kit";
import styled from "styled-components";

export const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  > svg.not-found {
    height: 100px;
    width: 100px;
  }
`;

export const StyledImage = styled.img`
  max-width: 150px;
  max-height: 110px;
`;

export const StyledPrice = styled(Price)`
  display: block !important;
  margin: 10px 0 10px 0;
  font-weight: 900;
`;

export const ProductGridWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  margin: 15px;
  @media (min-width: ${breakpoints.Tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.Desktop}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledStrong = styled.strong`
  padding-bottom: 5px;
`;

export const LayoutWrapper = styled.div`
  background-color: ${color.BRAND_LIGHT_GRAY};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
