import { AppRouteProps } from "../types";
import { CONST_ROUTE_AGREEMENTS_INDEX } from "../approutes";
import * as React from "react";

const Agreements = React.lazy(() => import("./Agreements"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_AGREEMENTS_INDEX,
    component: Agreements,
  },
];

export default routes;
