import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

export const StyledGridItemWrapper = styled.div`
  padding: 15px;
  min-width: 224px;
  background-color: ${color.BRAND_WHITE};
  min-height: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: ${color.BRAND_PREMIUM_GREY} 1px solid;
  box-sizing: border-box;
`;
const GridItemWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    // once rendered, dont remove
    triggerOnce: true,
    // fallback to show all if inview is not supported by the browser i.e. Safari < 12.1
    fallbackInView: true,
  });
  return (
    <StyledGridItemWrapper ref={ref}>
      {inView && children}
    </StyledGridItemWrapper>
  );
};
export default GridItemWrapper;
