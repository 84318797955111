import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../../components/Message/Message";
import { OwnershipRequest } from "./useGetOwnershipRequest";
import { useInvalidateOwnershipRequests } from "./useGetOwnershipRequests";

const post = async (request: OwnershipRequest) => {
  await axios.post("/api/self-service/ownership-request", request);
};

const usePostOwnershipRequest = () => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  const message = useMessage();
  const invalidateOwnershipRequests = useInvalidateOwnershipRequests();
  const createOwnershipRequest = useCallback(
    async (request: OwnershipRequest) => {
      await mutateAsync(request);
      invalidateOwnershipRequests();
      message({
        message: t("general.created"),
        theme: "positive",
        interval: 3000,
      });
    },
    [invalidateOwnershipRequests, message, mutateAsync, t]
  );

  return { createOwnershipRequest, isPending };
};

export default usePostOwnershipRequest;
