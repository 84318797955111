import * as React from "react";
import { useState } from "react";

/* 
  Helper hook for handle pagination when all records are supplied by backend
*/

export interface Paginated<T> extends PaginateState {
  data: T[];
}

export type PaginateState = {
  limit: number; // items per page
  offset: number; // first show item of total items
  total: number; // total number of items
};

export interface UsePaginateData<T> extends PaginateState {
  onPaginate: React.Dispatch<React.SetStateAction<PaginateState>>;
  pageData: T[];
  setLimit: (paginate: UsePaginateData<T>) => (limit: number) => void;
  showBottomPager?: boolean;
  showTopPager?: boolean;
  showPageLimit?: boolean;
}

interface PaginateOptions {
  showBottomPager?: boolean;
  showTopPager?: boolean;
  showPageLimit?: boolean;
}

const usePaginateData = <T>(
  data: T[],
  defaultState?: Partial<PaginateState>,
  opts?: PaginateOptions
): UsePaginateData<T> => {
  const [state, setState] = useState<PaginateState>({
    offset: 0,
    limit: 20,
    total: data.length,
    ...defaultState,
  });
  React.useEffect(() => {
    setState((prevState) => {
      if (data.length < prevState.offset + prevState.limit) {
        return {
          ...prevState,
          total: data.length,
          offset: 0,
        };
      }
      return { ...prevState, total: data.length };
    });
  }, [data.length]);

  const { offset, limit } = state;
  const pageData = React.useMemo(() => {
    return data.slice(offset, offset + limit);
  }, [data, offset, limit]);

  const setLimit = React.useCallback(
    (paginate: UsePaginateData<T>) => (limit: number) => {
      const { onPaginate, ...pageState } = paginate;
      onPaginate({ ...pageState, limit, offset: 0 });
    },
    []
  );

  return {
    ...state,
    ...opts,
    onPaginate: setState,
    pageData,
    setLimit,
  };
};

export default usePaginateData;
