import { HeaderButton } from "@tele2/t2-ui-kit";
import CartIcon from "@tele2/t2-ui-kit/build/Icon/icons/ShoppingCartOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router-dom";
import { ActiveCart } from "../../../api/self-service/cart/useActiveCart";
import { SelfServiceMode } from "../../../api/self-service/types";
import useGetCustomerCart, {
  useInvalidateCustomerCart,
} from "../../../api/self-service/useGetCustomerCart";
import { useModuleReadRight } from "../../../api/session/useModuleRight";
import {
  CONST_URL_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION,
  CONST_URL_SELF_SERVICE_CHECKOUT_CART,
  CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION,
  CONST_URL_SELF_SERVICE_CHECKOUT_SUMMARY,
  CONST_URL_SELF_SERVICE_EDIT_COMPONENTS,
} from "../../../Routes/approutes";
import ConfirmDeleteOppRowModal from "../../../Routes/SelfService/Cart/Modals/ConfirmDeleteOppRowModal";
import ConfirmOrderSendModal from "../../../Routes/SelfService/Cart/Modals/ConfirmOrderSendModal";
import DeleteCartModal from "../../../Routes/SelfService/Cart/Modals/DeleteCartModal";
import RevertProjectToDraftModal from "../../../Routes/SelfService/Cart/Modals/RevertProjectToDraftModal";
import SendToApprovalModal from "../../../Routes/SelfService/Cart/Modals/SendToApprovalModal";
import useIsEndUserPortal from "../../../utils/useIsEndUserPortal";
import MiniCartModal from "./MiniCartModal";

interface Props {
  activeCart: ActiveCart;
  disable: boolean;
}

export type DeleteOppRowModalObj = {
  oppRowId: number;
  packageInstanceId: number;
};

const MiniCartButtonWrapper: React.FC<Props> = ({ activeCart, disable }) => {
  const { t } = useTranslation();
  const { data: cartModel } = useGetCustomerCart({
    salesProjectId: activeCart?.salesProjectId,
    subRoleType: activeCart?.subRoleType,
  });
  const invalidateCustomerCart = useInvalidateCustomerCart();
  const numberOfProducts = React.useMemo(() => {
    if (Boolean(activeCart?.salesProjectId) && cartModel) {
      return cartModel.container.opportunityGroups
        .map((value) => {
          return value.rows
            .map((row) => {
              if (!row.shadow) {
                return row.quantity > 0 ? row.quantity : 1;
              }
              return 0;
            })
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
        })
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
    }
    return 0;
  }, [cartModel, activeCart?.salesProjectId]);
  const location = useLocation();
  const hasAccess = useModuleReadRight("SELF_SERVICE");
  const hasAccessEndUser = useModuleReadRight("SELF_SERVICE_END_USERS");
  const isCheckoutCart = useMatch(CONST_URL_SELF_SERVICE_CHECKOUT_CART);
  const isCheckoutSummary = useMatch(CONST_URL_SELF_SERVICE_CHECKOUT_SUMMARY);
  const isCheckoutConfirmation = useMatch(
    CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION
  );
  const isCheckoutAdditionalInformation = useMatch(
    CONST_URL_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION
  );
  const isEditComponent = useMatch(CONST_URL_SELF_SERVICE_EDIT_COMPONENTS);
  const isEndUserPortal = useIsEndUserPortal();

  const [showConfirmDeleteCartModal, setShowConfirmDeleteCartModal] =
    React.useState<boolean>(false);

  const [showConfirmDeleteOppRowModal, setShowConfirmDeleteOppRowModal] =
    React.useState<DeleteOppRowModalObj | null>();

  const [showConfirmOrderModal, setShowConfirmOrderModal] =
    React.useState<boolean>(false);

  const [showSendProjectToOrderApproval, setShowSendProjectToOrderApproval] =
    React.useState<boolean>(false);

  const [showRevertProjectToDraftModal, setShowRevertProjectToDraftModal] =
    React.useState<boolean>(false);

  const [showCartModal, setShowCartModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Only used in the end user portal. Remove if it is ever moved from project.
    if (location.hash && location.hash.indexOf("openCart") > -1) {
      setShowCartModal(true);
    }
  }, [location]);

  if (!(hasAccess || hasAccessEndUser) || !activeCart) {
    return null;
  }

  const disabled =
    !activeCart?.rows ||
    Boolean(isEditComponent) ||
    Boolean(isCheckoutCart) ||
    Boolean(isCheckoutAdditionalInformation) ||
    Boolean(isCheckoutSummary) ||
    Boolean(isCheckoutConfirmation) ||
    !cartModel ||
    disable;

  return (
    <>
      <HeaderButton
        data-cy={"cart-button"}
        onClick={() => {
          // Due to a timing issue in melody we must make sure to always
          // load new data when the modal is opened.
          invalidateCustomerCart();
          setShowCartModal(true);
        }}
        aria-label={t("module.self.service.cart.view")}
        disabled={disabled}
        notification={
          activeCart.salesProjectId !== 0 && numberOfProducts
            ? String(numberOfProducts)
            : undefined
        }
      >
        <CartIcon />
      </HeaderButton>
      {showCartModal && activeCart.salesProjectId > 0 && (
        <MiniCartModal
          activeCart={activeCart}
          setShowConfirmDeleteCartModal={setShowConfirmDeleteCartModal}
          setShowConfirmDeleteOppRowModal={setShowConfirmDeleteOppRowModal}
          setShowConfirmOrderModal={setShowConfirmOrderModal}
          setShowRevertProjectToDraftModal={setShowRevertProjectToDraftModal}
          setShowSendProjectToOrderApproval={setShowSendProjectToOrderApproval}
          onClose={() => {
            setShowCartModal(false);
          }}
        />
      )}
      {showConfirmDeleteCartModal && (
        <DeleteCartModal
          onClose={() => setShowConfirmDeleteCartModal(false)}
          onCloseParent={() => setShowCartModal(false)}
          salesProjectId={activeCart.salesProjectId}
        />
      )}
      {showConfirmDeleteOppRowModal && (
        <ConfirmDeleteOppRowModal
          oppRowId={showConfirmDeleteOppRowModal.oppRowId}
          packageInstanceId={showConfirmDeleteOppRowModal.packageInstanceId}
          onClose={() => setShowConfirmDeleteOppRowModal(null)}
          confirmMode={"SINGLE"}
        />
      )}
      {showConfirmOrderModal && (
        <ConfirmOrderSendModal
          onClose={() => setShowConfirmOrderModal(false)}
          onCloseParent={() => setShowCartModal(false)}
          salesProjectId={activeCart.salesProjectId}
          mode={
            isEndUserPortal ? SelfServiceMode.END_USER : SelfServiceMode.CARTS
          }
        />
      )}
      {showSendProjectToOrderApproval && (
        <SendToApprovalModal
          salesProjectId={activeCart.salesProjectId}
          onClose={() => setShowSendProjectToOrderApproval(false)}
          mode={
            isEndUserPortal ? SelfServiceMode.END_USER : SelfServiceMode.CARTS
          }
        />
      )}
      {showRevertProjectToDraftModal && (
        <RevertProjectToDraftModal
          salesProjectId={activeCart.salesProjectId}
          mode={
            isEndUserPortal ? SelfServiceMode.END_USER : SelfServiceMode.CARTS
          }
          onClose={() => setShowRevertProjectToDraftModal(false)}
        />
      )}
    </>
  );
};
export default MiniCartButtonWrapper;
