import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import styled from "styled-components";
import getPages from "./getPages";
import { PagerProps } from "./ReactPager";

const PageWrapper = styled.div`
  background-color: ${color.BRAND_WHITE};
  height: 32px;
  border: ${color.BRAND_PREMIUM_GREY} 1px solid;
  border-radius: 4px;
  display: flex;
  > .active {
    border-color: ${color.FUNCTIONAL_BLUE};
  }
`;

export const StyledPagerButtonWrapper = styled.button`
  margin: 5px;
  width: 20px;
  height: 20px;
  border: ${color.BRAND_WHITE} 1px solid;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${color.BRAND_WHITE};
`;

const ConcisePager: React.FC<PagerProps> = ({
  onPaginate,
  onPrefetch = () => undefined,
  isSmallDevice,
  ...paginationState
}) => {
  const pages = getPages(paginationState, isSmallDevice);
  const { limit, offset, total } = paginationState;
  return (
    <PageWrapper>
      <StyledPagerButtonWrapper
        onClick={() =>
          onPaginate({
            ...paginationState,
            offset: Math.max(0, offset - limit),
          })
        }
        onMouseEnter={() =>
          onPrefetch({
            ...paginationState,
            offset: Math.max(0, offset - limit),
          })
        }
      >
        <span>&laquo;</span>
      </StyledPagerButtonWrapper>
      {pages.map((p, i) => (
        <StyledPagerButtonWrapper
          key={p.offset || i}
          className={`${p.isActive && "active"}`}
          onClick={() =>
            p.offset !== undefined &&
            onPaginate({ limit, total, offset: p.offset })
          }
          onMouseEnter={() =>
            p.offset !== undefined &&
            onPrefetch({ limit, total, offset: p.offset })
          }
        >
          <span>{p.text}</span>
        </StyledPagerButtonWrapper>
      ))}
      <StyledPagerButtonWrapper
        onClick={() => {
          if (!(offset + limit >= total)) {
            onPaginate({
              ...paginationState,
              offset: Math.min(total, offset + limit),
            });
          }
        }}
        onMouseEnter={() =>
          onPrefetch({
            ...paginationState,
            offset: Math.min(total, offset + limit),
          })
        }
      >
        <span>&raquo;</span>
      </StyledPagerButtonWrapper>
    </PageWrapper>
  );
};
export default ConcisePager;
