import * as React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { TextInput, TextInputProps } from "@tele2/t2-ui-kit";

type FormInputProps = UseControllerProps & TextInputProps;

function FormInput({
  name,
  rules,
  defaultValue,
  control,
  ...props
}: FormInputProps): JSX.Element {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue, control });
  // removed ref from field items TODO: implement correct ref handling in ui-kit
  const { ref, ...rest } = field;
  return (
    <TextInput
      {...rest}
      {...props}
      block
      isStatic
      error={Boolean(error)}
      info={error?.message}
    />
  );
}
export default FormInput;
