import { Text } from "@tele2/t2-ui-kit";

import { OpportunityRow } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import * as React from "react";
import styled from "styled-components";

interface CartRowSubscriptionProps {
  row: OpportunityRow;
}

const StyledGrid = styled.div`
  grid-column: span 6;
`;

const StyledTitle = styled(Text)`
  font-weight: 500;
`;

const CartRowSubscription: React.FC<CartRowSubscriptionProps> = ({ row }) => {
  return (
    <StyledGrid>
      <StyledTitle xl>
        {row.subscription?.product?.name}
        {row.subscription?.alias && `- ${row.subscription?.alias} `}
      </StyledTitle>
      {row.keyAttr && (
        <div>
          <small>{row.keyAttr}</small>
        </div>
      )}
    </StyledGrid>
  );
};

export default CartRowSubscription;
