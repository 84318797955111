import { color } from "@tele2/t2-ui-kit";
import SimCardFeeInfoModal from "./SimCardFeeInfoModal";
import NoStyleButton from "../../../Buttons/NoStyleButton";
import * as React from "react";
import styled from "styled-components";
import { useComponentsAttributes } from "../../../component/ComponentsContext";
import { useTranslation } from "react-i18next";

const SimCardFeeInfo: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const { simCardFee } = useComponentsAttributes();

  if (!simCardFee) {
    return null;
  }

  return (
    <Container>
      <p>
        {t("module.self.service.attribute.element.label.sim.card.fee.info")}
        <PopupButton onClick={() => setShowModal(true)}>
          {t(
            "module.self.service.attribute.element.label.sim.card.fee.info.read.more"
          )}
        </PopupButton>
      </p>
      {showModal && <SimCardFeeInfoModal onClose={() => setShowModal(false)} />}
    </Container>
  );
};
export default SimCardFeeInfo;

const Container = styled.div`
  margin: 0.5em 0;
`;

const PopupButton = styled(NoStyleButton)`
  padding-left: 4px;
  color: ${color.FUNCTIONAL_BLUE};
  &:hover {
    text-decoration: underline;
  }
`;
