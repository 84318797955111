import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface PlaceOrderRequest {
  salesProjectId: number;
}

interface PlaceOrderResponse {
  errorMessage: string;
  notValid: boolean;
  failedCreditCheck: boolean;
  punchoutUri: string;
  punchoutError: boolean;
}

const post = async (model: PlaceOrderRequest) => {
  const { data } = await axios.post<PlaceOrderResponse>(
    "/api/self-service/checkout/place-order",
    {},
    {
      params: {
        ...model,
      },
    }
  );
  return data;
};

const usePlaceOrder = (onError: (e: AxiosError) => void) => {
  const { mutateAsync, isPending, isError, error } = useMutation<
    PlaceOrderResponse,
    AxiosError,
    PlaceOrderRequest
  >({
    mutationFn: post,
    throwOnError: false,
  });
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const placeOrder = useCallback(
    async (request: PlaceOrderRequest) => {
      const data = await mutateAsync(request);
      invalidateCheckoutData();
      return data;
    },
    [invalidateCheckoutData, mutateAsync]
  );

  useEffect(() => {
    if (isError) {
      onError(error);
    }
  }, [error, isError, onError]);

  return { placeOrder, isPending };
};

export default usePlaceOrder;
