import { color, Text } from "@tele2/t2-ui-kit";
import Cached from "@tele2/t2-ui-kit/build/Icon/icons/Cached";
import Done from "@tele2/t2-ui-kit/build/Icon/icons/Done";
import Help from "@tele2/t2-ui-kit/build/Icon/icons/Help";
import Warning from "@tele2/t2-ui-kit/build/Icon/icons/WarningOutlined";
import { useInvalidateActiveCart } from "../../../../api/self-service/cart/useActiveCart";
import { OpportunityRow } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import { useInvalidateCustomerCart } from "../../../../api/self-service/useGetCustomerCart";
import useGetOppRowValidations, {
  useInvalidateValidation,
} from "../../../../api/self-service/row-validations/useGetOppRowValidations";
import {
  IValidationStatuses,
  validationStatus,
} from "../../../Validation/validation-utils";
import * as React from "react";
import styled from "styled-components";
import { subscribeReact } from "../../../../stomp";

const StyledText = styled(Text)`
  margin-left: 8px;
`;

const StyledDiv = styled.div<{ $color: string }>`
  display: flex;
  color: ${(props) => props.$color};
`;

interface ValidationProps {
  row: OpportunityRow;
  displayValidation: boolean;
}

const Validation: React.FC<ValidationProps> = ({ row, displayValidation }) => {
  const { data: validationData } = useGetOppRowValidations(
    row.id,
    row.packageInstanceId
  );
  const invalidateSelfServiceCart = useInvalidateActiveCart();
  const invalidateCustomerCart = useInvalidateCustomerCart();
  const invalidateValidation = useInvalidateValidation(
    row.id,
    row.packageInstanceId
  );

  const [status, setStatus] = React.useState<IValidationStatuses>(
    validationStatus(130)
  );
  React.useEffect(() => {
    if (validationData) {
      setStatus(validationData.statuses);
    }
  }, [validationData]);
  React.useEffect(() => {
    const topic = `/topic/subsId/${row.id}/type/3`;
    const subscription = subscribeReact(
      topic,
      (data: { SubsStatusId: number; IsValidationDone: boolean }) => {
        setStatus(validationStatus(data.SubsStatusId));
        if (data.IsValidationDone) {
          invalidateSelfServiceCart();
          invalidateCustomerCart();
          invalidateValidation();
        }
      }
    );
    return () => {
      subscription.then((s) => {
        s.unsubscribe();
      });
    };
  }, [
    invalidateCustomerCart,
    invalidateSelfServiceCart,
    invalidateValidation,
    row.id,
  ]);
  const validationFailedGroup = React.useMemo(() => {
    if (validationData) {
      return validationData?.groups.find((group) => {
        return group.validations.length > 0;
      });
    }
    return "";
  }, [validationData]);
  if (!displayValidation) {
    return null;
  }
  return (
    <StyledDiv $color={getIconColor(status)}>
      {getIcon(status)}
      {validationFailedGroup && (
        <StyledText xl>{validationFailedGroup.category}</StyledText>
      )}
    </StyledDiv>
  );
};

export default Validation;

const getIconColor = (status: IValidationStatuses) => {
  if (status.validationOk) return color.BRAND_GREEN;
  if (status.validationFailed) return color.ALERT_RED;
  if (status.validationWarning) return color.ALERT_ORANGE;
  return color.BRAND_BLACK;
};

const getIcon = (status: IValidationStatuses) => {
  if (status?.validationOk) return <Done />;
  if (status?.validationInProgress) return <Cached />;
  if (status?.validationFailed || status?.validationWarning) return <Warning />;
  if (status?.notValidated) return <Help />;
  return null;
};
