import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_DOCUMENTS_FOLDERS,
  CONST_ROUTE_DOCUMENTS_PRODUCT_DOCUMENTS,
} from "../approutes";
import * as React from "react";

const Folders = React.lazy(() => import("./Folders"));
const ProductDocumentation = React.lazy(() => import("./ProductDocuments"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_DOCUMENTS_FOLDERS,
    component: Folders,
  },
  {
    path: CONST_ROUTE_DOCUMENTS_PRODUCT_DOCUMENTS,
    component: ProductDocumentation,
  },
];

export default routes;
