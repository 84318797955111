import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { Contact } from "../../contact/contact-involvement-model/useContactInvolvement";

interface RoleOption {
  id: number;
  description: string;
  selected: boolean;
}

export interface ContactModel {
  salesProjectId: number;
  oppRowId: number;
  subsId: number;
  roleId: number;
  contactId: number;
  availableContacts: Contact[];
  configuredContacts: Contact[];
  roleOptions: RoleOption[];
  oppRowIds: number[];
  contactsEditable: boolean;
  // salesProject: SalesProject; // ignoring this for now
}

interface ContactsRequest {
  oppRowId: number;
  salesProjectId: number;
}

const getContacts = async (request: ContactsRequest) => {
  const { data } = await axios.get<ContactModel>("/api/self-service/contacts", {
    params: request,
  });
  return data;
};

const QUERY_KEY = "self-service-contacts";
const useContacts = (request: ContactsRequest) => {
  return useQuery<ContactModel, AxiosError>({
    queryKey: [QUERY_KEY, request],
    queryFn: () => getContacts(request),
  });
};

export default useContacts;

export const useInvalidateContacts = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
