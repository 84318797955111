import * as React from "react";
import { PropsWithChildren } from "react";

const HelpTextIcon: React.FC<PropsWithChildren> = ({ children }) =>
  children ? (
    <span
      className="fa fa-question-circle"
      title={String(children)}
      style={{ paddingLeft: "5px" }}
    >
      <span className="sr-only">{children}</span>
    </span>
  ) : null;

export default HelpTextIcon;
