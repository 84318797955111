import useActiveCart from "../../../../api/self-service/cart/useActiveCart";
import { ProductItem } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import ConcisePageLimit from "../../../../components/pager/ConcisePageLimit";
import ConcisePager from "../../../../components/pager/ConciseReactPager";
import { ProductGridWrapper } from "../../../../components/Product";
import GridItemWrapper from "../../../../components/Product/GridItemWrapper";
import * as React from "react";
import { SelfServiceRenderMode } from "../product-catalog-type";
import { ROOT_DEFAULT } from "../ProductCatalogContainer";
import { match } from "./catalog-utils";
import ProductCatalogListItem from "./ProductCatalogListItem";
import RightColumnHeader from "./RightColumnHeader";
import styled from "styled-components";
import useDebounceValue from "../../../../utils/useDebounceValue";
import usePaginateData from "../../../../utils/usePaginateData";
import {
  useCatalog,
  useCatalogParams,
  useNodeKeyState,
  useProductSearch,
  useSelfServiceMode,
} from "../ProductCatalogContext";

const sortFn = (a: ProductItem, b: ProductItem) =>
  a.prodName && b.prodName
    ? a.prodName.toLocaleLowerCase() < b.prodName.toLocaleLowerCase()
      ? -1
      : a.prodName.toLocaleLowerCase() > b.prodName.toLocaleLowerCase()
      ? 1
      : 0
    : 0;

export const PagerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 15px;
  column-gap: 5px;
`;

function matchSearchString(
  searchedString: string[],
  searchTerm1: string,
  searchTerm2: string
) {
  let matched = true;
  for (let i = 0; i < searchedString.length; i++) {
    if (!match(searchedString[i], searchTerm1, searchTerm2)) {
      matched = false;
      break;
    }
  }
  return matched;
}

function filterServiceProduct(
  item: ProductItem,
  nodeKey: string,
  debouncedSearchText: string,
  searchedString: string[]
) {
  return (
    item.productCategory.indexOf(nodeKey) > -1 &&
    (debouncedSearchText.length === 0 ||
      matchSearchString(
        searchedString,
        item.prodName ?? "",
        item.productCategory
      ))
  );
}

const ProductCatalogList: React.FC = () => {
  const serviceRenderMode = useSelfServiceMode();
  const isEndUser = serviceRenderMode === SelfServiceRenderMode.END_USER;
  const [nodeKey] = useNodeKeyState();
  const [search] = useProductSearch();
  const [params] = useCatalogParams();

  const { data: activeCart } = useActiveCart();
  const [catalog] = useCatalog();

  const debouncedSearchText = useDebounceValue(search);

  const filtered = React.useMemo(() => {
    const searchedString = debouncedSearchText.split(" ");
    let oppItems: ProductItem[] = [];
    let productItems: ProductItem[] = [];
    if (catalog?.oppCatalogs) {
      oppItems = catalog.oppCatalogs
        .map((c) =>
          c.items.filter((item) => {
            return filterServiceProduct(
              item,
              nodeKey,
              debouncedSearchText,
              searchedString
            );
          })
        )
        .reduce<ProductItem[]>(
          (accumulator, catalog) => accumulator.concat(catalog),
          []
        );
    }
    if (catalog?.productCatalogItems) {
      productItems = catalog.productCatalogItems.filter((item) => {
        return (
          (ROOT_DEFAULT + "|" + item.productCategory).indexOf(nodeKey) > -1 &&
          (debouncedSearchText.length === 0 ||
            matchSearchString(
              searchedString,
              item.prodName ?? "",
              item.productCategory
            ))
        );
      });
    }
    return [...oppItems, ...productItems].sort(sortFn);
  }, [catalog, debouncedSearchText, nodeKey]);

  const {
    pageData: catalogPageData,
    onPaginate: catalogPaginate,
    limit: catalogLimit,
    offset: catalogOffset, // first show item of total items
    total: catalogTotal,
  } = usePaginateData(filtered, {
    limit: 9,
  });
  React.useEffect(() => {
    // on filter, return to first page
    catalogPaginate((prevState) => {
      return { ...prevState, offset: 0 };
    });
  }, [search, nodeKey, catalogPaginate]);

  return (
    <>
      <RightColumnHeader
        disabled={!activeCart?.salesProjectId || isEndUser}
        salesProjectId={activeCart?.salesProjectId}
        count={filtered.length}
      />
      <ProductGridWrapper>
        {catalog &&
          catalogPageData.map((c, index) => (
            <GridItemWrapper key={index}>
              <ProductCatalogListItem
                baseSubsId={params.baseSubsId}
                hasSelfService={params.hasSelfService}
                mode={params.mode}
                item={c}
                relatedSubsId={params.relatedSubsId}
              />
            </GridItemWrapper>
          ))}
      </ProductGridWrapper>

      <PagerWrapper>
        <ConcisePageLimit
          limit={catalogLimit}
          setLimit={(limit) =>
            catalogPaginate({
              limit: limit,
              offset: catalogOffset,
              total: catalogTotal,
            })
          }
          pageLimits={[3, 6, 9, 12]}
        />
        <ConcisePager
          limit={catalogLimit}
          offset={catalogOffset}
          total={catalogTotal}
          onPaginate={catalogPaginate}
        />
      </PagerWrapper>
    </>
  );
};

export default ProductCatalogList;
