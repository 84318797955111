import { color, padding } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

export type Breadcrumb = {
  title: string;
  link: string;
  translate?: boolean;
};

interface BreadcrumbsProps {
  title?: string;
  subTitle?: string;
  translate?: boolean;
  list?: Breadcrumb[];
  $maxWidth: "none" | number;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  title,
  subTitle,
  translate = true,
  list = [],
  $maxWidth = 1080,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {list.length ? (
        <BreadCrumbContainer>
          <BreadCrumbsUl>
            {list.map(({ link, title, translate = true }, i) => (
              <li key={i}>
                {link ? (
                  <Link to={link}>{translate ? t(title) : title}</Link>
                ) : (
                  <>{translate ? t(title) : title}</>
                )}
              </li>
            ))}
          </BreadCrumbsUl>
          <ContentWidth $maxWidth={$maxWidth}>
            {title && <Title>{translate ? t(title) : title}</Title>}
            {subTitle && (
              <SubTitle>{translate ? t(subTitle) : subTitle}</SubTitle>
            )}
          </ContentWidth>
        </BreadCrumbContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default Breadcrumbs;

const ContentWidth = styled.div`
  margin: 0 auto;
  max-width: ${(props: BreadcrumbsProps) =>
    props.$maxWidth === "none" ? props.$maxWidth : `${props.$maxWidth}px`};
  width: 100%;
  padding: 0 ${padding.XL};
`;

const BreadCrumbContainer = styled.div`
  background: ${color.BRAND_GRADIENT};
`;

const BreadCrumbsUl = styled.ul`
  margin: 62px 24px 8px;
  box-sizing: border-box;

  padding: 16px 0;

  list-style: none;

  & li {
    display: inline-block;
    padding: 0;
    margin-right: 6px;
    font-size: 14px;
  }
  & li:first-child {
    padding-left: 0;
    margin-left: 0;
  }
  & li:after {
    content: " / ";
    display: inline-block;
    color: ${color.BRAND_PREMIUM_GREY};
    margin-left: 6px;
  }
  & li:last-child:after {
    display: none;
  }
  & li {
    color: ${color.BRAND_PREMIUM_GREY};
  }
  & li a {
    color: ${color.BRAND_WHITE};
  }
  & li:hover a {
    color: ${color.BRAND_WHITE};
  }
`;

const Title = styled.h1`
  color: ${color.BRAND_WHITE};
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 0;

  @media only screen and (max-width: 1024px) {
    & {
      margin: 8px 16px;
      padding: 8px 0;
    }
  }
`;

const SubTitle = styled.h5`
  color: ${color.BRAND_WHITE};
  margin-bottom: 32px;
  margin-top: -8px;
  @media only screen and (max-width: 1024px) {
    & {
      margin-left: 16px;
    }
  }
`;
