import { Text, Tooltip } from "@tele2/t2-ui-kit";
import Warning from "@tele2/t2-ui-kit/build/Icon/icons/WarningOutlined";
import { OpportunityRow } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface PriceCellsProps {
  row: OpportunityRow;
}

const StyledText = styled(Text)`
  display: block;
`;

const PriceCells: React.FC<PriceCellsProps> = ({ row }) => {
  const { t } = useTranslation();
  if (row.shadow) return null;
  return (
    <div className="text-right">
      <>
        {row.recurringPrices.map((recurring, key1) => {
          return (
            <StyledText key={key1} xl>
              {`${recurring.amount} ${t("module.self.service.price.per.month", [
                recurring.currency,
              ])}`}
            </StyledText>
          );
        })}

        {row.onetimePrices.map((price, i) => {
          return (
            <StyledText xl key={"o-" + i}>
              {`${price.amount} ${price.currency} ${t(
                "module.self.service.price.onetime"
              )}`}
            </StyledText>
          );
        })}

        {row.hourlyPrices.map((hourly, key3) => {
          return (
            <StyledText xl key={key3}>
              {`${hourly.amount} ${t("module.self.service.price.per.hour", [
                hourly.currency,
              ])}`}
            </StyledText>
          );
        })}
      </>
      {row.notCustomerSpecificPrice && (
        <Tooltip tooltip={t("module.self.service.price.not.agreed.service")}>
          <Warning className="text-danger" />
        </Tooltip>
      )}
    </div>
  );
};

export default PriceCells;
