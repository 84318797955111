import { DropDown, DropDownOptionProp, useIsMobile } from "@tele2/t2-ui-kit";
import axios from "axios";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DetailsHeader, DetailsWrapper } from "../TopMenu-styles";

const ChangeLanguage: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const languageOptions: DropDownOptionProp<string>[] = [
    {
      dropdownLabel: t("general.language.en"),
      option: "en",
    },
    {
      dropdownLabel: t("general.language.sv"),
      option: "sv",
    },
  ];

  const getLanguageOptionByValue = (value: string): number => {
    return (
      languageOptions.findIndex((opt) => {
        return value.includes(opt.option);
      }) || 0
    );
  };

  const currentLanguage = getLanguageOptionByValue(
    document.documentElement.lang
  );

  return (
    <DetailsWrapper>
      {!isMobile && (
        <DetailsHeader xl>{t("general.language.change")}</DetailsHeader>
      )}
      <DropDown<string>
        onSelect={async (value) => {
          await axios.post(`/api/localization?locale=${value}`);
          window.location.reload();
        }}
        options={languageOptions}
        selectedOption={languageOptions[currentLanguage]}
        widthCss="100%"
      />
    </DetailsWrapper>
  );
};

export default ChangeLanguage;
