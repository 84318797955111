import { breakpoints, color, Text } from "@tele2/t2-ui-kit";
import PictureAsPdf from "@tele2/t2-ui-kit/build/Icon/icons/PictureAsPdfOutlined";

import { ProductItem } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import * as React from "react";
import styled from "styled-components";

const DocumentsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: ${breakpoints.Tablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DocumentWrapper = styled.a`
  align-items: center;
  color: ${color.BRAND_BLACK};
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;

  > svg {
    height: 48px;
    margin-right: 16px;
    width: 48px;
  }
`;

const fileIcon = {
  "application/pdf": <PictureAsPdf />,
};

const DocumentsPane: React.FC<{
  product: ProductItem;
}> = ({ product }) => {
  const { files } = product;
  return (
    <DocumentsWrapper>
      {files?.map((file, i) => {
        return (
          <DocumentWrapper key={i} href={file.url} download={file.fileName}>
            {fileIcon[file.mimeType as keyof typeof fileIcon]}
            <Text xxl>{file.fileName}</Text>
          </DocumentWrapper>
        );
      })}
    </DocumentsWrapper>
  );
};

export default DocumentsPane;
