export interface IValidationStatuses {
  notValidated?: boolean;
  validationFailed?: boolean;
  validationInProgress?: boolean;
  validationOk?: boolean;
  validationWarning?: boolean;
  unsupportedStatus?: boolean;
}

/**
 *  32 Not validated
 *  33 Validation ok
 *  34 Validation failed
 *  35 Under Import
 *  36 Not ready for validation
 *  53 Draft
 * 120 Validation warning
 * 130 Validation in progress
 */
export const validationStatus = (statusId: number): IValidationStatuses => {
  return {
    notValidated:
      statusId === 32 || statusId === 53 || statusId === 35 || statusId === 36,
    validationFailed: statusId === 34,
    validationInProgress: statusId === 130,
    validationOk: statusId === 33,
    validationWarning: statusId === 120,
    unsupportedStatus: statusId === 0,
  };
};
