import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useSaveAlias from "../../../../api/self-service/save-alias/useSaveAlias";
import {
  useAliasState,
  useComponentsAttributes,
} from "../../../../components/component/ComponentsContext";
import { useMessage } from "../../../../components/Message/Message";

const Alias: React.FC = () => {
  const { t } = useTranslation();
  const message = useMessage();
  const { firstRow, oppRowId, salesProjectId } = useComponentsAttributes();
  const [alias, setAlias] = useAliasState();
  const [inputValue, setInputValue] = React.useState(alias || "");
  const [aliasValidationMessage, setAliasValidationMessage] =
    React.useState("");
  const { saveAlias } = useSaveAlias();
  const save = React.useCallback(
    async (value: string) => {
      const response = await saveAlias({
        alias: value,
        salesProjectId,
        oppRowId,
      });
      setAliasValidationMessage(response.message);
      if (response.saved) {
        setAlias(value);
      }
      if (!response.message) {
        message({
          message: `${t("general.alias")} ${t(
            "module.self.service.change.saved"
          )}`,
          theme: "positive",
          interval: 2000,
        });
      }
    },
    [message, oppRowId, salesProjectId, saveAlias, setAlias, t]
  );
  const inputClasses = classNames(
    "form-control",
    alias !== inputValue ? "attr-unsaved-change" : ""
  );
  const columnClass = firstRow?.aliasEditable ? "" : "col-6";
  if (!firstRow) {
    return null;
  }
  return (
    <div className="row">
      <div className={`col-sm-4 ${columnClass}}`}>
        <label className="attr-label" htmlFor="alias-input">
          {t("general.alias")}
        </label>
        {firstRow.aliasRequired && <span className="input-required">*</span>}
      </div>
      <div className={`col-sm-8 ${columnClass}`}>
        {firstRow.aliasEditable && (
          <div className="form-group">
            <input
              className={inputClasses}
              id="alias-input"
              type="text"
              size={50}
              name="alias"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={() => save(inputValue)}
            />
          </div>
        )}
        {!firstRow.aliasEditable && alias}
        <div className="attr-validation help-block">
          {aliasValidationMessage}
        </div>
      </div>
    </div>
  );
};
export default Alias;
