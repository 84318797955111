import { Button, Loader } from "@tele2/t2-ui-kit";
import ModalBody from "@tele2/t2-ui-kit/build/Modal/ModalBody";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useRevertProjectToDraft from "../../../../api/self-service/checkout/useRevertProjectToDraft";
import { SelfServiceMode } from "../../../../api/self-service/types";
import { useMessage } from "../../../../components/Message/Message";
import ModalFooter from "../../../../components/modal/ModalFooter";
import ReactModal from "../../../../components/modal/ReactModal";
import {
  CONST_ROUTE_END_USER,
  CONST_URL_SELF_SERVICE_INDEX,
} from "../../../approutes";

export interface RejectProjectModalProps {
  onClose: () => void;
  salesProjectId: number;
  mode?: SelfServiceMode;
}

const RevertProjectToDraftModal: React.FC<RejectProjectModalProps> = ({
  onClose,
  salesProjectId,
  mode = SelfServiceMode.DEFAULT,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const message = useMessage();
  const [reason, setReason] = React.useState<string>("");
  const { revertProject, isPending } = useRevertProjectToDraft();
  return (
    <ReactModal
      onClose={onClose}
      title={t("module.self.service.reject.offer")}
      isLoading={isPending}
    >
      <ModalBody>
        {isPending ? (
          <Loader />
        ) : (
          <div className="form-group">
            <textarea
              name="reason"
              className="form-control"
              placeholder={t("module.self.service.reject.approval.message")}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button secondary sm onClick={onClose} disabled={isPending}>
          {t("general.close")}
        </Button>
        <Button
          data-testid={"revert-project"}
          sm
          onClick={async () => {
            const data = await revertProject({
              salesProjectId,
              reason,
            });
            if (data) {
              message({
                interval: 3000,
                message: t("general.saved"),
                theme: "positive",
              });
              if (mode === SelfServiceMode.END_USER) {
                navigate(CONST_ROUTE_END_USER);
              } else {
                navigate(CONST_URL_SELF_SERVICE_INDEX);
              }
            } else {
              message({
                interval: 6000,
                message: t("module.self.service.reject.approval.not.allowed"),
                theme: "alert",
              });
            }
          }}
          disabled={isPending || reason.length === 0}
        >
          {t("module.self.service.reject.offer")}
        </Button>
      </ModalFooter>
    </ReactModal>
  );
};
export default RevertProjectToDraftModal;
