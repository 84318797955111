import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

const QUERY_KEY = "price-details";

export interface IOpportunityPriceDetails {
  details: { [key: string]: IOpportunityPriceDetail[] };
  total: { [key: string]: IOpportunityPriceDetail[] };
  currencies: string[];
  defaultCurrency: string;
  notCustomerSpecificPrice: boolean;
  pricePerHourAndDiscount: IOpportunityPriceDetail | null;
  bundleDiscountDisclaimer: boolean;
}

interface IOpportunityPriceDetail {
  currency: string;
  actualCurrency: boolean;
  exchangeRateFactor: number;
  description: string;
  recurring: string;
  onetime: string;
  nonSpecificPrice: boolean;
  pricePerHour: string;
  discountPercentage: number;
}
interface PriceDetailsRequest {
  packageInstanceId?: number;
  oppRowId: number;
}

const getPriceDetails = async (request: PriceDetailsRequest) => {
  const { data } = await axios.get<IOpportunityPriceDetails>(
    "/api/self-service/price-details",
    {
      params: {
        oppRowIds: request.oppRowId,
        packageInstanceId: request.packageInstanceId,
      },
    }
  );
  return data;
};

const usePriceDetails = (request: PriceDetailsRequest) => {
  return useQuery<IOpportunityPriceDetails, AxiosError>({
    queryKey: [QUERY_KEY, request],
    queryFn: () => getPriceDetails(request),
    placeholderData: keepPreviousData,
  });
};

export default usePriceDetails;

export const useInvalidatePriceDetails = (): (() => void) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

export const usePrefetchPriceDetails = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (request: PriceDetailsRequest) => {
      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY, request],
        queryFn: () => getPriceDetails(request),
      });
    },
    [queryClient]
  );
};
