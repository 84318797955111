import { color, Text } from "@tele2/t2-ui-kit";
import { OpenContextMenuProps } from "./ContextMenuContext";
import * as React from "react";
import styled from "styled-components";
import useDetectOutsideClick from "../../utils/useDetectOutsideClick";

const ContextMenu: React.FC<
  OpenContextMenuProps & { closeMenu: () => void }
> = ({ entries, closeMenu, yPos, xPos, positionLeft }) => {
  const refObject = React.useRef(null);
  useDetectOutsideClick(refObject, () => {
    closeMenu();
  });

  return (
    <ContextMenuBlock>
      <ContextMenuWrapper
        $y={yPos}
        $x={xPos}
        $positionLeft={positionLeft}
        ref={refObject}
      >
        {entries.map((entry, i) => {
          if (entry.isDivider) {
            return <ContextMenuDivider key={i} />;
          }
          if (entry.isHeader) {
            return (
              <ContextMenuHeader key={i}>
                <Text sm>{entry.text}</Text>
              </ContextMenuHeader>
            );
          }
          return (
            <ContextMenuItem
              key={i}
              role="button"
              onClick={() => {
                entry.onClick?.();
                closeMenu();
              }}
            >
              {entry.text}
            </ContextMenuItem>
          );
        })}
      </ContextMenuWrapper>
    </ContextMenuBlock>
  );
};
export default ContextMenu;

const ContextMenuItem = styled.div`
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${color.BRAND_LIGHT_GRAY};
  }
`;

const ContextMenuDivider = styled.div`
  border-bottom: 1px solid ${color.BRAND_PREMIUM_GREY};
`;

const ContextMenuHeader = styled.div``;

export const ContextMenuWrapper = styled.div<{
  $x: number;
  $y: number;
  $positionLeft: boolean;
}>`
  min-width: 135px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 1002;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) =>
    `translateX(min(calc(${props.$x}px${
      props.$positionLeft ? " - 100%" : ""
    }), calc(100vw - 100%))) translateY(min(${
      props.$y
    }px, calc(100vh - 100%)))`};
  white-space: nowrap;
  border: 1px solid ${color.BRAND_PREMIUM_GREY};
  background-color: ${color.BRAND_WHITE};
  border-radius: 4px;
`;

export const ContextMenuBlock = styled.div`
  z-index: 1001;
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
`;
