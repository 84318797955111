import { color } from "@tele2/t2-ui-kit";
import Circle from "@tele2/t2-ui-kit/build/Icon/icons/Circle";
import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface StockBalanceProps {
  stockBalance: number | null;
  card?: boolean;
}

interface CardWrapperProps
  extends PropsWithChildren,
    Pick<StockBalanceProps, "card"> {}

type NoNullFields<Ob> = { [K in keyof Ob]: NonNullable<Ob[K]> };

const StyledDiv = styled.div`
  padding-bottom: 5px;
`;

const CardWrapper: React.FC<CardWrapperProps> = ({ card, children }) => (
  <>{card ? <small>{children}</small> : children}</>
);

const StockBalanceValue: React.FC<NoNullFields<StockBalanceProps>> = ({
  stockBalance,
  card,
}) => {
  const { t } = useTranslation();
  let data: {
    color: keyof typeof color;
    text: string;
  } = {
    color: "BRAND_GREEN",
    text: `${t("module.subscription.stock.balance")} (${stockBalance})`,
  };

  if (stockBalance === 0) {
    data = {
      color: "BRAND_RED",
      text: `${t("module.subscription.product_not_in_stock")}`,
    };
  }

  if (stockBalance > 100) {
    data = {
      color: "BRAND_GREEN",
      text: `${t("module.subscription.stock.balance")} (100+)`,
    };
  }
  return (
    <CardWrapper card={card}>
      <StyledDiv>
        <Circle color={data.color} xxs />
        {` ${data.text}`}
      </StyledDiv>
    </CardWrapper>
  );
};

const StockBalance: React.FC<StockBalanceProps> = ({
  stockBalance,
  ...props
}) => {
  if (stockBalance != null) {
    return <StockBalanceValue {...props} stockBalance={stockBalance} />;
  }
  return null;
};

export default StockBalance;
