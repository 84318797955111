import { Tooltip } from "@tele2/t2-ui-kit";
import AutorenewOutlined from "@tele2/t2-ui-kit/build/Icon/icons/AutorenewOutlined";
import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import { useTranslation } from "react-i18next";

const SessionInfoIcon: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading } = React.useContext(SessionContext);
  if (isLoading) {
    return (
      <Tooltip tooltip={t("module.loading.session.tooltip")} position={"left"}>
        <SpinningIcon>
          <AutorenewOutlined color="BRAND_GREY" />
        </SpinningIcon>
      </Tooltip>
    );
  }
  return null;
};
export default SessionInfoIcon;

const SpinningIcon = styled.div`
  animation-name: icon-spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes icon-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
