import { useModuleReadRight } from "../../api/session/useModuleRight";
import * as React from "react";
import {
  CONST_URL_END_USER_DOCUMENTS,
  CONST_URL_END_USER_ORDER_DELIVERY,
  CONST_URL_END_USER_PRODUCT_CATALOG,
  CONST_URL_END_USER_SUBSCRIPTIONS,
} from "../approutes";
import { useTranslation } from "react-i18next";

interface EndUserRoute {
  url: string;
  title: string;
}

const useEndUserRoutes = () => {
  const { t } = useTranslation();
  const selfService = useModuleReadRight("SELF_SERVICE_END_USERS");
  const viewOrder = useModuleReadRight("ORDERS_END_USERS");
  const viewDocuments = useModuleReadRight("DOCUMENTS_END_USERS");
  return React.useMemo<EndUserRoute[]>(() => {
    const arr = [];
    if (selfService) {
      arr.push({
        title: t("module.subscription.end_users.tab.shopping"),
        url: CONST_URL_END_USER_PRODUCT_CATALOG,
      });
    }
    arr.push({
      title: t("module.subscription.end_users.tab.subscriptions"),
      url: CONST_URL_END_USER_SUBSCRIPTIONS,
    });
    if (viewOrder) {
      arr.push({
        title: t("module.subscription.end_users.tab.delivery.status"),
        url: CONST_URL_END_USER_ORDER_DELIVERY,
      });
    }
    if (viewDocuments) {
      arr.push({
        title: t("module.subscription.end_users.tab.documents"),
        url: CONST_URL_END_USER_DOCUMENTS,
      });
    }
    return arr;
  }, [selfService, t, viewDocuments, viewOrder]);
};
export default useEndUserRoutes;
