import { useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface UserModuleRight {
  read: boolean;
  write: boolean;
}

const get = async () => {
  const { data } = await axios.get<Record<string, UserModuleRight>>(
    "/api/modules"
  );
  return data;
};

const useModuleRights = () => {
  return useSuspenseQuery<Record<string, UserModuleRight>, AxiosError>({
    queryKey: ["user-modules"],
    queryFn: get,
    staleTime: Infinity,
  });
};

export const useModuleReadRight = (module: string[] | string): boolean => {
  const { data } = useModuleRights();
  if (data && typeof module === "string") {
    return (data[module] && data[module].read) || false;
  }

  if (data && Array.isArray(module)) {
    return module.every(function (item) {
      return (data[item] && data[item].read) || false;
    });
  }
  return false;
};

export const useAnyModuleReadRights = (modules: string[]): boolean => {
  const { data = {} } = useModuleRights();
  for (const key in modules) {
    const module = modules[key];
    if (data[module] && data[module].read) {
      return true;
    }
  }
  return false;
};

export const useModuleWriteRight = (module: string): boolean => {
  const { data = {} } = useModuleRights();
  return data[module] && data[module].write;
};
