import { color, padding, TextInput } from "@tele2/t2-ui-kit";
import { useModuleReadRight } from "../../api/session/useModuleRight";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import onEnter from "../../utils/onEnter";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: ${padding.MD};
  > div.t2-input-search {
    > input {
      background: none;
      color: ${color.BRAND_WHITE};
      ::placeholder,
      ::-webkit-input-placeholder {
        color: ${color.BRAND_WHITE};
      }
      :-ms-input-placeholder {
        color: ${color.BRAND_WHITE};
      }
    }
    &:after {
      filter: invert(95%) sepia(100%) saturate(14%) hue-rotate(213deg)
        brightness(104%) contrast(104%);
    }
  }

  > div.t2-input-search > input:hover:not([active]):not([readonly]) {
    border-color: ${color.BRAND_GRAY_LIGHT_BLUE};
  }
`;

const MobileMenuSearch: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  const canSearch = useModuleReadRight("SEARCH");
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  if (!canSearch) return null;

  return (
    <Wrapper>
      <TextInput
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
        block
        label=""
        placeholder={t("general.search")}
        isSearch
        onKeyDown={onEnter(() => {
          navigate(`/home/search?q=${encodeURIComponent(search)}`);
          setSearch("");
          onClose?.();
        })}
      />
    </Wrapper>
  );
};

export default MobileMenuSearch;
