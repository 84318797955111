import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

interface CancelOppRowRequest {
  salesProjectId: number;
  oppRowId: number;
  packageInstanceId: number;
}

const put = async (request: CancelOppRowRequest) => {
  await axios.put<void>("/api/self-service/cancel-opp-row", null, {
    params: request,
  });
};
const useCancelOppRow = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation<
    void,
    AxiosError,
    CancelOppRowRequest
  >({ mutationFn: put });
  const cancelOppRow = useCallback(
    async (request: CancelOppRowRequest) => {
      await mutateAsync(request);
      invalidateCheckoutData();
    },
    [invalidateCheckoutData, mutateAsync]
  );
  return { cancelOppRow, isPending };
};

export default useCancelOppRow;
