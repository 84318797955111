const easeInOutQuart = function (t: number) {
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
};

export const scrollElemHoriz = (
  elem: HTMLDivElement,
  startingX: number,
  moveX: number,
  duration: number
) => {
  let scrollFrames = 0;

  if (!moveX) {
    cancelAnimationFrame(scrollFrames);
    return;
  }

  let start: number;
  scrollFrames = requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;

    const time = timestamp - start;

    let percentX = Math.min(time / duration, 1);

    percentX = easeInOutQuart(percentX);

    elem.scrollLeft = startingX + moveX * percentX;

    if (time < duration) {
      scrollFrames = requestAnimationFrame(step);
    }
  });
};
