import useHoverMouse from "@tele2/t2-ui-kit/build/Hooks/useHoverMouse";
import useGetOppRowValidations, {
  useInvalidateValidation,
  useRemoveValidation,
} from "../../../../api/self-service/row-validations/useGetOppRowValidations";
import classNames from "classnames";
import {
  useComponents,
  useComponentsAttributes,
} from "../../../../components/component/ComponentsContext";
import {
  IValidationStatuses,
  validationStatus,
} from "../../../../components/Validation/validation-utils";
import * as React from "react";
import ValidationPopover from "./ValidationPopover";
import styled from "styled-components";
import { ISubscription, subscribeReact } from "../../../../stomp";

const Validation: React.FC = () => {
  const { oppRowId, oppRowIds, packageInstanceId } = useComponentsAttributes();
  const [components] = useComponents();
  const invalidateValidation = useInvalidateValidation(
    oppRowId,
    packageInstanceId || 0
  );
  const removeValidation = useRemoveValidation(
    oppRowId,
    packageInstanceId || 0
  );
  const { data: validationsData } = useGetOppRowValidations(
    oppRowId,
    packageInstanceId || 0
  );
  const [status, setStatus] = React.useState<IValidationStatuses>({});
  const [popover, setPopover] = React.useState(false);
  const validationGroups = React.useMemo(() => {
    // Mapping to new objects since we are changing data and could affect others using the same query
    return validationsData?.groups.map((g) => {
      const validations = g.validations.map((v) => {
        let description = v.description;
        if (packageInstanceId) {
          // Note that the validation does not have prodCompId, so it can not be matched when > 1 component with the same subsId
          const component = components.find((c) => c.subsId === v.subsId);
          if (component?.prodName) {
            description = component.prodName + ": " + v.description;
          }
        }
        return { description, type: v.type };
      });
      return { validations, category: g.category };
    });
  }, [validationsData, components, packageInstanceId]);

  React.useEffect(() => {
    // Subscribing to all unique opp row ids
    const subs: Promise<ISubscription>[] = [];
    oppRowIds.forEach((id) => {
      const topic = `/topic/subsId/${id}/type/3`;
      const sub = subscribeReact(
        topic,
        (data: { SubsStatusId: number; IsValidationDone: boolean }) => {
          setStatus(validationStatus(data.SubsStatusId));
          if (data.IsValidationDone) {
            // Only invalidating the "first" opp rows validation. This only applies to package instances.
            invalidateValidation();
          }
        }
      );
      subs.push(sub);
    });
    return () => {
      subs.forEach((sub) => sub.then((s) => s.unsubscribe()));
    };
  }, [invalidateValidation, oppRowIds]);
  React.useEffect(() => {
    if (validationsData) {
      setStatus(validationsData.statuses);
    }
  }, [validationsData]);
  React.useEffect(() => {
    return () => {
      removeValidation();
    };
  }, [removeValidation]);
  const refObject = useHoverMouse({
    onEnter: () => setPopover(true),
    onLeave: () => setPopover(false),
  });
  const spanClasses = React.useMemo(() => {
    const faIcon = getFAIcon(status);
    return faIcon
      ? classNames("fa", "fa-fw", "fa-lg", faIcon, getTextClass(status))
      : "";
  }, [status]);
  return (
    <ValidationWrapper ref={refObject}>
      {spanClasses && <span className={spanClasses} />}
      {popover && validationGroups && (
        <ValidationPopover groups={validationGroups} />
      )}
    </ValidationWrapper>
  );
};
export default Validation;

const ValidationWrapper = styled.span`
  position: relative;
  margin: 0 8px;
`;

const getTextClass = (status: IValidationStatuses) => {
  if (status.validationFailed) {
    return "text-danger";
  }
  if (status.validationWarning) {
    return "text-warning";
  }
  if (status.validationOk) {
    return "text-success";
  }
  if (status.unsupportedStatus) {
    return "text-danger";
  }
  return "";
};

const getFAIcon = (status: IValidationStatuses) => {
  if (status.validationFailed || status.validationWarning) {
    return "fa-exclamation-triangle";
  }
  if (status.notValidated) {
    return "fa-question-circle";
  }
  if (status.validationInProgress) {
    return "fa-refresh fa-spin";
  }
  if (status.validationOk) {
    return "fa-check";
  }
  if (status.unsupportedStatus) {
    return "fa-exclamation-triangle";
  }
  return "fa-refresh fa-spin";
};
