import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

export interface UserGeneratedReport {
  id: number;
  created: string;
  generated: string | null;
  consumed: boolean;
  error: boolean;
  filename: string | null;
  description: string;
}

const get = async () => {
  const { data } = await axios.get<UserGeneratedReport[]>(
    "/api/generated-reports"
  );
  return data;
};

const QUERY_KEY = "user-generated-reports";

const useUserGeneratedReports = () =>
  useQuery({ queryKey: [QUERY_KEY], queryFn: get });
export default useUserGeneratedReports;

export const useUserGeneratedReportsSuspensed = () =>
  useSuspenseQuery({ queryKey: [QUERY_KEY], queryFn: get });

export const useInvalidateUserGeneratedReports = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

export const useMarkAsConsumed = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (id: number) => {
      queryClient.setQueryData<UserGeneratedReport[]>(
        [QUERY_KEY],
        (oldData) => {
          if (oldData) {
            const newData: UserGeneratedReport[] = oldData.map((value) => {
              if (value.id === id) {
                return { ...value, consumed: true };
              }
              return { ...value };
            });
            return newData;
          }
          return [];
        }
      );
    },
    [queryClient]
  );
};
