import { color, padding } from "@tele2/t2-ui-kit";
import Cancel from "@tele2/t2-ui-kit/build/Icon/icons/Cancel";
import Done from "@tele2/t2-ui-kit/build/Icon/icons/Done";
import Info from "@tele2/t2-ui-kit/build/Icon/icons/Info";
import { ContentWidth } from "../Layout/Layout";
import * as React from "react";
import styled from "styled-components";
import { MessageType } from "./message-types";

interface StyledContentWidthProps {
  $theme?: "alert" | "com" | "positive";
}
const StyledContentWidth = styled(ContentWidth)`
  padding: ${padding.XL};
  display: flex;
  color: ${color.BRAND_WHITE};
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  ${(props: StyledContentWidthProps) =>
    props.$theme &&
    `
  background-color: ${props.$theme === "positive" && `${color.BRAND_GREEN};`} 
     ${props.$theme === "alert" && `${color.BRAND_RED};`}
     ${props.$theme === "com" && `${color.PRODUCT_YELLOW};`}
    `}
`;

const WideMessageItem: React.FC<
  MessageType & {
    setWideMessagesQueue: React.Dispatch<React.SetStateAction<MessageType[]>>;
  }
> = ({ id, interval, message, setWideMessagesQueue, ...rest }) => {
  React.useEffect(() => {
    if (interval) {
      const removeMessageTimer = setTimeout(() => {
        setWideMessagesQueue((oldQueue) => oldQueue.filter((m) => m.id !== id));
      }, interval);
      return () => {
        clearInterval(removeMessageTimer);
      };
    }
    return;
  }, [id, interval, setWideMessagesQueue]);
  return (
    <StyledContentWidth $theme={rest.theme}>
      {rest.theme === "positive" ? (
        <Done />
      ) : rest.theme === "alert" ? (
        <Cancel />
      ) : (
        <Info />
      )}
      {typeof message !== "string" ? message : <span>{message}</span>}
    </StyledContentWidth>
  );
};

export default WideMessageItem;
