import {
  InvalidateQueryFilters,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

import toFormData from "../../../utils/toFormData";
import { SalesProject } from "../carts/carts-types";
import { RoleType } from "../types";
import {
  OpportunityGroup,
  OpportunityRowContainer,
} from "./useSalesProjectOppRows";
import { CartActionPermissions } from "./useSalesProjectSummaryValidation";

export interface CartModel {
  container: OpportunityRowContainer;
  permissions: CartActionPermissions;
  salesProject: SalesProject;
  roleType: RoleType;
  pagination: unknown;
  tableSessionId: number;
}

export interface OpportunityRowsRequestModel {
  attributesFilter?: string;
  oppTypesFilter?: string;
  productsFilter?: string;
  salesProjectId: number;
  tableSessionId?: number;
  subRoleType?: RoleType;
}

const QUERY_KEY = "cart-opp-rows";
const getCartOppRows = async (request: OpportunityRowsRequestModel) => {
  const { data } = await axios.post<CartModel>(
    "/api/self-service/checkout/opportunity-rows",
    toFormData(request)
  );
  return data;
};

export const useInvalidateCartOppRows = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (opts?: InvalidateQueryFilters) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY], ...opts });
    },
    [queryClient]
  );
};

export const getOppGroupKey = (group: OpportunityGroup) => {
  return `opp-group-${group.baseSubsId}-${group.packageInstanceId}-${group.rows[0].id}-${group.rows[0].subscription.id}`;
};

// maybe not suspense. need keepPrevious
const useCartOppRows = (request: OpportunityRowsRequestModel) => {
  return useSuspenseQuery<CartModel, AxiosError>({
    queryKey: [QUERY_KEY, request],
    queryFn: () => getCartOppRows(request),
    staleTime: 100,
  });
};
export default useCartOppRows;
