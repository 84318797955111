import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_INVOICE_ANALYSIS,
  CONST_ROUTE_INVOICE_DIMENSIONS,
  CONST_ROUTE_INVOICE_INDEX,
  CONST_ROUTE_INVOICE_INVOICE,
  CONST_ROUTE_INVOICE_INVOICE_RECIPIENTS,
  CONST_ROUTE_INVOICE_INVOICE_WINDOW,
  CONST_ROUTE_INVOICE_INVOICES,
} from "../approutes";
import * as React from "react";

const Dimensions = React.lazy(() => import("./Dimensions"));
const Invoice = React.lazy(() => import("./Invoice"));
const InvoiceAnalysis = React.lazy(() => import("./Analysis"));
const InvoiceRecipients = React.lazy(() => import("./InvoiceRecipients"));
const Invoices = React.lazy(() => import("./Invoices"));
const InvoiceWindow = React.lazy(() => import("./InvoiceView/InvoiceWindow"));
const Overview = React.lazy(() => import("./Overview"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_INVOICE_ANALYSIS,
    component: InvoiceAnalysis,
  },
  {
    path: CONST_ROUTE_INVOICE_INDEX,
    component: Overview,
  },
  {
    path: CONST_ROUTE_INVOICE_INVOICES,
    component: Invoices,
  },
  {
    path: CONST_ROUTE_INVOICE_INVOICE,
    component: Invoice,
  },
  {
    path: CONST_ROUTE_INVOICE_INVOICE_RECIPIENTS,
    component: InvoiceRecipients,
  },
  {
    path: CONST_ROUTE_INVOICE_INVOICE_WINDOW,
    component: InvoiceWindow,
  },
  { path: CONST_ROUTE_INVOICE_DIMENSIONS, component: Dimensions },
];

export default routes;
