import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export default function ScrollToTop(): null {
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params, pathname]);

  return null;
}
