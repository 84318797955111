import { color, Text } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IOpportunityPriceDetails } from "../../../../api/self-service/price-details/usePriceDetails";
import LinkStyleButton from "../../../../components/Buttons/LinkStyleButton";

interface PriceDetailsPopoverProps {
  priceDetails: IOpportunityPriceDetails;
}

const PopoverWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, -100%);
  background: white;
  border: grey solid 1px;
  padding: 8px;
  white-space: nowrap;
  text-align: left;
`;

const DisclaimerText = styled(Text)`
  color: ${color.ALERT_RED};
  font-weight: 500;
`;

const PriceDetailsPopover: React.FC<PriceDetailsPopoverProps> = ({
  priceDetails,
}) => {
  const { t } = useTranslation();
  const {
    currencies,
    defaultCurrency,
    details,
    total,
    bundleDiscountDisclaimer,
  } = priceDetails;
  const [selected, setSelected] = React.useState(defaultCurrency);
  const [det, setDet] = React.useState(details[defaultCurrency]);
  const [tot, setTot] = React.useState(total[defaultCurrency]);
  React.useEffect(() => {
    setDet(details[selected]);
    setTot(total[selected]);
  }, [details, selected, total]);
  return (
    <PopoverWrapper>
      <div className="price-details-popup">
        <div>
          <ul className="currencies list-inline text-nowrap">
            {currencies.map((currency, i) => (
              <li
                key={i}
                className={`list-inline-item ${
                  currency === selected ? "selected" : ""
                }`}
              >
                <LinkStyleButton
                  onClick={() => {
                    setSelected(currency);
                  }}
                >
                  {currency}
                </LinkStyleButton>
              </li>
            ))}
          </ul>
        </div>
        <table className="table table-d table-hover">
          <tbody>
            {det &&
              det.map((detail, j) => (
                <React.Fragment key={j}>
                  {detail.onetime && (
                    <tr>
                      <td className="text-nowrap">{detail.description}</td>
                      <td className="text-right">
                        <strong>{detail.onetime}</strong>
                      </td>
                      <td>{selected}</td>
                      <td>
                        {detail.nonSpecificPrice && (
                          <span
                            title={t(
                              "module.self.service.price.not.agreed.service"
                            )}
                            className="fa fa-exclamation-triangle text-danger"
                          ></span>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            {tot &&
              tot.map((detail, j) => (
                <React.Fragment key={j}>
                  {detail.onetime && (
                    <>
                      <tr className={"summary"}>
                        <td className="text-nowrap">
                          {t("module.self.service.total.onetime.price")}
                        </td>
                        <td className="text-right">
                          <strong>{detail.onetime}</strong>
                        </td>
                        <td>{detail.currency}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{ border: 0 }}>
                          &nbsp;
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}
            {det &&
              det.map((detail, j) => (
                <React.Fragment key={j}>
                  {detail.recurring && (
                    <tr>
                      <td className="text-nowrap">{detail.description}</td>
                      <td className="text-right">
                        <strong>{detail.recurring}</strong>
                      </td>
                      <td>
                        {t("module.self.service.price.per.month", [selected])}
                      </td>
                      <td>
                        {detail.nonSpecificPrice && (
                          <span
                            title={t(
                              "module.self.service.price.not.agreed.service"
                            )}
                            className="fa fa-exclamation-triangle text-danger"
                          ></span>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            {tot &&
              tot.map((detail, j) => (
                <React.Fragment key={j}>
                  {detail.recurring && (
                    <tr className={"summary"}>
                      <td className="text-nowrap">
                        {t("module.self.service.price.total.recurring")}
                      </td>
                      <td className="text-right">
                        <strong>{detail.recurring}</strong>
                      </td>
                      <td>
                        {t("module.self.service.price.per.month", [selected])}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
        {bundleDiscountDisclaimer && (
          <DisclaimerText lg>
            {t("module.self.service.bundling.discount.disclaimer")}
          </DisclaimerText>
        )}
        <p className="text-muted">
          <small>
            {t("module.self.service.price.currency.internal")}
            <br />
            {t("module.self.service.prices.without.vat")}
          </small>
        </p>
      </div>
    </PopoverWrapper>
  );
};
export default PriceDetailsPopover;
