import { Card, CardProps, color, Flex, H4, Text } from "@tele2/t2-ui-kit";
import ArrowForwardIcon from "@tele2/t2-ui-kit/build/Icon/icons/ArrowForward";
import CloseIcon from "@tele2/t2-ui-kit/build/Icon/icons/Close";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  NotificationProps,
  NotificationsViewState,
} from "../../../NotificationsContext/notification-utils";
import { useAppNotifications } from "../../../NotificationsContext/NotificationsContext";

interface NotificationCardProps extends NotificationProps {
  delay?: number;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  id,
  title,
  body,
  color,
  delay,
  link,
}) => {
  const { dismissNotification, notificationsViewState } = useAppNotifications();
  const navigate = useNavigate();
  const Container =
    notificationsViewState === NotificationsViewState.NOT_READ ||
    notificationsViewState === NotificationsViewState.ALL
      ? NormalContainer
      : AnimatedContainer;
  return (
    <Container
      $darkBorder={notificationsViewState === NotificationsViewState.NEW}
      delay={delay}
      padding="SM"
      role={link ? "link" : ""}
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
      link={link}
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <HeaderWrapper>
          <H4 marginBottom="xxs" color={color}>
            {title}
          </H4>
          {(notificationsViewState === NotificationsViewState.NOT_READ ||
            notificationsViewState === NotificationsViewState.NEW) && (
            <CloseButton
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                dismissNotification(id);
              }}
            >
              <CloseIcon lg />
            </CloseButton>
          )}
        </HeaderWrapper>
      </Flex>
      <MessageBody alignItems="flex-end" justifyContent="space-between">
        {typeof body === "string" ? (
          <Text
            xl
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        ) : (
          body
        )}
        {link && (
          <LinkContainer>
            <ArrowWrapper>
              <ArrowForwardIcon />
            </ArrowWrapper>
          </LinkContainer>
        )}
      </MessageBody>
    </Container>
  );
};
export default NotificationCard;

const MessageBody = styled(Flex)`
  word-break: break-word;
`;

const CloseButton = styled.div`
  color: ${color.BRAND_PREMIUM_GREY};
  :hover {
    color: ${color.DIGITAL_TEXT};
  }
`;

const LinkContainer = styled.div`
  min-width: 25px;
  min-height: 25px;
`;
const ArrowWrapper = styled.div`
  color: ${color.BRAND_PREMIUM_GREY};
  :hover {
    color: ${color.DIGITAL_TEXT};
  }
`;

const Wrapper = styled(Card)<
  Pick<NotificationCardProps, "link"> & { $darkBorder: boolean }
>`
  margin-bottom: 8px;
  background-color: ${color.BRAND_LIGHT_GRAY};
  border: 1px solid
    ${(props) =>
      props.$darkBorder ? color.BRAND_PREMIUM_GREY : color.BRAND_LIGHT_GRAY};
  :hover {
    background-color: ${color.BRAND_WHITE};
    border: 1px solid ${color.DIGITAL_DARK_GREY};
    ${ArrowWrapper} {
      display: block;
    }
  }
  ${ArrowWrapper} {
    display: none;
  }
  cursor: ${(props) => (props.link ? "pointer" : "default")};
`;

const AnimatedContainer = styled(Wrapper)<
  CardProps &
    Pick<NotificationCardProps, "delay" | "link"> & { $darkBorder: boolean }
>`
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-delay: ${(props) => (props.delay ? 0.1 + props.delay * 0.05 : 0)}s;
  animation-name: fadeInRight;

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(60px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const NormalContainer = styled(Wrapper)<
  CardProps &
    Pick<NotificationCardProps, "delay" | "link"> & { $darkBorder: boolean }
>``;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
