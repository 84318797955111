import { useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { AvailableBaseSubs } from "../../home/useAvailableBaseSubs";

const availableBaseSubs = async (endUser: boolean) => {
  const { data } = await axios.get<AvailableBaseSubs[]>(
    `/api/self-service/available-base-services`,
    { params: { endUser } }
  );
  return data;
};

function useAvailableBaseServices(endUser: boolean) {
  return useSuspenseQuery<AvailableBaseSubs[], AxiosError>({
    queryKey: ["available-base-services", endUser],
    queryFn: () => availableBaseSubs(endUser),
  });
}

export default useAvailableBaseServices;
