import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { useInvalidateCartOppRows } from "../checkout/useCartOppRows";
import { useInvalidateContacts } from "../contacts/useContacts";

interface DisconnectContactRequest {
  oppRowId: number;
  salesProjectId: number;
  contactId: number;
}

const post = async (request: DisconnectContactRequest) => {
  await axios.post("/api/self-service/disconnect-contact", null, {
    params: request,
  });
};

const useDisconnectContact = () => {
  const invalidateContacts = useInvalidateContacts();
  const invalidatePostOppRowsGet = useInvalidateCartOppRows();

  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  const disconnect = useCallback(
    async (request: DisconnectContactRequest) => {
      await mutateAsync(request);
      invalidateContacts();
      invalidatePostOppRowsGet();
    },
    [invalidateContacts, invalidatePostOppRowsGet, mutateAsync]
  );

  return { disconnect, isPending };
};

export default useDisconnectContact;
