import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  useComponentsAttributes,
  useGetComponent,
  useUpdateComponent,
  useUpdateComponents,
} from "../../../components/component/ComponentsContext";
import { isSameComponentAttribute } from "../../../components/component/utils";

import { useMessage } from "../../../components/Message/Message";
import {
  getMessageTheme,
  getMessageType,
} from "../../../Routes/SelfService/EditComponents/utils";
import { AttributeComponent } from "../../subscription/subscription-view/useSubscriptionModel";

export interface AttributeSaveParams {
  value: string;
  hash: string | null;
  subsId: number;
  compAttrId: number;
  prodCompAttrId: number;
  hiddenCompAttrIds: string | null;
  prodCompId: number;
}

interface AttributeSaveRequest {
  subsId: number;
  roleType: string;
  subRoleType: string;
  hash: string;
  data: string;
  prodCompAttrId: number;
  packageInstanceId: number;
  compAttrId: number;
  salesProjectId: number;
  hiddenCompAttrIds: string;
  simulateDraftForSalesProjectId: boolean;
  showCaseData: boolean;
  oneFieldForAllProducts: boolean;
}

export interface AttributeSaveResult {
  hashChanged: boolean;
  hash: string;
  components?: AttributeComponent[];
  validationCode: string;
  validationMessage: string;
  invisibleCompAttrIds: string | null;
}

const post = async (request: AttributeSaveRequest) => {
  const { data } = await axios.post<AttributeSaveResult>(
    "/api/self-service/save-attribute",
    request
  );
  return data;
};

const useSaveAttribute = (label?: string) => {
  const message = useMessage();
  const attributes = useComponentsAttributes();
  const getComponent = useGetComponent();
  const updateComponent = useUpdateComponent();
  const updateComponents = useUpdateComponents();
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useMutation<
    AttributeSaveResult,
    AxiosError,
    AttributeSaveParams
  >({
    mutationFn: (params: AttributeSaveParams) =>
      post({
        data: params.value,
        hash: params.hash || "",
        subsId: params.subsId,
        compAttrId: params.compAttrId,
        prodCompAttrId: params.prodCompAttrId,
        hiddenCompAttrIds: params.hiddenCompAttrIds || "",
        roleType: attributes.roleType,
        subRoleType: attributes.subRoleType,
        packageInstanceId: attributes.packageInstanceId,
        salesProjectId: attributes.salesProjectId,
        simulateDraftForSalesProjectId:
          attributes.simulateDraftForSalesProjectId,
        showCaseData: attributes.showCaseData,
        oneFieldForAllProducts: attributes.oneFieldForAllProducts,
      }),
  });
  const saveAttribute = useCallback(
    async (params: AttributeSaveParams): Promise<AttributeSaveResult> => {
      const response = await mutateAsync(params);
      if (!response.hashChanged) {
        if (response.components) {
          // Assuming that if the hash has not changed there should only be one component and one attribute in the response
          const attribute = response.components[0].attributes[0];
          const component = getComponent(params.subsId, params.prodCompId);
          if (component) {
            const attr = component.attributes.find((a) =>
              isSameComponentAttribute(a, attribute)
            );
            if (attr) {
              //Only update some attributes. The response does populate all fields in the BE.
              attr.attrData = attribute.attrData;
              attr.validationCode = attribute.validationCode;
              attr.validationMessage = attribute.validationMessage;
            } else {
              throw new Error("Could not find attribute when saving attribute");
            }
            updateComponent(component);
            if (!attributes.quickCase) {
              message(
                getMessageType(t, {
                  attrLabel: label,
                  message: response.validationMessage,
                  theme: getMessageTheme(response.validationCode),
                })
              );
            }
          } else {
            throw new Error("Could not find component");
          }
        }
      } else if (response.components) {
        updateComponents(response.components);
        if (!attributes.quickCase) {
          message(
            getMessageType(t, {
              message: response.validationMessage,
              theme: getMessageTheme(response.validationCode),
            })
          );
        }
      }
      return response;
    },
    [
      attributes.quickCase,
      getComponent,
      label,
      message,
      mutateAsync,
      t,
      updateComponent,
      updateComponents,
    ]
  );
  return { saveAttribute, isPending };
};

export default useSaveAttribute;
