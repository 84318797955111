import { AppRouteProps } from "../types";
import { CONST_ROUTE_BUSINESSTRUNK_TRUNK_SUBSCRIPTIONS } from "../approutes";
import * as React from "react";

const TrunkSubscriptions = React.lazy(() => import("./TrunkSubscriptions"));
const NumberForward = React.lazy(() => import("./NumberForward"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_BUSINESSTRUNK_TRUNK_SUBSCRIPTIONS,
    component: TrunkSubscriptions,
  },
  {
    path: "number-forward",
    component: NumberForward,
  },
];

export default routes;
