import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useInvoiceRecipient, {
  InvoiceRecipientOption,
} from "../../../../api/self-service/invoice-recipient/useInvoiceRecipient";
import useSetInvoiceRecipient from "../../../../api/self-service/set-invoice-recipient/useSetInvoiceRecipient";
import {
  useComponentsAttributes,
  useOneFieldForAllProducts,
} from "../../../../components/component/ComponentsContext";
import ReactDropDown from "../../../../components/dropdown/ReactDropDown";
import { useMessage } from "../../../../components/Message/Message";
import useDebounceValue from "../../../../utils/useDebounceValue";
import useDetectOutsideClick from "../../../../utils/useDetectOutsideClick";
import { getMessageType } from "../utils";

interface IvrComponentProps {
  invoiceRecipientEditable: boolean;
  invoiceRecipientValidationMessage?: string;
  invoiceRecipient?: { name: string; id: number };
  oppRowId: number;
  slaveField: boolean;
  onSave: (invoiceRecipientState: {
    invoiceRecipientValidationMessage: string;
    invoiceRecipient?: { name: string; id: number };
  }) => void;
}

const IvrComponent: React.FC<IvrComponentProps> = ({
  invoiceRecipientEditable,
  invoiceRecipientValidationMessage,
  invoiceRecipient,
  oppRowId,
  slaveField,
  onSave,
}) => {
  const { t } = useTranslation();
  const message = useMessage();
  const containerRef = React.useRef(null);
  const [validationMessage, setValidationMessage] = React.useState(
    invoiceRecipientValidationMessage
  );
  const [inputValue, setInputValue] = React.useState(
    invoiceRecipient?.name || ""
  );
  const [savedValue, setSavedValue] = React.useState(invoiceRecipient?.id);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [ignoreFilter, setIgnoreFilter] = React.useState(true);
  const [showDropdown, setShowDropdown] = React.useState(false);
  React.useEffect(() => {
    setInputValue(invoiceRecipient?.name || "");
    setSavedValue(invoiceRecipient?.id);
  }, [invoiceRecipient]);
  React.useEffect(() => {
    setValidationMessage(invoiceRecipientValidationMessage);
  }, [invoiceRecipientValidationMessage]);
  useDetectOutsideClick(containerRef, () => setShowDropdown(false));
  const debounceValue = useDebounceValue<string>(inputValue);
  const [oneFieldForAllProducts] = useOneFieldForAllProducts();
  const { salesProjectId } = useComponentsAttributes();
  const { data, isLoading: loadingData } = useInvoiceRecipient(
    {
      filter: ignoreFilter ? "" : debounceValue,
      oppRowIds: [oppRowId],
      salesProjectId,
    },
    showDropdown
  );
  const { setInvoiceRecipient } = useSetInvoiceRecipient();

  const saveIvr = async (id: number, name: string) => {
    setUnsavedChanges(true);
    const response = await setInvoiceRecipient({
      invoiceRecipientId: id,
      oppRowIds: oppRowId,
      salesProjectId,
    });
    onSave({
      invoiceRecipientValidationMessage: response.message,
      invoiceRecipient: { id, name },
    });
    setSavedValue(id);
    setInputValue(name);
    setUnsavedChanges(!response.saved);
    message(
      getMessageType(t, {
        theme: response.saved ? "positive" : "alert",
        message: response.message,
        attrLabel: t("module.self.service.invoice.recipient"),
      })
    );
    if (response.message) {
      setValidationMessage(response.message);
    }
  };
  const inputClasses = classNames(
    "form-control",
    "attr-dynamic-select",
    unsavedChanges ? "attr-unsaved-change" : ""
  );
  const show =
    (oneFieldForAllProducts && !slaveField) ||
    (!oneFieldForAllProducts && slaveField);
  const optionsWhileLoading = [
    {
      selectable: false,
      level: 0,
      selected: false,
      name: "Loading",
      id: 0,
      allowNewChild: false,
    },
  ];
  const ivrs = (showDropdown && data) || [];
  const rows = loadingData ? optionsWhileLoading : ivrs;
  if (!show) {
    return null;
  }
  return (
    <div className="row">
      <div className={`col-sm-4 ${invoiceRecipientEditable ? "" : "col-6"}`}>
        <label className="attr-label" htmlFor="invoice-recipient-input-header">
          {t("module.self.service.invoice.recipient")}
        </label>
        <span className="input-required">*</span>
      </div>
      <div className={`col-sm-8 ${invoiceRecipientEditable ? "" : "col-6"}`}>
        {invoiceRecipientEditable && (
          <div className="form-inline" ref={containerRef}>
            <div className="combo-box form-group">
              <span className="input-group" data-cy={"invoice-recipient-group"}>
                {showDropdown && (
                  <ReactDropDown<InvoiceRecipientOption>
                    format={(option) => (
                      <>
                        {option.level > 0 ? (
                          <i className={`tree-addon${option.level}`} />
                        ) : null}
                        {option.name}
                      </>
                    )}
                    rows={rows}
                    onSelect={(value) => {
                      setInputValue(value.name);
                      setShowDropdown(false);
                      saveIvr(value.id, value.name);
                    }}
                  />
                )}
                <input
                  onFocus={() => setShowDropdown(true)}
                  className={inputClasses}
                  type="text"
                  id="invoice-recipient-input-header"
                  data-cy={"invoice-recipient-input"}
                  name="invoice-recipient"
                  size={80}
                  value={inputValue}
                  data-value={savedValue}
                  onChange={(e) => {
                    setIgnoreFilter(false);
                    setInputValue(e.target.value);
                  }}
                />
                <span className="combo-box-btn input-group-append">
                  <button
                    data-cy={"invoice-recipient-dropdown-button"}
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    onClick={() => {
                      setIgnoreFilter(true);
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    {loadingData && (
                      <i className="combo-box-icon fa fa-spinner fa-spin" />
                    )}
                  </button>
                </span>
              </span>
            </div>
          </div>
        )}
        {!invoiceRecipientEditable && <span>{invoiceRecipient?.name}</span>}
        <div className="attr-validation help-block">{validationMessage}</div>
      </div>
    </div>
  );
};
export default IvrComponent;
