import { color, padding, TabProps, Tabs } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ProductItem } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import DocumentsPane from "./DocumentsPane";
import RelatedPane from "./RelatedPane";
import SpecificationPane from "./SpecificationPane";

const ProductTabsWrapper = styled.div`
  background-color: ${color.DIGITAL_LIGHT_GREY};
`;

const ProductTabsContainer = styled.div`
  margin: 0 auto;
  max-width: 1176px;
  padding: ${padding.XL};
  width: 100%;
`;

const ProductTabsPaneContainer = styled.div`
  margin-top: 24px;
`;

export interface TabPaneProps {
  active: boolean;
}

export const TabPane = styled.div`
  display: none;
  transition: opacity 0.15s linear;

  ${(props: TabPaneProps) =>
    props.active &&
    `
        display: block;
      `}
`;

interface ProductTabsProps {
  product: ProductItem;
}

type TabNames = "accessories" | "specification" | "documents" | "";

const ProductTabs: React.FC<ProductTabsProps> = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<TabNames>("");
  React.useEffect(() => {
    setActiveTab(
      product.relatedProductCatalogItems?.length
        ? "accessories"
        : product.specification?.MainSpec
        ? "specification"
        : product.files?.length
        ? "documents"
        : ""
    );
  }, [product]);

  const tabs: TabProps[] = [
    ...(product.relatedProductCatalogItems?.length
      ? [
          {
            id: "accessories",
            name: t("general.accessories"),
          },
        ]
      : []),
    ...(product.specification?.MainSpec
      ? [
          {
            id: "specification",
            name: t("general.specification"),
          },
        ]
      : []),
    ...(product.files?.length
      ? [
          {
            id: "documents",
            name: t("general.documents.and.manuals"),
          },
        ]
      : []),
  ];

  const panes: { id: string; content: JSX.Element }[] = [
    ...(product.relatedProductCatalogItems?.length
      ? [
          {
            id: "accessories",
            content: <RelatedPane product={product} />,
          },
        ]
      : []),
    {
      id: "specification",
      content: <SpecificationPane product={product} />,
    },
    ...(product.files?.length
      ? [{ id: "documents", content: <DocumentsPane product={product} /> }]
      : []),
  ];

  const onTabChange = (tab: TabProps) => {
    setActiveTab(tab.id.toString() as TabNames);
  };

  const getTabFromId = (id?: string) => {
    return tabs.find((tab) => {
      return tab.id === id;
    });
  };

  return (
    <ProductTabsWrapper>
      <ProductTabsContainer>
        <Tabs
          tabs={tabs}
          onSelectChange={onTabChange}
          setTab={getTabFromId(activeTab)}
        />
        <ProductTabsPaneContainer>
          {panes.map(({ id, content }) => {
            return (
              <TabPane key={id} active={id === activeTab}>
                {content}
              </TabPane>
            );
          })}
        </ProductTabsPaneContainer>
      </ProductTabsContainer>
    </ProductTabsWrapper>
  );
};

export default ProductTabs;
