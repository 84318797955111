import {
  CONST_ROUTE_SELF_SERVICE_ACCESS_REQUEST,
  CONST_ROUTE_SELF_SERVICE_BATCH_UPDATE,
  CONST_ROUTE_SELF_SERVICE_CARTS,
  CONST_ROUTE_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION,
  CONST_ROUTE_SELF_SERVICE_CHECKOUT_CART,
  CONST_ROUTE_SELF_SERVICE_CHECKOUT_CONFIRMATION,
  CONST_ROUTE_SELF_SERVICE_CHECKOUT_SUMMARY,
  CONST_ROUTE_SELF_SERVICE_EDIT_COMPONENTS,
  CONST_ROUTE_SELF_SERVICE_INDEX,
  CONST_ROUTE_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT,
  CONST_ROUTE_SELF_SERVICE_SUBSCRIPTION_OWNERSHIP_REQUEST,
} from "../approutes";
import { AppRouteProps } from "../types";
import { CDC_ROUTE } from "./DataCollect/config";
import * as React from "react";

const OwnershipRequest = React.lazy(
  () => import("../SelfService/OwnershipRequest/OwnershipRequest")
);
const AccessRequest = React.lazy(() => import("./AccessRequest"));
const BatchUpdate = React.lazy(() => import("./BatchUpdate/BatchUpdate"));
const Carts = React.lazy(() => import("./Carts"));
const AdditionalInformation = React.lazy(
  () => import("./Checkout/AdditionalInformation")
);
const Cart = React.lazy(() => import("./Checkout/Cart"));
const SalesProjectConfirmation = React.lazy(
  () => import("./Checkout/SalesProjectConfirmation")
);
const SalesProjectSummary = React.lazy(
  () => import("./Checkout/SalesProjectSummary")
);
const DataCollect = React.lazy(() => import("./DataCollect"));
const EditComponents = React.lazy(
  () => import("./EditComponents/EditComponents")
);
const Overview = React.lazy(() => import("./Overview"));
const PunchoutSessionTimeout = React.lazy(
  () => import("./PunchoutSessionTimeout")
);

const routes: AppRouteProps[] = [
  { path: CONST_ROUTE_SELF_SERVICE_EDIT_COMPONENTS, component: EditComponents },
  { path: CONST_ROUTE_SELF_SERVICE_CARTS, component: Carts },
  {
    path: CDC_ROUTE.root,
    component: DataCollect,
  },
  {
    path: CDC_ROUTE.collectionPath,
    component: DataCollect,
  },
  {
    path: CDC_ROUTE.productPath,
    component: DataCollect,
  },
  // {
  //   path: CONST_ROUTE_SELF_SERVICE_TERMS_OF_SERVICE,
  //   component: TermsOfService,
  // },
  {
    path: CONST_ROUTE_SELF_SERVICE_CHECKOUT_CART,
    component: Cart,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_CHECKOUT_SUMMARY,
    component: SalesProjectSummary,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_CHECKOUT_CONFIRMATION,
    component: SalesProjectConfirmation,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_CHECKOUT_ADDITIONAL_INFORMATION,
    component: AdditionalInformation,
  },
  { path: CONST_ROUTE_SELF_SERVICE_BATCH_UPDATE, component: BatchUpdate },
  {
    path: CONST_ROUTE_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT,
    component: PunchoutSessionTimeout,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_INDEX,
    component: Overview,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_ACCESS_REQUEST,
    component: AccessRequest,
  },
  {
    path: CONST_ROUTE_SELF_SERVICE_SUBSCRIPTION_OWNERSHIP_REQUEST,
    component: OwnershipRequest,
  },
];
export default routes;
