import { Flex, Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import { PropsWithChildren } from "react";

interface SuspenseBoundaryProps {
  fallback?: React.SuspenseProps["fallback"];
}
const SuspenseBoundary: React.FC<PropsWithChildren<SuspenseBoundaryProps>> = ({
  fallback,
  children,
}) => {
  return (
    <React.Suspense fallback={fallback || <SuspenseComponent />}>
      {children}
    </React.Suspense>
  );
};
export default SuspenseBoundary;

const SuspenseComponent: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Loader />
    </Flex>
  );
};
