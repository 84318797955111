import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { EditComponentsMode } from "../edit-components-model/useEditComponentsModel";
import { RoleType } from "../types";

export type NodeType = "ROOT" | "PRODUCT" | "OPPORTUNITY";

export enum ProductType {
  CATALOG = "CATALOG",
  SERVICE = "SERVICE",
}

export interface ProductItem {
  currency: string;
  description: string;
  extendedSpecification: {
    [key: string]: Specification[];
  };
  files?: File[];
  allowAmountOnLine?: boolean;
  header?: boolean;
  pictureUrl?: string;
  pictureUrls?: string[];
  priceOneTime: string | null;
  productCategory: string;
  priceRecurring: string | null;
  priceSetting?: string;
  prodImgPublicId?: string | null;
  prodName: string;
  prodDescription: string;
  subsId?: number;
  specification?: {
    MainSpec: Specification[];
  };
  stockBalance: number;
  templateSubsId?: number;
  isCatalogItem?: boolean;
  relatedProductCatalogItems?: ProductItem[];
  productCode: string;
  id?: number;
  type: ProductType;
}

export interface File {
  fileName: string;
  mimeType: string;
  url: string;
}

export interface Specification {
  header: string;
  body: string;
  displayOrder: number;
}
export interface CatalogNode {
  name: string;
  key: string;
  nodeType: NodeType;
  subCategories: CatalogNode[];
}

interface OpportunityTypeCatalogs {
  key: string;
  items: ProductItem[];
}

export interface EsalesProductCatalogModel {
  baseSubsId: number;
  roleType: RoleType;
  subRoleType: RoleType;
  productCatalogItems: ProductItem[];
  catalogTree: CatalogNode;
  oppCatalogs: OpportunityTypeCatalogs[];
  showPrices: boolean;
}

export interface EsalesParams {
  salesProjectId: number;
  baseSubsId: number;
  roleType: RoleType;
  subRoleType?: RoleType;
  mode: EditComponentsMode;
  relatedSubsId?: number;
  hasSelfService: boolean;
}

export interface EsalesCatalogRequestParams {
  baseSubsId: number;
  roleType: RoleType;
  subRoleType?: RoleType;
  relatedSubsId?: number;
}

export const getReferrer = () => {
  return (
    window.location.pathname + window.location.search + window.location.hash
  );
};

const getEsalesProductCatalogModel = async (
  params: EsalesCatalogRequestParams
) => {
  const { data } = await axios.get<EsalesProductCatalogModel>(
    "/api/self-service/esales-product-catalog-model",
    {
      params,
    }
  );
  return data;
};

const useEsalesProductCatalogModel = (params: EsalesCatalogRequestParams) => {
  return useQuery<EsalesProductCatalogModel, AxiosError>({
    queryKey: ["esales-product-catalog-model", params.baseSubsId],
    queryFn: () =>
      getEsalesProductCatalogModel({
        baseSubsId: params.baseSubsId,
        relatedSubsId: params.relatedSubsId,
        roleType: params.roleType,
        subRoleType: params.subRoleType,
      }),
    staleTime: 600_000,
  });
};
export default useEsalesProductCatalogModel;
