import classNames from "classnames";
import * as React from "react";
import useSaveAttribute from "../../../api/self-service/save-attribute/useSaveAttribute";
import useUpdateMatrixRow from "../../../api/self-service/update-matrix-row/useUpdateMatrixRow";
import {
  useUpdateAllSlaveAttributes,
  useUpdateOverviewAttribute,
} from "../../component/ComponentsContext";
import { AttributeProps } from "../AttributeRow";
import Undo from "../Undo";

const TextAreaAttribute: React.FC<AttributeProps> = ({
  subsId,
  prodCompAttrId,
  attrData,
  relatedAttrData,
  colSize,
  rowSize,
  isMatrixCell,
  validationMessage,
  required,
  hash,
  compAttrId,
  hiddenCompAttrIds,
  label,
  readOnly,
  oppRowId,
  matrixRowId,
  overViewAttribute,
  prodCompId,
  parentProdCompAttrId,
}) => {
  const attrDataString = attrData || "";
  const [inputValue, setInputValue] = React.useState(attrData || "");
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(true);
  React.useEffect(() => {
    setInputValue(attrData || "");
  }, [attrData]);
  const { saveAttribute, isPending: isSavingAttribute } =
    useSaveAttribute(label);
  const { updateMatrixRow, isPending: isSavingMatrixAttribute } =
    useUpdateMatrixRow(label, parentProdCompAttrId);
  const updateOverviewAttribute = useUpdateOverviewAttribute();
  const updateAllSlaveAttributes = useUpdateAllSlaveAttributes(label);
  const saveValue = React.useCallback(
    async (newValue: string) => {
      if (readOnly) {
        return;
      }
      if (savedSuccessfully && newValue === attrDataString) {
        return;
      }
      if (isMatrixCell) {
        updateMatrixRow({
          data: newValue,
          matrixRowId,
          subsId,
          compAttrId,
          oppRowId,
          prodCompId,
        });
      } else {
        const response = await saveAttribute({
          value: newValue,
          hash,
          compAttrId,
          prodCompAttrId,
          hiddenCompAttrIds,
          subsId,
          prodCompId,
        });
        const validSave = response.validationCode === "OK";
        setSavedSuccessfully(validSave);
        if (overViewAttribute) {
          updateOverviewAttribute(subsId, prodCompId, prodCompAttrId, response);
          if (validSave) {
            // If this is an overview field, also update slave fields
            await updateAllSlaveAttributes({
              value: newValue,
              hash,
              compAttrId,
              prodCompAttrId,
              hiddenCompAttrIds,
              subsId,
              prodCompId,
            });
          }
        }
      }
    },
    [
      attrDataString,
      compAttrId,
      hash,
      hiddenCompAttrIds,
      isMatrixCell,
      matrixRowId,
      oppRowId,
      overViewAttribute,
      prodCompAttrId,
      prodCompId,
      readOnly,
      saveAttribute,
      savedSuccessfully,
      subsId,
      updateAllSlaveAttributes,
      updateMatrixRow,
      updateOverviewAttribute,
    ]
  );
  const inputClasses = classNames(
    "form-control",
    isMatrixCell ? "attr-matrix-value" : "attr-value",
    inputValue !== attrDataString ? "attr-unsaved-change" : "",
    required ? "attr-required" : ""
  );
  const id = `attr_${subsId}_${prodCompAttrId}`;
  return (
    <>
      <div className={`form-group ${validationMessage ? " has-error" : ""}`}>
        <textarea
          data-cy={prodCompAttrId}
          className={inputClasses}
          cols={colSize || 40}
          rows={rowSize || 3}
          id={id}
          name={id}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => saveValue(inputValue)}
          value={inputValue}
          disabled={readOnly || isSavingAttribute || isSavingMatrixAttribute}
        />
      </div>
      {!readOnly && (
        <Undo
          relatedAttrData={relatedAttrData}
          attrData={attrData}
          onClick={() => {
            if (relatedAttrData) {
              setInputValue(relatedAttrData);
              saveValue(relatedAttrData);
            }
          }}
        />
      )}
    </>
  );
};

export default TextAreaAttribute;
