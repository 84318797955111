import { color } from "@tele2/t2-ui-kit";
import * as React from "react";
import styled from "styled-components";
import {
  NotificationProps,
  NotificationsViewState,
} from "../../../NotificationsContext/notification-utils";
import { useAppNotifications } from "../../../NotificationsContext/NotificationsContext";
import NotificationsList from "./NotificationsList";
import { useTranslation } from "react-i18next";

const NotificationsPopup: React.FC = () => {
  const { t } = useTranslation();
  const {
    allNotifications,
    notReadNotifications,
    newNotifications,
    notificationsViewState,
    setNotificationsViewState,
    dismissAllNotifications,
  } = useAppNotifications();
  const [notifications, setNotifications] =
    React.useState<NotificationProps[]>();
  React.useEffect(() => {
    switch (notificationsViewState) {
      case NotificationsViewState.ALL:
        setNotifications(allNotifications);
        break;
      case NotificationsViewState.NOT_READ:
        setNotifications(notReadNotifications);
        break;
      default:
        setNotifications([]);
        break;
    }
  }, [
    allNotifications,
    newNotifications,
    notificationsViewState,
    notReadNotifications,
  ]);
  const onDismissAll = React.useCallback(() => {
    dismissAllNotifications();
  }, [dismissAllNotifications]);
  const hasNotReadNotifications =
    notReadNotifications && notReadNotifications.length > 0;
  return (
    <>
      <NotificationsContainer>
        <NotifcationsHeaderText>
          {t("module.notification.notifications")}
        </NotifcationsHeaderText>
        <TabBottonContainer>
          <TabButton
            $selected={
              notificationsViewState === NotificationsViewState.NOT_READ
            }
            onClick={() =>
              setNotificationsViewState(NotificationsViewState.NOT_READ)
            }
          >
            <div>
              <UnreadHeaderWrapper>
                {t("module.notification.unread")}
              </UnreadHeaderWrapper>
              {hasNotReadNotifications && (
                <NotificationCircle>
                  {notReadNotifications?.length || ""}
                  <NotificationCirclebackground />
                </NotificationCircle>
              )}
            </div>
          </TabButton>
          <TabButton
            $selected={notificationsViewState === NotificationsViewState.ALL}
            onClick={() =>
              setNotificationsViewState(NotificationsViewState.ALL)
            }
          >
            {t("module.notification.all")}
          </TabButton>
        </TabBottonContainer>
        {notifications && notifications.length ? (
          <NotificationsList notifications={notifications} />
        ) : (
          <EmptyListContainer>
            {notificationsViewState === NotificationsViewState.NOT_READ
              ? t("module.notification.no.new.notification")
              : t("module.notification.no.notification")}
          </EmptyListContainer>
        )}
        {notificationsViewState === NotificationsViewState.NOT_READ &&
          hasNotReadNotifications && (
            <MarkAllAsReadButtonWrapper>
              <MarkAllAsReadButton onClick={() => onDismissAll()}>
                {t("module.notification.mark.all.as.read")}
              </MarkAllAsReadButton>
            </MarkAllAsReadButtonWrapper>
          )}
      </NotificationsContainer>
    </>
  );
};

export default NotificationsPopup;

const MarkAllAsReadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 16px;
`;

const MarkAllAsReadButton = styled.button`
  color: ${color.FUNCTIONAL_BLUE};
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  :hover {
    border: none;
  }
  :focus {
    outline: none;
  }
`;

const EmptyListContainer = styled.div`
  min-width: 300px;
  padding: 8px 16px 8px 16px;
  color: ${color.TEXT_GREY};
`;

const TabBottonContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding-left: 16px;
  margin-bottom: 8px;
`;

const TabButton = styled.button<{ $selected: boolean }>`
  border: none;
  border-bottom: ${(props) =>
    props.$selected ? `2px solid ${color.DIGITAL_DARK_GREY}` : "none"};
  color: ${(props) =>
    props.$selected ? color.BRAND_BLACK : color.DIGITAL_DARK_GREY};
  background: none;
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
  :hover {
    border: none;
    border-bottom: 2px solid ${color.DIGITAL_DARK_GREY};
  }
  :focus {
    outline: none;
  }
`;

const NotifcationsHeaderText = styled.div`
  font-size: 18px;
  color: ${color.TEXT_GREY};
  padding-left: 16px;
  margin-bottom: 12px;
`;

const NotificationsContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 5px;
  background-color: ${color.BRAND_WHITE};
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${color.BRAND_PREMIUM_GREY};
  width: 420px;
`;

const UnreadHeaderWrapper = styled.span`
  margin-right: 4px;
`;

const NotificationCircle = styled.span`
  text-align: center;
  color: ${color.BRAND_WHITE};
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  min-width: 18px;
  position: relative;
  z-index: 1;
`;
const NotificationCirclebackground = styled.span`
  content: "";
  background-color: ${color.FUNCTIONAL_RED};
  border-radius: 100%;
  bottom: 0;
  display: block;
  margin: auto;
  padding: 0 0 100% 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;
