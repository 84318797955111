import { useInvalidateUserGeneratedReports } from "../api/generated-reports/useUserGeneratedReports";
import useNotifications from "../api/notifications/useNotifications";
import * as React from "react";
import { SessionContext } from "../SessionContext";
import {
  getArsNotification,
  getBrowserWarningNotification,
  getCaseNotification,
  getGeneratedReportsNotifications,
  getLoginMessageNotification,
  getNotificationsFromStorage,
  getOperationalDisturbancesNotification,
  getPlannedWorksContactNotification,
  markNotificationAsDismissed,
  NotificationProps,
  NotificationState,
  NotificationsViewState,
  NotificationType,
  saveNotificationsToStorage,
  sortNotificationsByLevel,
} from "./notification-utils";
import { useTranslation } from "react-i18next";

interface NotificationsContextValue {
  fetchNotificationsError: boolean;
  allNotifications?: NotificationProps[];
  notReadNotifications?: NotificationProps[];
  newNotifications?: NotificationProps[];
  dismissNotification: (id: string) => void;
  dismissAllNotifications: () => void;
  notificationsViewState: NotificationsViewState;
  setNotificationsViewState: React.Dispatch<
    React.SetStateAction<NotificationsViewState>
  >;
}

const NotificationsContext = React.createContext<NotificationsContextValue>({
  fetchNotificationsError: false,
  allNotifications: [],
  notReadNotifications: [],
  newNotifications: [],
  dismissNotification: () => undefined,
  dismissAllNotifications: () => undefined,
  notificationsViewState: NotificationsViewState.NONE,
  setNotificationsViewState: () => undefined,
});

const NotificationsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { user } = React.useContext(SessionContext);
  const userLogin = window.btoa(user?.login ?? "ANONYMOUS_USER"); // base64 window.btoa
  const { data, isError: fetchNotificationsError } = useNotifications();
  const invalidateUserGeneratedReports = useInvalidateUserGeneratedReports();

  const [allNotifications, setAllNotifications] = React.useState<
    NotificationProps[] | undefined
  >();
  const [notReadNotifications, setNotReadNotifications] = React.useState<
    NotificationProps[] | undefined
  >();
  const [newNotifications, setNewNotifications] = React.useState<
    NotificationProps[] | undefined
  >();
  const [notificationsViewState, setNotificationsViewState] =
    React.useState<NotificationsViewState>(NotificationsViewState.NONE);

  const dismissNotification = React.useCallback(
    (id: string) => {
      setNewNotifications((prevState) => prevState?.filter((n) => n.id !== id));
      setNotReadNotifications((prevState) =>
        prevState?.filter((n) => n.id !== id)
      );
      markNotificationAsDismissed(userLogin, id);
    },
    [userLogin]
  );
  const dismissAllNotifications = React.useCallback(() => {
    notReadNotifications?.forEach((n) => {
      markNotificationAsDismissed(userLogin, n.id);
    });
    setNotReadNotifications([]);
    setNewNotifications([]);
  }, [notReadNotifications, userLogin]);

  React.useEffect(() => {
    const loadedNotifications: NotificationProps[] = [];
    const newArr: NotificationProps[] = [];
    const notReadArr: NotificationProps[] = [];
    if (data) {
      if (data.numberOfArsTickets) {
        loadedNotifications.push(
          getArsNotification(data.numberOfArsTickets, t)
        );
      }
      if (data.numberOfOperatingTickets) {
        loadedNotifications.push(
          getOperationalDisturbancesNotification(
            data.numberOfOperatingTickets,
            t
          )
        );
      }
      if (data.doesCustomerLackPlannedWorkContacts) {
        loadedNotifications.push(getPlannedWorksContactNotification(t));
      }
      if (data.loginMessages) {
        loadedNotifications.push(
          ...data.loginMessages.map((loginMessage) =>
            getLoginMessageNotification(loginMessage, t)
          )
        );
      }
      if (data.caseActivities) {
        loadedNotifications.push(
          ...data.caseActivities.map((activity) =>
            getCaseNotification(activity, t)
          )
        );
      }
      if (data.generatedReports) {
        loadedNotifications.push(
          ...data.generatedReports.map((report) =>
            getGeneratedReportsNotifications(report, t)
          )
        );
      }
      const browserWarningNotification = getBrowserWarningNotification(t);
      if (browserWarningNotification) {
        loadedNotifications.push(browserWarningNotification);
      }

      sortNotificationsByLevel(loadedNotifications);

      const notificationsFromStorage = getNotificationsFromStorage(userLogin);
      loadedNotifications.forEach((n) => {
        const storedNotification = notificationsFromStorage[n.id];
        if (storedNotification) {
          if (storedNotification.state === NotificationState.NOT_READ) {
            notReadArr.push(n);
          }
          n.state = storedNotification.state;
        } else {
          notReadArr.push(n);
          newArr.push(n);
        }
      });
      saveNotificationsToStorage(userLogin, loadedNotifications);
    }

    if (
      newArr.find((value) => value.type === NotificationType.GENERATED_REPORT)
    ) {
      invalidateUserGeneratedReports();
    }
    setAllNotifications(loadedNotifications);
    setNotReadNotifications(notReadArr);
    setNewNotifications(newArr);
    if (newArr.length > 0) {
      setNotificationsViewState(NotificationsViewState.NEW);
    }
  }, [data, invalidateUserGeneratedReports, t, userLogin]);
  const contextValue = React.useMemo<NotificationsContextValue>(() => {
    return {
      fetchNotificationsError,
      allNotifications,
      notReadNotifications,
      newNotifications,
      dismissNotification,
      dismissAllNotifications,
      notificationsViewState,
      setNotificationsViewState,
    };
  }, [
    fetchNotificationsError,
    allNotifications,
    dismissAllNotifications,
    dismissNotification,
    newNotifications,
    notificationsViewState,
    notReadNotifications,
  ]);
  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};
export default NotificationsContextProvider;

export const useAppNotifications = (): NotificationsContextValue => {
  return React.useContext(NotificationsContext);
};
