import { Header, margin, padding, Text } from "@tele2/t2-ui-kit";
import styled from "styled-components";

/* to-do: remove when bootstrap is removed */
export const StyledHeader = styled(Header)`
  font-size: 16px;
  font-weight: 400;

  button.t2-btn {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
  }
`;
/* ^ */

export const DetailsWrapper = styled.div`
  padding: 0 ${padding.MD};
  > * {
    margin-top: ${margin.MD};
    margin-bottom: ${margin.MD};
  }
`;

export const DetailsHeader = styled(Text)`
  margin-top: 0;
`;
