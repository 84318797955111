import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface SalesforceChatModel {
  customerServiceLabel: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  contactId: string;
  melodyAccountId: string;
  frankId: string;
  francisId: string;
  spocId: string;
  spocDiscoveryId: string;
  smeRamId: string;
  powerId: string;
  serviceCenterId: string;
  salesforceUrl: string;
  chatUrl: string;
  chatId: string;
  baseLiveAgentContentURL: string;
  deploymentId: string;
  buttonId: string;
  baseLiveAgentURL: string;
  eswLiveAgentDevName: string;
  scriptSource: string;
  enabled: boolean;
}

const getSalesforceChatProps = async () => {
  const { data } = await axios.get<SalesforceChatModel>(
    `/api/contact/customer-service/salesforcechat-model`
  );
  return data;
};

const useGetSalesforceChatProps = (enabled: boolean) => {
  return useQuery<SalesforceChatModel, AxiosError>({
    queryKey: ["salesforceChat"],
    queryFn: getSalesforceChatProps,
    staleTime: Infinity,
    enabled,
  });
};

export default useGetSalesforceChatProps;
