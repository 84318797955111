import { AppRouteProps } from "../types";
import {
  CONST_ROUTE_STATISTICS_BASESUBSID_TOPOLOGY_SUBSID,
  CONST_ROUTE_STATISTICS_CFW_GRAPHS,
  CONST_ROUTE_STATISTICS_HCS,
  CONST_ROUTE_STATISTICS_ISEC_GRAPHS,
  CONST_ROUTE_STATISTICS_NET_GRAPHS,
  CONST_ROUTE_STATISTICS_PROOPTI,
  CONST_ROUTE_STATISTICS_SIMPLE_STATISTICS_VIEW,
  CONST_ROUTE_STATISTICS_SUBSCRIPTION_SLA,
  CONST_ROUTE_STATISTICS_TOPOLOGY,
  CONST_ROUTE_STATISTICS_USAGE_REPORT,
  CONST_ROUTE_STATISTICS_VOICE_REPORT,
  CONST_ROUTE_STATISTICS_VOICE_REPORT_ADD,
  CONST_ROUTE_STATISTICS_VOICE_REPORT_EDIT,
  CONST_ROUTE_STATISTICS_VOIP_GRAPHS,
} from "../approutes";
import * as React from "react";

const CfwGraphs = React.lazy(() => import("./CfwGraphs"));
const IsecGraphs = React.lazy(() => import("./IsecGraphs"));
const Network = React.lazy(() => import("./Network"));
const SimpleStatistics = React.lazy(() => import("./SimpleStatistics"));
const SubscriptionSla = React.lazy(() => import("./SubscriptionSla"));
const Topology = React.lazy(() => import("./Topology"));
const Usage = React.lazy(() => import("./Usage"));
const VoiceReport = React.lazy(() => import("./VoiceReport"));
const VoiceReportAdd = React.lazy(() => import("./VoiceReportAdd"));
const VoipGraphs = React.lazy(() => import("./VoipGraphs"));
const VoiceReportEdit = React.lazy(() => import("./VoiceReportEdit"));
const Hcs = React.lazy(() => import("./Hcs/Hcs"));
const ProOpti = React.lazy(() => import("./ProOpti"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_STATISTICS_NET_GRAPHS,
    component: Network,
  },
  {
    path: CONST_ROUTE_STATISTICS_VOIP_GRAPHS,
    component: VoipGraphs,
  },
  {
    path: CONST_ROUTE_STATISTICS_CFW_GRAPHS,
    component: CfwGraphs,
  },
  {
    path: CONST_ROUTE_STATISTICS_ISEC_GRAPHS,
    component: IsecGraphs,
  },
  {
    path: CONST_ROUTE_STATISTICS_SIMPLE_STATISTICS_VIEW,
    component: SimpleStatistics,
  },
  {
    path: CONST_ROUTE_STATISTICS_SUBSCRIPTION_SLA,
    component: SubscriptionSla,
  },
  {
    path: CONST_ROUTE_STATISTICS_TOPOLOGY,
    component: Topology,
  },
  {
    path: CONST_ROUTE_STATISTICS_BASESUBSID_TOPOLOGY_SUBSID,
    component: Topology,
  },
  {
    path: CONST_ROUTE_STATISTICS_USAGE_REPORT,
    component: Usage,
  },
  {
    path: CONST_ROUTE_STATISTICS_VOICE_REPORT,
    component: VoiceReport,
  },
  {
    path: CONST_ROUTE_STATISTICS_VOICE_REPORT_ADD,
    component: VoiceReportAdd,
  },
  {
    path: CONST_ROUTE_STATISTICS_VOICE_REPORT_EDIT,
    component: VoiceReportEdit,
  },
  {
    path: CONST_ROUTE_STATISTICS_HCS,
    component: Hcs,
  },
  {
    path: CONST_ROUTE_STATISTICS_PROOPTI,
    component: ProOpti,
  },
];

export default routes;
