import { CatalogNode } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import * as React from "react";
import { useCatalog, useNodeKeyState } from "../ProductCatalogContext";
import RenderCategory from "./RenderCategory";

const ProductCatalogTreeView: React.FC = () => {
  const [selectedNode] = useNodeKeyState();

  const [catalog] = useCatalog();

  const array = selectedNode.split("|");

  return catalog?.catalogTree?.subCategories ? (
    <>
      {catalog?.catalogTree?.subCategories.map((item: CatalogNode) => {
        return (
          <RenderCategory
            key={item.key}
            item={item}
            nodeLevel={0}
            showKey={array.slice(0, 0).join("|")}
          />
        );
      })}
    </>
  ) : null;
};

export default ProductCatalogTreeView;
