import {
  CONST_ROUTE_END_USER_DOCUMENTS,
  CONST_ROUTE_END_USER_ORDER_DELIVERY,
  CONST_ROUTE_END_USER_SUBSCRIPTIONS,
} from "../approutes";
import { AppRouteProps } from "../types";
import * as React from "react";

const EndUserDocuments = React.lazy(
  () => import("./Documents/EndUserDocuments")
);
const EndUserOrderDelivery = React.lazy(
  () => import("./OrderDelivery/EndUserOrderDelivery")
);
const EndUserSubscriptions = React.lazy(
  () => import("./Subscriptions/EndUserSubscriptions")
);

const routes: AppRouteProps[] = [
  { path: CONST_ROUTE_END_USER_SUBSCRIPTIONS, component: EndUserSubscriptions },
  {
    path: CONST_ROUTE_END_USER_ORDER_DELIVERY,
    component: EndUserOrderDelivery,
  },
  { path: CONST_ROUTE_END_USER_DOCUMENTS, component: EndUserDocuments },
];
export default routes;
