import useEsalesProduct from "../../../../api/self-service/esales-product/useEsalesProduct";
import { RoleType } from "../../../../api/self-service/types";

import GridItemWrapper from "../../../../components/Product/GridItemWrapper";
import * as React from "react";
import ProductCatalogListItem from "../../ProductCatalog/ProductCatalogList/ProductCatalogListItem";

interface RelatedItem {
  baseSubsId: number;
  vismaProductCode: string | undefined;
}

const RelatedItemWrapper: React.FC<RelatedItem> = ({
  baseSubsId,
  vismaProductCode,
}) => {
  const product = useEsalesProduct(vismaProductCode ?? "", {
    baseSubsId,
    roleType: RoleType.SELF_SERVICE,
  });
  if (!product) return null;
  return (
    <GridItemWrapper>
      <ProductCatalogListItem
        item={product}
        baseSubsId={baseSubsId}
        mode="ADD_FROM_OTHER_PAGE"
      />
    </GridItemWrapper>
  );
};

export default RelatedItemWrapper;
