import * as React from "react";
import { PropsWithChildren } from "react";
import { OpportunityRow } from "../../api/self-service/checkout/useSalesProjectOppRows";
import {
  EditComponentsMode,
  IOpportunityCaseData,
} from "../../api/self-service/edit-components-model/useEditComponentsModel";
import useSaveAttribute, {
  AttributeSaveParams,
  AttributeSaveResult,
} from "../../api/self-service/save-attribute/useSaveAttribute";
import useSetInvoiceRecipient from "../../api/self-service/set-invoice-recipient/useSetInvoiceRecipient";
import { RoleType, SelfServiceMode } from "../../api/self-service/types";
import { AttributeComponent } from "../../api/subscription/subscription-view/useSubscriptionModel";
import { Attribute } from "../AttributeRow/AttributeRow";
import { Types } from "./types";
import { isSameComponent, isSameComponentAttribute } from "./utils";

interface InvoiceRecipientState {
  invoiceRecipient?: { name: string; id: number };
  invoiceRecipientValidationMessage: string;
}

export interface ComponentsContextProps {
  cases?: IOpportunityCaseData[];
  contactsEditable?: boolean;
  detailedPrice?: boolean;
  firstRow?: OpportunityRow;
  mode?: EditComponentsMode;
  oppProductDescriptions?: string[] | null;
  oppRowId?: number;
  oppRowIds: number[];
  opportunityName?: string;
  opportunityTypes?: string | null;
  packageInstanceId?: number;
  productDescription?: string | null;
  quickCase?: boolean;
  referrer?: string | null;
  roleType?: RoleType;
  salesProjectId?: number;
  selfServiceMode?: SelfServiceMode;
  showCaseData?: boolean;
  simulateDraftForSalesProjectId?: boolean;
  subRoleType?: RoleType;
  subsId?: number;
  componentsState: [
    AttributeComponent[],
    React.Dispatch<React.SetStateAction<AttributeComponent[]>>
  ];
  oneFieldForAllProductsState: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  invoiceRecipientState: [
    InvoiceRecipientState,
    React.Dispatch<React.SetStateAction<InvoiceRecipientState>>
  ];
  aliasState: [string, React.Dispatch<React.SetStateAction<string>>];
  overviewAttributesState: [
    Attribute[],
    React.Dispatch<React.SetStateAction<Attribute[]>>
  ];
  componentsType: Types;
  simCardFee?: boolean;
}

const ComponentsContext = React.createContext<ComponentsContextProps>({
  cases: [],
  contactsEditable: false,
  detailedPrice: false,
  firstRow: undefined,
  mode: "NONE",
  oppProductDescriptions: [],
  oppRowId: 0,
  oppRowIds: [],
  opportunityName: "",
  opportunityTypes: "",
  packageInstanceId: 0,
  productDescription: "",
  quickCase: false,
  referrer: "",
  roleType: RoleType.NONE,
  salesProjectId: 0,
  selfServiceMode: SelfServiceMode.DEFAULT,
  showCaseData: false,
  simulateDraftForSalesProjectId: false,
  subRoleType: RoleType.NONE,
  subsId: 0,
  componentsState: [[], () => null],
  oneFieldForAllProductsState: [true, () => null],
  aliasState: ["", () => null],
  invoiceRecipientState: [
    { invoiceRecipientValidationMessage: "" },
    () => null,
  ],
  overviewAttributesState: [[], () => null],
  componentsType: Types.EDIT_COMPONENTS,
  simCardFee: false,
});

interface ComponentsContextProviderProps {
  cases?: IOpportunityCaseData[];
  components: AttributeComponent[];
  contactsEditable?: boolean;
  detailedPrice?: boolean;
  firstRow?: OpportunityRow;
  invoiceRecipient?: { name: string; id: number } | null;
  mode?: EditComponentsMode;
  oneFieldForAllProducts?: boolean;
  oppProductDescriptions?: string[] | null;
  oppRowId?: number;
  opportunityName?: string;
  opportunityTypes?: string | null;
  overViewAttributes?: Attribute[];
  packageInstanceId?: number;
  productDescription?: string | null;
  quickCase?: boolean;
  referrer?: string | null;
  roleType?: RoleType;
  salesProjectId?: number;
  selfServiceMode?: SelfServiceMode;
  showCaseData?: boolean;
  simulateDraftForSalesProjectId?: boolean;
  subRoleType?: RoleType;
  subsId?: number;
  componentsType: Types;
  simCardFee?: boolean;
}

export const useComponents = (): ComponentsContextProps["componentsState"] =>
  React.useContext(ComponentsContext).componentsState;

export const useOneFieldForAllProducts =
  (): ComponentsContextProps["oneFieldForAllProductsState"] =>
    React.useContext(ComponentsContext).oneFieldForAllProductsState;

export const useInvoiceRecipientState =
  (): ComponentsContextProps["invoiceRecipientState"] =>
    React.useContext(ComponentsContext).invoiceRecipientState;

export const useAliasState = (): ComponentsContextProps["aliasState"] =>
  React.useContext(ComponentsContext).aliasState;

export const useOverviewAttributesState =
  (): ComponentsContextProps["overviewAttributesState"] =>
    React.useContext(ComponentsContext).overviewAttributesState;

// Static values
export const useComponentsAttributes = () => {
  const componentsContextProps = React.useContext(ComponentsContext);
  return React.useMemo(
    () => ({
      cases: componentsContextProps.cases,
      contactsEditable: componentsContextProps.contactsEditable || false,
      detailedPrice: componentsContextProps.detailedPrice || false,
      firstRow: componentsContextProps.firstRow,
      mode: componentsContextProps.mode || "NONE",
      oneFieldForAllProducts:
        componentsContextProps.oneFieldForAllProductsState[0],
      oppProductDescriptions: componentsContextProps.oppProductDescriptions,
      oppRowId: componentsContextProps.oppRowId || 0,
      oppRowIds: componentsContextProps.oppRowIds || [],
      opportunityName: componentsContextProps.opportunityName,
      opportunityTypes: componentsContextProps.opportunityTypes,
      packageInstanceId: componentsContextProps.packageInstanceId || 0,
      productDescription: componentsContextProps.productDescription,
      quickCase: componentsContextProps.quickCase,
      referrer: componentsContextProps.referrer || "",
      roleType: componentsContextProps.roleType || RoleType.NONE,
      salesProjectId: componentsContextProps.salesProjectId || 0,
      selfServiceMode:
        componentsContextProps.selfServiceMode || SelfServiceMode.DEFAULT,
      showCaseData: componentsContextProps.showCaseData || false,
      simulateDraftForSalesProjectId:
        componentsContextProps.simulateDraftForSalesProjectId || false,
      subRoleType: componentsContextProps.subRoleType || RoleType.NONE,
      componentsType: componentsContextProps.componentsType,
      simCardFee: componentsContextProps.simCardFee,
    }),
    [
      componentsContextProps.cases,
      componentsContextProps.componentsType,
      componentsContextProps.contactsEditable,
      componentsContextProps.detailedPrice,
      componentsContextProps.firstRow,
      componentsContextProps.mode,
      componentsContextProps.oneFieldForAllProductsState,
      componentsContextProps.oppProductDescriptions,
      componentsContextProps.oppRowId,
      componentsContextProps.oppRowIds,
      componentsContextProps.opportunityName,
      componentsContextProps.opportunityTypes,
      componentsContextProps.packageInstanceId,
      componentsContextProps.productDescription,
      componentsContextProps.quickCase,
      componentsContextProps.referrer,
      componentsContextProps.roleType,
      componentsContextProps.salesProjectId,
      componentsContextProps.selfServiceMode,
      componentsContextProps.showCaseData,
      componentsContextProps.simCardFee,
      componentsContextProps.simulateDraftForSalesProjectId,
      componentsContextProps.subRoleType,
    ]
  );
};

const Provider: React.FC<PropsWithChildren<ComponentsContextProviderProps>> = ({
  children,
  componentsType,
  invoiceRecipient,
  overViewAttributes,
  components,
  oneFieldForAllProducts,
  ...componentsModel
}) => {
  const aliasState = React.useState(
    componentsModel.firstRow?.subscription?.alias || ""
  );
  const invoiceRecipientState = React.useState<InvoiceRecipientState>({
    invoiceRecipient: invoiceRecipient || undefined,
    invoiceRecipientValidationMessage: "",
  });
  const componentsState = React.useState<AttributeComponent[]>(components);
  const oneFieldForAllProductsState = React.useState<boolean>(
    oneFieldForAllProducts || false
  );
  const overviewAttributesState = React.useState<Attribute[]>(
    overViewAttributes || []
  );
  const [oppRowIds] = React.useState(
    Array.from(new Set(components.map((c) => c.subsId)))
  );
  validateComponentsModel({
    oppRowId: componentsModel.oppRowId,
    componentsType,
  });
  /**
   * Some of the logic works only due to the context value not being memoized.
   * If that happens in the future we will have to make sure all changes to states also triggers rerenders.
   */
  const contextValue = React.useMemo(() => {
    return {
      ...componentsModel,
      componentsType,
      componentsState,
      oneFieldForAllProductsState,
      invoiceRecipientState,
      aliasState,
      overviewAttributesState,
      oppRowIds,
    };
  }, [
    aliasState,
    componentsModel,
    componentsState,
    componentsType,
    invoiceRecipientState,
    oneFieldForAllProductsState,
    oppRowIds,
    overviewAttributesState,
  ]);
  return (
    <ComponentsContext.Provider value={contextValue}>
      {children}
    </ComponentsContext.Provider>
  );
};
export default Provider;

const validateComponentsModel = (componentsModel: {
  componentsType: Types;
  oppRowId?: number;
}) => {
  switch (componentsModel.componentsType) {
    case Types.BATCH_UPDATE:
    case Types.SERVICE_REQUEST:
    case Types.EDIT_COMPONENTS:
      if (!componentsModel.oppRowId) {
        console.error("No opp row id!");
      }
      return;
    case Types.SUBSCRIPTION_VIEW:
    default:
      return;
  }
};

export const useUpdateComponent = () => {
  const [components, setComponents] = useComponents();
  return React.useCallback(
    (component: AttributeComponent) => {
      setComponents(
        JSON.parse(
          JSON.stringify(
            components.map((c) =>
              isSameComponent(c, component) ? component : c
            )
          )
        )
      );
    },
    [components, setComponents]
  );
};

export const useUpdateComponents = () => {
  const { packageInstanceId } = useComponentsAttributes();
  const [components, setComponents] = useComponents();
  return React.useCallback(
    (comps: AttributeComponent[]) => {
      if (packageInstanceId > 0) {
        // For package products, only one component is returned on attribute save when the hash changes
        // so only update the components that are returned. Hopefully there will not be a case where
        // a component is added after edit.
        const updatedComponents = components.map((c) => {
          const component = comps.find((comp) => isSameComponent(comp, c));
          if (component) {
            return component;
          }
          return c;
        });
        setComponents(JSON.parse(JSON.stringify(updatedComponents)));
      } else {
        // For other products and especially KST-requests components can be added when an opp-row is edited.
        setComponents(JSON.parse(JSON.stringify(comps)));
      }
    },
    [components, packageInstanceId, setComponents]
  );
};

export const useGetComponent = () => {
  const [components] = useComponents();
  return React.useCallback(
    (subsId: number, prodCompId: number) => {
      const component = components.find((comp) =>
        isSameComponent(comp, { subsId, prodCompId })
      );
      // A component should always be found. Throw error if not.
      return component;
    },
    [components]
  );
};

export const useUpdateAllInvoiceRecipients = () => {
  const [components, setComponents] = useComponents();
  const { oppRowId, subRoleType, packageInstanceId, salesProjectId } =
    useComponentsAttributes();
  const { setInvoiceRecipient } = useSetInvoiceRecipient();
  // TODO does all components have an IVR?
  return React.useCallback(
    async (invoiceRecipient?: { id: number; name: string }) => {
      for (const c of components) {
        c.invoiceRecipient = invoiceRecipient || null;
        // Only save slave fields
        if (c.subsId && c.subsId !== oppRowId) {
          await setInvoiceRecipient({
            oppRowIds: c.subsId,
            invoiceRecipientId: invoiceRecipient?.id,
            subRoleType,
            packageInstanceId,
            salesProjectId,
          });
        }
      }
      setComponents(JSON.parse(JSON.stringify(components)));
    },
    [
      components,
      setInvoiceRecipient,
      oppRowId,
      packageInstanceId,
      salesProjectId,
      setComponents,
      subRoleType,
    ]
  );
};

export const useUpdateAllSlaveAttributes = (label: string) => {
  const { saveAttribute } = useSaveAttribute(label);
  const [components] = useComponents();
  return React.useCallback(
    async (params: AttributeSaveParams) => {
      for (const c of components) {
        // Find the same type of attribute. It is however not the same attribute since it belongs to another product.
        const attribute = c.attributes.find(
          (a) => a.compAttrId === params.compAttrId
        );
        if (attribute) {
          // Only save slave fields
          if (c.subsId && !isSameComponent(c, params)) {
            // Make sure that the subsId and prodCompAttrId is overridden.
            await saveAttribute({
              ...params,
              subsId: c.subsId,
              prodCompAttrId: attribute.prodCompAttrId,
            });
          }
        }
      }
    },
    [components, saveAttribute]
  );
};

export const useUpdateOverviewAttribute = () => {
  const [overviewAttributes, setOverviewAttributes] =
    useOverviewAttributesState();
  return React.useCallback(
    (
      subsId: number,
      prodCompId: number,
      prodCompAttrId: number,
      saveResult: AttributeSaveResult
    ) => {
      const component = saveResult.components?.find((c) =>
        isSameComponent(c, { subsId, prodCompId })
      );
      if (component) {
        const attribute = component.attributes.find((a) =>
          isSameComponentAttribute(a, { prodCompAttrId })
        );
        if (attribute) {
          const attr = overviewAttributes.find(
            (a) => a.prodCompAttrId === attribute.prodCompAttrId
          );
          if (attr) {
            attr.attrData = attribute.attrData;
            attr.validationCode = attribute.validationCode;
            attr.validationMessage = attribute.validationMessage;
            setOverviewAttributes(
              JSON.parse(JSON.stringify(overviewAttributes))
            );
          } else {
            throw new Error(
              "Could not find overview attribute when updating overview attribute"
            );
          }
        } else {
          throw new Error(
            "Could not find attribute when updating overview attribute"
          );
        }
      } else {
        throw new Error(
          "Could not find component when updating overview attribute"
        );
      }
    },
    [overviewAttributes, setOverviewAttributes]
  );
};
