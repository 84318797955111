import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { useComponentsAttributes } from "../../../components/component/ComponentsContext";
import { useInvalidateCartOppRows } from "../checkout/useCartOppRows";
import { RoleType } from "../types";

interface SaveInvoiceRecipientRequest {
  invoiceRecipientId?: number;
  oppRowIds: number;
  packageInstanceId?: number;
  salesProjectId: number;
  subRoleType?: RoleType;
}

export interface InvoiceRecipientSaveResult {
  saved: boolean;
  value: string;
  message: string;
}

const saveInvoiceRecipient = async (request: SaveInvoiceRecipientRequest) => {
  const { data } = await axios.post<InvoiceRecipientSaveResult>(
    "/api/self-service/set-invoice-recipient",
    null,
    { params: request }
  );
  return data;
};

const useSetInvoiceRecipient = () => {
  const { packageInstanceId, subRoleType } = useComponentsAttributes();
  const invalidatePostOppRowsGet = useInvalidateCartOppRows();
  const { mutateAsync, isPending } = useMutation<
    InvoiceRecipientSaveResult,
    AxiosError,
    SaveInvoiceRecipientRequest
  >({
    mutationFn: (request: SaveInvoiceRecipientRequest) =>
      saveInvoiceRecipient({
        subRoleType,
        packageInstanceId,
        ...request,
      }),
  });
  const setInvoiceRecipient = useCallback(
    async (request: SaveInvoiceRecipientRequest) => {
      const data = await mutateAsync(request);
      invalidatePostOppRowsGet();
      return data;
    },
    [invalidatePostOppRowsGet, mutateAsync]
  );
  return { setInvoiceRecipient, isPending };
};

export default useSetInvoiceRecipient;
