import { Button, color, useIsMobile } from "@tele2/t2-ui-kit";
import Tune from "@tele2/t2-ui-kit/build/Icon/icons/Tune";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CONST_URL_SELF_SERVICE_CHECKOUT_CART } from "../../../approutes";
import FilterModal from "../Filter/FilterModal";
import { useNodeKeyState } from "../ProductCatalogContext";
import SelectedNodePath from "./SelectedNodePath";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const RightColumnHeaderWrapper = styled.div`
  flex: 0 0 75%;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  justify-content: flex-start;
`;
export const RightColumnFlexWrapper = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? `column` : `row`)};
  justify-content: space-between;
  width: 100%;
`;
export const FullWidthDiv = styled.div`
  width: 100%;
`;

export const FilterButtonText = styled.span`
  display: flex;
`;

export const StyledFilterButton = styled(Button)`
  width: 100%;
  background-color: ${color.BRAND_WHITE} !important;
  border-color: ${color.BRAND_PREMIUM_GREY} !important;
  color: ${color.BRAND_BLACK} !important;
  padding: 6px 0 0 0 !important;
`;

const RightColumnHeader: React.FC<{
  count: number;
  disabled: boolean;
  salesProjectId: number | undefined;
}> = ({ count, disabled, salesProjectId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [nodeKey] = useNodeKeyState();
  const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);

  return (
    <RightColumnHeaderWrapper>
      <RightColumnFlexWrapper $isMobile={isMobile}>
        {isMobile ? (
          <>
            <h6>{t("module.self.service.showing.products", [count])}</h6>
            <StyledFilterButton onClick={() => setShowFilterModal(true)} xxs>
              <FilterButtonText>
                <Tune />
                {` `}
                {t("general.filter")}
              </FilterButtonText>
            </StyledFilterButton>
          </>
        ) : (
          <>
            <FullWidthDiv>
              <RightColumnFlexWrapper $isMobile={isMobile}>
                <h3>{t("module.self.service.showing.products", [count])}</h3>
                {!disabled && (
                  <Button
                    data-cy={"go-to-cart-button"}
                    xs
                    onClick={() => {
                      navigate(
                        `${CONST_URL_SELF_SERVICE_CHECKOUT_CART}?salesProjectId=${salesProjectId}`
                      );
                    }}
                  >
                    {t("module.self.service.go.to.cart")}
                  </Button>
                )}
              </RightColumnFlexWrapper>
              {nodeKey && <SelectedNodePath />}
            </FullWidthDiv>
          </>
        )}
        {showFilterModal && (
          <FilterModal onClose={() => setShowFilterModal(false)} />
        )}
      </RightColumnFlexWrapper>
    </RightColumnHeaderWrapper>
  );
};
export default RightColumnHeader;
