import * as React from "react";
import { PropsWithChildren, useCallback } from "react";

type HeaderTypes = {
  backgroundImage?: string;
  breadcrumb?: React.ReactNode;
  content?: React.ReactNode;
  subHeader?: React.ReactNode;
  solidBackground?: boolean;
  title?: string;
};

export type HeaderContextType = {
  header: HeaderTypes;
  clearHeader: () => void;
  updateHeader: (props: HeaderTypes) => void;
};

const defaultHeader = {
  backgroundImage: undefined,
  breadcrumb: undefined,
  content: undefined,
  subHeader: undefined,
  solidHeader: true,
  title: undefined,
};

export const HeaderContext = React.createContext<HeaderContextType>({
  header: defaultHeader,
  clearHeader: () => null,
  updateHeader: () => null,
});

export const useHeader = (): HeaderContextType => {
  return React.useContext(HeaderContext);
};

export const HeaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [header, setHeader] = React.useState<HeaderTypes>(defaultHeader);
  const updateHeader = useCallback((newHeader: HeaderTypes) => {
    setHeader((previous) => {
      return {
        ...previous,
        ...newHeader,
      };
    });
  }, []);
  const clearHeader = useCallback(() => {
    setHeader(defaultHeader);
  }, []);
  return (
    <HeaderContext.Provider
      value={{
        header,
        clearHeader,
        updateHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
