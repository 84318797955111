import * as React from "react";
import { InfoMessage } from "@tele2/t2-ui-kit";
import { MessageType } from "./message-types";
import { useTranslation } from "react-i18next";

const MessageItem: React.FC<
  MessageType & {
    setMessageQueue: React.Dispatch<React.SetStateAction<MessageType[]>>;
  }
> = ({
  dismissable,
  dismissButtonText,
  id,
  interval,
  message,
  onDismiss,
  setMessageQueue,
  ...rest
}) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    if (interval) {
      const removeMessageTimer = setTimeout(() => {
        setMessageQueue((oldQueue) => oldQueue.filter((m) => m.id !== id));
      }, interval);
      return () => {
        clearInterval(removeMessageTimer);
      };
    }
    return;
  }, [id, interval, setMessageQueue]);
  const handleDismiss = (e: React.MouseEvent) => {
    if (onDismiss) {
      onDismiss(e);
    }
    setMessageQueue((oldQueue) => oldQueue.filter((m) => m.id !== id));
  };

  const isDismissable = dismissable || dismissButtonText || onDismiss;

  const buttonText = dismissButtonText ? dismissButtonText : t("general.close");
  return (
    <InfoMessage {...rest}>
      <span>{message}</span>
      {isDismissable && (
        <button
          onClick={handleDismiss}
          className="t2-btn t2-ghost t2-highlight t2-xs"
        >
          {buttonText}
        </button>
      )}
    </InfoMessage>
  );
};

export default MessageItem;
