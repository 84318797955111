import { Button } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OwnershipRequest } from "../../../api/self-service/ownership-request/useGetOwnershipRequest";
import usePostOwnershipRequest from "../../../api/self-service/ownership-request/usePostOwnershipRequest";
import { useMessage } from "../../../components/Message/Message";
import ModalFooter from "../../../components/modal/ModalFooter";
import Modal from "../../../components/modal/ReactModal";
import OwnershipRequestForm from "./components/OwnershipRequestForm";

interface CreateMsisdnMandateModalProps {
  title: string;
  onClose?: () => void;
}
const CreateOwnershipRequestModal: React.FC<CreateMsisdnMandateModalProps> = ({
  title,
  onClose,
}) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = useForm<OwnershipRequest>({
    mode: "onChange",
    defaultValues: {
      lang: document.documentElement.lang || navigator.language,
    },
  });
  const message = useMessage();
  const { t } = useTranslation();
  const { createOwnershipRequest, isPending } = usePostOwnershipRequest();

  return (
    <Modal title={title} onClose={onClose} centered size={"lg"}>
      <OwnershipRequestForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        trigger={trigger}
        errors={errors}
      />
      <ModalFooter>
        <Button
          xxs
          disabled={!isValid || isPending}
          onClick={
            onClose &&
            handleSubmit(
              async (request) => {
                await createOwnershipRequest(request);
                onClose?.();
              },
              () =>
                message({
                  message: t("general.invalid.form"),
                  theme: "alert",
                  interval: 5000,
                })
            )
          }
        >
          {t("general.create")}
        </Button>
        <Button secondary onClick={onClose} xxs>
          {t("general.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default CreateOwnershipRequestModal;
