import { Card, HeaderButton, PopupManager, TextInput } from "@tele2/t2-ui-kit";
import SearchIcon from "@tele2/t2-ui-kit/build/Icon/icons/Search";
import { useModuleReadRight } from "../../api/session/useModuleRight";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import onEnter from "../../utils/onEnter";
import { useTranslation } from "react-i18next";

const SearchButton: React.FC = () => {
  const { t } = useTranslation();
  const canSearch = useModuleReadRight("SEARCH");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  return canSearch ? (
    <PopupManager
      align="right"
      element={() => (
        <HeaderButton role={"button"} aria-label={t("general.search")}>
          <SearchIcon />
        </HeaderButton>
      )}
    >
      {(onClose) => (
        <Card padding="none">
          <TextInput
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            autoFocus
            label=""
            placeholder={t("general.search")}
            isSearch
            onKeyDown={onEnter(() => {
              navigate(`/home/search?q=${encodeURIComponent(search)}`);
              onClose?.();
              setSearch("");
            })}
          />
        </Card>
      )}
    </PopupManager>
  ) : null;
};
export default SearchButton;
