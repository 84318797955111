import NotificationCard from "./NotificationCard";
import * as React from "react";
import styled from "styled-components";
import { NotificationProps } from "../../../NotificationsContext/notification-utils";

const NotificationsList: React.FC<{
  notifications?: NotificationProps[];
}> = ({ notifications }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [fade, setFade] = React.useState(false);
  React.useEffect(() => {
    // Checking length to trigger useEffect
    if (Number(notifications?.length) > 0) {
      const clientHeight = ref?.current?.clientHeight || 0;
      const scrollHeight = ref?.current?.scrollHeight || 0;
      setFade(scrollHeight - 20 > clientHeight);
    }
  }, [notifications?.length]);
  const handleScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (
        e.currentTarget.scrollTop >
        e.currentTarget.scrollHeight - e.currentTarget.offsetHeight - 20
      ) {
        setFade(false);
      } else if (!fade) {
        setFade(true);
      }
    },
    [fade]
  );
  return (
    <NotificationsWrapper
      ref={ref}
      $fade={fade}
      data-testid="notification-wrapper"
      onScroll={(e) => handleScroll(e)}
    >
      {notifications &&
        notifications.map((cardProps, i) => (
          <NotificationCard {...cardProps} key={i} delay={0} />
        ))}
    </NotificationsWrapper>
  );
};
export default NotificationsList;

const NotificationsWrapper = styled.div<{ $fade: boolean }>`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 16px;
  padding-right: 16px;
  --mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 100%
    )
    0% 0% / 100% 100%;

  ${(props) =>
    props.$fade
      ? `
          mask: var(--mask);
    `
      : ""}
`;
