import { ProductItem } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import * as React from "react";
import { useUrlParams } from "../../../../utils/useUrlParams";
import RelatedItemWrapper from "./RelatedItemWrapper";
import { ProductGridWrapper } from "../../../../components/Product";

const RelatedPane: React.FC<{
  product: ProductItem;
}> = ({ product }) => {
  const baseSubsId = Number(useUrlParams("baseSubsId") || 0);
  return (
    <ProductGridWrapper>
      {product &&
        product.relatedProductCatalogItems?.map((item, i) => (
          <RelatedItemWrapper
            key={i}
            vismaProductCode={item.productCode}
            baseSubsId={baseSubsId}
          />
        ))}
    </ProductGridWrapper>
  );
};

export default RelatedPane;
