import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

const cancel = async (salesProjectId: number) => {
  await axios.post("/api/self-service/cancel-project", null, {
    params: { salesProjectId },
  });
};

const useCancelProject = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation<void, AxiosError, number>({
    mutationFn: cancel,
  });
  const cancelProject = useCallback(
    async (salesProjectId: number) => {
      await mutateAsync(salesProjectId);
      invalidateCheckoutData();
    },
    [invalidateCheckoutData, mutateAsync]
  );
  return { cancelProject, isPending };
};

export default useCancelProject;
