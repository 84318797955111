import {
  CONST_ROUTE_KST_CHOOSE_SERVICE_REQUEST,
  CONST_ROUTE_KST_SERVICE_REQUEST,
  CONST_ROUTE_KST_TICKETS_LIST,
} from "../approutes";
import { AppRouteProps } from "../types";
import * as React from "react";

const ChooseServiceRequest = React.lazy(() => import("./ChooseServiceRequest"));
const ServiceRequest = React.lazy(
  () => import("./ServiceRequest/ServiceRequest")
);
const List = React.lazy(() => import("./Tickets/KstTicketsList"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_KST_CHOOSE_SERVICE_REQUEST,
    component: ChooseServiceRequest,
  },
  {
    path: CONST_ROUTE_KST_TICKETS_LIST,
    component: List,
  },
  {
    path: CONST_ROUTE_KST_SERVICE_REQUEST,
    component: ServiceRequest,
  },
];
export default routes;
