import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { Attribute } from "../../../components/AttributeRow/AttributeRow";
import {
  useGetComponent,
  useUpdateComponent,
} from "../../../components/component/ComponentsContext";
import { isSameComponentAttribute } from "../../../components/component/utils";

interface CreateMatrixRowResult {
  id: number;
  subsId: number;
  prodCompAttrId: number;
  cells: { [compAttrId: number]: string };
}

interface CreateMatrixRowRequest {
  prodCompId: number;
  prodCompAttrId: number;
  subsId: number;
}

const post = async (request: CreateMatrixRowRequest) => {
  const { data } = await axios.post<CreateMatrixRowResult>(
    "/api/self-service/create-matrix-row",
    null,
    {
      params: {
        prodCompAttrId: request.prodCompAttrId,
        subsId: request.subsId,
      },
    }
  );
  return data;
};

const useCreateMatrixRow = (columns: Attribute[]) => {
  const getComponent = useGetComponent();
  const updateComponent = useUpdateComponent();

  const { mutateAsync, isPending } = useMutation<
    CreateMatrixRowResult,
    AxiosError,
    CreateMatrixRowRequest
  >({ mutationFn: post });
  const createMatrixRow = useCallback(
    async (request: CreateMatrixRowRequest) => {
      const data = await mutateAsync(request);

      const component = getComponent(request.subsId, request.prodCompId);
      if (component) {
        const attribute = component.attributes.find((a) =>
          isSameComponentAttribute(a, data)
        );
        if (attribute) {
          const cells: Attribute[] = [];
          columns.forEach((column) => {
            const newColumn = JSON.parse(JSON.stringify(column)) as Attribute;
            const cellValue = data.cells[column.compAttrId];
            if (cellValue) {
              newColumn.attrData = cellValue;
            }
            cells.push(newColumn);
          });
          attribute.rows?.push({
            id: data.id,
            prodCompAttrId: data.prodCompAttrId,
            subsId: data.subsId,
            cells: cells,
          });
        } else {
          throw new Error("Could not find attribute when creating matrix row");
        }
        updateComponent(component);
      } else {
        throw new Error("Could not find component");
      }
    },
    [columns, getComponent, mutateAsync, updateComponent]
  );

  return { createMatrixRow, isPending };
};

export default useCreateMatrixRow;
