import * as React from "react";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export type FormSelectOption = {
  value: string | number;
  textKey?: string;
  text?: string;
};

interface FormSelectProps {
  className?: string;
  data: FormSelectOption[];
  id?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string | number;
  label: string;
  hide?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  data,
  label,
  hide,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const selectClassName = className ? className : "form-control";
  return !hide ? (
    <div className="form-group">
      <label>{t(label)}</label>
      <select {...props} className={selectClassName}>
        {data.map(({ value, text, textKey }, i) => (
          <option key={i} value={value}>
            {text ? text : textKey ? t(textKey) : ""}
          </option>
        ))}
      </select>
    </div>
  ) : null;
};
export default FormSelect;
