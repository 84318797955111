import * as React from "react";
import {
  CONST_ROUTE_ADMIN_END_USER_MODULES,
  CONST_ROUTE_ADMIN_GROUP,
  CONST_ROUTE_ADMIN_GROUPS,
  CONST_ROUTE_ADMIN_NEW_USER,
  CONST_ROUTE_ADMIN_USER,
  CONST_ROUTE_ADMIN_USER_LOG,
  CONST_ROUTE_ADMIN_USERS,
} from "../approutes";
import { AppRouteProps } from "../types";

const UserLog = React.lazy(() => import("./UserLog/UserLog"));
const Groups = React.lazy(() => import("./Groups"));
const EndUserModules = React.lazy(() => import("./EndUserModules"));
const Users = React.lazy(() => import("./Users"));
const User = React.lazy(() => import("./User"));
const NewUser = React.lazy(() => import("./NewUser"));
const Group = React.lazy(() => import("./Group"));

const routes: AppRouteProps[] = [
  {
    path: CONST_ROUTE_ADMIN_END_USER_MODULES,
    component: EndUserModules,
  },
  {
    path: CONST_ROUTE_ADMIN_USERS,
    component: Users,
  },
  {
    path: CONST_ROUTE_ADMIN_USER,
    component: User,
  },
  {
    path: CONST_ROUTE_ADMIN_NEW_USER,
    component: NewUser,
  },
  { path: CONST_ROUTE_ADMIN_USER_LOG, component: UserLog },
  {
    path: CONST_ROUTE_ADMIN_GROUPS,
    component: Groups,
  },
  {
    path: CONST_ROUTE_ADMIN_GROUP,
    component: Group,
  },
];
export default routes;
