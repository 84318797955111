import { color, padding } from "@tele2/t2-ui-kit";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGATracking } from "../../GAContext";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { LayoutProps } from "./layout-types";

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  backgroundColor,
  breadcrumbs,
  children,
  maxWidth = 1176,
  title,
  subTitle,
  translate = true,
  navbar,
}) => {
  const { t } = useTranslation();
  const pageTitle = translate && title ? t(title) : title;
  useGATracking(pageTitle);
  React.useEffect(() => {
    if (pageTitle) {
      document.title = `${pageTitle} - ${t("header.title")}`;
    }
  }, [pageTitle, t]);

  return (
    <>
      <Breadcrumbs
        list={breadcrumbs}
        title={title}
        subTitle={subTitle}
        $maxWidth={maxWidth}
        translate={translate}
      />
      {navbar}
      <Background $backgroundColor={backgroundColor}>
        <ContentWidth $maxWidth={maxWidth}>
          <ContentPadding>{children}</ContentPadding>
        </ContentWidth>
      </Background>
    </>
  );
};

interface ContentWidthProps {
  $maxWidth?: "none" | number;
}

const ContentPadding = styled.div`
  padding: ${padding.XL};
`;

export const ContentWidth = styled.div`
  margin: 0 auto;
  ${(props: ContentWidthProps) =>
    props.$maxWidth &&
    `
    max-width: ${
      props.$maxWidth === "none" ? props.$maxWidth : `${props.$maxWidth}px`
    };
  `}
  width: 100%;
  flex: 1;
`;

export default Layout;

const Background = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : color.BRAND_WHITE};
  flex: 1;
`;
