import Image from "@tele2/t2-ui-kit/build/Icon/icons/Image";
import { ProductType } from "../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import { ImageWrapper, StyledImage } from "./index";
import * as React from "react";
import { Link } from "react-router-dom";
import { useProductLink } from "../../Routes/SelfService/ProductCatalog/ProductCatalogContext";
import styled from "styled-components";

export const FlexGrowWrapper = styled.div`
  flex-grow: 1;
`;
const ImageContainer: React.FC<{
  prodImgPublicId: string;
  prodName: string;
  productCode: string;
  type: ProductType;
  baseSubsId: number;
  pictureUrl: string;
  pictureUrls: string[];
}> = ({
  prodImgPublicId,
  prodName,
  productCode,
  type,
  baseSubsId,
  pictureUrl,
  pictureUrls,
}) => {
  const productLink = useProductLink();
  return (
    <FlexGrowWrapper>
      {prodImgPublicId ? (
        <ImageWrapper>
          <StyledImage
            src={`/api/self-service/opportunity-image/${prodImgPublicId}`}
            alt={prodName}
          />
        </ImageWrapper>
      ) : type === ProductType.CATALOG ? (
        <Link
          to={`${productLink}?prodId=${encodeURIComponent(
            productCode
          )}&baseSubsId=${baseSubsId}`}
        >
          <ImageWrapper>
            {pictureUrls?.[0] ? (
              <StyledImage src={pictureUrls[0]} alt={prodName} />
            ) : pictureUrl ? (
              <StyledImage src={pictureUrl} alt={prodName} />
            ) : (
              <Image color="BRAND_GREY" className="not-found" />
            )}
          </ImageWrapper>
        </Link>
      ) : (
        <ImageWrapper>
          <Image color="BRAND_GREY" className="not-found" />
        </ImageWrapper>
      )}
    </FlexGrowWrapper>
  );
};
export default ImageContainer;
