import * as React from "react";
import styled from "styled-components";
import NoStyleButton from "../Buttons/NoStyleButton";

interface CollapsibleProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title: React.ReactNode | ((show: boolean) => React.ReactNode);
  defaultHidden?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  defaultHidden,
  children,
}) => {
  const [show, setShow] = React.useState(defaultHidden !== true);
  return (
    <div>
      <CollapsibleButton onClick={() => setShow(!show)}>
        {typeof title === "function" ? title(show) : title}
      </CollapsibleButton>
      {show ? children : null}
    </div>
  );
};

export default Collapsible;

const CollapsibleButton = styled(NoStyleButton)`
  text-align: start;
  width: 100%;
`;
