import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { Attribute } from "../../../components/AttributeRow/AttributeRow";
import { ProductAttachment } from "../../documents/product-documents/useProductDocuments";
import { AttributeComponent } from "../../subscription/subscription-view/useSubscriptionModel";
import { OpportunityRow } from "../checkout/useSalesProjectOppRows";
import { RoleType, SelfServiceMode } from "../types";

export interface ComponentsModel {
  alias: string | null;
  attachments: ProductAttachment[] | null;
  cases: IOpportunityCaseData[];
  components: AttributeComponent[];
  componentsHash: string | null;
  contactsEditable: boolean;
  detailedPrice: boolean;
  firstRow: OpportunityRow;
  invoiceRecipient?: { name: string; id: number } | null;
  invoiceRecipientId: number;
  mode: EditComponentsMode;
  moreOpportunityTypes: boolean;
  oneFieldForAllProducts: boolean;
  oppProductDescriptions: string[] | null;
  oppRowId: number;
  opportunityName: string;
  opportunityTypes: string | null;
  overViewAttributes: Attribute[];
  packageInstanceId: number;
  packageProduct: boolean;
  productDescription: string | null;
  quickCase: boolean;
  referrer: string | null;
  roleType: RoleType;
  rows: OpportunityRow[];
  salesProjectId: number;
  selfServiceMode: SelfServiceMode;
  showCart: boolean;
  showCaseData: boolean;
  showSubscriptionLink: boolean;
  simulateDraftForSalesProjectId: boolean;
  subRoleType: RoleType;
  subsId?: number;
  simCardFee: boolean;
}

export interface IOpportunityCaseData {
  id: number;
  label: string;
  name: string;
  value: string | null;
  type: OpportunityCaseType;
  size: number;
  help: string | null;
  required: boolean;
  readOnly: boolean;
  validationMessage: string | null;
}

type OpportunityCaseType =
  | "TEXT"
  | "CHECK_BOX"
  | "TEXT_AREA"
  | "FLOAT"
  | "DATE"
  | "TIME";

export type EditComponentsMode =
  | "ADD_FROM_OTHER_PAGE"
  | "EDIT_FROM_OTHER_PAGE"
  | "QUICK_CASE_FROM_OTHER_PAGE"
  | "QUICK_CASE_FROM_END_USER"
  | "ADD_FROM_END_USER"
  | "EDIT_FROM_END_USER"
  | "ADD_FROM_CART"
  | "EDIT_FROM_CART"
  | "NONE";

export const END_USER_MODES = [
  "QUICK_CASE_FROM_END_USER",
  "ADD_FROM_END_USER",
  "EDIT_FROM_END_USER",
];

export interface EditComponentsModelRequest {
  salesProjectId: number;
  oppRowId: number;
  packageInstanceId?: number;
  referrer?: string;
  mode: EditComponentsMode;
}

const getEditComponentsModel = async (request: EditComponentsModelRequest) => {
  const { data } = await axios.get<ComponentsModel>(
    "/api/self-service/edit-components-model",
    { params: request }
  );
  return data;
};

const QUERY_KEY = "edit-components-model";

const useEditComponentsModel = (request: EditComponentsModelRequest) => {
  return useSuspenseQuery<ComponentsModel, AxiosError>({
    queryKey: getQueryKey(request),
    queryFn: () => getEditComponentsModel(request),
  });
};
export default useEditComponentsModel;

export const useRemoveEditComponentsModel = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (request: EditComponentsModelRequest) => {
      queryClient.removeQueries({
        queryKey: getQueryKey(request),
        exact: true,
      });
    },
    [queryClient]
  );
};

const getQueryKey = (request: EditComponentsModelRequest) => {
  return [
    QUERY_KEY,
    request.mode,
    request.packageInstanceId,
    request.salesProjectId,
    request.oppRowId,
  ];
};
