import { PaginateState } from "../../utils/usePaginateData";

type Page = {
  offset?: number;
  text: string;
  isActive?: boolean;
};

const getPages = (
  { limit, offset, total }: PaginateState,
  isSmallDevice?: boolean
): Page[] => {
  const maxPages = isSmallDevice ? 5 : 7;
  const middleIndexStart = isSmallDevice ? 2 : 3;
  const numPages = Math.ceil(total / limit);
  const activeIndex = Math.floor(offset / limit); // page - 1
  if (numPages <= maxPages) {
    return Array(numPages)
      .fill("")
      .map((_, i) => ({
        offset: i * limit,
        text: String(i + 1),
        isActive: i === activeIndex,
      }));
  } else if (activeIndex < maxPages - 3) {
    // < [1, 2, 3, aI,  ...] numPages >
    return Array(maxPages)
      .fill("")
      .map((_, i) => {
        if (i === maxPages - 2) {
          return {
            text: "...",
          };
        } else if (i === maxPages - 1) {
          return {
            offset: (numPages - 1) * limit,
            text: String(numPages),
          };
        } else {
          return {
            offset: i * limit,
            text: String(i + 1),
            isActive: i === activeIndex,
          };
        }
      });
  } else if (activeIndex >= numPages - (maxPages - 3)) {
    // < 1 [..., aI, numPages - 2,numPages- 1 numPages] >
    return Array(maxPages)
      .fill(numPages - maxPages)
      .map((a, i) => {
        if (i === 0) {
          return {
            offset: 0,
            text: "1",
          };
        } else if (i === 1) {
          return {
            text: "...",
          };
        } else {
          return {
            offset: (a + i) * limit,
            text: String(a + i + 1),
            isActive: a + i === activeIndex,
          };
        }
      });
  } else {
    // < 1 [..., aI -1, aI, aI + 1, ...] numPages >
    return Array(maxPages)
      .fill(activeIndex - middleIndexStart)
      .map((a, i) => {
        if (i === 0) {
          return {
            offset: 0,
            text: "1",
          };
        } else if (i === maxPages - 1) {
          return {
            offset: (numPages - 1) * limit,
            text: String(numPages),
          };
        } else if (i === 1 || i === maxPages - 2) {
          return {
            text: "...",
          };
        } else {
          return {
            offset: (a + i) * limit,
            text: String(a + i + 1),
            isActive: a + i === activeIndex,
          };
        }
      });
  }
};

export default getPages;
