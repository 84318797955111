import { Loader } from "@tele2/t2-ui-kit";
import ModalBody from "@tele2/t2-ui-kit/build/Modal/ModalBody";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSendProjectToOrderApproval from "../../../../api/self-service/send-project-to-order-approval/useSendProjectToOrderApproval";
import { SelfServiceMode } from "../../../../api/self-service/types";
import { useMessage } from "../../../../components/Message/Message";
import ModalFooter from "../../../../components/modal/ModalFooter";
import ReactModal from "../../../../components/modal/ReactModal";
import {
  CONST_ROUTE_END_USER,
  CONST_URL_SELF_SERVICE_INDEX,
} from "../../../approutes";
import ApprovalForm from "../ApprovalForm";

export interface SendToApprovalModalProps {
  onClose: () => void;
  salesProjectId: number;
  mode: SelfServiceMode;
}

const SendToApprovalModal: React.FC<SendToApprovalModalProps> = ({
  onClose,
  salesProjectId,
  mode = SelfServiceMode.DEFAULT,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const message = useMessage();
  const [approverId, setApproverId] = React.useState<number>(0);
  const { sendProjectToApproval, isPending } = useSendProjectToOrderApproval();
  const sendToApproval = async () => {
    await sendProjectToApproval({
      salesProjectId,
      assignTo: approverId,
    });
    message({
      interval: 3000,
      message: t("module.self.service.send.to.approval.success"),
      theme: "positive",
    });
    navigate(
      mode === SelfServiceMode.END_USER
        ? CONST_ROUTE_END_USER
        : CONST_URL_SELF_SERVICE_INDEX
    );
    onClose();
  };
  return (
    <ReactModal
      onClose={onClose}
      title={t("general.confirm")}
      isLoading={isPending}
    >
      <ModalBody>
        {isPending ? (
          <Loader />
        ) : (
          <ApprovalForm setApprover={setApproverId} approver={approverId} />
        )}
      </ModalBody>
      <ModalFooter>
        <button
          disabled={isPending}
          type="button"
          className="btn btn-secondary"
          onClick={onClose}
          title={t("general.close")}
        >
          {t("general.close")}
        </button>
        <button
          data-testid={"send-to-approval"}
          className="btn btn-primary"
          disabled={approverId === 0 || isPending}
          onClick={sendToApproval}
          title={t("general.ok")}
        >
          {t("general.ok")}
        </button>
      </ModalFooter>
    </ReactModal>
  );
};
export default SendToApprovalModal;
