import * as React from "react";
import useEsalesProductCatalogModel, {
  EsalesCatalogRequestParams,
} from "../esales-product-catalog-model/useEsalesProductCatalogModel";

const useEsalesProduct = (
  vismaProductCode: string,
  params: EsalesCatalogRequestParams
) => {
  const { data } = useEsalesProductCatalogModel(params);
  return React.useMemo(() => {
    if (vismaProductCode && data?.productCatalogItems) {
      return data.productCatalogItems.find(
        (item) => item.productCode === vismaProductCode
      );
    }
    return undefined;
  }, [data, vismaProductCode]);
};
export default useEsalesProduct;
