import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import {
  useGetComponent,
  useUpdateComponent,
} from "../../../components/component/ComponentsContext";
import { isSameComponentAttribute } from "../../../components/component/utils";

interface DeleteMatrixRowRequest {
  subsId: number;
  matrixRowId: number;
  prodCompId: number;
  prodCompAttrId: number;
}

const post = async (request: DeleteMatrixRowRequest) => {
  const { data } = await axios.post(
    "/api/self-service/delete-matrix-row",
    null,
    { params: { subsId: request.subsId, matrixRowId: request.matrixRowId } }
  );
  return data;
};

const useDeleteMatrixRow = () => {
  const getComponent = useGetComponent();
  const updateComponent = useUpdateComponent();

  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  const deleteMatrixRow = useCallback(
    async (request: DeleteMatrixRowRequest) => {
      await mutateAsync(request);

      const component = getComponent(request.subsId, request.prodCompId);
      if (component) {
        const attribute = component.attributes.find((a) =>
          isSameComponentAttribute(a, request)
        );
        if (attribute) {
          if (attribute.rows) {
            for (let i = 0; i < attribute.rows?.length; i++) {
              if (attribute.rows[i].id === request.matrixRowId) {
                attribute.rows?.splice(i, 1);
              }
            }
          }
        } else {
          throw new Error("Could not find attribute when deleting matrix row");
        }
        updateComponent(component);
      } else {
        throw new Error("Could not find component");
      }
    },
    [getComponent, mutateAsync, updateComponent]
  );

  return { deleteMatrixRow, isPending };
};

export default useDeleteMatrixRow;
