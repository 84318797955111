import * as React from "react";
import { PropsWithChildren } from "react";
import EndUserLayout from "./EndUserLayout";

interface Props {
  backgroundColor?: string;
  title: string;
}

const EndUserWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return <EndUserLayout {...props}>{children}</EndUserLayout>;
};
export default EndUserWrapper;
