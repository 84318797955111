import * as React from "react";
import styled from "styled-components";
import { PropsWithChildren } from "react";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;

  .t2-text {
    grid-column: 1 / -1;
  }
`;

export const Item = styled.div`
  ${(props: { fullWidth?: boolean }) =>
    props.fullWidth ? "grid-column: 1 / -1;" : ""}
`;

const FormGroup: React.FC<PropsWithChildren> = (props): React.ReactElement => {
  return <Container>{props.children}</Container>;
};
export default FormGroup;
