import { color, Text } from "@tele2/t2-ui-kit";
import EditOutlined from "@tele2/t2-ui-kit/build/Icon/icons/EditOutlined";
import { CartCreateItemResponse } from "../../../../api/self-service/cart/create-items/useCreateCartItem";
import { getReferrer } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import * as React from "react";
import { Link } from "react-router-dom";
import { CONST_URL_SELF_SERVICE_EDIT_COMPONENTS } from "../../../approutes";
import styled from "styled-components";
import useDetectOutsideClick from "../../../../utils/useDetectOutsideClick";
import useIsEndUserPortal from "../../../../utils/useIsEndUserPortal";
import { useTranslation } from "react-i18next";

const EditOppRowLink: React.FC<{
  params: CartCreateItemResponse;
  onClose: () => void;
}> = ({ params, onClose }) => {
  const { t } = useTranslation();
  const refObject = React.useRef(null);
  useDetectOutsideClick(refObject, () => {
    onClose();
  });
  const isEndUserPortal = useIsEndUserPortal();
  const editUrl = `${CONST_URL_SELF_SERVICE_EDIT_COMPONENTS}?referrer=${encodeURIComponent(
    getReferrer()
  )}&mode=${
    isEndUserPortal ? "EDIT_FROM_END_USER" : "EDIT_FROM_OTHER_PAGE"
  }&packageInstanceId=${params.packageInstanceId}&oppRowId=${
    params.oppRowId
  }&salesProjectId=${params.salesProjectId}`;
  return (
    <div ref={refObject}>
      <EditLink to={editUrl} data-cy={"edit-opp-row-link"}>
        <Text lg>{t("general.edit")}</Text>
        <EditOutlined color={"BRAND_BLACK"} marginLeft={"xs"} />
      </EditLink>
    </div>
  );
};

export default EditOppRowLink;

const EditLink = styled(Link)`
  position: absolute;
  right: 0;
  background: ${color.BRAND_WHITE};
  border: 1px solid ${color.BRAND_PREMIUM_GREY};
  &:hover {
    border-color: ${color.DARK_GREY};
    text-decoration: none;
  }
  white-space: nowrap;
  bottom: -78px;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${color.BRAND_BLACK};
`;
