import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export interface ApprovalUser {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  showName: string;
  level: number;
  selectable: boolean;
}

export interface ApprovalUserModel {
  approvalUserLevel1: ApprovalUser | undefined;
  approvalUserLevel2: ApprovalUser | undefined;
  approvalUserLevel3: ApprovalUser | undefined;
  usersExists: boolean;
}

const getApproval = async () => {
  const { data } = await axios.get<ApprovalUserModel>(
    `/api/self-service/approver-list`
  );

  return data;
};

const useGetApproval = () => {
  return useQuery<ApprovalUserModel>({
    queryKey: ["getApproval"],
    queryFn: getApproval,
  });
};

export default useGetApproval;
