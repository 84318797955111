export enum SalesProjectStatus {
  DRAFT = "DRAFT",
  SIGNED_BY_CUSTOMER = "SIGNED_BY_CUSTOMER",
  LOST_PROJECT = "LOST_PROJECT",
  AWAITING_ORDER_ATTEST = "AWAITING_ORDER_ATTEST",
  UNKNOWN = "UNKNOWN",
}

export enum SelfServiceMode {
  END_USER = "END_USER",
  DEFAULT = "DEFAULT",
  APPROVALS = "APPROVALS",
  CARTS = "CARTS",
}

export enum RoleType {
  NONE = "NONE",
  INTERNAL = "INTERNAL",
  PARTNER = "PARTNER",
  SELF_SERVICE = "SELF_SERVICE",
  END_USER = "END_USER",
}
