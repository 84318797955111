import { HeaderButton, Tooltip } from "@tele2/t2-ui-kit";
import NotificationIcon from "@tele2/t2-ui-kit/build/Icon/icons/NotificationsOutlined";
import NotificationDisabledIcon from "@tele2/t2-ui-kit/build/Icon/icons/NotificationsOffOutlined";
import * as React from "react";
import styled from "styled-components";
import useDetectOutsideClick from "../../utils/useDetectOutsideClick";
import { NotificationsViewState } from "../../NotificationsContext/notification-utils";
import { useAppNotifications } from "../../NotificationsContext/NotificationsContext";
import NewNotificationsPopup from "./Notifications/NewNotificationsPopup";
import NotificationsPopup from "./Notifications/NotificationsPopup";
import { useTranslation } from "react-i18next";

const NotificationButton: React.FC = () => {
  const { t } = useTranslation();
  const {
    fetchNotificationsError,
    notReadNotifications,
    notificationsViewState,
    setNotificationsViewState,
  } = useAppNotifications();
  const refObject = React.useRef(null);
  useDetectOutsideClick(refObject, () => {
    if (notificationsViewState !== NotificationsViewState.NEW) {
      setNotificationsViewState(NotificationsViewState.NONE);
    }
  });
  return (
    <ButtonWrapper ref={refObject}>
      {!fetchNotificationsError ? (
        <HeaderButton
          role={"button"}
          aria-label={t("module.self.service.recent.activities")}
          notification={
            notReadNotifications && notReadNotifications.length > 0
              ? String(notReadNotifications.length)
              : undefined
          }
          onClick={() => {
            setNotificationsViewState(
              toggleNotificationsPopup(
                notificationsViewState,
                notReadNotifications?.length || 0
              )
            );
          }}
        >
          <NotificationIcon />
        </HeaderButton>
      ) : (
        <Tooltip
          tooltip={t("module.notification.notavailable")}
          position={"left"}
        >
          <HeaderButton aria-label={t("module.notification.notavailable")}>
            <NotificationDisabledIcon />
          </HeaderButton>
        </Tooltip>
      )}
      {(notificationsViewState === NotificationsViewState.ALL ||
        notificationsViewState === NotificationsViewState.NOT_READ) && (
        <NotificationsPopup />
      )}
      {notificationsViewState === NotificationsViewState.NEW && (
        <NewNotificationsPopup />
      )}
    </ButtonWrapper>
  );
};
export default NotificationButton;

const ButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const toggleNotificationsPopup = (
  notificationsViewState: NotificationsViewState,
  unreadNotifications: number
): NotificationsViewState => {
  if (notificationsViewState !== NotificationsViewState.NONE) {
    return NotificationsViewState.NONE;
  }
  return unreadNotifications > 0
    ? NotificationsViewState.NOT_READ
    : NotificationsViewState.ALL;
};
