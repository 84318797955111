import * as React from "react";
import styled from "styled-components";
import { TabProps, Tabs, Text } from "@tele2/t2-ui-kit";
import { TabPane } from "./ProductTabs";
import {
  ProductItem,
  Specification,
} from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import { useTranslation } from "react-i18next";

const SpecRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpecRow = styled.div`
  padding: 8px;
  &:nth-child(odd) {
    background-color: #fff;
  }
`;

const InnerPaneWrapper = styled.div`
  margin-bottom: 56px;
`;

const SpecificationPane: React.FC<{
  product: ProductItem;
}> = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const tabs = [
    { id: 0, name: t("general.specification") },
    {
      id: 1,
      name: t("general.extended.specification"),
      disabled: !product.extendedSpecification,
    },
  ];

  const onTabChange = (tab: TabProps) => {
    setActiveTab(tab.id as number);
  };

  interface SpecificationArrayObject {
    title: string;
    data: Specification[];
  }

  const sortedSpecs = React.useMemo(() => {
    const result: SpecificationArrayObject[] = [];
    if (product.extendedSpecification) {
      const temp = product.extendedSpecification;
      Object.keys(temp).forEach((element) => {
        const data = temp[element];
        if (data) {
          let lowestOrder = 999999;
          for (const row of data) {
            if (row.displayOrder < lowestOrder) {
              lowestOrder = row.displayOrder;
            }
          }
          result[lowestOrder] = { title: element, data: data };
        }
      });
    }
    return result;
  }, [product]);

  return (
    <>
      {product.extendedSpecification && (
        <Tabs tabs={tabs} onSelectChange={onTabChange} />
      )}
      <InnerPaneWrapper>
        <TabPane active={0 === activeTab}>
          {product.specification?.MainSpec?.map((spec, i) => {
            return (
              <SpecRow key={i}>
                <Text medium lg>
                  {spec.header}
                </Text>
                <div>{spec.body}</div>
              </SpecRow>
            );
          })}
        </TabPane>
        <TabPane active={1 === activeTab}>
          <SpecRowWrapper>
            {sortedSpecs.map((element, j) => {
              const { data, title } = element;

              return (
                <SpecRow key={j}>
                  <Text medium lg>
                    {title}
                  </Text>
                  <div>
                    {data.map((row, k) => {
                      return (
                        <div key={k}>
                          <Text medium lg>
                            {row.header}:
                          </Text>{" "}
                          {row.body}
                        </div>
                      );
                    })}
                  </div>
                </SpecRow>
              );
            })}
          </SpecRowWrapper>
        </TabPane>
      </InnerPaneWrapper>
    </>
  );
};

export default SpecificationPane;
