import styled from "styled-components";

const LinkStyleButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #1067d0;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`;
export default LinkStyleButton;
