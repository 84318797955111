import Circle from "@tele2/t2-ui-kit/build/Icon/icons/Circle";
import CircleOutlined from "@tele2/t2-ui-kit/build/Icon/icons/CircleOutlined";
import * as React from "react";

import styled from "styled-components";

const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PagerItem = styled.span`
  cursor: pointer;
  height: 12px;
  margin-left: 4px;
  margin-right: 4px;
  width: 12px;
  > svg {
    height: 12px;
    width: 12px;
  }
`;

interface CarouselPagerProps {
  activePage: number;
  itemsCount: number;
  onPagerClick: (num: number) => void;
}

const CarouselPager: React.FC<CarouselPagerProps> = ({
  activePage,
  itemsCount,
  onPagerClick,
}) => {
  const pagerItems = () => {
    const rows: Array<React.ReactElement> = [];

    if (isFinite(itemsCount)) {
      for (let i = 0; i < itemsCount; i++) {
        rows.push(
          <PagerItem key={i} onClick={() => onPagerClick(i)}>
            {activePage === i ? <Circle /> : <CircleOutlined />}
          </PagerItem>
        );
      }
    }

    return rows;
  };

  return <PagerWrapper>{pagerItems()}</PagerWrapper>;
};

export default CarouselPager;
