import { AxiosError } from "axios";
import * as React from "react";
import { PropsWithChildren } from "react";
import { redirectToLogin } from "../../SessionContext";

import ErrorPage from "./ErrorPage";

interface State {
  errorStatus: number;
  errorCode: string;
}

interface ErrorHandlerProps {
  source: string;
}

class ErrorHandler extends React.PureComponent<
  PropsWithChildren<ErrorHandlerProps>,
  State
> {
  public state: State = {
    errorStatus: 0,
    errorCode: "",
  };

  public static getDerivedStateFromError(error: AxiosError | Error): State {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError?.request?.status === 401) {
        redirectToLogin();
      }
      const errorData = axiosError.response?.data;
      let errorCode = "";
      if (errorData) {
        const obj = errorData as { reference: string };
        if (obj.reference) {
          errorCode = obj.reference;
        } else {
          errorCode = String(errorData);
        }
      }
      return {
        errorStatus: axiosError?.request?.status || 500,
        errorCode: errorCode,
      };
    }
    return { errorStatus: -1, errorCode: error.message };
  }

  public render(): React.ReactNode {
    if (this.state.errorStatus) {
      return (
        <ErrorPage
          status={this.state.errorStatus}
          errorCode={this.state.errorCode}
          resetErrorCode={() =>
            this.setState({ errorStatus: 0, errorCode: "" })
          }
          source={this.props.source}
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorHandler;

const isAxiosError = (error: Error): boolean => {
  return (error as AxiosError).isAxiosError;
};
