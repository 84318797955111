import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useComponents,
  useComponentsAttributes,
} from "../../../../components/component/ComponentsContext";
import ComponentsView from "../ComponentsView";
import PriceDetails from "../PriceDetails/PriceDetails";
import Validation from "../Validation/Validation";
import OpportunityButtons from "./OpportunityButtons";
import QuickCaseButtons from "./QuickCaseButtons";

const OpportunityContainer: React.FC = () => {
  const { t } = useTranslation();
  const {
    opportunityName,
    opportunityTypes,
    firstRow,
    productDescription,
    oppProductDescriptions,
    quickCase,
    detailedPrice,
  } = useComponentsAttributes();
  const [components] = useComponents();
  return (
    <div
      className="container edit-component-container-gdpr"
      style={{ paddingTop: "80px", paddingBottom: "150px" }}
    >
      <h1>
        {opportunityName}
        {!firstRow?.productCatalogueRowId && opportunityTypes && (
          <small> ({opportunityTypes})</small>
        )}
      </h1>
      {productDescription && (
        <div className="card">
          <div
            className="card-body multi-line"
            dangerouslySetInnerHTML={{ __html: productDescription }}
          />
        </div>
      )}
      {oppProductDescriptions?.map((value, index) => (
        <div className="card" key={index}>
          <div className="card-body multi-line">{value}</div>
        </div>
      ))}
      <ComponentsView />
      {components.length === 0 && (
        <div className="alert alert-success">
          {t("module.self.service.no.attributes.to.fill")}
        </div>
      )}
      <div className="modal-footer modal-footer-fixed">
        <div className="container opportunity-footer">
          <div className="row">
            <div className="price-details-container text-left col-sm-4">
              {detailedPrice && components.length > 0 && <PriceDetails />}
            </div>
            <div className="col-sm-8 text-right">
              {components.length > 0 && <Validation />}
              {!quickCase && <OpportunityButtons />}
              {quickCase && <QuickCaseButtons />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OpportunityContainer;
