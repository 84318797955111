import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface DeleteOpportunityRowModel {
  oppRowId: number;
  packageInstanceId?: number;
}

const del = async (model: DeleteOpportunityRowModel) => {
  await axios.post("/api/self-service/delete-opportunity-row", null, {
    params: model,
  });
};

const useDeleteOpportunityRow = () => {
  const { mutateAsync, isPending } = useMutation<
    void,
    AxiosError,
    DeleteOpportunityRowModel
  >({ mutationFn: del });
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const deleteOpportunityRow = useCallback(
    async (params: DeleteOpportunityRowModel) => {
      await mutateAsync(params);
      invalidateCheckoutData();
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { deleteOpportunityRow, isPending };
};

export default useDeleteOpportunityRow;
