import { BigTitle, Button, Text } from "@tele2/t2-ui-kit";
import AddShoppingCart from "@tele2/t2-ui-kit/build/Icon/icons/AddShoppingCartOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  getReferrer,
  ProductItem,
} from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import useSaveEsalesItemsToCart from "../../../../api/self-service/save-esales-items-to-cart/useSaveEsalesItemsToCart";
import Carousel from "../../../../components/Carousel/Carousel";
import { useWideMessage } from "../../../../components/Message/Message";
import StockBalance from "../../../../components/Product/StockBalance";
import { formatToLocalNumber } from "../../../../utils/number-formatters";
import { useSalesProjectId } from "../../ProductCatalog/ProductCatalogContext";
import { StyledQuantityInput } from "../../ProductCatalog/ProductCatalogList/ProductCatalogListItem";

const PageDivider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 128px;
`;

const StockWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 24px;
  max-height: 192px;
  overflow: auto;
  text-overflow: ellipsis;
`;

const AddToCartWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 64px;
`;

const AddToCartButton = styled(Button)`
  display: block;
`;

const GalleryImage = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

const ProductDetails: React.FC<{
  baseSubsId: number;
  product: ProductItem;
}> = ({ baseSubsId, product }) => {
  const { t } = useTranslation();
  const [salesProjectId, setSalesProjectId] = useSalesProjectId();
  const wideMessage = useWideMessage();
  const [qty, setQty] = React.useState<number>(1);

  const { saveEsalesItemsToCart, isPending: isSaving } =
    useSaveEsalesItemsToCart();

  return (
    <PageDivider>
      <div style={{ width: "50%" }}>
        <BigTitle style={{ marginBottom: "24px" }}>{product.prodName}</BigTitle>
        <Text xl>
          {t("general.article.number")}: {product.productCode}
        </Text>
        <StockWrapper>
          <StockBalance stockBalance={product.stockBalance} />
        </StockWrapper>
        <DescriptionWrapper>
          {product.description && <Text xl>{product.description}</Text>}
          <Text xl>{product.description}</Text>
        </DescriptionWrapper>
        {product.priceRecurring ? (
          <Text xxl>{`${t("general.price")}: ${formatToLocalNumber(
            Number(product.priceRecurring),
            2,
            2
          )} ${t("module.self.service.price.per.month", [
            product.currency,
          ])}`}</Text>
        ) : (
          Boolean(product.priceOneTime) && (
            <Text xxl>{`${t("general.price")}: ${formatToLocalNumber(
              Number(product.priceOneTime),
              2,
              2
            )} ${product.currency}`}</Text>
          )
        )}
        <AddToCartWrapper>
          <StyledQuantityInput
            disabled={Boolean(
              (!product.priceOneTime && product.priceRecurring) || isSaving
            )}
            label={t("module.self.service.price.onetime.quantity")}
            value={qty}
            inProgress={isSaving}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!isNaN(Number(e.target.value))) {
                setQty(Number(e.target.value));
              }
            }}
          />
          {product.productCode && (
            <AddToCartButton
              xxs
              icon={<AddShoppingCart />}
              onClick={async () => {
                const data = await saveEsalesItemsToCart({
                  baseSubsId,
                  // mode, - to-do: recieve from parent or pass via api-call?
                  mode: "ADD_FROM_OTHER_PAGE",
                  productCatalogueRowId: "",
                  quantity: qty,
                  referrer: encodeURIComponent(getReferrer()),
                  salesProjectId,
                  vismaProductCode: product.productCode ?? "",
                  identity: !product.allowAmountOnLine ?? false, // TODO fix "?? false" has no effect !product.allowAmountOnLine is always boolean
                });
                setSalesProjectId(data.salesProjectId);
                wideMessage({
                  message: t("module.self.service.cart.updated"),
                  theme: "positive",
                  interval: 4000,
                });
              }}
              disabled={Boolean(
                (!product.priceOneTime && product.priceRecurring) || isSaving
              )}
            >
              {t("module.self.service.cart.add")}
            </AddToCartButton>
          )}
        </AddToCartWrapper>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "50%",
        }}
      >
        <Carousel>
          {product.pictureUrls && product.pictureUrls?.length > 0 ? (
            product.pictureUrls.map((url, index) => {
              return <GalleryImage key={index} src={url} />;
            })
          ) : product.pictureUrl ? (
            <GalleryImage src={product.pictureUrl} />
          ) : null}
        </Carousel>
      </div>
    </PageDivider>
  );
};

export default ProductDetails;
