import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import toFormData from "../../../../utils/toFormData";
import useInvalidateCheckoutData from "../../useInvalidateCheckoutData";

export interface CartCreateItemResponse {
  salesProjectId: number;
  oppRowId: number;
  packageInstanceId: number;
}

interface CartCreateItemsInput {
  mode: string;
  oppTypeId: number;
  prodId: number;
  quantity: number;
  referrer: string;
  relatedSubsId: number;
  salesProjectId: number;
  subsId: number;
}
const post = async (input: CartCreateItemsInput) => {
  const { data } = await axios.post<CartCreateItemResponse>(
    "/api/self-service/cart/create-items",
    toFormData(input)
  );
  return data;
};

const useCreateCartItem = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation({ mutationFn: post });
  const createCartItem = useCallback(
    async (request: CartCreateItemsInput) => {
      const data = await mutateAsync(request);
      invalidateCheckoutData();
      return data;
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { createCartItem, isPending };
};

export default useCreateCartItem;
