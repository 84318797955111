import { useCallback } from "react";
import { useInvalidateActiveCart } from "./cart/useActiveCart";
import { useRemoveAvailableItems } from "./cart/useAvailableItems";
import { useInvalidateSubscriptionsInActiveCart } from "./cart/useSubscriptionsInActiveCart";
import { useInvalidatedCartsList } from "./carts/useCartsList";
import { useInvalidateGetCarts } from "./carts/useGetCarts";
import { useInvalidateCartOppRows } from "./checkout/useCartOppRows";
import { useInvalidateOppRowsTableSessionData } from "./checkout/useOppRowsTableSessionData";
import { useInvalidateSalesProjectOppRows } from "./checkout/useSalesProjectOppRows";
import { useInvalidateSalesProjectSummary } from "./checkout/useSalesProjectSummary";
import { useInvalidateSalesProjectSummaryValidation } from "./checkout/useSalesProjectSummaryValidation";
import { useInvalidateCustomerCart } from "./useGetCustomerCart";

const useInvalidateCheckoutData = () => {
  const invalidateSubscriptionsInActiveCart =
    useInvalidateSubscriptionsInActiveCart();
  const invalidatedCartsList = useInvalidatedCartsList();
  const invalidateSelfServiceCart = useInvalidateActiveCart();
  const invalidateCustomerCart = useInvalidateCustomerCart();
  const invalidatePostOppRowsGet = useInvalidateCartOppRows();
  const invalidateSalesProjectSummary = useInvalidateSalesProjectSummary();
  const invalidateSalesProjectSummaryValidation =
    useInvalidateSalesProjectSummaryValidation();
  const invalidateSalesProjectOppRows = useInvalidateSalesProjectOppRows();
  const invalidateGetCarts = useInvalidateGetCarts();
  const invalidateOppRowsTableSessionData =
    useInvalidateOppRowsTableSessionData();
  const removeAvailableItems = useRemoveAvailableItems();
  return useCallback(() => {
    invalidateSubscriptionsInActiveCart();
    invalidatedCartsList();
    invalidateSelfServiceCart();
    invalidateCustomerCart();
    invalidatePostOppRowsGet();
    invalidateSalesProjectSummary();
    invalidateSalesProjectSummaryValidation();
    invalidateSalesProjectOppRows();
    invalidateGetCarts();
    invalidateOppRowsTableSessionData();
    removeAvailableItems();
  }, [
    invalidateSubscriptionsInActiveCart,
    invalidatedCartsList,
    invalidateSelfServiceCart,
    invalidateCustomerCart,
    invalidatePostOppRowsGet,
    invalidateSalesProjectSummary,
    invalidateSalesProjectSummaryValidation,
    invalidateSalesProjectOppRows,
    invalidateGetCarts,
    invalidateOppRowsTableSessionData,
    removeAvailableItems,
  ]);
};
export default useInvalidateCheckoutData;
