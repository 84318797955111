import { CONST_URL_HOME_HOME, CONST_URL_ORDERS_INDEX } from "../../approutes";

const CDC_REL_PATH = "data-collect";
export const CDC_BASE_PATH = `/self-service/${CDC_REL_PATH}`;

export const CDC_ROUTE = {
  root: CDC_REL_PATH,
  collectionPath: `${CDC_REL_PATH}/:collectionId`,
  productPath: `${CDC_REL_PATH}/:collectionId/:productId`,
};

export const CDC_HEADER_TITLE = "module.data_collect";

export const CDC_DEFAULT_BREADCRUMBS = [
  { link: CONST_URL_HOME_HOME, title: "module.home" },
  { link: CONST_URL_ORDERS_INDEX, title: "module.orders" },
  { link: "", title: "module.data_collect" },
];

export const CDC_BREADCRUMB = {
  link: CDC_BASE_PATH,
  title: "module.data_collect",
};
