import { Button, Loader } from "@tele2/t2-ui-kit";
import ModalBody from "@tele2/t2-ui-kit/build/Modal/ModalBody";
import { AxiosError } from "axios";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePlaceOrder from "../../../../api/self-service/checkout/usePlaceOrder";
import { SelfServiceMode } from "../../../../api/self-service/types";
import { useMessage } from "../../../../components/Message/Message";
import ModalFooter from "../../../../components/modal/ModalFooter";
import ReactModal from "../../../../components/modal/ReactModal";
import useIsEndUserPortal from "../../../../utils/useIsEndUserPortal";
import {
  CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION,
  CONST_URL_SELF_SERVICE_CHECKOUT_SUMMARY,
  CONST_URL_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT,
} from "../../../approutes";

export interface ConfirmOrderSendModalProps {
  onClose: () => void;
  onCloseParent?: () => void;
  salesProjectId: number;
  mode: SelfServiceMode;
}

const ConfirmOrderSendModal: React.FC<ConfirmOrderSendModalProps> = ({
  onClose,
  onCloseParent,
  salesProjectId,
  mode = SelfServiceMode.DEFAULT,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const message = useMessage();
  const isEndUserPortal = useIsEndUserPortal();

  const { placeOrder, isPending } = usePlaceOrder((error: AxiosError) => {
    onClose();
    const errorData = error.response?.data as { reference?: string };
    if (mode === SelfServiceMode.END_USER) {
      let errorMessage = t("general.error");
      if (errorData.reference) {
        errorMessage +=
          "\n" +
          t("module.self.service.checkout.sales.project.error.p3", [
            errorData.reference,
          ]);
      }
      message({
        interval: 6000,
        message: errorMessage,
        theme: "alert",
      });
    } else {
      onCloseParent?.();
      const reference = errorData.reference
        ? `&reference=${errorData.reference}`
        : "";
      navigate(
        `${CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION}?salesProjectId=${salesProjectId}&failedOrder=1${reference}`
      );
    }
  });
  return (
    <ReactModal
      onClose={onClose}
      title={t("general.confirm")}
      isLoading={isPending}
    >
      <ModalBody>
        {isPending ? (
          <Loader />
        ) : (
          <p>
            {isEndUserPortal
              ? t("module.subscription.end_users.send.cart.confirm")
              : t("module.self.service.confirm.order")}
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button secondary sm onClick={onClose} disabled={isPending}>
          {t("general.cancel")}
        </Button>
        <Button
          data-testid={"placeOrderButton"}
          sm
          onClick={async () => {
            const data = await placeOrder({
              salesProjectId,
            });
            onClose();
            onCloseParent?.();
            if (data.errorMessage) {
              message({
                interval: 6000,
                message: data.errorMessage,
                theme: "alert",
              });
            }
            if (data.notValid) {
              if (mode === SelfServiceMode.END_USER) {
                message({
                  interval: 6000,
                  message: t("general.error"),
                  theme: "alert",
                });
              } else {
                navigate(
                  `${CONST_URL_SELF_SERVICE_CHECKOUT_SUMMARY}?salesProjectId=${salesProjectId}`
                );
              }
            } else if (data.failedCreditCheck) {
              navigate(
                `${CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION}?salesProjectId=${salesProjectId}&failedCreditCheck=1`
              );
            } else if (data.punchoutError) {
              navigate(CONST_URL_SELF_SERVICE_PUNCHOUT_SESSION_TIMEOUT);
            } else if (data.punchoutUri) {
              window.location.href = data.punchoutUri;
            } else if (mode === SelfServiceMode.END_USER) {
              message({
                interval: 6000,
                message: t("module.self.service.order.sent", [salesProjectId]),
                theme: "positive",
              });
            } else {
              navigate(
                `${CONST_URL_SELF_SERVICE_CHECKOUT_CONFIRMATION}?salesProjectId=${salesProjectId}`
              );
            }
          }}
          disabled={isPending}
        >
          {t("general.ok")}
        </Button>
      </ModalFooter>
    </ReactModal>
  );
};
export default ConfirmOrderSendModal;
