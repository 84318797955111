import ReactModal from "../../../modal/ReactModal";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
}

const SimCardFeeInfoModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <ReactModal
      onClose={onClose}
      title={t(
        "module.self.service.attribute.element.label.sim.card.fee.modal.title"
      )}
      hideFooter
      centered
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t(
            "module.self.service.attribute.element.label.sim.card.fee.modal.text"
          ),
        }}
      />
    </ReactModal>
  );
};
export default SimCardFeeInfoModal;
