import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

export interface SummaryValidationModel {
  permissions: CartActionPermissions;
  customerFieldsError: string[];
  externalOrderNoErrors: string[];
  otherErrors: string[];
  hasErrors: boolean;
  orderDescriptionError: boolean;
  rowsError: boolean;
  salesProjectId: number;
}

export interface CartActionPermissions {
  addOppRows: boolean;
  allowAttachments: boolean;
  cancelProject: boolean;
  deleteOppRows: boolean;
  displayOrderInfo: boolean;
  displayPrices: boolean;
  displayValidation: boolean;
  externalOrderNumberRequired: boolean;
  orderDescriptionRequired: boolean;
  placeOrder: boolean;
  revertProjectToDraft: boolean;
  salesProjectEdit: boolean;
  sendProjectToOrderApproval: boolean;
  showCustomerFields: boolean;
  showExternalOrderNumber: boolean;
}

const getSalesProjectSummaryValidation = async (salesProjectId: number) => {
  const { data } = await axios.get<SummaryValidationModel>(
    "/api/self-service/checkout/summary-validation",
    {
      params: {
        salesProjectId,
      },
    }
  );
  return data;
};

const QUERY_KEY = "salesProjectSummaryValidation";

const useSalesProjectSummaryValidation = (salesProjectId: number) => {
  return useSuspenseQuery<SummaryValidationModel, AxiosError>({
    queryKey: [QUERY_KEY, salesProjectId],
    queryFn: () => getSalesProjectSummaryValidation(salesProjectId),
    staleTime: 1000,
  });
};

export default useSalesProjectSummaryValidation;

export const useInvalidateSalesProjectSummaryValidation = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
