import classNames from "classnames";
import * as React from "react";
import useSaveAttribute from "../../../api/self-service/save-attribute/useSaveAttribute";
import useUpdateMatrixRow from "../../../api/self-service/update-matrix-row/useUpdateMatrixRow";
import { parseDate, toIsoDate } from "../../../utils/dates";
import {
  useUpdateAllSlaveAttributes,
  useUpdateOverviewAttribute,
} from "../../component/ComponentsContext";
import Datepicker from "../../Datepicker/Datepicker";
import { AttributeProps } from "../AttributeRow";
import Undo from "../Undo";

const TextAttribute: React.FC<AttributeProps> = ({
  subsId,
  prodCompAttrId,
  readOnly,
  attrData,
  dateReformat,
  isMatrixCell,
  required,
  maxLength,
  size,
  hash,
  compAttrId,
  hiddenCompAttrIds,
  label,
  oppRowId,
  matrixRowId,
  overViewAttribute,
  prodCompId,
  parentProdCompAttrId,
  relatedAttrData,
}) => {
  const attrDataString = attrData || "";
  const [inputValue, setInputValue] = React.useState(attrData || "");
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(true);
  React.useEffect(() => {
    setInputValue(attrData || "");
  }, [attrData]);
  const { saveAttribute, isPending: isSavingAttribute } =
    useSaveAttribute(label);
  const { updateMatrixRow, isPending: isSavingMatrixAttribute } =
    useUpdateMatrixRow(label, parentProdCompAttrId);
  const updateOverviewAttribute = useUpdateOverviewAttribute();
  const updateAllSlaveAttributes = useUpdateAllSlaveAttributes(label);
  const saveValue = React.useCallback(
    async (newValue: string) => {
      if (savedSuccessfully && newValue === attrDataString) {
        return;
      }
      if (isMatrixCell) {
        updateMatrixRow({
          data: newValue,
          matrixRowId,
          subsId,
          compAttrId,
          oppRowId,
          prodCompId,
        });
      } else {
        const response = await saveAttribute({
          value: newValue,
          hash,
          compAttrId,
          prodCompAttrId,
          hiddenCompAttrIds,
          subsId,
          prodCompId,
        });
        const validSave = response.validationCode === "OK";
        setSavedSuccessfully(validSave);
        if (overViewAttribute) {
          updateOverviewAttribute(subsId, prodCompId, prodCompAttrId, response);
          if (validSave) {
            // If this is an overview field, also update slave fields
            await updateAllSlaveAttributes({
              value: newValue,
              hash,
              compAttrId,
              prodCompAttrId,
              hiddenCompAttrIds,
              subsId,
              prodCompId,
            });
          }
        }
      }
    },
    [
      attrDataString,
      compAttrId,
      hash,
      hiddenCompAttrIds,
      isMatrixCell,
      matrixRowId,
      oppRowId,
      overViewAttribute,
      prodCompAttrId,
      prodCompId,
      saveAttribute,
      savedSuccessfully,
      subsId,
      updateAllSlaveAttributes,
      updateMatrixRow,
      updateOverviewAttribute,
    ]
  );
  const inputClasses = classNames(
    "form-control",
    isMatrixCell ? "attr-matrix-value" : "attr-value",
    required ? "attr-required" : "",
    inputValue !== attrDataString ? "attr-unsaved-change" : "",
    dateReformat ? "attr-date" : ""
  );
  if (readOnly) {
    return <>{inputValue}</>;
  } else {
    const id = `attr_${subsId}_${prodCompAttrId}`;
    if (dateReformat) {
      return (
        <>
          <div className="form-group">
            <Datepicker
              data-cy={prodCompAttrId}
              selected={inputValue ? parseDate(inputValue) : null}
              onChange={(date) => {
                setInputValue(date ? toIsoDate(date) : "");
                saveValue(date ? toIsoDate(date) : "");
              }}
              id={id}
              className={inputClasses}
              name={id}
              minDate={new Date()}
              disabled={isSavingAttribute || isSavingMatrixAttribute}
              dateFormat="P"
            />
          </div>
          <Undo
            relatedAttrData={relatedAttrData}
            attrData={attrData}
            onClick={() => {
              if (relatedAttrData) {
                setInputValue(relatedAttrData);
                saveValue(relatedAttrData);
              }
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <input
            data-cy={prodCompAttrId}
            className={inputClasses}
            maxLength={maxLength}
            id={id}
            name={id}
            size={size || 20}
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => saveValue(inputValue)}
            disabled={isSavingAttribute || isSavingMatrixAttribute}
          />
          <Undo
            relatedAttrData={relatedAttrData}
            attrData={attrData}
            onClick={() => {
              if (relatedAttrData) {
                setInputValue(relatedAttrData);
                saveValue(relatedAttrData);
              }
            }}
          />
        </>
      );
    }
  }
};

export default TextAttribute;
