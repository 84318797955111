import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface SendProjectToApprovalProps {
  salesProjectId: number;
  assignTo: number;
}

const post = async (model: SendProjectToApprovalProps) => {
  await axios.post("/api/self-service/send-project-to-order-approval", null, {
    params: model,
  });
};

const useSendProjectToApproval = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation<
    void,
    AxiosError,
    SendProjectToApprovalProps
  >({ mutationFn: post });
  const sendProjectToApproval = useCallback(
    async (request: SendProjectToApprovalProps) => {
      const data = await mutateAsync(request);
      invalidateCheckoutData();
      return data;
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { sendProjectToApproval, isPending };
};
export default useSendProjectToApproval;
