import { Tooltip } from "@tele2/t2-ui-kit";
import Delete from "@tele2/t2-ui-kit/build/Icon/icons/DeleteOutline";

import Edit from "@tele2/t2-ui-kit/build/Icon/icons/EditOutlined";
import { OpportunityGroup } from "../../../../api/self-service/checkout/useSalesProjectOppRows";
import { CartActionPermissions } from "../../../../api/self-service/checkout/useSalesProjectSummaryValidation";
import { getReferrer } from "../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getEditComponentsURL } from "../../../../Routes/SelfService/EditComponents/EditComponents";
import styled from "styled-components";
import useIsEndUserPortal from "../../../../utils/useIsEndUserPortal";

import { useTranslation } from "react-i18next";
import { DeleteOppRowModalObj } from "../MiniCartButtonWrapper";
interface ActionsColumnProps {
  group: OpportunityGroup;
  permissions: CartActionPermissions;
  salesProjectId: number;
  onClose?: () => void;
  oppRowId: number;
  attributesEditable: boolean;
  setShowConfirmDeleteOppRowModal: (props: DeleteOppRowModalObj) => void;
}

const StyledLink = styled(Link)`
  color: inherit;
`;

const StyledSpan = styled.span`
  cursor: pointer;
`;

const ActionsColumn: React.FC<ActionsColumnProps> = ({
  group,
  permissions,
  salesProjectId,
  onClose,
  oppRowId,
  setShowConfirmDeleteOppRowModal,
  attributesEditable,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEndUserPortal = useIsEndUserPortal();

  const onEditClick = (e: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    e.preventDefault();
    navigate(url);
    onClose?.();
  };

  if (group.shadow) return <div />;

  const editUrl = getEditComponentsURL({
    salesProjectId,
    oppRowId,
    packageInstanceId: group.packageInstanceId,
    mode: isEndUserPortal ? "EDIT_FROM_END_USER" : "EDIT_FROM_CART",
    referrer: encodeURIComponent(getReferrer()),
  });

  return (
    <div className="action-column text-right">
      {attributesEditable && (
        <Tooltip tooltip={t("general.edit")}>
          <StyledLink
            className="row-action-edit cart-item-edit"
            to={editUrl}
            onClick={(e) => onEditClick(e, editUrl)}
          >
            <Edit />
          </StyledLink>
        </Tooltip>
      )}
      {permissions.deleteOppRows && (
        <Tooltip tooltip={t("general.delete")}>
          <StyledSpan
            className="cart-item-delete"
            onClick={() =>
              setShowConfirmDeleteOppRowModal({
                oppRowId: oppRowId,
                packageInstanceId: group.packageInstanceId,
              })
            }
          >
            <Delete />
          </StyledSpan>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsColumn;
