import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { SalesProjectStatus } from "../types";
import { CartsResponse } from "./carts-types";

interface CartsListRequest {
  statusId?: number;
  statuses?: SalesProjectStatus[];
  approvalUserId?: number;
  responsibleId?: number;
  pageSize: number;
  page: number;
  search: string;
  sort: number;
  descending: boolean;
}

const get = async (params: CartsListRequest) => {
  const { data } = await axios.get<CartsResponse>(
    "/api/self-service/carts/list",
    { params }
  );
  return data;
};

const QUERY_KEY = "carts-list";
const useCartsList = (params: CartsListRequest) =>
  useQuery({ queryKey: [QUERY_KEY, params], queryFn: () => get(params) });
export default useCartsList;

export const useInvalidatedCartsList = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
