import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface MenuEntry {
  module: string;
  firstLevel?: boolean;
  url: string | null;
  key: string | null;
  entries: MenuEntry[];
  hidden: boolean;
}

const getMenuEntries = async (): Promise<MenuEntry[]> => {
  const { data } = await axios.get<MenuEntry>("/api/home/header");
  return data.entries.filter((e) => !e.hidden);
};

const useMenuEntries = () => {
  return useQuery<MenuEntry[], AxiosError>({
    queryKey: ["menu-entries"],
    queryFn: getMenuEntries,
    staleTime: Infinity,
  });
};

export default useMenuEntries;
