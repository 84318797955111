import { LoaderSpinner } from "@tele2/t2-ui-kit/build/Loader/LoaderSpinner";
import { ActiveCart } from "../../../api/self-service/cart/useActiveCart";

import useGetCustomerCart from "../../../api/self-service/useGetCustomerCart";
import ModalFooter from "../../modal/ModalFooter";
import ReactModal from "../../modal/ReactModal";
import * as React from "react";

import MiniCartContents from "./Components/MiniCartContents";
import MiniCartModalFooter from "./Components/MiniCartModalFooter";

import { useTranslation } from "react-i18next";
import { DeleteOppRowModalObj } from "./MiniCartButtonWrapper";

const MiniCartModal: React.FC<{
  onClose: () => void;
  setShowConfirmOrderModal: (value: boolean) => void;
  setShowConfirmDeleteCartModal: (value: boolean) => void;
  setShowConfirmDeleteOppRowModal: (props: DeleteOppRowModalObj) => void;
  setShowSendProjectToOrderApproval: (value: boolean) => void;
  setShowRevertProjectToDraftModal: (value: boolean) => void;
  activeCart: ActiveCart;
}> = ({
  onClose,
  setShowConfirmOrderModal,
  setShowConfirmDeleteCartModal,
  setShowConfirmDeleteOppRowModal,
  setShowSendProjectToOrderApproval,
  setShowRevertProjectToDraftModal,
  activeCart,
}) => {
  const { t } = useTranslation();
  const { data: cartModel, isLoading: cartIsLoading } = useGetCustomerCart({
    salesProjectId: activeCart.salesProjectId,
    subRoleType: activeCart.subRoleType,
  });
  if (!cartModel) return null;
  return (
    <ReactModal
      centered={true}
      hideFooter={cartIsLoading}
      onClose={onClose}
      size="xl"
      title={`${t("module.self.service.order.number")} ${
        cartModel?.salesProjectId
      }`}
    >
      {cartIsLoading ? (
        <LoaderSpinner />
      ) : (
        <MiniCartContents
          salesProjectId={activeCart.salesProjectId}
          permissions={cartModel.permissions}
          container={cartModel.container}
          onClose={onClose}
          setShowConfirmDeleteOppRowModal={setShowConfirmDeleteOppRowModal}
        />
      )}
      <ModalFooter>
        <MiniCartModalFooter
          salesProjectId={activeCart.salesProjectId}
          onClose={onClose}
          permissions={cartModel.permissions}
          setShowConfirmOrderModal={setShowConfirmOrderModal}
          setShowConfirmDeleteCartModal={setShowConfirmDeleteCartModal}
          setShowSendProjectToOrderApproval={setShowSendProjectToOrderApproval}
          setShowRevertProjectToDraftModal={setShowRevertProjectToDraftModal}
        />
      </ModalFooter>
    </ReactModal>
  );
};
export default MiniCartModal;
