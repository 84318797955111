import Portal from "../Portal/Portal";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Block: React.FC = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    // Stops propagation of mousedown events that useDetectOutsideClick listen to.
    // That can cause this component to be removed if the component that is rendering the Block is no longer rendered
    // We could consider having an other component render the Block instead using a context
    const handleClickOutside = (e: MouseEvent) => {
      e.stopImmediatePropagation();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Portal containerId="block-container">
      <BlockContainer tabIndex={-1} role="dialog">
        <BlockWrapper className="modal-dialog" role="document">
          <div className="modal-content">
            <BlockTextBody className="modal-body text-center">
              <strong>
                <i className="ajax-loader fa-spin" />
                <br />
                {t("general.please.wait")}
              </strong>
            </BlockTextBody>
          </div>
        </BlockWrapper>
        <div className="modal-backdrop fade show" />
      </BlockContainer>
    </Portal>
  );
};
export default Block;

const BlockContainer = styled.div`
  display: block;
  z-index: 2000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
`;

const BlockWrapper = styled.div`
  z-index: 2001;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const BlockTextBody = styled.div`
  margin: 0px 100px 0px 100px;
`;
