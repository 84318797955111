import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";

interface ValidationGroup {
  category: string;
  validations: Validation[];
}

interface Validation {
  description: string;
  type: string;
}

interface ValidationPopoverProps {
  groups: ValidationGroup[];
}

const PopoverWrapper = styled.div`
  position: absolute;
  left: 12px;
  top: -13px;
  transform: translate(-50%, -100%);
  background: white;
  border: grey solid 1px;
  padding: 8px;
  white-space: nowrap;
  text-align: left;
  z-index: 1070;
  margin-bottom: 10px;
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    border: 1px solid #e3e5e6;
    background-color: #ffffff;
    border-top: none;
    border-left: none;
    transform: translate(-50%, -4%) rotate(45deg);
    left: 50%;
    bottom: -10px;
  }
`;

const ValidationPopover: React.FC<ValidationPopoverProps> = ({ groups }) => {
  const hasValidations = useMemo(
    () => groups.find((group) => group.validations.length > 0),
    [groups]
  );
  if (!hasValidations) {
    return null;
  }
  return (
    <PopoverWrapper>
      <div className="opp-validation-popup">
        {groups.map(({ category, validations }, i) => {
          if (!validations || validations.length === 0) return null;
          return (
            <React.Fragment key={i}>
              <h4>{category}</h4>
              <ul className="list-unstyled">
                {validations.map(({ description }, j) => (
                  <li key={j}>{description}</li>
                ))}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
    </PopoverWrapper>
  );
};
export default ValidationPopover;
