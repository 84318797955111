import * as React from "react";

const useHighlight = (highlight: string) => {
  return React.useCallback(
    (text: string) => {
      if (!highlight || !text) {
        return text;
      } else {
        const parts = text.split(
          new RegExp(`(${escapeRegExp(highlight)})`, "gi")
        );
        return (
          <>
            {parts.map((part, i) =>
              part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={i} role={"mark"} className={"highlight"}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </>
        );
      }
    },
    [highlight]
  );
};

export default useHighlight;

const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
