import * as React from "react";
import { toErrorClass } from "../../Routes/SelfService/EditComponents/utils";
import AttributeCol from "./AttributeCol";
import HelpTextIcon from "./HelpTextIcon";

export enum Type {
  UNKNOWN = "UNKNOWN",
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  CHECK_BOX = "CHECK_BOX",
  HEAD_LINE = "HEAD_LINE",
  LINE_FEED = "LINE_FEED",
  RADIO = "RADIO",
  SELECT = "SELECT",
  LABEL = "LABEL",
  DATA = "DATA",
  MATRIX = "MATRIX",
  HELP_TEXT = "HELP_TEXT",
  URL = "URL",
}

export interface AttributeRowType {
  id: number;
  prodCompAttrId: number;
  scaSubsId?: number;
  subsId: number;
  cells: Attribute[];
}

export interface Attribute {
  attrData: string | null;
  caseId: number;
  colSize: number;
  columns?: Attribute[];
  compAttrId: number;
  dateReformat: boolean;
  elementLabel: string | null;
  globalAttributeName: string;
  hash: string | null;
  hashChanged: boolean;
  helpText: string | null;
  hiddenCompAttrIds: string | null;
  hideLabel: boolean;
  indent: number;
  label: string;
  lockedForBatchUpdate: boolean;
  matrixRowId: number;
  maxLength: number;
  maxRows?: number;
  oppRowId: number;
  parentProdCompAttrId: number;
  prodCompAttrId: number;
  prodCompId: number;
  prodCompName: string | null;
  readOnly: boolean;
  relatedAttrData: string | null;
  required: boolean;
  rowSize: number;
  rows?: AttributeRowType[];
  scaSubsId: number;
  size: number;
  slaveField: boolean;
  sortable?: boolean;
  subsId: number;
  type: Type;
  validationClass?: boolean;
  validationCode: string | null;
  validationMessage: string | null;
}

export interface AttributeRowProps extends Attribute {
  type: Type;
  hidden?: boolean;
  overViewAttribute?: boolean;
  isMatrixCell?: boolean;
  onSave?: () => void;
  oneFieldForAllProducts?: boolean;
}

export interface AttributeProps extends Attribute {
  isMatrixCell?: boolean;
  overViewAttribute?: boolean;
}

const AttributeRow: React.FC<AttributeRowProps> = (props) => {
  const {
    type,
    hidden,
    overViewAttribute,
    readOnly,
    indent,
    subsId,
    prodCompAttrId,
    hideLabel,
    label,
    helpText,
    required,
    validationCode,
    slaveField,
    oneFieldForAllProducts,
  } = props;
  const showAttribute =
    !hidden &&
    ((!overViewAttribute && !slaveField) ||
      (oneFieldForAllProducts && overViewAttribute) ||
      (!oneFieldForAllProducts && slaveField));
  const content = React.useMemo(() => {
    switch (type) {
      case Type.MATRIX:
        return (
          <div className={"col " + toErrorClass(validationCode)}>
            <AttributeCol {...props} />
          </div>
        );
      case Type.HEAD_LINE:
      case Type.LABEL:
      case Type.HELP_TEXT:
      case Type.LINE_FEED:
        return (
          <div className="col header-row">
            <AttributeCol {...props} />
          </div>
        );
      default:
        return (
          <>
            <div className={`col-sm-4${readOnly ? " col-6" : ""}`}>
              <label
                style={{ marginLeft: `${indent * 10}px` }}
                className="attr-label"
                htmlFor={`${subsId}_${prodCompAttrId}`}
              >
                {hideLabel ? null : label}
              </label>
              <HelpTextIcon>{helpText}</HelpTextIcon>
              {!readOnly && required && (
                <span className="input-required"> *</span>
              )}
            </div>

            <div
              className={`col-sm-8${readOnly ? " col-6" : ""} ${toErrorClass(
                validationCode
              )}`}
            >
              <div>
                {/*{false && (*/}
                {/*  <i className="combo-box-icon fa fa-spinner fa-spin" />*/}
                {/*)}*/}
                <AttributeCol {...props} />
              </div>
            </div>
          </>
        );
    }
  }, [
    props,
    helpText,
    hideLabel,
    indent,
    label,
    prodCompAttrId,
    readOnly,
    required,
    subsId,
    type,
    validationCode,
  ]);
  if (!showAttribute) {
    return null;
  }
  return <div className="row attr-row">{content}</div>;
};

export default AttributeRow;
