import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import toFormData from "../../../utils/toFormData";
import useInvalidateCheckoutData from "../useInvalidateCheckoutData";

export interface CartCreateItemResponse {
  salesProjectId: number;
  oppRowId: number;
  packageInstanceId: number;
}

interface SaveEsalesItemsToCartInput {
  baseSubsId: number;
  mode: string; // TODO remove??
  productCatalogueRowId: string; // TODO remove??
  quantity: number;
  referrer: string; // TODO remove??
  salesProjectId: number;
  vismaProductCode: string;
  identity: boolean; // TODO what does this mean??
}

const post = async (input: SaveEsalesItemsToCartInput) => {
  const { data } = await axios.post<CartCreateItemResponse>(
    "/api/self-service/save-esales-items-to-cart",
    toFormData(input)
  );
  return data;
};

const useSaveEsalesItemsToCart = () => {
  const invalidateCheckoutData = useInvalidateCheckoutData();
  const { mutateAsync, isPending } = useMutation<
    CartCreateItemResponse,
    AxiosError,
    SaveEsalesItemsToCartInput
  >({ mutationFn: post });
  const saveEsalesItemsToCart = useCallback(
    async (request: SaveEsalesItemsToCartInput) => {
      const data = await mutateAsync(request);
      invalidateCheckoutData();
      return data;
    },
    [invalidateCheckoutData, mutateAsync]
  );

  return { saveEsalesItemsToCart, isPending };
};

export default useSaveEsalesItemsToCart;
