import { Button, color, TextInput } from "@tele2/t2-ui-kit";
import Circle from "@tele2/t2-ui-kit/build/Icon/icons/Circle";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useCreateCartItem, {
  CartCreateItemResponse,
} from "../../../../../api/self-service/cart/create-items/useCreateCartItem";
import { EditComponentsMode } from "../../../../../api/self-service/edit-components-model/useEditComponentsModel";
import {
  getReferrer,
  ProductItem,
  ProductType,
} from "../../../../../api/self-service/esales-product-catalog-model/useEsalesProductCatalogModel";
import useSaveEsalesItemsToCart from "../../../../../api/self-service/save-esales-items-to-cart/useSaveEsalesItemsToCart";
import { useWideMessage } from "../../../../../components/Message/Message";
import {
  AddButtonWrapper,
  StyledPrice,
  StyledStrong,
} from "../../../../../components/Product";
import ImageContainer from "../../../../../components/Product/ImageContainer";
import StockBalance from "../../../../../components/Product/StockBalance";
import { formatToLocalNumber } from "../../../../../utils/number-formatters";
import useHighLight from "../../../../../utils/useHighlight";
import {
  useProductLink,
  useProductSearch,
  useSalesProjectId,
} from "../../ProductCatalogContext";
import EditOppRowLink from "../EditOppRowLink";

interface ProductCatalogListItemProps {
  hasSelfService?: boolean;
  baseSubsId: number;
  item: ProductItem;
  mode: EditComponentsMode;
  relatedSubsId?: number;
}

export const StyledQuantityInput = styled(TextInput)`
  width: 80px !important;
`;

const StyledProductLink = styled(Link)`
  color: ${color.BRAND_BLACK};
  padding-bottom: 5px;
`;

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledLinkSpan = styled.span`
  color: ${color.FUNCTIONAL_BLUE};
  cursor: pointer;
`;

const DESCRIPTION_MAX_LENGTH = 50;

const ProductCatalogListItem: React.FC<ProductCatalogListItemProps> = ({
  item,
  baseSubsId,
  hasSelfService,
  mode,
  relatedSubsId,
}) => {
  const { t } = useTranslation();
  const productLink = useProductLink();
  const [salesProjectId, setSalesProjectId] = useSalesProjectId();
  const wideMessage = useWideMessage();
  const [qty, setQty] = React.useState<number>(1);
  const [hideProdDescription, setHideProdDescription] = React.useState(
    Number(item.prodDescription?.length) > DESCRIPTION_MAX_LENGTH
  );

  const [createItemResponse, setCreateItemResponse] =
    React.useState<CartCreateItemResponse>();

  React.useEffect(() => {
    setHideProdDescription(
      Number(item.prodDescription?.length) > DESCRIPTION_MAX_LENGTH
    );
  }, [item]);

  const { saveEsalesItemsToCart, isPending: addingProductItem } =
    useSaveEsalesItemsToCart();

  const { createCartItem, isPending: addingServiceItem } = useCreateCartItem();

  const [search] = useProductSearch();
  const highLightSearch = useHighLight(search);

  return (
    <>
      <ImageContainer
        productCode={item.productCode}
        prodName={item.prodName}
        type={item.type}
        prodImgPublicId={item.prodImgPublicId ?? ""}
        pictureUrls={item.pictureUrls ?? []}
        pictureUrl={item.pictureUrl ?? ""}
        baseSubsId={baseSubsId}
      />
      <TextContentWrapper>
        <>
          {item.type === ProductType.CATALOG ? (
            <>
              <StyledProductLink
                to={`${productLink}?prodId=${encodeURIComponent(
                  item.productCode
                )}&baseSubsId=${baseSubsId}`}
              >
                <strong className="product-name searchable-field">
                  {highLightSearch(item.prodName ?? "")}
                </strong>
              </StyledProductLink>
              <StockBalance {...item} />
              <strong>
                {Boolean(item.priceOneTime) || Boolean(item.priceRecurring) ? (
                  <StyledPrice align="left">
                    {item.priceRecurring ? (
                      <>{`${formatToLocalNumber(
                        Number(item.priceRecurring),
                        2,
                        2
                      )} ${t("module.self.service.price.per.month", [
                        item.currency,
                      ])}`}</>
                    ) : (
                      <>{`${formatToLocalNumber(
                        Number(item.priceOneTime),
                        2,
                        2
                      )} ${item.currency}`}</>
                    )}
                  </StyledPrice>
                ) : (
                  <>
                    <Circle color="ALERT_RED" xxs />
                    {` ${t("module.subscription.product_not_in_stock")}`}
                  </>
                )}
              </strong>
            </>
          ) : (
            <>
              {item.prodName && (
                <StyledStrong className="product-name searchable-field">
                  {highLightSearch(item.prodName)}
                </StyledStrong>
              )}
              {item?.prodDescription && hideProdDescription ? (
                <small>
                  {item.prodDescription
                    ?.trim()
                    .slice(0, DESCRIPTION_MAX_LENGTH) || ""}
                  {"..."}
                  <StyledLinkSpan
                    onClick={() => {
                      setHideProdDescription(false);
                    }}
                  >
                    {t("general.show.more")}
                  </StyledLinkSpan>
                </small>
              ) : (
                <small>{highLightSearch(item.prodDescription || "")}</small>
              )}
              <StockBalance {...item} />
              <StyledPrice align="left">
                {item.priceSetting === "SHOW_FROM_PRICES" &&
                  `${t("general.from")} `}
                {item.priceRecurring ? (
                  <span>{`${item.priceRecurring} ${t(
                    "module.self.service.price.per.month",
                    [item.currency]
                  )}`}</span>
                ) : (
                  item.priceSetting !== "NOT_VISIBLE" && (
                    <span>{`${item.priceOneTime} ${item.currency}`}</span>
                  )
                )}
              </StyledPrice>
            </>
          )}
        </>

        {hasSelfService && (
          <>
            <AddButtonWrapper>
              <StyledQuantityInput
                label={t("module.self.service.price.onetime.quantity")}
                value={qty}
                id={`quantity-${item.productCode}`}
                data-cy={`quantity-${item.productCode}`}
                inProgress={addingProductItem}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!isNaN(Number(e.target.value))) {
                    setQty(Number(e.target.value));
                  }
                }}
              />
              <Button
                data-testid={"btnAddItem"}
                xxs
                className={`btnAddItem-${item.productCode}`}
                data-cy={`btnAddItem-${item.productCode}`}
                onClick={async () => {
                  if (item.type === ProductType.CATALOG) {
                    const data = await saveEsalesItemsToCart({
                      baseSubsId,
                      mode,
                      productCatalogueRowId: "",
                      quantity: qty,
                      referrer: encodeURIComponent(getReferrer()),
                      salesProjectId,
                      vismaProductCode: item.productCode,
                      identity: !item.allowAmountOnLine ?? false,
                    });
                    setSalesProjectId(data.salesProjectId);
                    if (data.oppRowId > 0) {
                      setCreateItemResponse(data);
                    }
                    wideMessage({
                      message: t("module.self.service.cart.updated"),
                      theme: "positive",
                      interval: 4000,
                    });
                  } else if (item.id && item.type === ProductType.SERVICE) {
                    const data = await createCartItem({
                      mode,
                      oppTypeId: item.id,
                      prodId: Number(item.productCode),
                      quantity: qty,
                      referrer: encodeURIComponent(getReferrer()),
                      relatedSubsId: relatedSubsId || 0,
                      salesProjectId,
                      subsId: baseSubsId,
                    });
                    setSalesProjectId(data.salesProjectId);
                    if (data.oppRowId > 0) {
                      setCreateItemResponse(data);
                    }
                    wideMessage({
                      message: t("module.self.service.cart.updated"),
                      theme: "positive",
                      interval: 4000,
                    });
                  }
                }}
                disabled={Boolean(
                  (!item.priceRecurring &&
                    !item.priceOneTime &&
                    item.type === ProductType.CATALOG) ||
                    addingProductItem ||
                    addingServiceItem
                )}
              >
                {addingProductItem || addingServiceItem ? (
                  <span className="fa fa-spinner fa-spin" />
                ) : (
                  t("general.choose")
                )}
              </Button>
              <LinkWrapper>
                {createItemResponse && (
                  <EditOppRowLink
                    params={createItemResponse}
                    onClose={() => {
                      setCreateItemResponse(undefined);
                    }}
                  />
                )}
              </LinkWrapper>
            </AddButtonWrapper>
          </>
        )}
      </TextContentWrapper>
    </>
  );
};

export default ProductCatalogListItem;

const LinkWrapper = styled.div`
  position: absolute;
`;
