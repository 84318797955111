import classNames from "classnames";
import * as React from "react";

interface DropdownTableProps<T> {
  rows: T[];
  columns: { id: number; name: string }[];
  onSelect: (row: T) => void;
  format: (row: T) => React.ReactNode;
}

interface DropdownTrProps {
  selectable?: boolean;
  id: number;
}

const DropdownTable = <T extends DropdownTrProps>(
  props: React.PropsWithChildren<DropdownTableProps<T>>
): JSX.Element => {
  const { rows, onSelect, format, columns } = props;
  const [selected, setSelected] = React.useState<number>(-1);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTableElement>) => {
    if (e.key === "ArrowDown") {
      setSelected(selected >= rows.length - 1 ? 0 : selected + 1);
    } else if (e.key === "ArrowUp") {
      setSelected(selected <= 0 ? rows.length - 1 : selected - 1);
    } else if (e.key === "Enter") {
      onSelect(rows[selected]);
    }
  };
  const tableClassNames = classNames("table", "table-striped", "table-hover");
  return (
    <table className={tableClassNames} onKeyDown={handleKeyDown} tabIndex={0}>
      <thead>
        <tr className="combo-box-disabled">
          {columns?.map((column) => (
            <th key={column.id}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          const classes = classNames(i === selected ? "active" : "");
          return (
            <tr
              key={row.id}
              role={"option"}
              aria-selected={i === selected}
              className={`${!row.selectable ? "combo-box-disabled" : classes}`}
              onClick={() => {
                if (row.selectable) {
                  setSelected(i);
                  onSelect(row);
                }
              }}
            >
              {format(row)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DropdownTable;
