import { color, DropDown } from "@tele2/t2-ui-kit";
import { PageLimitInfo } from "./PageLimit";
import * as React from "react";
import { DropDownValue } from "../../Routes/SelfService/ProductCatalog/ProductCatalogContainer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DropDownWrapper = styled.div`
  display: flex;
`;

const LabelWrapper = styled(DropDownWrapper)`
  margin-right: 8px;
  flex-direction: column;
  justify-content: center;
`;

export const StyledPageLimitDropDown = styled(DropDown)`
  > div#t2-dropdown-undefined {
    height: 32px;
    font-size: 14px;
    padding: 7px 30px 14px 14px;
    border: ${color.BRAND_PREMIUM_GREY} 1px solid;
    border-radius: 4px;
  }
  > div#t2-dropdown-undefined:after {
    top: 5px;
  }
`;

const ConcisePageLimit: React.FC<PageLimitInfo> = ({
  limit,
  setLimit,
  pageLimits = [10, 20, 50, 100],
}) => {
  const { t } = useTranslation();
  const pageLimitsDropDownArray: DropDownValue[] = pageLimits.map((item) => {
    return {
      dropdownLabel: String(item),
      option: item,
    };
  });

  const handlePageLimitSelect = (value: unknown) => {
    setLimit(Number(value));
  };

  const findOptionByValue = (value: unknown, list?: DropDownValue[]) => {
    return list?.find((item) => {
      return Number(item.option) === value;
    });
  };

  const getSelectedPageLimit = findOptionByValue(
    limit,
    pageLimitsDropDownArray
  );
  return (
    <DropDownWrapper>
      <LabelWrapper>{t("general.page.size")}</LabelWrapper>
      <StyledPageLimitDropDown
        options={pageLimitsDropDownArray}
        onSelect={(value: unknown) => handlePageLimitSelect(value)}
        selectedOption={getSelectedPageLimit}
        widthCss={"65px"}
      />
    </DropDownWrapper>
  );
};
export default ConcisePageLimit;
