import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface InvoiceRecipientRequest {
  oppRowIds: number[];
  filter: string;
  salesProjectId: number;
}

export interface InvoiceRecipientOption {
  id: number;
  name: string;
  level: number;
  allowNewChild: boolean;
  selectable: boolean;
}

const getInvoiceRecipient = async (request: InvoiceRecipientRequest) => {
  const { data } = await axios.get<InvoiceRecipientOption[]>(
    "/api/self-service/invoice-recipient",
    {
      params: { ...request, oppRowIds: request.oppRowIds.join(",") },
    }
  );
  return data;
};

const useInvoiceRecipient = (
  request: InvoiceRecipientRequest,
  enabled: boolean
) => {
  return useQuery<InvoiceRecipientOption[], AxiosError>({
    queryKey: ["self-service-invoice-recipient", request],
    queryFn: () => getInvoiceRecipient(request),
    enabled,
  });
};

export default useInvoiceRecipient;
