import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { OpportunityClassification, OpportunityTypeEntry } from "./cart-types";

export interface AvailableItemsParams {
  salesProjectId?: number;
  subsId?: number;
  sessionId?: number;
  classifications: OpportunityClassification[];
}

// TODO remove
const serializeParams = (p: AvailableItemsParams): string => {
  return (Object.keys(p) as Array<keyof AvailableItemsParams>)
    .reduce<string[]>((acc, key) => {
      if (Array.isArray(p[key])) {
        return acc.concat((p[key] as unknown[]).map((v) => `${key}=${v}`));
      } else {
        return acc.concat(`${key}=${p[key]}`);
      }
    }, [])
    .join("&");
};

const get = async (params: AvailableItemsParams) => {
  // TODO change to to only params
  const { data } = await axios.get<OpportunityTypeEntry[]>(
    "/api/self-service/cart/available-items?" + serializeParams(params)
  );
  return data;
};

const QUERY_KEY = "available-items";
export const usePrefetch = (): ((params: AvailableItemsParams) => void) => {
  const queryClient = useQueryClient();
  return useCallback(
    (params: AvailableItemsParams) => {
      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY, params],
        queryFn: () => get(params),
      });
    },
    [queryClient]
  );
};

const useAvailableItems = (params: AvailableItemsParams, enabled: boolean) => {
  return useQuery<OpportunityTypeEntry[], AxiosError>({
    queryKey: [QUERY_KEY, params],
    queryFn: () => get(params),
    enabled,
  });
};
export default useAvailableItems;

export const useRemoveAvailableItems = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.removeQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};
