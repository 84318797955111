import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { OpportunityRowContainer } from "./checkout/useSalesProjectOppRows";
import { CartActionPermissions } from "./checkout/useSalesProjectSummaryValidation";

export interface CustomerCartModel {
  salesProjectId: number;
  container: OpportunityRowContainer;
  permissions: CartActionPermissions;
  statusDescription: string;
  roleType: string;
  activeSalesProject: boolean;
  statusActive: boolean;
}

interface CustomerCartRequestParams {
  salesProjectId?: number;
  subRoleType?: string;
}

const get = async (
  params: CustomerCartRequestParams
): Promise<CustomerCartModel> => {
  const { data } = await axios.get("/api/self-service/customer-cart", {
    params,
  });
  return data;
};

const CUSTOMER_CART_QUERY_KEY = "customerCart";
const useGetCustomerCart = (params: CustomerCartRequestParams) => {
  return useQuery<CustomerCartModel, AxiosError>({
    queryKey: [CUSTOMER_CART_QUERY_KEY, params],
    queryFn: () => get(params),
    staleTime: 1000, // Short stale time to always fetch new data when opening the mini cart
  });
};
export default useGetCustomerCart;

export const useInvalidateCustomerCart = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [CUSTOMER_CART_QUERY_KEY] });
  }, [queryClient]);
};
