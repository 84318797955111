import * as React from "react";
import { AttributeProps } from "../AttributeRow";

const UrlAttribute: React.FC<AttributeProps> = (props) => {
  const {
    subsId,
    prodCompAttrId,
    attrData,
    size,
    maxLength,
    isMatrixCell,
    required,
  } = props;
  return (
    <div className="form-group">
      <input
        maxLength={maxLength}
        className={`form-control ${
          isMatrixCell ? "attr-matrix-value" : "attr-value"
        }${required ? " required" : ""}`}
        id={`attr_${subsId}_${prodCompAttrId}`}
        name={`attr_${subsId}_${prodCompAttrId}`}
        size={size || 20}
        type="text"
        value={attrData || ""}
        readOnly
      />
    </div>
  );
};

export default UrlAttribute;
